import styled, { css } from "styled-components";
import { COLORS, SIZES } from "theme";
import { TypographyProps } from "./Typography.d";

const typography = css<TypographyProps>`
  ${({ color }) => color && `color: ${color};`}
  font-family: "Playfair Display", sans-serif;
  font-weight: ${({ weight }) => SIZES.fontWeight[weight || "regular"]};
  margin: 0;
`;

export const Header1 = styled.h1`
  ${typography}
  font-size: 21px;
  line-height: 44px;
  font-weight: 600;

  ${SIZES.media.phone} {
    font-size: 30px;
  }
`;

export const Header2 = styled.h2`
  ${typography}
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 1px;

  ${SIZES.media.phone} {
    font-size: 26px;
    line-height: 37px;
  }
`;

export const Header3 = styled.h3`
  ${typography}
  font-family: "Playfair Display", sans-serif;
  font-size: 22px;
  line-height: 36px;
  font-weight: 500;
`;

export const Header4 = styled.h4`
  ${typography}
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 29px;
  font-weight: 500;
`;

export const Subtitle = styled.h4`
  ${typography}
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
`;

export const Body = styled.p`
  ${typography}
  font-family: "Inter", sans-serif;
  font-size: 13px;
  line-height: 24px;

  ${SIZES.media.phone} {
    font-size: 16px;
  }
`;

export const Caption = styled.span`
  ${typography}
  font-family: "Inter", sans-serif;
  font-size: 12px;
  line-height: 30px;

  ${SIZES.media.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const Overline = styled.span`
  ${typography}
  font-family: "Inter", sans-serif;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: ${SIZES.fontWeight.regular};
`;

export const Link = styled.span`
  ${typography}
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${SIZES.fontWeight.bold};
  color: ${COLORS.accent.primary};
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.accent.primary};
`;
