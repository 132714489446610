import { Box } from "components/Box";
import { FC, useEffect, useState } from "react";
import {
  approveReservation,
  approveReservationChange,
  cancelReservation,
  fetchReservations,
  rejectReservation,
} from "services/Bookings";
import { Reservation } from "types/booking";
import { BookingStatus } from "types/enums";
import { ReservationListItem } from "../ReservationListItem";

interface Props {
  filter: "upcoming" | "past" | "cancelled";
}

const ReservationsList: FC<Props> = ({ filter }) => {
  const [reservations, setReservations] = useState<Array<Reservation>>([]);
  useEffect(() => {
    const getBookings = async () => {
      const response = await fetchReservations(filter);
      setReservations(response.parsedBody);
    };
    getBookings();
  }, [filter]);

  const handleApproveReservation = async (
    id: string,
    status: BookingStatus
  ) => {
    const response =
      status === BookingStatus.WAITING_FOR_CHANGES_APPROVAL
        ? await approveReservationChange(id)
        : await approveReservation(id);
    if (response.ok) {
      const updatedReservations = reservations.map((reservation) => {
        if (reservation.id === id) {
          return {
            ...reservation,
            is_cancellable: true,
            status: BookingStatus.APPROVED_BY_ADMIN,
          };
        }
        return reservation;
      });
      setReservations(updatedReservations);
    }
    return response;
  };

  const handleRejectReservation = async (id: string, status: BookingStatus) => {
    const response =
      status === BookingStatus.NEW
        ? await rejectReservation(id)
        : await cancelReservation(id);

    if (response.status === 200) {
      const updatedReservations = reservations.filter((reservation) => {
        return reservation.id !== id;
      });
      setReservations(updatedReservations);
    }
    return response;
  };

  const handleCancelReservation = async (id: string) => {
    const response = await cancelReservation(id);
    if (response.status === 200) {
      const updatedBooking = reservations.filter((booking) => {
        return booking.id !== id;
      });
      setReservations(updatedBooking);
    }
  };

  return (
    <Box>
      {reservations.map((reservation) => {
        return (
          <ReservationListItem
            id={reservation.id}
            house_id={reservation.house_id}
            key={reservation.id}
            is_cancellable={reservation.is_cancellable}
            is_main_owner={reservation.is_main_owner}
            check_in_date={reservation.check_in_date}
            check_out_date={reservation.check_out_date}
            adults_num={reservation.adults}
            children_num={reservation.children}
            main_photo={reservation.house_image}
            title={reservation.house_title}
            price={reservation.price}
            price_without_discount={reservation.price_without_discount}
            reservation_status={reservation.status}
            guest_full_name={
              reservation.guest.name + " " + reservation.guest.last_name
            }
            guest_id={reservation.guest.id}
            refund={reservation.refund}
            guest_photo={reservation.guest.avatar}
            guest_gender={reservation.guest.gender}
            guest_no_connection={`${reservation.guest.connections}`}
            handleCancelReservation={handleCancelReservation}
            approveReservation={handleApproveReservation}
            rejectReservation={handleRejectReservation}
            extras={reservation.extras}
          />
        );
      })}
    </Box>
  );
};

export default ReservationsList;
