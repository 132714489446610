import { Box, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const MainContainer = styled(Box)`
  grid-template-columns: auto 416px;

  ${SIZES.media.tablet} {
    margin: ${pxToRem(31)} ${pxToRem(32)};
  }

  ${SIZES.media.desktop} {
    margin: ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(100)};
  }
`;
export const HeaderContainer = styled(Typography)`
  margin-top: ${pxToRem(32)};
  margin-bottom: ${pxToRem(32)};
  font-size: 18px;

  ${SIZES.media.desktop} {
    font-size: 30px;
    margin-top: ${pxToRem(60)};
    margin-bottom: ${pxToRem(60)};
  }
`;

export const ContentContainer = styled(Box)`
  grid-template-columns: unset;

  ${SIZES.media.tablet} {
    grid-template-columns: 405px auto;
  }
`;
