import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS } from "theme";
import { StaticChipContainerType } from "./Chip.d";
import { ChipVariant } from "./Chip.enums";
import { ReactComponent as IconClose } from "assets/icons/icon_x_square.svg";
import { Typography } from "components/Typography";

export const StyledContainer = styled(Box)<StaticChipContainerType>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? COLORS.stroke.main};
  width: fit-content;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "unset")};
  ${({ variant, selected }) =>
    variant === ChipVariant.INTEREST &&
    css`
      border-radius: 100px;
      padding: ${pxToRem(9)};
      background-color: ${selected
        ? COLORS.stroke.main
        : COLORS.typography.secondary.main};

      border: 1px solid ${COLORS.stroke.main};

      & span {
        font-size: 14px;
        text-transform: capitalize;
      }
    `};
`;

export const FriendLabel = styled(Typography)`
  text-transform: initial !important;
`;

export const StyledIconClose = styled(IconClose)`
  width: 18px;
  height: 18px;
  padding-left: 8px;
  cursor: pointer;

  &:hover {
    fill: ${COLORS.accent.hover};
  }
`;

export const FriendChipContainer = styled(Box)`
  direction: ltr;
`;
