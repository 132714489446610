import { Box, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const Content = styled(Box)`
box-sizing: border-box;
background-color: ${COLORS.typography.secondary.main};
padding: ${pxToRem(32)} ${pxToRem(14)};
width: ${pxToRem(284)};
height: ${pxToRem(368)};

${SIZES.media.tablet} {
  width: ${pxToRem(550)};
  height: ${pxToRem(394)};
  padding: ${pxToRem(64)}};
}
`;
export const TextTypography = styled(Typography)`
  text-align: center;
`;
