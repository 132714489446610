import { Notification, NotificationType } from "types/notification";
import {
  BOOKINGS_PAGE,
  FRIENDS_PAGE,
  HOUSE_REDIRECT,
  RESERVATIONS_PAGE,
} from "urls/frontend";

export const getRedirectPathname = (notification: Notification) => {
  const { code } = notification;
  if (code < 200) return FRIENDS_PAGE;
  else if (code < 300) return RESERVATIONS_PAGE;
  else if (code < 400) return BOOKINGS_PAGE;
  else if (
    code < 500 &&
    notification.details &&
    "house" in notification.details
  )
    return HOUSE_REDIRECT(notification.details.house);
  return "";
};

export const renderDateToGBLocaleString = (date: Date) => {
  return date.toLocaleString("en-GB", {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
  });
};
