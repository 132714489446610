import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { StaticContainerType } from "./ImageInput.d";
import { Button } from "components/Button";

export const StyledMainContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.phone} {
    width: 305px;
  }
`;

export const StyledContainer = styled(Box)<StaticContainerType>`
  border: 1px solid
    ${({ error }) => (error ? COLORS.stroke.error : COLORS.stroke.main)};
  justify-items: center;
  position: relative;
`;

export const StyledInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const StyledUploadContainer = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: ${pxToRem(10)} 0 0 0;
  width: 44px;
  height: 44px;

  &:enabled {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;
