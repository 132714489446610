import * as Yup from "yup";

export const IMG_MAX_SIZE = 1024 * 1024 * 20; // 20 MB

export const imageSchema = Yup.mixed()
  .required("Please upload the image.")
  .nullable(true)
  .test("isBelowMaxSize", "The file is too big", (value: File) => {
    if (value) {
      return value.size < IMG_MAX_SIZE;
    }
    return true;
  });

export const formValidationSchema = Yup.object({
  houseOwners: Yup.array().required("Please, add at least one house owner."),
  avatar: imageSchema,
  tellUsAboutYourself: Yup.string()
    .required("Please, insert the text.")
    .min(50, "Must be at least 50 characters long"),
});
