import styled from "styled-components";
import { COLORS } from "theme";
import { Link } from "components/Link";
import { hexToRGB, pxToRem } from "global/utils";
import { StyledPaginationItemProps } from "./PaginationItem.d";

export const StyledPaginationItem = styled(Link)<StyledPaginationItemProps>`
  padding: ${pxToRem(11)} ${pxToRem(13)};
  border: ${({ isCurrent }) =>
    isCurrent
      ? `1px solid ${COLORS.accent.primary}`
      : `1px solid ${COLORS.stroke.main}`};
  color: ${({ isCurrent }) =>
    isCurrent ? COLORS.accent.primary : COLORS.typography.headline};
  ${({ isCurrent }) => isCurrent && "pointer-events: none"};
  background-color: ${({ isCurrent }) =>
    isCurrent ? hexToRGB(COLORS.accent.primary, "0.1") : "none"};
  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;
