import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled, { css } from "styled-components";
import { COLORS, SIZES } from "theme";
import { StyledContainerProps } from "./Map.d";

export const StyledContainer = styled(Box)<StyledContainerProps>`
  width: 100%;
  height: ${({ draggable }) => (draggable ? "275px" : "547px")};
  .map {
    -webkit-transform: translateZ(0);
  }
  .gm-style-iw-c {
    top: ${({ draggable }) => (draggable ? 0 : "-32px")};
    left: ${({ draggable }) => (draggable ? "128px" : "140px")};
    ${({ draggable }) => draggable && "overflow: unset"};
    border-radius: 0;

    ${SIZES.media.phone} {
      top: ${({ draggable }) => (draggable ? "-5px" : "-31px")};
      left: ${({ draggable }) => (draggable ? "1.25%" : "1.94%")};
    }

    ${({ draggable }) =>
      draggable &&
      css`
        &:before {
          content: " ";
          position: absolute;
          top: 35%;
          left: -19px;
          border: 10px solid transparent;
          height: 0;
          width: 0;
          display: block;
          transform: translateX(0) translateY(-24%);
          border-right-color: ${COLORS.typography.secondary.main};
        }
      `}
  }
  .gm-style .gm-style-iw-tc::after {
    display: none;
  }
`;

export const StyledEditMapTooltip = styled(Typography)`
  font-size: 12px;
`;
