import { HouseItem } from "components/HousesList/HousesList.d";
import {
  FAVORITES_ENDPOINT,
  FAVORITES_UPDATE_ENDPOINT,
  HOUSES_ENDPOINT,
  MY_HOUSES_ENDPOINT,
} from "urls/api";
import { HouseStatusVariant } from "views/MyHouse/HouseStatuses.enums";
import fetchApi from "./fetchApi";

interface Houses {
  houses: Array<HouseItem>;
  filters: Array<Filter>;
  total_houses: number;
}

interface HousesResponse extends Response {
  results: Houses;
}

export interface Filter {
  name: HouseStatusVariant;
  counter: number;
  country_code: string;
}

interface MyHousesResponse extends Houses {
  filters: Filter[];
}

export const fetchHouses = (queryParams?: URLSearchParams) => {
  let queryString = "";
  if (queryParams) {
    queryString = queryParams.toString();
  }

  const fetchHouses = fetchApi<HousesResponse>(
    `${HOUSES_ENDPOINT}?${queryString}`
  );
  return fetchHouses;
};

export const fetchMyHouses = (status?: string | null) => {
  const fetchHouses = fetchApi<MyHousesResponse>(
    `${MY_HOUSES_ENDPOINT}${status ? `?status=${status}` : ""}`
  );
  return fetchHouses;
};

export const fetchFavoritesHouses = () => {
  const fetchHouses = fetchApi<MyHousesResponse>(FAVORITES_ENDPOINT);
  return fetchHouses;
};

export const fetchChangeFavoriteHouse = (id: string) => {
  const fetchFavorite = fetchApi<Response>(FAVORITES_UPDATE_ENDPOINT(id), {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  });
  return fetchFavorite;
};
