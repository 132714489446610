import { Box } from "components/Box";
import { FC } from "react";
import { ReactComponent as DoneIcon } from "assets/icons/icon_done.svg";
import {
  StyledDoneIconContainer,
  StyledContent,
  StyledButton,
} from "./ConfirmSendingRequest.styled";
import { COLORS } from "theme";
import { Typography } from "components/Typography";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { useNavigate } from "react-router";
import { MAIN_PAGE } from "urls/frontend";
import { getLanguage } from "utils/getLanguage";
import { useProfile } from "context/Profile";
import translation from "./translation.json";
import { ConfirmSendingRequestProps } from "./ConfirmSendingRequest.d";

export const ConfirmSendingRequest: FC<ConfirmSendingRequestProps> = ({
  title,
  description,
}) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const backToHome = () => {
    profile?.fetchProfileData();
    navigate(MAIN_PAGE);
  };

  return (
    <Box display="grid" gridGap={2} justifyItems="center" mt={3.75}>
      <StyledDoneIconContainer>
        <DoneIcon stroke={COLORS.background.main} />
      </StyledDoneIconContainer>
      <Typography variant="h2">{title}</Typography>
      <StyledContent variant="body">{description}</StyledContent>
      <StyledButton
        onClick={backToHome}
        leftIcon={
          <ArrowIcon
            stroke={COLORS.background.boxes}
            fill="none"
            width="20px"
            height="20px"
            style={{ transform: "rotate(180deg)" }}
          />
        }
      >
        {translation["backToHome"][selectedLanguage]}
      </StyledButton>
    </Box>
  );
};
