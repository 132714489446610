import { Booking, Reservation } from "types/booking";
import {
  APPROVE_CHANGES_RESERVATION,
  APPROVE_RESERVATION,
  CANCEL_RESERVATION,
  GET_BOOKINGS,
  GET_RESERVATIONS,
  PAY_BALANCE,
  PAY_DEPOSIT,
  REJECT_RESERVATION,
} from "urls/api";
import fetchAPI from "./fetchApi";

interface PayingResponse {
  link: string;
}

export const fetchReservations = (filter: string) => {
  const fetchReservations = fetchAPI<Array<Reservation>>(
    `${GET_RESERVATIONS}${`?filter=${filter}`}`
  );
  return fetchReservations;
};

export const fetchBookings = (filter: string) => {
  const fetchBookings = fetchAPI<Array<Booking>>(
    `${GET_BOOKINGS}${`?filter=${filter}`}`
  );
  return fetchBookings;
};

export const approveReservation = (id: string) => {
  const response = fetchAPI(APPROVE_RESERVATION(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};

export const approveReservationChange = (id: string) => {
  const response = fetchAPI(APPROVE_CHANGES_RESERVATION(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};

export const cancelReservation = (id: string) => {
  const response = fetchAPI(CANCEL_RESERVATION(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};

export const rejectReservation = (id: string) => {
  const response = fetchAPI(REJECT_RESERVATION(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};

export const payBalance = (id: string) => {
  const response = fetchAPI<PayingResponse>(PAY_BALANCE(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};

export const payDeposit = (id: string) => {
  const response = fetchAPI<PayingResponse>(PAY_DEPOSIT(id), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  return response;
};
