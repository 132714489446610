import { FC } from "react";
import ReactDOM from "react-dom";
import { useShortcuts } from "global/hooks/useShortcuts";
import { BookingModalProps } from "./BookingModal.d";
import {
  Wrapper,
  StyledModal,
  CloseButton,
  Content,
  Backdrop,
  StyledTitle,
  StyledHeader,
} from "./BookingModal.styled";
import { Chip } from "components/Chip";
import { COLORS } from "theme";

export const BookingModal: FC<BookingModalProps> = ({
  open,
  onClose,
  modalContent,
  isPrivate = false,
  discount,
}) => {
  useShortcuts(open, onClose);

  const modal = (
    <>
      <Backdrop onClick={onClose} />
      <Wrapper
        aria-modal
        aria-labelledby={
          isPrivate ? "Reservations calendar" : "Booking details"
        }
        tabIndex={-1}
        role="dialog"
      >
        <StyledModal>
          <StyledHeader
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
          >
            <CloseButton onClick={onClose} />
            <StyledTitle variant="h4">
              {isPrivate ? "Reservations calendar" : "Booking details"}
            </StyledTitle>
            {!!discount && discount > 0 && (
              <Chip
                label={`-${discount}%`}
                color={COLORS.success.main}
                backgroundColor={COLORS.success.hover}
              />
            )}
          </StyledHeader>
          <Content>{modalContent}</Content>
        </StyledModal>
      </Wrapper>
    </>
  );
  return open ? ReactDOM.createPortal(modal, document.body) : null;
};
