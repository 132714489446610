import React, { FC } from "react";
import { Typography } from "../Typography";
import { ReactComponent as BulletIcon } from "assets/icons/bullet_icon.svg";
import { BulletListProps } from "./BulletList.d";
import { StyledList, ListOption, ListBulletIcon } from "./BulletList.styled";

export const BulletList: FC<BulletListProps> = ({ items, icon }) => {
  const Icon = icon ? icon : BulletIcon;

  return (
    <StyledList>
      {items.map((item, index) => (
        <ListOption key={`bullet-${name}-${index}`}>
          <ListBulletIcon>
            <Icon />
          </ListBulletIcon>
          <Typography variant="body">{item}</Typography>
        </ListOption>
      ))}
    </StyledList>
  );
};
