import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { hexToRGB } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import CloseIcon from "assets/icons/icon-close.svg";
import { Link } from "components/Link";

export const StyledLink = styled(Link)`
  color: ${COLORS.accent.primary};
  margin-left: 3px;
  font-size: 12px;
`;

export const Wrapper = styled(Box)`
  position: fixed;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  outline: 0;
  width: 100%;
  height: 95vh;
  overflow-y: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ${SIZES.media.phone} {
    height: 90vh;
  }
`;

export const Backdrop = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${hexToRGB(COLORS.typography.headline, "0.2")};
  z-index: 500;
`;

export const StyledModal = styled(Box)`
  z-index: 100;
  background: ${COLORS.typography.secondary.main};
  position: relative;
  margin: auto;
  padding: ${SIZES.spacing(3.7)} ${SIZES.spacing(2.2)} ${SIZES.spacing(
  19
)} ${SIZES.spacing(2.2)}};

  ${SIZES.media.phone} {
    padding: ${SIZES.spacing(7.5)} ${SIZES.spacing(4)} ${SIZES.spacing(17.5)}
    ${SIZES.spacing(4)};
  }
`;

export const StyledHeader = styled(Box)`
  grid-template-columns: 27px 1fr 32px;
`;

export const CloseButton = styled.button`
  width: 27px;
  height: 27px;
  border: 1.5px solid ${COLORS.stroke.main};
  border-radius: 15px;
  background-image: url(${CloseIcon});
  background-position: 50% 50%;
  background-size: 65%;
  background-repeat: no-repeat;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledTitle = styled(Typography)`
  font-family: "Inter", sans-serif;
  justify-self: center;
`;
