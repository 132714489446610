import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledDoneIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${COLORS.accent.primary};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContent = styled(Typography)`
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  color: ${COLORS.typography.headline};
  width: 98%;
  margin-top: ${SIZES.spacing(1)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${SIZES.spacing(2)};
  padding: ${pxToRem(19.5)} ${pxToRem(32)};

  ${SIZES.media.tablet} {
    width: ${pxToRem(214)};
  }
`;
