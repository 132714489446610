import styled, { createGlobalStyle } from "styled-components";
import { Box } from "components/Box";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";
import { StaticTabletsContainerType } from "./BookingCalculator.d";
import { Link } from "components/Link/Link";

export const StyledContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
  width: 350px;
  position: sticky;
  top: 10px;
`;

export const TabletsContainer = styled(Box)<StaticTabletsContainerType>`
  box-shadow: 8px 0px 20px rgba(209, 206, 206, 0.25);
  height: ${pxToRem(72)};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 800;
  background-color: ${COLORS.typography.secondary.main};

  & p,
  & h4 {
    font-size: 15px;
  }
  & button {
    padding: ${pxToRem(12)} ${pxToRem(8)};
  }

  ${SIZES.media.phone} {
    height: ${pxToRem(96)};
    padding: ${pxToRem(18)} ${pxToRem(32)};

    & button {
      padding: ${pxToRem(18)} ${pxToRem(32)};
    }
  }
`;

export const TabletsHolder = styled.div`
  position: relative;
  overflow: hidden;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const StyledContactSupportText = styled(Typography)`
  font-size: 12px;
  margin: 0 2px;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.accent.primary};
  margin-left: 3px;
  font-size: 12px;
`;

export const StyledConfirmationModalContent = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.small};
  }
`;

export const StyledMinPrice = styled(Typography)`
  font-size: 12px;
  opacity: 0.7;
  padding-left: ${pxToRem(16)};
`;
