import { Box } from "components/Box";
import styled from "styled-components";
import { pxToRem } from "global/utils";
import { SIZES } from "theme/sizes";

export const StyledContainer = styled(Box)`
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: ${pxToRem(24)};
  margin-top: ${pxToRem(22)};

  ${SIZES.media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
