import { FC, useState } from "react";
import {
  StyledContainer,
  StyledInput,
  StyledTypography,
  StyledUploadContainer,
} from "./AvatarInput.styled";
import { AvatarInputProps } from "./AvatarInput.d";
import { Box } from "components/Box";
import { Label } from "components/Label";
import { Image } from "components/Image";
import { ReactComponent as AvatarUploadIcon } from "assets/icons/icon_upload-photo.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";
import { COLORS } from "theme";
import { GenderVariant } from "components/Profile/ProfileForm/ProfileForm.enums";
import { CropImageModal } from "components/Modals/CropImageModal";
import { useFormContext, Controller } from "react-hook-form";
import { getDefaultAvatar } from "utils/getDefaultAvatar";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const AvatarInput: FC<AvatarInputProps> = ({
  label,
  disabled = false,
  error,
  id,
  handleRemoveAvatar,
  gender = GenderVariant.GENDER_UNKNOWN,
  avatar,
  setAvatar,
  avatarInputRef,
  control,
}) => {
  const { register, setValue, trigger } = useFormContext();
  const avatarInput = register("profile.avatar");
  const [uncroppedImage, setUncroppedImage] = useState("");
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const onUploadAvatarClick = () => {
    avatarInputRef.current && avatarInputRef?.current.click();
  };

  const onUploadAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setUncroppedImage(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleAvatarInputChange = (file: File) => {
    setAvatar({ file, source: file.name });
    setValue("profile.avatar", file);
    trigger("profile.avatar");
  };

  const cancelCrop = () => {
    if (avatarInputRef?.current) {
      avatarInputRef.current.value = "";
    }
    setUncroppedImage("");
  };

  const removeAvatar = () => {
    handleRemoveAvatar();
    cancelCrop();
    trigger("profile.avatar");
    setValue("profile.avatar", null);
    setAvatar(null);
  };

  return (
    <Box display="grid" gridGap={1.5}>
      {label && (
        <Label htmlFor={id} disabled={disabled} error={error}>
          {error ? error : label}
        </Label>
      )}
      <Controller
        control={control}
        name="profile.avatar"
        render={({ field: { ref } }) => (
          <StyledContainer
            ref={ref}
            display="grid"
            gridGap={1.5}
            px={8}
            py={5}
            error={!!error}
          >
            <Image
              isRound
              variant="avatarLarge"
              src={
                avatar
                  ? URL.createObjectURL(avatar.file)
                  : getDefaultAvatar(gender)
              }
            />
            <StyledInput
              {...avatarInput}
              ref={(e) => {
                avatarInput.ref(e);
                avatarInputRef.current = e;
              }}
              disabled={disabled}
              type="file"
              accept="image/png, image/jpeg, image/svg+xml"
              onChange={onUploadAvatar}
            />
            {avatar ? (
              <StyledUploadContainer
                onClick={removeAvatar}
                display="grid"
                gridAutoFlow="column"
                gridGap={1}
              >
                <DeleteIcon
                  stroke={COLORS.typography.headline}
                  strokeWidth={1.5}
                  fill="none"
                  width="24px"
                  height="24px"
                />
                <StyledTypography
                  variant="body"
                  color={COLORS.typography.headline}
                >
                  Remove photo
                </StyledTypography>
              </StyledUploadContainer>
            ) : (
              <StyledUploadContainer
                display="grid"
                gridAutoFlow="column"
                gridGap={1}
                onClick={onUploadAvatarClick}
              >
                <AvatarUploadIcon />
                <StyledTypography
                  variant="body"
                  color={COLORS.typography.headline}
                >
                  {translation["uploadPhoto"][selectedLanguage]}
                </StyledTypography>
              </StyledUploadContainer>
            )}
          </StyledContainer>
        )}
      />
      <CropImageModal
        imgSrc={uncroppedImage}
        setAvatar={handleAvatarInputChange}
        onCancel={cancelCrop}
      />
    </Box>
  );
};
