import { Chip } from "components/Chip";
import { Image } from "components/Image";
import { FC } from "react";
import { COLORS } from "theme";
import { HOST_URL } from "urls/api";
import { MutualFriends } from "../MutualFriends";
import { FriendCardProps } from "./FriendCard.d";
import {
  StyledContainer,
  StyledFullName,
  StyledButton,
  StyledLocation,
  HouseOwnerContainer,
  AvatarContainer,
  StyledConfirmationModalContent,
  StyledKebabMenu,
} from "./FriendCard.styled";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { FriendCardVariant } from "./FriendCard.enums";
import { getDefaultAvatar } from "utils/getDefaultAvatar";
import { Box } from "components/Box";
import { UserStatus } from "types/enums";
import { ReactComponent as HouseIcon } from "assets/icons/icon_house.svg";
import { useModal } from "global/hooks/useModal";
import { Modal } from "components/Modal";
import { Typography } from "components";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { Button } from "components/Button";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { fetchUpdateFriendOptions } from "services/Friends";
import { toast } from "react-toastify";
import TrustedFriendModal from "components/Modals/TrustedFriendModal/TrustedFriendModal";

export const FriendCard: FC<FriendCardProps> = ({
  friend,
  declineInvitation,
  deactivateEmailInvitation,
  deactivateConnectionInvitation,
  deleteFromFriends,
  sendInvitation,
  sendInvitationReminder,
  acceptInvitation,
  variant,
  onFriendCardClick,
}) => {
  const { isShown, toggle } = useModal();
  const { isShown: isShownTrustedModal, toggle: toggleTrustedModal } =
    useModal();
  const profile = useProfile();
  const onTrustedFriendClick = async () => {
    const response = await fetchUpdateFriendOptions(
      { is_trusted: true },
      friend.id
    );
    if (response.ok) {
      toast.success("Update send successfully");
    } else {
      toast.error("There was an error during updating");
    }
    toggleTrustedModal();
  };
  const fullName = `${friend.name} ${friend.last_name}`;
  const fullLocation =
    friend.profile &&
    [friend.profile.city, friend.profile.country].filter(Boolean).join(", ");
  const containMutualFriends =
    friend.mutual_relations && !!friend.mutual_relations?.relations_num;
  const isHouseOwner = profile?.profileInfo?.is_house_owner || false;

  const onNameClick = () => onFriendCardClick(friend.id);
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  const canAddTrustedFriend =
    isHouseOwner &&
    friend.invited_by_you &&
    !friend.is_owner &&
    !friend.is_trusted;

  const getCardContent = () => {
    switch (variant) {
      case FriendCardVariant.PEOPLE_YOU_MIGHT_KNOW:
        return (
          <>
            <AvatarContainer>
              <Image
                isRound
                src={
                  friend?.profile?.avatar
                    ? `${HOST_URL}${friend.profile.avatar}`
                    : getDefaultAvatar(friend.gender)
                }
                variant="avatarFriend"
              />
              {friend.is_owner && (
                <HouseOwnerContainer>
                  <HouseIcon />
                </HouseOwnerContainer>
              )}
            </AvatarContainer>
            <Box pt={1.5} display="grid" gridGap={1.5} justifyItems="center">
              <StyledFullName variant="body" onClick={onNameClick}>
                {fullName}
              </StyledFullName>
              {containMutualFriends &&
                friend.mutual_relations?.relations_num && (
                  <MutualFriends
                    images={friend.mutual_relations?.avatars ?? []}
                    number={friend.mutual_relations?.relations_num}
                  />
                )}
              <StyledButton
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                onClick={() => sendInvitation(friend.id)}
              >
                Send request
              </StyledButton>
            </Box>
          </>
        );
      case FriendCardVariant.REQUESTS_RECEIVED:
        return (
          <>
            <StyledKebabMenu
              menuOptions={[
                {
                  label: "Decline",
                  value: "Decline",
                  isShown: true,
                  onClick: () =>
                    friend.invitation &&
                    declineInvitation(friend.id, friend.invitation.id),
                },
              ]}
            />
            <AvatarContainer>
              <Image
                isRound
                src={
                  friend?.profile?.avatar
                    ? `${HOST_URL}${friend.profile.avatar}`
                    : getDefaultAvatar(friend.gender)
                }
                variant="avatarFriend"
              />
              {friend.is_owner && (
                <HouseOwnerContainer>
                  <HouseIcon />
                </HouseOwnerContainer>
              )}
            </AvatarContainer>
            <Box pt={1.5} display="grid" gridGap={1.5} justifyItems="center">
              <Chip
                label={`Invite sent ${friend.invitation?.invitation_sent}`}
                color={COLORS.typography.body}
              />
              <StyledFullName variant="body" onClick={onNameClick}>
                {fullName}
              </StyledFullName>
              {(friend.profile?.country || friend.profile?.city) && (
                <StyledLocation variant="overline">
                  {fullLocation}
                </StyledLocation>
              )}
              {containMutualFriends &&
                friend.mutual_relations?.relations_num && (
                  <MutualFriends
                    images={friend.mutual_relations?.avatars ?? []}
                    number={friend.mutual_relations?.relations_num}
                  />
                )}
              <StyledButton
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.SMALL}
                onClick={() =>
                  friend.invitation &&
                  acceptInvitation(friend.id, friend.invitation.id)
                }
              >
                Accept
              </StyledButton>
            </Box>
          </>
        );
      case FriendCardVariant.REQUESTS_SENT:
        return (
          <>
            <StyledKebabMenu
              menuOptions={[
                {
                  label: translation["sendReminder"][selectedLanguage],
                  value: "Send a reminder",
                  isShown: friend.status === UserStatus.INVITED,
                  onClick: () =>
                    friend.invitation &&
                    sendInvitationReminder(friend, friend.email),
                },
                {
                  label: translation["deactivate"][selectedLanguage],
                  value: "Deactivate",
                  isShown: true,
                  onClick: () =>
                    friend.status === UserStatus.INVITED
                      ? deactivateEmailInvitation(friend.email)
                      : friend.invitation &&
                        deactivateConnectionInvitation(friend.invitation?.id),
                },
              ]}
            />
            <AvatarContainer>
              <Image
                isRound
                src={
                  friend?.profile?.avatar
                    ? `${HOST_URL}${friend.profile.avatar}`
                    : getDefaultAvatar(friend.gender)
                }
                variant="avatarFriend"
              />
              {friend.is_owner && (
                <HouseOwnerContainer>
                  <HouseIcon />
                </HouseOwnerContainer>
              )}
            </AvatarContainer>
            <Box pt={1.5} display="grid" gridGap={1.5} justifyItems="center">
              <Chip
                label={`${translation["inviteSent"][selectedLanguage]} ${friend.invitation?.invitation_sent}`}
                color={COLORS.typography.body}
              />
              <StyledFullName variant="body" onClick={onNameClick}>
                {fullName}
              </StyledFullName>
              {(friend.profile?.country || friend.profile?.city) && (
                <StyledLocation variant="overline">
                  {fullLocation}
                </StyledLocation>
              )}
              {containMutualFriends &&
                friend.mutual_relations?.relations_num && (
                  <MutualFriends
                    images={friend.mutual_relations?.avatars ?? []}
                    number={friend.mutual_relations?.relations_num}
                  />
                )}
            </Box>
          </>
        );
      case FriendCardVariant.CONNECTIONS:
        return (
          <>
            <TrustedFriendModal
              isShown={isShownTrustedModal}
              toggle={toggleTrustedModal}
              onTrustedFriendClick={onTrustedFriendClick}
            />
            <Modal isShown={isShown}>
              <StyledConfirmationModalContent>
                <Typography variant="h4">
                  {translation["confirmRemoveUserPartOne"][selectedLanguage]}{" "}
                  {fullName}{" "}
                  {translation["confirmRemoveUserPartTwo"][selectedLanguage]}
                </Typography>
                <Box
                  display="grid"
                  mt={5.6}
                  gridAutoFlow="column"
                  justifyContent="end"
                  gridGap={3}
                  alignItems="center"
                >
                  <Button variant={ButtonVariant.SECONDARY} onClick={toggle}>
                    {translation["cancel"][selectedLanguage]}
                  </Button>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.SMALL}
                    icon={
                      <ArrowIcon
                        stroke={COLORS.stroke.main}
                        fill="none"
                        width="20px"
                        height="20px"
                      />
                    }
                    onClick={() => deleteFromFriends(friend.id)}
                  >
                    {translation["delete"][selectedLanguage]}
                  </Button>
                </Box>
              </StyledConfirmationModalContent>
            </Modal>
            <StyledKebabMenu
              menuOptions={[
                {
                  label: translation["delete"][selectedLanguage],
                  value: "Delete",
                  isShown: true,
                  onClick: toggle,
                },
                {
                  label: translation["trustedFriend"][selectedLanguage],
                  value: "Trusted friend",
                  isShown: canAddTrustedFriend,
                  onClick: toggleTrustedModal,
                },
              ]}
            />
            <AvatarContainer>
              <Image
                isRound
                src={
                  friend?.profile?.avatar
                    ? `${HOST_URL}${friend.profile.avatar}`
                    : getDefaultAvatar(friend.gender)
                }
                variant="avatarFriend"
              />
              {friend.is_owner && (
                <HouseOwnerContainer>
                  <HouseIcon />
                </HouseOwnerContainer>
              )}
            </AvatarContainer>
            <Box pt={1.5} display="grid" gridGap={1.5} justifyItems="center">
              <StyledFullName variant="body" onClick={onNameClick}>
                {fullName}
              </StyledFullName>
              {(friend.profile?.country || friend.profile?.city) && (
                <StyledLocation variant="overline">
                  {fullLocation}
                </StyledLocation>
              )}
              {containMutualFriends &&
                friend.mutual_relations?.relations_num && (
                  <MutualFriends
                    images={friend.mutual_relations?.avatars ?? []}
                    number={friend.mutual_relations?.relations_num}
                  />
                )}
            </Box>
          </>
        );
    }
  };

  return (
    <StyledContainer
      p={2.8}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      {getCardContent()}
    </StyledContainer>
  );
};
