import { Box } from "components/Box";
import { Label } from "components/Label";
import { forwardRef } from "react";
import { COLORS } from "theme";
import { TextareaProps } from "./Textarea.d";
import { StyledTextarea, StyledHelperText } from "./Textarea.styled";

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ id, label, error, helperText, required, ...textareaProps }, ref) => {
    const { disabled, ...otherTextareaProps } = textareaProps;
    return (
      <Box display="grid" gridGap={1.5}>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridGap={1.5}
          justifyContent="start"
        >
          {label && (
            <Label
              htmlFor={id}
              disabled={disabled}
              error={error}
              required={required}
            >
              {error ? error : label}
            </Label>
          )}
          {helperText && (
            <StyledHelperText variant="body" color={COLORS.typography.body}>
              {helperText}
            </StyledHelperText>
          )}
        </Box>
        <StyledTextarea
          ref={ref}
          error={!!error}
          disabled={disabled}
          {...otherTextareaProps}
        />
      </Box>
    );
  }
);

Textarea.displayName = "Textarea";
