import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { Box } from "../../Box";

export const TabletsContainer = styled(Box)`
  width: 100%;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  background-color: ${COLORS.typography.secondary.main};
  height: ${pxToRem(72)};
  position: fixed;
  box-shadow: 8px 0px 20px rgba(209, 206, 206, 0.25);
  z-index: 800;

  & button {
    padding: ${pxToRem(12)} ${pxToRem(8)};
  }

  ${SIZES.media.phone} {
    height: ${pxToRem(96)};

    & button {
      padding: ${pxToRem(18)} ${pxToRem(32)};
    }
  }

  ${SIZES.media.tablet} {
    padding: ${pxToRem(18)} ${pxToRem(32)};
  }
`;

export const TabletsHolder = styled(Box)`
  position: relative;
  overflow: hidden;
`;

export const StyledDesktopContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
  padding: ${SIZES.spacing(3.5)} ${SIZES.spacing(4)};
  min-width: 280px;
`;
