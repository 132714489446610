import { Box, Typography } from "components";
import { Button } from "components/Button";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const NotificationContainer = styled(Box)`
  cursor: pointer;
  text-align: left;
`;

export const Container = styled(Box)`
  grid-column-gap: 16px;
  position: relative;
`;

export const Indicator = styled(Box)`
  position: absolute;
  width: 8px;
  height: 8px;
  left: -20px;
  border-radius: 50%;
  background-color: red;
`;

export const ButtonsContainer = styled(Box)`
  margin: 2px 0;
  grid-column-gap: 10px;
  width: 200px;
`;

export const NotificationButton = styled(Button)`
  padding: 8px;
`;

export const CreateDate = styled(Typography)`
  font-style: normal;
  font-size: 8px;
  line-height: 16px;
  margin-top: 14px;
  color: ${COLORS.typography.headline};
`;

export const UnreadIndicator = styled(Box)`
  position: absolute;
  width: 15px;
  height: 15px;
  left: 46px;
  bottom: 28px;
  background-color: red;
  text-align: center;
  color: white;
  border-radius: 50%;
  verical-align: middle;
`;

export const IndicatorContainer = styled(Box)`
  height: 15px;
  line-height: 15px;
`;

export const UnreadIndicatorTypography = styled(Typography)`
  line-height: 15px;
  vertical-align: middle;
`;

export const NotificationText = styled(Typography)`
  font-size: 13px;
`;

export const DefaultIconContainer = styled(Box)`
  svg {
    width: 40px;
    height: 40px;
  }

  ${SIZES.media.tablet} {
    svg {
      width: 60px;
      height: 60px;
    }
  }
`;
