import { useNotifications } from "context/Notifications";
import { FC } from "react";
import { useNavigate } from "react-router";
import {
  Notification as NotifictaiontData,
  InvitationDetails,
  BookingDetails,
} from "types/notification";
import {
  BOOKINGS_PAGE,
  FRIENDS_REDIRECT,
  HOUSE_REDIRECT,
  RESERVATIONS_PAGE,
} from "urls/frontend";
import { renderDateToGBLocaleString } from "./utils";
import { Box } from "components/Box/Box";
import { HOST_URL } from "urls/api";
import { Image } from "components/Image";
import NotificationMessage from "./NotificationMessage";
import {
  Container,
  CreateDate,
  DefaultIconContainer,
  Indicator,
  NotificationContainer,
} from "./Notifications.styled";
import {
  updateNotificationStatus,
  acceptInvitation,
} from "services/Notifications";
import { ReactComponent as MaleIcon } from "assets/icons/icon_avatar_male.svg";
import { Link } from "components/Link";
import { useProfile } from "context/Profile";

interface NotificationProps {
  notification: NotifictaiontData;
}

const Notification: FC<NotificationProps> = ({ notification }) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const { markNotificationAsClicked } = useNotifications();

  const { id, status, type, created_at, details, code } = notification;
  const { invitation_id, inviter, invitee, accepted } =
    (notification.details as InvitationDetails) ?? {
      invitation_id: "",
      inviter: "",
      invitee: "",
      accepted: false,
    };
  const { guest, check_in_date, check_out_date, booking_code } =
    (notification.details as BookingDetails) ?? {
      guest: "",
      check_in_date: "",
      check_out_date: "",
      booking_code: "",
    };
  const { house } = (notification.details as Pick<BookingDetails, "house">) ?? {
    house: "",
  };

  const checkInDate = renderDateToGBLocaleString(new Date(check_in_date));
  const checkOutDate = renderDateToGBLocaleString(new Date(check_out_date));
  const createdAtDate = new Date(created_at);
  const createdAt = `${renderDateToGBLocaleString(
    createdAtDate
  )} at ${createdAtDate.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;

  const handleAcceptButtonClick = async () => {
    if (accepted) {
      navigate(FRIENDS_REDIRECT);
    } else {
      const response = await acceptInvitation(invitation_id);
      if (response.ok) {
        markNotificationAsClicked(response.parsedBody.id);
      }
    }
  };

  const handleNotificationClick = async () => {
    if (status === 1) {
      const response = await updateNotificationStatus(id);
      if (response.ok) {
        markNotificationAsClicked(response.parsedBody.id);
      }
    }
  };

  const isIndicatorVisible = () => status === 0 || status === 1;

  const pathname = () => {
    let to = "";
    if (
      (code < 200 && profile?.profileInfo?.access_type !== "SELECTED") ||
      code >= 600
    )
      to = FRIENDS_REDIRECT;
    else if (code >= 200 && code < 300) to = RESERVATIONS_PAGE;
    else if (code >= 300 && code < 400) to = BOOKINGS_PAGE;
    else if (
      code >= 400 &&
      code < 500 &&
      notification.details &&
      "house" in notification.details
    )
      to = HOUSE_REDIRECT(notification.details.house);
    return to;
  };

  return (
    <NotificationContainer onClick={handleNotificationClick}>
      <Link to={pathname()} linkColor="black">
        <Container
          display="grid"
          justifyContent="start"
          alignItems="center"
          gridAutoFlow="column"
        >
          {isIndicatorVisible() && <Indicator />}
          {details && details.image ? (
            <Image
              variant="avatarMedium"
              src={`${HOST_URL}${details.image}`}
              alt="Notification img"
              isRound
            />
          ) : (
            <DefaultIconContainer>
              <MaleIcon />
            </DefaultIconContainer>
          )}
          <Box>
            <NotificationMessage
              type={type}
              guest={guest}
              inviter={inviter}
              accepted={accepted}
              invitee={invitee}
              house={house}
              checkInDate={checkInDate}
              checkOutDate={checkOutDate}
              bookingCode={booking_code}
              handleAcceptButtonClick={handleAcceptButtonClick}
            />
            <CreateDate variant="body">{createdAt}</CreateDate>
          </Box>
        </Container>
      </Link>
    </NotificationContainer>
  );
};

export default Notification;
