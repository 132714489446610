import { FC } from "react";
import ReactDOM from "react-dom";
import { Backdrop, Wrapper, StyledModal, CloseButton } from "./Modal.styled";
import { ModalProps } from "./Modal.d";
import { Box } from "components/Box";
import { useShortcuts } from "global/hooks/useShortcuts";
import { ModalAlignVariant } from "./Modal.enums";

export const Modal: FC<ModalProps> = ({
  modalRef,
  children,
  isShown,
  onClose,
  withCloseButton = false,
  variant = ModalAlignVariant.CENTER,
  className,
  ...props
}) => {
  useShortcuts(isShown, onClose);

  const modal = (
    <Box ref={modalRef}>
      <Backdrop onClick={onClose} />
      <Wrapper
        aria-modal
        tabIndex={-1}
        role="dialog"
        variant={variant}
        className={className}
      >
        <StyledModal
          p={4}
          display="grid"
          gridAutoFlow="row"
          gridGap={0.2}
          variant={variant}
          {...props}
        >
          {withCloseButton && <CloseButton onClick={onClose}></CloseButton>}
          {children}
        </StyledModal>
      </Wrapper>
    </Box>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
