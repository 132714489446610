import { Box } from "components/Box";
import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { StaticAvatarType, StaticImagesContainerType } from "./HouseOwners.d";

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.accent.secondary};

  ${SIZES.media.phone} {
    padding: ${pxToRem(64)} ${pxToRem(32)};
    grid-gap: 72px;
  }
  ${SIZES.media.desktop} {
    grid-template-columns: auto 510px;
  }
`;

export const StyledAvatarsContainer = styled(Box)`
  grid-gap: ${pxToRem(10)} 0;
  grid-template-columns: repeat(auto-fit, minmax(58px, 1fr));
  justify-items: center;

  ${SIZES.media.phone} {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    grid-gap: ${pxToRem(18)};
    justify-content: start;
    grid-auto-flow: column;
    grid-template-columns: unset;
    justify-items: unset;
  }
`;

export const StyledTextContent = styled(Box)`
  width: 100%;

  ${SIZES.media.phone} {
    width: 80%;
  }
  ${SIZES.media.tablet} {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    grid-gap: 68px;
  }
`;

export const StyledImagesContainer = styled(Box)<StaticImagesContainerType>`
  width: 100%;
  ${({ imagesSize }) => imagesSize <= 1 && "height: 280px"};
  grid-template-rows: 85%;
  ${({ imagesSize }) =>
    imagesSize > 1 && "grid-template-columns: repeat(4, 1fr)"};

  & img {
    grid-column: span 2;
  }
  & img:nth-last-child(1):nth-child(odd) {
    ${({ imagesSize }) => imagesSize > 1 && "grid-column: 2 / span 2"};
  }

  ${SIZES.media.phone} {
    width: 70%;
  }

  ${SIZES.media.tablet} {
    grid-column: 2;
    grid-row: 1 / 4;
    ${({ imagesSize }) => imagesSize <= 1 && "height: 450px"};
    width: 100%;
  }
`;

export const StyledAvatarContainer = styled(Button)<StaticAvatarType>`
  height: fit-content;
  width: fit-content;
  border-radius: 50%;
  ${({ isActive }) => isActive && `border: 2px solid ${COLORS.accent.primary}`};
`;

export const OwnerDescription = styled(Typography)`
  white-space: pre-wrap;
`;
