import styled from "styled-components";
import { Box } from "components/Box";
import { StyledFieldBasic } from "components/Form/common.styled";
import { pxToRem } from "global/utils";
import { COLORS } from "theme";
import ArrowIcon from "assets/icons/icon_select_arrow.svg";
import { StaticContainerType } from "./PhoneInput.d";

export const StyledContainer = styled(Box)<StaticContainerType>`
  .react-tel-input {
    .input--default {
      ${StyledFieldBasic}
      padding-left: ${pxToRem(100)};

      :focus {
        border-color: ${({ error }) =>
          error ? COLORS.stroke.error : COLORS.stroke.main};
      }
      :hover {
        border-color: ${({ error }) =>
          error ? COLORS.stroke.error : COLORS.stroke.main};
      }
    }

    .country-list {
      width: 288px;
      .country {
        padding: ${pxToRem(12)} ${pxToRem(24)};
        .country-name {
          margin-left: ${pxToRem(10)};
        }
      }
    }

    .country.highlight {
      color: ${COLORS.accent.primary};
    }

    .flag {
      transform: scale(2);
    }

    .arrow {
      margin-left: 2px;
      border: none;
      &::after {
        content: "";
        position: absolute;
        background-image: url(${ArrowIcon});
        background-size: 7px 6px;
        display: inline-block;
        width: 7px;
        height: 6px;
        top: -1px;

        svg {
          width: 10px;
        }
      }
    }

    .flag-dropdown {
      border-radius: 0;
      border-right: none;
      border-color: ${({ error }) =>
        error ? COLORS.stroke.error : COLORS.stroke.main};
      background-color: ${COLORS.typography.secondary.main};

      &.open {
        border-radius: 0;
      }

      .selected-flag {
        border-radius: 0;
        padding-left: ${pxToRem(25)};

        &.open {
          border-radius: 0;
        }
      }
    }
  }
`;
