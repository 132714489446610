import { Box, Link, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledHousesListContainer = styled(Box)`
  ${SIZES.media.phone} {
    margin: ${pxToRem(32)};
  }

  ${SIZES.media.tablet} {
    margin: ${pxToRem(80)} ${pxToRem(80)};
  }
`;

export const StyledPaginationContainer = styled(Box)`
  ${SIZES.media.phone} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledContainer = styled(Box)`
  width: 100%;
  position: relative;
  padding-bottom: 100px;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
    padding-bottom: 0;
  }
`;

export const StyledTitle = styled(Typography)`
  letter-spacing: 0.04em;
`;
export const StyledContentTitle = styled(Typography)`
  letter-spacing: 0.04em;
  margin-top: ${pxToRem(36)};
  margin-bottom: ${pxToRem(16)};
  text-align: center;
`;

export const StyledTypography = styled(Typography)`
  line-height: 30px;
`;

export const ContactUsExternalLink = styled(Link)`
  color: ${COLORS.accent.primary};
  text-decoration: underline;
  font-size: 12px;

  ${SIZES.media.tablet} {
    font-size: 16px;
  }
`;

export const SectionBox = styled(Box)`
  margin-left: ${pxToRem(18)};
  margin-right: ${pxToRem(18)};
  margin-top: ${pxToRem(29)};

  ${SIZES.media.phone} {
    padding-top: ${pxToRem(100)};
  }

  ${SIZES.media.tablet} {
    padding-top: 0;
  }
`;

export const StyledContentTypography = styled(Typography)`
  text-align: center;
  line-height: 30px;
`;

export const ButtonWrapper = styled(Box)`
  position: sticky;
  bottom: 0;
  background-color: white;
`;

export const ButtonContainer = styled(Box)`
  width: fit-content;
  margin: 16px 0;
`;
