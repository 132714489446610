import { parseISO, isValid } from "date-fns";
import format from "date-fns-tz/format";
import {
  createContext,
  FC,
  forwardRef,
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { InputComponentProps, InputProps, InputValue } from "./types";

type Hook<T, U = void> = (args: U) => T;

type ContextType<T> = {
  useConsumer: Hook<T>;
  Provider: React.FC<PropsWithChildren>;
};

export const pxToRem = (px: number) => `${px / 16}rem`;

const HiddenInput = styled.input`
  display: none;
`;

export function makeInputControl<Props, Value extends InputValue>(
  Component: FC<InputComponentProps<Props, Value>>,
  type = "text"
) {
  // eslint-disable-next-line react/display-name
  return forwardRef<HTMLInputElement, InputProps<Value, Props>>(
    (props, initialRef) => {
      const { value, checked, onChange, ...attributes } = props;
      /* Checkbox inputs require special treatment, as they use `checked`, instead of `value` */
      const isCheckbox = type === "checkbox";

      /* DOM reference of input control, either forwarded from parent component or created as new */
      const ref =
        (initialRef as RefObject<HTMLInputElement>) ||
        useRef<HTMLInputElement>(null);

      /* Value for controlled input, if passed down from parent state (`undefined` for uncontrolled) */
      const stateValue = isCheckbox ? checked : value;

      /* Value for uncontrolled input (handled regardles if ref passed or not, to persist DOM state) */
      const refValue = isCheckbox ? ref.current?.checked : ref.current?.value;

      /* Output value, synchronized by watching either state change (controlled) or user input change */
      const [val, setVal] = useState<Value>((stateValue || refValue) as Value);

      /* Update local state on controlled value change */
      useEffect(() => {
        setVal(stateValue as Value);
      }, [stateValue]);

      /* Update local state on user input */
      const set = (inputValue: Value) => {
        setVal(inputValue);

        /* Update ref with new value (uncontrolled inputs) */
        if (ref.current) {
          if (isCheckbox) {
            ref.current.checked = inputValue as boolean;
          } else {
            ref.current.value = inputValue as string;
          }
        }

        /* Emit onChange event with new value (controlled inputs) */
        if (onChange) {
          /* In a custom control, there is no need to dispatch an event, so it passes value directly. */
          onChange(inputValue);
        }
      };

      return (
        <>
          <Component value={val} onChange={set} props={attributes} />
          <HiddenInput ref={ref} type={type} />
        </>
      );
    }
  );
}

/* Debounces function execution with default interval of 100ms */
export const debounce = (callback: () => void, ms = 100) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  return () => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      callback();
    }, ms);
  };
};

export const formatDate = (
  date: string | Date | undefined,
  dateFormat = "dd/MM/yyyy",
  timezone = {}
) => {
  if (!date) {
    return "-";
  }
  if (typeof date === "string") {
    const parsed = parseISO(date);
    return isValid(parsed) ? format(parsed, dateFormat, timezone) : date;
  }
  return format(date, dateFormat);
};

export const uuid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
};

export const makeContext = function <T>(contextHook: Hook<T>): ContextType<T> {
  const Context = createContext<T>({} as T);
  const Provider: React.FC<PropsWithChildren> = ({ children, ...rest }) => (
    <Context.Provider value={contextHook()} {...rest}>
      {children}
    </Context.Provider>
  );
  const useConsumer = (): T => useContext(Context);

  return {
    useConsumer,
    Provider,
  };
};

export const getCurrentTime = () =>
  new Date().toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

export const getMocks = (count: number) => Array(count).fill("").map(uuid);

export const remToValue = (rem: string) => +rem.replace("rem", "");

export const getRand = (min: number, max: number) =>
  Math.round(Math.random() * (max - min) + min);

export const normalize = (val: number) => {
  if (val > 1) return 1;
  if (val < 0) return 0;
  return val;
};

export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const addSpacesToNumber = (num: number | null) =>
  num !== null &&
  parseFloat(num.toFixed(2))
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const getDateFormat = (date: Date) => date.toLocaleDateString("pl-PL");

export const isEmptyObject = (object: Record<string, any>) =>
  !Object.values(object).some((x) => x !== null && x !== "");
