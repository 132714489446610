import Layout from "components/Layout";
import { SecondaryHeader } from "components/SecondaryHeader";
import { FC } from "react";
import HeaderBackgroundImage from "assets/houses-list-header-photo.jpg";
import IdeaImage from "assets/idea_image.jpg";
import MembersImage from "assets/members_image.jpg";
import HowItWorksImage from "assets/howitworks_image.jpg";
import ApplyImage from "assets/apply_image.jpg";
import Photo from "assets/photo.png";
import {
  ContactUsExternalLink,
  HowItWorksContainer,
  HowItWorksContainerText,
  PhotoBox,
  SectionBox,
  StyledContainer,
  StyledContentTitle,
  StyledContentTypography,
  StyledTitle,
  StyledTypography,
  TextBox,
} from "./HowItWorks.styled";
import { hexToRGB } from "global/utils";
import { COLORS } from "theme";
import { Image } from "components/Image";
import { Box, Typography } from "components";
import { CONTACT_PAGE } from "urls/frontend";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HowItWorks: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Layout>
      <SecondaryHeader image={HeaderBackgroundImage} />
      <HowItWorksContainer>
        <HowItWorksContainerText>
          <StyledContainer display="grid" gridGap={2} py={18.8} px={11}>
            <StyledTitle variant="h2" color={COLORS.typography.headline}>
              {translation["whatIf"][selectedLanguage]}
            </StyledTitle>
            <StyledTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["friendsHouses"][selectedLanguage]}
            </StyledTypography>
            <StyledTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["housesAccessibility"][selectedLanguage]}
            </StyledTypography>
            <StyledTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["rentingPossibilities"][selectedLanguage]}
            </StyledTypography>
          </StyledContainer>
        </HowItWorksContainerText>
        <PhotoBox display="flex" flexDirection="column" alignItems="center">
          <Image variant={"quote"} src={Photo} />
          <Box mt={7}>
            <StyledTitle variant="h2" color={COLORS.typography.headline}>
              Florian Schoeller
            </StyledTitle>
            <Typography variant={"overline"}>
              {translation["founder"][selectedLanguage]}
            </Typography>
          </Box>
        </PhotoBox>
      </HowItWorksContainer>
      <SectionBox
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pt={7}
      >
        <Image variant={"howitworks"} src={IdeaImage} />
        <TextBox
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledContentTitle variant="h2" color={COLORS.typography.headline}>
            {translation["theIdea"][selectedLanguage]}
          </StyledContentTitle>
          <StyledContentTypography
            variant="caption"
            color={hexToRGB(COLORS.typography.headline, "0.7")}
          >
            {
              translation["houseOfFriendsGivingAccessToRental"][
                selectedLanguage
              ]
            }
          </StyledContentTypography>
        </TextBox>
      </SectionBox>
      <SectionBox
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image variant={"howitworks"} src={MembersImage} />
        <TextBox
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledContentTitle variant="h2" color={COLORS.typography.headline}>
            {translation["theMembers"][selectedLanguage]}
          </StyledContentTitle>
          <Box display="grid" gridGap={2}>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {
                translation["internationalCommunityOfOwnersAndFriends"][
                  selectedLanguage
                ]
              }
            </StyledContentTypography>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["networkOfFriends"][selectedLanguage]}
            </StyledContentTypography>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["connectedMembers"][selectedLanguage]}
            </StyledContentTypography>
          </Box>
        </TextBox>
      </SectionBox>
      <SectionBox
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image variant={"howitworks"} src={HowItWorksImage} />
        <TextBox
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledContentTitle variant="h2" color={COLORS.typography.headline}>
            {translation["howItWorks"][selectedLanguage]}
          </StyledContentTitle>
          <Box display="grid" gridGap={2}>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["rentalProcess"][selectedLanguage]}
            </StyledContentTypography>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["chooseFavoriteHouse"][selectedLanguage]}
            </StyledContentTypography>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["bookingRequest"][selectedLanguage]}
            </StyledContentTypography>
            <StyledContentTypography
              variant="caption"
              color={hexToRGB(COLORS.typography.headline, "0.7")}
            >
              {translation["travelDocument"][selectedLanguage]}
            </StyledContentTypography>
          </Box>
        </TextBox>
      </SectionBox>
      <SectionBox
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pt={7}
        mb={4}
      >
        <Image variant={"howitworks"} src={ApplyImage} />
        <TextBox
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <StyledContentTitle variant="h2" color={COLORS.typography.headline}>
            {translation["wantToApplyWithAHouse"][selectedLanguage]}
          </StyledContentTitle>
          <StyledContentTypography
            variant="caption"
            color={hexToRGB(COLORS.typography.headline, "0.7")}
          >
            {translation["doYouHaveAnIconicVacationHome"][selectedLanguage]}{" "}
            <ContactUsExternalLink to={CONTACT_PAGE}>
              {translation["tellUsMore"][selectedLanguage]}
            </ContactUsExternalLink>{" "}
            {translation["aboutYourHomeForEvaluation"][selectedLanguage]}
          </StyledContentTypography>
        </TextBox>
      </SectionBox>
    </Layout>
  );
};
