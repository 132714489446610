import { Box } from "components/Box";
import styled from "styled-components";
import { RequestFullAccessHeaderProps } from "./RequestFullAccessHeader.d";
import { COLORS, SIZES } from "theme";
import { Typography } from "components/Typography";
import { Button } from "components/Button";
import { pxToRem } from "global/utils";

export const StyledContainer = styled(Box)<RequestFullAccessHeaderProps>`
  height: fit-content;
  background-color: ${({ isRequestSent }) =>
    isRequestSent ? COLORS.success.secondary : COLORS.accent.primary};

  ${SIZES.media.phone} {
    padding: ${pxToRem(16)} ${pxToRem(33)};
  }
  ${SIZES.media.tablet} {
    padding: ${pxToRem(8)} ${pxToRem(80)};
  }

  ${SIZES.media.desktop} {
    height: 29px;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const StyledButton = styled(Button)`
  &:enabled {
    color: ${COLORS.background.main};
    text-decoration-color: ${COLORS.background.main};

    &:hover {
      color: ${COLORS.typography.secondary.light};
      text-decoration-color: ${COLORS.typography.secondary.light};

      svg {
        stroke: ${COLORS.typography.secondary.light};
      }
    }
  }
`;
