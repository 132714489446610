import { Box, Typography } from "components";
import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const MainContainer = styled(Box)`
  margin: ${pxToRem(32)} ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(18)};
  ${SIZES.media.tablet} {
    margin: ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(80)} ${pxToRem(80)};
  }
`;

export const StyledHeaderContainer = styled(Box)`
  ${SIZES.media.phone} {
    grid-template-columns: auto 320px;
    margin-top: ${pxToRem(48)};
  }
`;

export const StyledSectionContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.phone} {
    width: 75%;
    margin-top: ${pxToRem(60)};
  }
`;

export const StyledStaffOnSiteContainer = styled(Box)`
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-row-gap: 28px;

  ${SIZES.media.phone} {
    width: 85%;
  }
`;

export const MobilesHolder = styled(Box)`
  box-shadow: 8px 0px 20px rgba(209, 206, 206, 0.25);
  height: ${pxToRem(65)};
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 1000;
  background-color: ${COLORS.typography.secondary.main};
  & button {
    padding: ${pxToRem(12)} ${pxToRem(8)};
  }
`;

export const StyledButton = styled(Button)`
  width: 154px;
  padding: ${pxToRem(12)} ${pxToRem(17)};
`;

export const StyledErrorHeadline = styled(Typography)`
  font-size: 15px;
`;

export const StyledErrorText = styled(Typography)`
  font-size: 15px;
  text-transform: capitalize;
`;

export const StyledErrorContainer = styled(Box)`
  ${SIZES.media.tablet} {
    align-items: flex-end;
    margin-top: 0;
  }
`;
