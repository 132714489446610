import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { FC, useEffect, useState } from "react";
import {
  MainContainer,
  StyledHeaderContainer,
  StyledSectionContainer,
  StyledStaffOnSiteContainer,
  MobilesHolder,
  StyledErrorText,
  StyledErrorHeadline,
  StyledErrorContainer,
} from "./HouseEdit.styled";
import {
  HouseEditingProps,
  HouseProps,
  RecommendationImageToDelete,
  RecommendationsByCategories,
} from "types/house.d";
import { useParams } from "react-router";
import {
  fetchHouse,
  fetchUpdateHouse,
  deleteRecommendationsImage,
} from "services/House";
import { ReactComponent as IconBed } from "assets/icons/icon_bed.svg";
import { IconWithLabel } from "components/IconWithLabel";
import { Box, Typography } from "components";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import {
  FormProvider,
  useForm,
  SubmitHandler,
  DeepMap,
  FieldError,
  FieldValues,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { houseEditingSchema } from "./validators";
import { Textarea } from "components/Textarea";
import { isValidForm } from "utils/isValidForm";
import { toast } from "react-toastify";
import { Input } from "components/Input";
import { Checkbox } from "components/Checkbox";
import { PhoneInput } from "components/PhoneInput";
import { useWindowSize } from "global/hooks/useWindowSize";
import { HOUSES_PAGE, HOUSE_REDIRECT, MAIN_PAGE } from "urls/frontend";
import { OwnerAdvice } from "components/House/OwnerAdvice";
import { Recommendations } from "components/Recommendations";
import { HouseServices } from "components/House/HouseServices";
import { Tabs } from "components/Tabs";
import { TabsVariant } from "components/Tabs/Tabs.enums";
import { RecommendationsEdit } from "./RecommendationsEdit";
import { COLORS } from "theme";
import { RecommendationEditItemVariant } from "components/HouseEdit/RecommendationEditItem/RecommendationEditItem.enums";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseEdit: FC = () => {
  const { id } = useParams();
  const { isMobile } = useWindowSize();
  const [house, setHouse] = useState<HouseProps>();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const fullLocation =
    house &&
    [house.location.city, house.location.country].filter(Boolean).join(", ");
  const [imagesToDelete, setImagesToDelete] = useState<
    RecommendationImageToDelete[]
  >([]);
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const isOwnerAdviceSectionEmpty =
    !house?.owneradvice ||
    !house?.owneradvice?.description ||
    house?.owneradvice?.description.length === 0;
  const isRecommendationsSectionEmpty =
    house?.map_recommendations &&
    Object.keys(house?.map_recommendations).length === 0;
  const isServicesSectionEmpty =
    house?.recommendations_without_map &&
    Object.keys(house?.recommendations_without_map).length === 0;

  const breadcrumbsRoutes = [
    { breadcrumbName: "Home", path: MAIN_PAGE },
    { breadcrumbName: "Houses", path: HOUSES_PAGE },
    {
      breadcrumbName: house?.title ?? "",
      path: house ? HOUSE_REDIRECT(house?.id) : "",
    },
    { breadcrumbName: "Edit", path: `/houses/${id}` },
  ];

  const methods = useForm<HouseEditingProps>({
    resolver: yupResolver(houseEditingSchema),
    mode: "onTouched",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
  } = methods;

  const whatsApp = watch("staffonsite.whatsapp");

  const getHouseData = async () => {
    const response = id && (await fetchHouse(id));
    response && setHouse(response.parsedBody);
  };

  const assignData = async () => {
    if (house) {
      setValue(
        "owneradvice.description",
        house.owneradvice ? house.owneradvice.description : ""
      );
      setValue(
        "staffonsite.responsibility",
        house.staffonsite ? house.staffonsite.responsibility : ""
      );
      setValue(
        "staffonsite.first_name",
        house.staffonsite ? house.staffonsite.first_name : ""
      );
      setValue(
        "staffonsite.last_name",
        house.staffonsite ? house.staffonsite.last_name : ""
      );
      setValue(
        "staffonsite.email",
        house.staffonsite ? house.staffonsite.email : ""
      );
      setValue(
        "staffonsite.phone",
        house.staffonsite ? house.staffonsite.phone : ""
      );
      setValue(
        "staffonsite.whatsapp",
        house.staffonsite ? house.staffonsite.whatsapp : false
      );
      setValue(
        "recommendations",
        house.map_recommendations ? house.map_recommendations : {}
      );
      setValue(
        "services",
        house.recommendations_without_map
          ? house.recommendations_without_map
          : {}
      );
    }
  };

  useEffect(() => {
    getHouseData();
  }, []);

  useEffect(() => {
    assignData();
  }, [house]);

  const updateImagesToDelete = (newImageId: string) => {
    setImagesToDelete([...imagesToDelete, { id: newImageId }]);
  };

  const sendForm = async (data: HouseEditingProps) => {
    if (house) {
      const response = await fetchUpdateHouse(house.id, data);
      if (response.ok) {
        toast.success(translation["successMessage"][selectedLanguage]);
        getHouseData();
      } else {
        toast.error(translation["errorMessage"][selectedLanguage]);
      }
    }
  };

  const onSubmit: SubmitHandler<HouseEditingProps> = async (data) => {
    if (house) {
      if (imagesToDelete.length > 0) {
        const deleteData = {
          delete_recommendations_image: imagesToDelete,
        };
        const deleteResponse = await deleteRecommendationsImage(
          house.id,
          deleteData
        );
        if (deleteResponse.status === 200) {
          sendForm(data);
        }
      } else {
        sendForm(data);
      }
    }
  };

  const getRecommendationsTabs = (
    data: RecommendationsByCategories,
    variant: RecommendationEditItemVariant
  ) =>
    Object.entries(data).map(([label, data]) => ({
      label,
      content: (
        <RecommendationsEdit
          control={control}
          register={register}
          data={data}
          categoryName={label}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
          variant={variant}
          updateImagesToDelete={updateImagesToDelete}
          houseCenter={
            house?.location.latitude && house.location.longitude
              ? { lat: house.location.latitude, lng: house.location.longitude }
              : { lat: 0, lng: 0 }
          }
        />
      ),
    }));

  const getRecommendationsErrors = (errors: DeepMap<FieldValues, FieldError>) =>
    Object.entries(errors)
      .map(([categoryName, data]) => {
        const errorsInCategory: any = data;
        const errorsNum = errorsInCategory
          .filter(Boolean)
          .reduce(
            (acc: number, element: FieldError) =>
              acc + Object.keys(element).length,
            0
          );

        return `${categoryName}: ${errorsNum} error${errorsNum > 1 ? "s" : ""}`;
      })
      .filter(Boolean)
      .join(", ");

  const getErrorsBox = (errors: DeepMap<FieldValues, FieldError>) => {
    return (
      <>
        {Object.entries(errors).map(([section, errorsData], index) => {
          const isRecommendations =
            section === RecommendationEditItemVariant.SERVICES ||
            section === RecommendationEditItemVariant.RECOMMENDATION;
          return (
            <Box
              mt={0.5}
              key={`${section}-${index}`}
              display="grid"
              gridGap={0.5}
              justifyItems="end"
              gridAutoFlow="column"
            >
              <StyledErrorText variant="body" color={COLORS.error.main}>
                {`${section}${isRecommendations ? ": " : ""}`}
              </StyledErrorText>
              {isRecommendations && (
                <StyledErrorText variant="body" color={COLORS.error.main}>
                  {getRecommendationsErrors(errorsData)}
                </StyledErrorText>
              )}
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Layout isHousePage>
      {house && (
        <MainContainer variant="div">
          <Breadcrumbs
            currentPageTitle={house.title}
            routes={breadcrumbsRoutes}
          />
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <StyledHeaderContainer
                display="grid"
                gridAutoFlow="column"
                alignItems="start"
                mt={4}
                mb={1}
              >
                <Typography variant="h1">{house.title}</Typography>
                {isMobile ? (
                  <MobilesHolder
                    px={2.3}
                    py={1.5}
                    display="grid"
                    gridAutoFlow="column"
                    gridGap={1}
                  >
                    <Button
                      type="button"
                      variant={ButtonVariant.SECONDARY}
                      size={ButtonSize.SMALL}
                      onClick={() => setIsPreview(!isPreview)}
                    >
                      {isPreview ? "Edit" : "Preview"}
                    </Button>
                    <Button
                      type="submit"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {translation["saveChanges"][selectedLanguage]}
                    </Button>
                  </MobilesHolder>
                ) : (
                  <Box display="grid" gridGap={2.3} gridAutoFlow="column">
                    <Button
                      type="button"
                      variant={ButtonVariant.SECONDARY}
                      size={ButtonSize.SMALL}
                      onClick={() => setIsPreview(!isPreview)}
                    >
                      {isPreview ? "Edit" : "Preview"}
                    </Button>
                    <Button
                      type="submit"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {translation["saveChanges"][selectedLanguage]}
                    </Button>
                  </Box>
                )}
              </StyledHeaderContainer>
              <IconWithLabel gap={1.5} icon={IconBed} label={fullLocation} />
              {isPreview ? (
                <Box display="grid" gridGap={6.3} mt={4.5}>
                  {!isRecommendationsSectionEmpty && (
                    <Recommendations
                      tabsData={house.map_recommendations}
                      houseLatitude={house.location.latitude}
                      houseLongitude={house.location.longitude}
                    />
                  )}
                  {!isServicesSectionEmpty && (
                    <HouseServices
                      tabsData={house.recommendations_without_map}
                    />
                  )}
                  {!isOwnerAdviceSectionEmpty && (
                    <OwnerAdvice ownerAdvice={house.owneradvice.description} />
                  )}
                </Box>
              ) : (
                <>
                  {!!Object.keys(errors).length && !isValidForm(errors) && (
                    <StyledErrorContainer
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      mt={1}
                    >
                      <StyledErrorHeadline
                        variant="body"
                        color={COLORS.error.main}
                        weight="bold"
                      >
                        {translation["checkErrors"][selectedLanguage]}
                      </StyledErrorHeadline>
                      {getErrorsBox(errors)}
                    </StyledErrorContainer>
                  )}
                  {!!Object.keys(house.map_recommendations).length && (
                    <Box display="grid" gridGap={4} mt={4.5}>
                      <Typography variant="h2">
                        {translation["recommendations"][selectedLanguage]}
                      </Typography>
                      <Tabs
                        variant={TabsVariant.LARGE}
                        tabs={getRecommendationsTabs(
                          house.map_recommendations,
                          RecommendationEditItemVariant.RECOMMENDATION
                        )}
                      />
                    </Box>
                  )}
                  {!!Object.keys(house.recommendations_without_map).length && (
                    <Box display="grid" gridGap={4} mt={4.5}>
                      <Typography variant="h2">
                        {translation["services"][selectedLanguage]}
                      </Typography>
                      <Tabs
                        variant={TabsVariant.LARGE}
                        tabs={getRecommendationsTabs(
                          house.recommendations_without_map,
                          RecommendationEditItemVariant.SERVICES
                        )}
                      />
                    </Box>
                  )}
                  <StyledSectionContainer display="grid" gridGap={4} mt={4.5}>
                    <Typography variant="h2">
                      {translation["ownerAdvice"][selectedLanguage]}
                    </Typography>
                    <Textarea
                      rows={6}
                      label="note"
                      {...register("owneradvice.description")}
                      error={
                        !isValidForm(errors) && errors.owneradvice?.description
                      }
                    />
                  </StyledSectionContainer>
                  <StyledSectionContainer display="grid" gridGap={4} mt={4.5}>
                    <Typography variant="h2">
                      {translation["contactPersonOnSite"][selectedLanguage]}
                    </Typography>
                    <StyledStaffOnSiteContainer
                      display="grid"
                      gridGap={3}
                      alignItems="center"
                    >
                      <Input
                        label="Responsibility"
                        {...register("staffonsite.responsibility")}
                        error={
                          !isValidForm(errors) &&
                          errors.staffonsite?.responsibility?.message
                        }
                      />
                      <Input
                        label="First name"
                        {...register("staffonsite.first_name")}
                        error={
                          !isValidForm(errors) &&
                          errors.staffonsite?.first_name?.message
                        }
                      />
                      <Input
                        label="Last name"
                        {...register("staffonsite.last_name")}
                        error={
                          !isValidForm(errors) &&
                          errors.staffonsite?.last_name?.message
                        }
                      />
                      <Input
                        label="E-mail"
                        {...register("staffonsite.email")}
                        inputMode="email"
                        error={
                          !isValidForm(errors) &&
                          errors.staffonsite?.email?.message
                        }
                      />
                      <PhoneInput
                        id="staffonsite.phone"
                        control={control}
                        label="Phone number"
                        error={
                          !isValidForm(errors) &&
                          errors.staffonsite?.phone?.message
                        }
                      />
                      <Checkbox
                        {...register("staffonsite.whatsapp")}
                        value={whatsApp ? 1 : 0}
                        label="WhatsApp"
                        checked={whatsApp}
                      />
                    </StyledStaffOnSiteContainer>
                  </StyledSectionContainer>
                </>
              )}
            </form>
          </FormProvider>
        </MainContainer>
      )}
    </Layout>
  );
};
