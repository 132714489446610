import { Typography } from "components/Typography";
import { FC, PropsWithChildren } from "react";
import { ChipProps } from "./Chip.d";
import { ChipVariant } from "./Chip.enums";
import { StyledContainer } from "./Chip.styled";

export const Chip: FC<PropsWithChildren<ChipProps>> = ({
  label,
  onClick,
  color,
  backgroundColor,
  variant = ChipVariant.REGULAR,
  selected = false,
  children,
  ...props
}) => {
  return (
    <StyledContainer
      variant={variant}
      onClick={onClick ?? undefined}
      px={0.5}
      backgroundColor={backgroundColor}
      selected={selected}
      {...props}
    >
      {children
        ? children
        : label && (
            <Typography variant="overline" color={color}>
              {label}
            </Typography>
          )}
    </StyledContainer>
  );
};
