import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { createGlobalStyle } from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  width: 100%;
  position: relative;
  padding-bottom: 100px;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
    padding-bottom: 0;
  }
`;

export const StyledTitleContainer = styled(Box)`
  width: 100%;

  h4 {
    line-height: 22px;
  }
  ${SIZES.media.tablet} {
    width: 55%;
  }
`;

export const TopContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const MiddleContainer = styled(Box)`
  grid-template-columns: auto 60px;

  ${SIZES.media.tablet} {
    grid-template-columns: auto 145px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const TabletsHolder = styled(Box)`
  box-shadow: 8px 0px 20px rgba(209, 206, 206, 0.25);
  width: 100%;
  height: ${pxToRem(72)};
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 1000;
  background-color: ${COLORS.typography.secondary.main};

  & button {
    padding: ${pxToRem(12)} ${pxToRem(8)};
  }
  & p,
  & h4 {
    font-size: 15px;
  }

  ${SIZES.media.phone} {
    height: ${pxToRem(96)};
    padding: ${pxToRem(18)} ${pxToRem(32)};

    & button {
      padding: ${pxToRem(18)} ${pxToRem(32)};
    }
  }
`;
