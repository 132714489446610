import { FC } from "react";
import { Box, Typography } from "..";
import { COLORS } from "theme";
import { UserLabelProps } from "./UserLabel.d";
import { ReactComponent as UserIcon } from "assets/icons/icon_guest.svg";
import { AvatarIconButton } from "./UserLabel.styled";

export const Avatar: FC<UserLabelProps> = ({ image }) => (
  <Box display="flex" alignItems="center" flexDirection="row">
    <AvatarIconButton>
      {image ? <img src={image} /> : <UserIcon />}
    </AvatarIconButton>
    {/* {firstName && lastName && (
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box>
          <Typography variant="body" color={COLORS.typography.body}>
            Hello, {firstName}
          </Typography>
        </Box>
      </Box>
    )} */}
  </Box>
);
