import { Box } from "components";
import styled, { createGlobalStyle } from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: 12px;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
  }
`;

export const StyledFormContainer = styled(Box)`
  ${SIZES.media.tablet} {
    min-height: 90vh;
  }

  ${SIZES.media.desktop} {
    min-height: inherit;
  }
`;

export const StyledInputsContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledButtonsContainer = styled(Box)`
  ${SIZES.media.tablet} {
    width: 452px;
    padding-bottom: 12px;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const StyledLanguageContainer = styled(Box)`
  grid-template-rows: 1fr 1fr;

  ${SIZES.media.tablet} {
    grid-template-rows: 1fr;
  }
`;
