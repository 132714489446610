import { Button } from "components/Button";

import { FC, useEffect } from "react";
import { COLORS } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { ButtonsBox, ContentContainer } from "./PasswordForm.styled";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Input } from "components/Input";
import { ResetPassword } from "types/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordValidationSchema } from "../ProfileForm/validators";
import { isValidForm } from "utils/isValidForm";
import { passwordChangeRequest } from "services/Profile";
import { toast } from "react-toastify";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const PasswordForm: FC = () => {
  const methods = useForm<ResetPassword>({
    resolver: yupResolver(passwordValidationSchema),
    mode: "onSubmit",
  });

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = methods;
  const onSubmit: SubmitHandler<ResetPassword> = async (data) => {
    const form: ResetPassword = {
      ...data,
    };
    try {
      await passwordChangeRequest(form);
      toast.success(translation["passwordChangeSuccess"][selectedLanguage]);
      reset();
    } catch (error) {
      toast.error(translation["passwordChangeError"][selectedLanguage]);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <ContentContainer display="grid" gridGap={2.5} pt={1.5}>
          <Input
            required
            label={translation["oldPassword"][selectedLanguage]}
            type="password"
            {...register("old_password")}
          />
          <Input
            required
            label={translation["newPassword"][selectedLanguage]}
            type="password"
            error={
              !isValidForm(errors) &&
              errors.confirm_password?.message &&
              "New password"
            }
            {...register("password")}
          />
          <Input
            required
            label={translation["repeatNewPassword"][selectedLanguage]}
            type="password"
            error={!isValidForm(errors) && errors.confirm_password?.message}
            {...register("confirm_password")}
          />
          <ButtonsBox
            display="grid"
            gridAutoFlow="column"
            gridGap={6.5}
            justifyContent="start"
            alignItems="center"
            mt={4.5}
            pb={8}
          >
            <Button
              icon={
                <ArrowIcon
                  stroke={COLORS.stroke.main}
                  fill="none"
                  width="24px"
                  height="24px"
                />
              }
              type="submit"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.SMALL}
              disabled={Object.keys(errors).length > 0}
            >
              {translation["save"][selectedLanguage]}
            </Button>
          </ButtonsBox>
        </ContentContainer>
      </form>
    </FormProvider>
  );
};
