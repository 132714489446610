import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { FC } from "react";
import { MainContainer } from "views/House/House.styled";
import {
  ContentParagraphContainer,
  ContentTitle,
  HeaderContainer,
} from "./TermsAndConditions.styled";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const TermsAndConditions: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Layout>
      <MainContainer ml={10} my={2} mb={38}>
        <Breadcrumbs currentPageTitle="Terms and conditions" />
        <HeaderContainer variant="h1">
          {translation["generalTermsAndConditions"][selectedLanguage]}
        </HeaderContainer>
        <ContentParagraphContainer variant="body">
          {translation["bookingProccesing"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["rentalServices"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["rentalAgreement"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["contract"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["bookingTermsAndConditions"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["miete"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["rentalTerms"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["paymentTerms"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["paymentInfo"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["paymentDetails"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["cancellation"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["cancellationPolicy"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["houseOfFriendsCompensation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["standardCancellationFees"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["cancellationFee61Days"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["cancellationFee60Days"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["cancellationFee7Days"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["rentalPrice"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["rebooking"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["rebookingConditions"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["renterObligations"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["arrivalNotice"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["travelInsurance"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["insuranceRecommendation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["pets"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["petPolicy"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["noSmokingPolicy"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["noSmokingNotice"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["datenschutz"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["userProfileDataProcessing"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["requiredInformation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["personalData"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["dataRegistration"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["dataProcessors"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["dataController"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["rights"][selectedLanguage]}
        </ContentParagraphContainer>
      </MainContainer>
    </Layout>
  );
};
