import { Box } from "components/Box";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Chip } from "components/Chip";
import { IconButton } from "components/IconButton";
import { Typography } from "components/Typography";
import { FC, useState } from "react";
import { ReservationDatePicker } from "../ReservationDatePicker";
import { ReservationListItemProps } from "./ReservationListItem.d";
import {
  ReservationMainBox,
  ReservationImageBox,
  ReservationContentBox,
  ReservationTypographyTitle,
  ReservationButtonsBox,
  ReservationDeclineButton,
  ReservationTypographyOldPrice,
  ReservationBox,
  PeopleBox,
  PriceBox,
  ReservationGuestImage,
  ReservationGuestBox,
  StyledFullName,
  StyledAvatarContainer,
  ConnectioBox,
  ExtraBox,
} from "./ReservationListItem.styled";
import { Image } from "components/Image";
import { ReactComponent as GuestIcon } from "assets/icons/icon_guests.svg";
import { HOST_URL } from "urls/api";
import { Button } from "components/Button";
import { COLORS } from "theme";
import {
  BookingsStatusAction,
  BookingStatus,
  FriendRelationType,
} from "types/enums";
import { generatePath, useNavigate } from "react-router";
import { CONTACT_PAGE, HOUSE_PAGE } from "urls/frontend";
import { KebabMenu } from "components/KebabMenu";
import { useProfile } from "context/Profile";
import { GenderVariant } from "components/Profile/ProfileForm/ProfileForm.enums";
import { getDefaultAvatar } from "utils/getDefaultAvatar";
import { Link } from "components/Link";
import { useModal } from "global/hooks/useModal";
import ReservationModal from "../../Modals/ReservationsModal/ReservationModal";
import { cancelReservation } from "services/Bookings";
import { UserProfilePreviewModal } from "components/Modals/UserProfilePreviewModal";
import {
  EditableFriendOptions,
  Friend,
  FriendEmailInvitation,
  FriendLimitedInfo,
} from "types/friend";
import { FriendCardVariant } from "components/Friends/FriendCard/FriendCard.enums";
import {
  fetchSendInviteToConnections,
  fetchAcceptConnectionInvitation,
  fetchDeclineConnectionInvitation,
  fetchDeactivateConnectionInvitation,
  fetchDeactivateEmailInvitation,
  fetchDeleteFromFriends,
  fetchUpdateFriendOptions,
  fetchUpdateEmailInvitation,
  fetchUserInfo,
} from "services/Friends";
import { DiscountOption } from "types/discount.d";
import { fetchDiscountList } from "services/Discount";
import { toast } from "react-toastify";
import { AccessVariant } from "components/Modals/InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";
import { fetchSendEmailInvitation } from "services/Invitation";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const ReservationListItem: FC<ReservationListItemProps> = ({
  id,
  house_id,
  is_main_owner,
  check_in_date,
  check_out_date,
  refund,
  adults_num,
  children_num,
  main_photo,
  title,
  price,
  price_without_discount,
  reservation_status,
  reservation_background_color,
  reservation_text_color,
  guest_photo,
  guest_gender = GenderVariant.GENDER_UNKNOWN,
  guest_full_name,
  guest_id,
  guest_no_connection,
  is_cancellable,
  extras,
  handleCancelReservation,
  approveReservation,
  rejectReservation,
}) => {
  const [status, setStatus] = useState<BookingsStatusAction>(
    BookingsStatusAction.NONE
  );
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();
  const reservationStatusLabel = reservation_status.split("_").join(" ");
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const reservationRedirectHouseLink = generatePath(HOUSE_PAGE, {
    id: house_id,
  });
  const [userInfo, setUserInfo] = useState<FriendLimitedInfo | null>(null);
  const [isShownUserProfilePreviewModal, setIsShownUserProfileModal] =
    useState<boolean>(false);
  const [discountOptions, setDiscountOptions] = useState<DiscountOption[]>([
    { name: "", rate: "", description: "" },
  ]);

  const showModal = async (action: BookingsStatusAction) => {
    if (action === BookingsStatusAction.APPROVE) {
      const response = await approveReservation(id, reservation_status);
      if (response.ok) {
        setStatus(action);
        toggle();
      }
    } else {
      setStatus(action);
      toggle();
    }
  };

  const closeModal = () => {
    setStatus(BookingsStatusAction.NONE);
    toggle();
  };

  const onConfirm = async () => {
    if (status === BookingsStatusAction.APPROVE) {
      closeModal();
    } else if (BookingsStatusAction.REJECT) {
      const response = await rejectReservation(id, reservation_status);
      if (response.ok) {
        closeModal();
      }
    } else {
      const response = await cancelReservation(id);
      if (response.ok) {
        closeModal();
      }
    }
  };

  const getCancelStatus = () => {
    if (reservation_status === BookingStatus.DEPOSIT_PAID) {
      return BookingsStatusAction.CANCELLED_BY_OWNER_AND_PAID_DEPOSIT;
    } else if (
      reservation_status === BookingStatus.BOOKED ||
      reservation_status === BookingStatus.ABOUT_TO_START
    ) {
      return BookingsStatusAction.CANCELLED_BY_OWNER_AND_PAID_BALANCE;
    } else {
      return BookingsStatusAction.CANCELLED_BY_OWNER;
    }
  };

  const getUserInfo = async () => {
    if (guest_id) {
      const response = await fetchUserInfo(guest_id);
      response.ok && setUserInfo(response.parsedBody);
    }
  };

  const getDiscountOptions = async () => {
    const response = await fetchDiscountList();
    response && setDiscountOptions(response?.parsedBody);
  };

  const openUserPreviewModal = () => {
    setIsShownUserProfileModal(true);
    getUserInfo();
    getDiscountOptions();
  };

  const closeUserProfilePreviewModal = () => {
    setUserInfo(null);
    setIsShownUserProfileModal(false);
  };

  const declineInvitation = async (friendId: string, invitationId: string) => {
    const data = {
      invited_user: friendId,
    };
    const response = await fetchDeclineConnectionInvitation(data, invitationId);
    if (response.ok) {
      toast.success(translation["invitationDeclined"][selectedLanguage]);
      getUserInfo();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationDeclineError"][selectedLanguage]);
    }
  };

  const deactivateConnectionInvitation = async (invitationId: string) => {
    const response = await fetchDeactivateConnectionInvitation(invitationId);
    if (response?.ok) {
      toast.success(translation["invitationDeactivated"][selectedLanguage]);
      getUserInfo();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationDeactivateError"][selectedLanguage]);
    }
  };

  const deactivateEmailInvitation = async (email: string) => {
    const response = await fetchDeactivateEmailInvitation({
      email,
    });
    if (response?.ok) {
      toast.success(translation["invitationDeactivated"][selectedLanguage]);
      getUserInfo();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["invitationDeactivateError"][selectedLanguage]);
    }
  };

  const deleteFromFriends = async (friendId: string) => {
    const response = await fetchDeleteFromFriends(friendId);
    if (response.ok) {
      toast.success(translation["friendDeleted"][selectedLanguage]);
      getUserInfo();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["deleteError"][selectedLanguage]);
    }
  };

  const sendInvitation = async (friendId: string) => {
    const data = {
      invited_user: friendId,
    };
    const response = await fetchSendInviteToConnections(data);
    if (response.ok) {
      toast.success(translation["requestSendSuccess"][selectedLanguage]);
      getUserInfo();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["sendRequestError"][selectedLanguage]);
    }
  };

  const sendInvitationReminder = async (friendData: Friend, email: string) => {
    const [findAccessVariant] =
      Object.entries(AccessVariant).find(
        ([, variant]) => variant === friendData.access_type
      ) ?? [];
    const data: FriendEmailInvitation = {
      email,
      name: friendData.name,
      last_name: friendData.last_name,
      access_type:
        AccessVariant[findAccessVariant as keyof typeof AccessVariant],
      discount: friendData.discount,
    };
    const response = await fetchSendEmailInvitation(data);
    if (response.ok) {
      toast.success(translation["reminderSendSuccess"][selectedLanguage]);
    } else {
      toast.error(translation["sendReminderError"][selectedLanguage]);
    }
  };

  const acceptInvitation = async (friendId: string, invitationId: string) => {
    const data = {
      invited_user: friendId,
    };
    const response = await fetchAcceptConnectionInvitation(data, invitationId);
    if (response.ok) {
      toast.success(translation["invitationAccepted"][selectedLanguage]);
      getUserInfo();
      isShownUserProfilePreviewModal && closeUserProfilePreviewModal();
    } else {
      toast.error(translation["acceptInvitationError"][selectedLanguage]);
    }
  };

  const updateFriendOptions = async (
    data: EditableFriendOptions,
    friendId: string
  ) => {
    const response = await fetchUpdateFriendOptions(data, friendId);
    if (response.ok) {
      toast.success(translation["updateSendSuccess"][selectedLanguage]);
      getUserInfo();
    } else {
      toast.error(translation["updateError"][selectedLanguage]);
    }
  };

  const updateEmailInvitation = async (
    data: EditableFriendOptions,
    invitationId: string
  ) => {
    const response = await fetchUpdateEmailInvitation(data, invitationId);
    if (response.ok) {
      toast.success(translation["updateSendSuccess"][selectedLanguage]);
      getUserInfo();
    } else {
      toast.error(translation["updateError"][selectedLanguage]);
    }
  };

  const getUserPreviewVariant = () => {
    const isRequestReceived =
      userInfo?.received_connection_invitation ||
      userInfo?.received_friend_invitation;

    const isRequestSent =
      userInfo?.sent_connection_invitation || userInfo?.sent_friend_invitation;

    if (isRequestReceived) return FriendCardVariant.REQUESTS_RECEIVED;
    if (isRequestSent) return FriendCardVariant.REQUESTS_SENT;
    return userInfo?.relation_type === FriendRelationType.CONNECTION ||
      userInfo?.relation_type === FriendRelationType.FRIEND
      ? FriendCardVariant.CONNECTIONS
      : FriendCardVariant.PEOPLE_YOU_MIGHT_KNOW;
  };

  return (
    <ReservationMainBox pl={2} py={2} mb={2} display="flex" flexWrap="wrap">
      {status !== BookingsStatusAction.NONE && (
        <ReservationModal
          status={status}
          isShown={isShown}
          price={price}
          refund={refund}
          onConfirm={onConfirm}
          onClose={closeModal}
        />
      )}
      <ReservationImageBox mr={2}>
        <Image
          variant={"bookingMedium"}
          src={main_photo && `${HOST_URL}${main_photo}`}
        />
      </ReservationImageBox>
      <ReservationContentBox
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box display="flex" flexDirection="column" alignItems="start">
            <Link to={reservationRedirectHouseLink} linkColor="black">
              <ReservationTypographyTitle variant={"h4"}>
                {title}
              </ReservationTypographyTitle>
            </Link>
            <Chip
              label={reservationStatusLabel}
              backgroundColor={reservation_background_color}
              color={reservation_text_color}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <ReservationBox>
            <ReservationDatePicker
              checkInDate={check_in_date ? new Date(check_in_date) : undefined}
              checkOutDate={
                check_out_date ? new Date(check_out_date) : undefined
              }
              withBorder={false}
            />
          </ReservationBox>
          <PeopleBox display="flex" alignItems="center">
            <IconButton>
              <GuestIcon />
            </IconButton>
            <Typography variant={"caption"} color={COLORS.typography.body}>
              {adults_num ? `${adults_num} adults` : ""}
              {adults_num && children_num ? ", " : ""}
              {children_num ? `${children_num} children` : ""}
            </Typography>
          </PeopleBox>
        </Box>
        <PriceBox display="flex">
          {price_without_discount ? (
            <>
              <ReservationTypographyOldPrice
                variant={"h4"}
                color={COLORS.stroke.main}
              >
                €{price_without_discount}
              </ReservationTypographyOldPrice>
              <Typography variant={"h4"}>
                €{price} {translation["totalPrice"][selectedLanguage]}
              </Typography>
            </>
          ) : (
            <Typography variant={"h4"}>
              €{price} {translation["totalPrice"][selectedLanguage]}
            </Typography>
          )}
        </PriceBox>
        <ReservationGuestBox display="flex" alignItems="start">
          <Box>
            <Typography variant={"overline"} color={COLORS.typography.body}>
              {translation["guest"][selectedLanguage]}
            </Typography>
            <ConnectioBox
              display="grid"
              alignItems="center"
              gridAutoFlow="column"
              justifyContent="space-between"
              gridGap={2}
            >
              <StyledAvatarContainer onClick={openUserPreviewModal}>
                <ReservationGuestImage
                  isRound
                  variant={"icon"}
                  src={
                    guest_photo
                      ? `${HOST_URL}${guest_photo}`
                      : getDefaultAvatar(guest_gender)
                  }
                />
              </StyledAvatarContainer>
              {guest_full_name && (
                <StyledFullName
                  variant={"caption"}
                  onClick={openUserPreviewModal}
                >
                  {guest_full_name}
                </StyledFullName>
              )}
              {guest_no_connection && (
                <Typography variant={"overline"} color={COLORS.typography.body}>
                  {guest_no_connection}{" "}
                  {translation["connection"][selectedLanguage]}
                </Typography>
              )}
            </ConnectioBox>
          </Box>
          {extras.length !== 0 && (
            <Box>
              <Box>
                <Typography variant={"overline"} color={COLORS.typography.body}>
                  {translation["extras"][selectedLanguage]}
                </Typography>
              </Box>
              {extras.map((extra, index) => {
                return (
                  <ExtraBox
                    display="grid"
                    gridAutoFlow="column"
                    justifyContent="space-between"
                    key={`extra-${index}`}
                  >
                    <Box>
                      <Typography variant={"caption"}>{extra.name}</Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant={"caption"}
                        color={COLORS.typography.body}
                      >
                        {extra.price} $
                      </Typography>
                    </Box>
                  </ExtraBox>
                );
              })}
            </Box>
          )}
        </ReservationGuestBox>
      </ReservationContentBox>
      <ReservationButtonsBox display="flex" alignItems="center" flexWrap="wrap">
        {is_main_owner && (
          <>
            {(reservation_status === BookingStatus.NEW ||
              reservation_status ===
                BookingStatus.WAITING_FOR_CHANGES_APPROVAL) && (
              <>
                <Box pr={3}>
                  <ReservationDeclineButton
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.SECONDARY}
                    onClick={() => showModal(BookingsStatusAction.REJECT)}
                  >
                    {translation["decline"][selectedLanguage]}
                  </ReservationDeclineButton>
                </Box>

                <Box pr={3}>
                  <Button
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.SECONDARY}
                    onClick={() => showModal(BookingsStatusAction.APPROVE)}
                  >
                    {translation["approve"][selectedLanguage]}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
        <KebabMenu
          menuOptions={[
            {
              label: translation["contactSupport"][selectedLanguage],
              value: "Contact support",
              isShown: true,
              onClick: () => navigate(CONTACT_PAGE),
            },
            {
              label: translation["cancel"][selectedLanguage],
              value: "Cancel",
              isShown: is_cancellable,
              onClick: () => showModal(getCancelStatus()),
            },
          ]}
        />
      </ReservationButtonsBox>
      {userInfo && (
        <UserProfilePreviewModal
          variant={getUserPreviewVariant()}
          userInfo={userInfo}
          discountOptions={discountOptions}
          isShown={isShownUserProfilePreviewModal}
          closeModal={closeUserProfilePreviewModal}
          declineInvitation={declineInvitation}
          deactivateConnectionInvitation={deactivateConnectionInvitation}
          deactivateEmailInvitation={deactivateEmailInvitation}
          deleteFromFriends={deleteFromFriends}
          sendInvitation={sendInvitation}
          sendInvitationReminder={sendInvitationReminder}
          acceptInvitation={acceptInvitation}
          updateFriendOptions={updateFriendOptions}
          updateEmailInvitation={updateEmailInvitation}
        />
      )}
    </ReservationMainBox>
  );
};
