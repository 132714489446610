import { forwardRef } from "react";
import {
  StyledChooseDate,
  StyledItem,
  StyledLabel,
} from "./ReservationDateItem.styled";
import { ReservationDateItemProps } from "./ReservationDateItem.d";
import { formatDate } from "global/utils";
import { COLORS } from "theme";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const ReservationDateItem = forwardRef<
  HTMLDivElement,
  ReservationDateItemProps
>(
  (
    {
      label,
      date,
      hasIcon,
      onClick,
      withBorder = true,
      filterVariant = false,
      ...props
    },
    ref
  ) => {
    const userProfile = useProfile();
    const selectedLanguage = userProfile?.selectedLanguage || getLanguage();

    return (
      <StyledItem
        ref={ref}
        display="flex"
        hasIcon={hasIcon}
        onClick={onClick}
        clickable={!!onClick}
        withBorder={withBorder}
        filterVariant={filterVariant}
        {...props}
      >
        <StyledLabel
          variant="overline"
          filterVariant={filterVariant}
          color={COLORS.typography.body}
        >
          {label}
        </StyledLabel>
        {date ? (
          <StyledChooseDate
            filterVariant={filterVariant}
            variant="caption"
            color={COLORS.typography.headline}
          >
            {formatDate(date, "dd-MM-yyyy")}
          </StyledChooseDate>
        ) : (
          <StyledChooseDate
            filterVariant={filterVariant}
            variant="caption"
            color={COLORS.typography.headline}
          >
            {translation["chooseDate"][selectedLanguage]}
          </StyledChooseDate>
        )}
      </StyledItem>
    );
  }
);

ReservationDateItem.displayName = "ReservationDateItem";
