import { useEffect, useState } from "react";
import { makeContext } from "global/utils";
import { useAuth } from "./Auth";
import { Notification } from "types/notification";
import {
  getNotifications,
  markNotificationsAsRead,
} from "services/Notifications";
import { NOTIFICATIONS_WEBSOCKET } from "urls/api";

type NotificationsContext = {
  notifications: Notification[];
  unreadNotificationsCount: number;
  setNotificationsStatusToRead: () => void;
  markNotificationAsClicked: (id: string) => void;
  fetchUserNotifications: () => void;
};

const { useConsumer, Provider } = makeContext((): NotificationsContext => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const auth = useAuth();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";

  useEffect(() => {
    if (auth?.user && !isBasicUserType) {
      fetchUserNotifications();
      const notificationsSocket = new WebSocket(
        NOTIFICATIONS_WEBSOCKET(auth.user.user_id)
      );

      notificationsSocket.onmessage = function (e) {
        const newNotifications: Notification[] = JSON.parse(e.data);
        setUnreadNotificationsCount(
          (unreadNotificationsCount) =>
            unreadNotificationsCount + newNotifications.length
        );
        setNotifications((notifications) => [
          ...newNotifications,
          ...notifications,
        ]);
      };
    }
  }, [auth?.user]);

  const fetchUserNotifications = async () => {
    const response = await getNotifications(notifications.length + 10);
    if (response?.status === 200) {
      const { results, unread_count } = response.parsedBody;
      setUnreadNotificationsCount(unread_count);
      setNotifications(results);
    }
  };

  const updateNotificationsList = () => {
    const updatedNotifications: Notification[] = notifications.map(
      (notification) => {
        if (notification.status === 0) return { ...notification, status: 1 };
        return notification;
      }
    );
    setUnreadNotificationsCount(0);
    setNotifications(updatedNotifications);
  };

  const setNotificationsStatusToRead = () => {
    if (unreadNotificationsCount > 0)
      markNotificationsAsRead().then((response) => {
        if (response.ok) {
          updateNotificationsList();
        }
      });
  };

  const markNotificationAsClicked = (id: string) => {
    const updatedNotifications: Notification[] = notifications.map(
      (notification) => {
        if (notification.id === id) return { ...notification, status: 2 };
        return notification;
      }
    );
    setNotifications(updatedNotifications);
  };

  return {
    notifications,
    unreadNotificationsCount,
    setNotificationsStatusToRead,
    markNotificationAsClicked,
    fetchUserNotifications,
  };
});

export const NotificationsProvider = Provider;
export const useNotifications = useConsumer;
