import { Tabs } from "components/Tabs";
import { FC } from "react";
import { HouseServicesProps } from "./HouseServices.d";
import { ServicesItem } from "./ServicesItem";
import { withSlider } from "global/hooks/withSlider";
import { Slider } from "components/Slider";
import { Box } from "components/Box";
import { StyledTitle, StyledContainer } from "./HouseServices.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseServices: FC<HouseServicesProps> = ({ tabsData }) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const tabsWithData = Object.entries(tabsData).filter(
    ([, data]) => data.length
  );

  return (
    <StyledContainer>
      {tabsWithData.length > 0 && (
        <Box>
          <StyledTitle variant="h2">
            {translation["services"][selectedLanguage]}
          </StyledTitle>
          <Tabs
            tabs={tabsWithData.map(([label, data]) => ({
              label,
              content: (
                <Box>
                  <Slider
                    itemsNumber={data.length}
                    spaceBetween={20}
                    allowTouchMove={false}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        allowTouchMove: true,
                      },
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        allowTouchMove: true,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {data.map((item) => {
                      const ServicesItemWithSlider = withSlider(ServicesItem);
                      return (
                        <ServicesItemWithSlider
                          key={item.id}
                          id={item.id}
                          title={item.title}
                          comment={item.comment}
                          url={item.url}
                          phoneNumber={item.phone_number}
                          email={item.email}
                          images={item.images}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              ),
            }))}
          />
        </Box>
      )}
    </StyledContainer>
  );
};
