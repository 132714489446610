import { ACCEPTORS, APPLY_FOR_MEMBERSHIP } from "urls/api";
import fetchApi from "./fetchApi";

interface Acceptor {
  id: string;
  name: string;
  last_name: string;
}

export const fetchAcceptors = () => {
  const fetchAcceptors = fetchApi<Acceptor[]>(ACCEPTORS);
  return fetchAcceptors;
};

interface ApplyForMembershipForm {
  acceptors: string[];
  message: string;
}

export const fetchApplyForMembership = (data: ApplyForMembershipForm) =>
  fetchApi(APPLY_FOR_MEMBERSHIP, {
    body: JSON.stringify(data),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
