import { FC, PropsWithChildren } from "react";
import {
  StyledHeaderContainer,
  StyledLeftImage,
  StyledRightImage,
} from "./Header.styled";

export const Header: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledHeaderContainer>
      <StyledLeftImage />
      {children}
      <StyledRightImage />
    </StyledHeaderContainer>
  );
};
