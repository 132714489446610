import { Box } from "components/Box";
import styled from "styled-components";
import { pxToRem } from "global/utils";
import { SIZES } from "theme";

export const Wrapper = styled(Box)`
  width: 100%;
  min-height: 100vh;
  background-image: url(/images/background.jpeg);
  background-size: cover;
`;

export const HeaderLogoWrapper = styled(Box)`
  display: none;

  ${SIZES.media.tablet} {
    display: block;
    width: 260px;
    position: absolute;
    top: ${pxToRem(42)};
    left: ${pxToRem(50)};

    svg {
      path {
        fill: white;
      }
    }
  }
`;

export const ContentWrapper = styled(Box)`
  flex-grow: 1;
`;

export const LanguageSelectWrapper = styled(Box)`
  position: absolute;
  right: 52px;
  top: 32px;
`;
