import { IconButton } from "components/IconButton";
import { List } from "components/List";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS } from "theme";

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  width: 24px;
  height: 24px;

  svg {
    &:hover {
      path:first-child {
        fill: ${COLORS.accent.hover};
      }

      path:nth-of-type(2) {
        fill: ${COLORS.background.main};
      }
    }
  }
`;

export const StyledList = styled(List)`
  li {
    padding: ${pxToRem(12)} ${pxToRem(18)};
    margin: 0 auto;

    p {
      font-weight: 600;
      font-size: 13px;
      color: ${COLORS.typography.headline};
      text-align: center;

      &:hover {
        color: ${COLORS.accent.primary};
      }
    }
  }
`;
