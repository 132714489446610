import { Box } from "components/Box";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { ImageProps, StaticNoImageType } from "./Image.d";

const photoWidth = {
  icon: "25px",
  galleryBig: "846px",
  galleryMedium: "413px",
  gallerySmall: "196px",
  sectionMedium: "630px",
  card: "305px",
  gallery: "100%",
  smallGallery: "100%",
  flexible: "100%",
  avatarLarge: "156px",
  avatarBig: "76px",
  avatarMedium: "60px",
  avatarSmall: "15px",
  avatarFriend: "112px",
  avatarSearchResult: "56px",
  avatarUserPreview: "112px",
  avatarPreview: "32px",
  mutualFriend: "19px",
  mapTooltip: "94px",
  bookingMedium: "197px",
  quote: "315px",
  howitworks: "630px",
};

const photoHeight = {
  icon: "25px",
  galleryBig: "564px",
  galleryMedium: "348px",
  gallerySmall: "196px",
  sectionMedium: "420px",
  card: "194px",
  gallery: "275px",
  smallGallery: "169px",
  flexible: "100%",
  avatarLarge: "156px",
  avatarBig: "76px",
  avatarMedium: "60px",
  avatarSmall: "15px",
  avatarFriend: "110px",
  avatarSearchResult: "56px",
  avatarUserPreview: "110px",
  avatarPreview: "32px",
  mutualFriend: "19px",
  mapTooltip: "82px",
  bookingMedium: "139px",
  quote: "426px",
  howitworks: "420px",
};

const mobileWidth = {
  icon: "25px",
  galleryBig: "846px",
  galleryMedium: "413px",
  gallerySmall: "196px",
  sectionMedium: "370px",
  card: "305px",
  gallery: "100%",
  smallGallery: "100%",
  flexible: "100%",
  avatarLarge: "156px",
  avatarBig: "56px",
  avatarMedium: "40px",
  avatarSmall: "15px",
  avatarFriend: "112px",
  avatarSearchResult: "56px",
  avatarUserPreview: "112px",
  avatarPreview: "32px",
  mutualFriend: "19px",
  bookingMedium: "100%",
  quote: "233px",
  howitworks: "284px",
  mapTooltip: "82px",
};

const mobileHeight = {
  icon: "25px",
  galleryBig: "215px",
  galleryMedium: "348px",
  gallerySmall: "196px",
  sectionMedium: "170px",
  card: "194px",
  gallery: "193px",
  smallGallery: "193px",
  flexible: "100%",
  avatarLarge: "156px",
  avatarBig: "56px",
  avatarMedium: "40px",
  avatarSmall: "15px",
  avatarFriend: "110px",
  avatarSearchResult: "56px",
  avatarUserPreview: "110px",
  avatarPreview: "32px",
  mutualFriend: "19px",
  bookingMedium: "100%",
  quote: "316px",
  howitworks: "191px",
  mapTooltip: "82px",
};

export const StyledImage = styled.img<ImageProps>`
  display: block;
  ${({ variant }) => variant && `height: ${mobileHeight[variant]}`};
  ${({ variant }) => variant && `width: ${mobileWidth[variant]}`};
  max-width: 100%;
  object-fit: cover;
  ${({ isRound }) => isRound && "border-radius: 50%"};

  ${SIZES.media.phone} {
    height: 100%;
    width: 100%;
    ${({ variant }) => variant === "mapTooltip" && "width: 94px"};
    ${({ variant }) => variant === "avatarMedium" && "width: 60px"};
    ${({ variant }) => variant === "avatarMedium" && "height: 60px"};
    ${({ variant }) => variant === "avatarBig" && "width: 76px"};
    ${({ variant }) => variant === "avatarBig" && "height: 76px"};
    ${({ variant }) => variant === "avatarPreview" && "height: 32px"};
    ${({ variant }) => variant === "avatarPreview" && "width: 32px"};
    ${({ variant }) => variant === "quote" && "width: 315px"};
    ${({ variant }) => variant === "quote" && "height: 426px"};
    ${({ variant }) => variant === "howitworks" && "width: 630px"};
    ${({ variant }) => variant === "howitworks" && "height: 420px"};
    ${({ variant }) => variant === "gallery" && "height: 250px"};
  }

  ${SIZES.media.tablet} {
    height: ${({ variant }) => variant && photoHeight[variant]};
    width: ${({ variant }) => variant && photoWidth[variant]};
  }
`;

export const StyledNoImageContainer = styled(Box)<StaticNoImageType>`
  ${({ withBorder }) =>
    withBorder && `border: 1px solid ${COLORS.stroke.main}`};
  height: auto;
  width: 100%;

  ${SIZES.media.tablet} {
    height: ${({ sizeVariant }) => sizeVariant && photoHeight[sizeVariant]};
    width: ${({ sizeVariant }) => sizeVariant && photoWidth[sizeVariant]};
  }
`;
