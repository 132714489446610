import styled from "styled-components";

interface Props {
  variant: "PUBLIC" | "PRIVATE";
}

export const StyledSelect = styled.select<Props>`
  width: 50px;
  border: none;
  background-color: white;
  outline: none;
  ${({ variant }) =>
    variant === "PRIVATE"
      ? `
  margin-left: 16px;
  `
      : `
  background-color: transparent;
  color: white;
  `}
`;

export const StyledOption = styled.option`
  color: black;
`;
