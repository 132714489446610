import {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { Box } from "components/Box";
import { Select } from "components/Select";
import { fetchHouses } from "services/Houses";
import {
  FilterContainer,
  FilterContentContainer,
  GuestContainer,
  GuestButton,
  GuestButtonContainer,
  SearchButton,
  SelectContainer,
  StyledCalendar,
  GuestLabel,
  GuestHeader,
} from "./HousesFilters.styled";
import { Option } from "components/Select/Select.d";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Guests } from "components/Booking/Guests";
import { HouseItem } from "components/HousesList/HousesList.d";
import ReactDatePicker from "react-datepicker";
import { CalendarVariant } from "components/Calendar/Calendar.enums";
import { useCalculator } from "context/Calculator";
import { CalendarFooter } from "components/Calendar/CalendarFooter";
import { useWindowSize } from "global/hooks/useWindowSize";
import { COLORS } from "theme";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

interface Props {
  setHouses: Dispatch<SetStateAction<HouseItem[]>>;
  setTotalHouses: Dispatch<SetStateAction<number>>;
  setisLoaded: Dispatch<SetStateAction<boolean>>;
  options: Array<Option>;
  selectedOption: Option | undefined;
  setSelectedOption: Dispatch<SetStateAction<Option | undefined>>;
}

const HousesFilter: FC<Props> = ({
  options,
  selectedOption,
  setSelectedOption,
  setHouses,
  setTotalHouses,
  setisLoaded,
}) => {
  const profile = useProfile();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = userType ? JSON.parse(userType) === "BASIC" : false;
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const {
    adults,
    children,
    infants,
    startDate,
    endDate,
    setAdults,
    setChildren,
    setInfants,
    setStartDate,
    setEndDate,
    resetDates,
    resetCalculator,
  } = useCalculator();

  const [showGuest, setShowGuests] = useState(false);

  const { isMobile, isTablet } = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<
    ReactDatePicker & { setOpen: (open: boolean) => void }
  >(null);

  const handleDateChange = (date: [Date | null, Date | null]) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    }
  };

  const updateCounter = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    counterValue: number
  ) => {
    e.preventDefault();
    if (id === "adults_num") {
      setAdults(counterValue);
    } else if (id === "children_num") {
      setChildren(counterValue);
    } else if (id === "infants_num") {
      setInfants(counterValue);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (!ref.current?.contains(target)) {
      setShowGuests(false);
    }
  };

  const closeDesktopCalendar = () => {
    calendarRef?.current?.setOpen(false);
  };

  const canSubtract = (counter: number, isAdult = false) =>
    isAdult ? counter > 1 : counter > 0;

  const handleSubmit = async () => {
    setisLoaded(false);
    const queryParams = new URLSearchParams({
      location: selectedOption ? selectedOption.value : "",
      date_from: startDate?.toISOString().slice(0, 10) ?? "",
      date_to: endDate?.toISOString().slice(0, 10) ?? "",
      adults: `${adults}`,
      children: `${children}`,
      infants: `${infants}`,
    });

    const response = await fetchHouses(queryParams);
    if (response.ok) {
      setHouses(response.parsedBody.results.houses);
      setTotalHouses(response.parsedBody.results.total_houses);
    }
    setisLoaded(true);
  };

  const guestsLabel = `${adults} ${translation["adultsCounter"][selectedLanguage]}, ${children} ${translation["childrenCounter"][selectedLanguage]}, ${infants} ${translation["infantsCounter"][selectedLanguage]}`;

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const guestsTypes = [
    {
      id: "adults_num",
      label: translation["adultsLabel"][selectedLanguage],
      value: adults,
      max: 5,
      price: 100,
    },
    {
      id: "children_num",
      label: translation["childrenLabel"][selectedLanguage],
      value: children,
      max: 5,
      price: 200,
    },
    {
      id: "infants_num",
      label: translation["infantsLabel"][selectedLanguage],
      value: infants,
      max: 5,
      price: 300,
    },
  ];

  return (
    <FilterContainer>
      <FilterContentContainer display="grid" gridGap={2}>
        <SelectContainer>
          <Select
            value={selectedOption}
            options={options}
            onChange={(option) => setSelectedOption(option)}
          />
        </SelectContainer>
        <StyledCalendar
          ref={calendarRef}
          minDate={new Date()}
          checkInDate={startDate}
          monthsShown={isMobile || isTablet ? 1 : 2}
          checkOutDate={endDate}
          onChange={handleDateChange}
          variant={CalendarVariant.PUBLIC}
          filterVariant
          calendarFooter={
            <CalendarFooter
              isPrivate={false}
              onConfirmClick={closeDesktopCalendar}
              showConfirmButtonMobile={true}
              clearData={resetDates}
            />
          }
        />
        <GuestButtonContainer ref={ref}>
          <GuestButton
            variant={ButtonVariant.SECONDARY}
            onClick={() => setShowGuests(!showGuest)}
          >
            <Box p={2} display="grid" gridAutoFlow="row">
              <GuestHeader variant="overline" color={COLORS.typography.body}>
                {translation["guests"][selectedLanguage]}
              </GuestHeader>
              <GuestLabel variant="caption" color={COLORS.typography.headline}>
                {guestsLabel}
              </GuestLabel>
            </Box>
          </GuestButton>
          {showGuest && (
            <GuestContainer>
              <Guests
                guestsTypes={guestsTypes}
                canSubtract={canSubtract}
                canAdd={() => true}
                updateCounter={updateCounter}
              />
            </GuestContainer>
          )}
        </GuestButtonContainer>
        <SearchButton
          size={ButtonSize.SMALL}
          onClick={handleSubmit}
          disabled={isBasicUserType}
        >
          {translation["searchHouse"][selectedLanguage]}
        </SearchButton>
      </FilterContentContainer>
    </FilterContainer>
  );
};

export default HousesFilter;
