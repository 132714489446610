import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  width: 78px;
  padding: ${pxToRem(10)} ${pxToRem(12)};
  font-size: 13px;
`;

export const StyledMutualFriends = styled(Typography)`
  opacity: 0.7;
  line-height: 26px;
`;

export const StyledFullName = styled(Typography)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
