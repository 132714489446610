import { Box } from "components/Box";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Chip } from "components/Chip";
import { IconButton } from "components/IconButton";
import { Typography } from "components/Typography";
import { FC, useMemo, useRef, useState } from "react";
import { ReservationDatePicker } from "../ReservationDatePicker";
import { BookingListItemProps } from "./BookingListItem.d";
import {
  BookingMainBox,
  BookingImageBox,
  BookingContentBox,
  BookingTypographyTitle,
  BookingButtonsBox,
  BookingTypographyOldPrice,
  PriceBox,
  ReservationBox,
  PeopleBox,
  ExtraBox,
} from "./BookingListItem.styled";
import { Image } from "components/Image";
import { ReactComponent as GuestIcon } from "assets/icons/icon_guests.svg";
import { HOST_URL } from "urls/api";
import { Button } from "components/Button";
import { COLORS } from "theme";
import { generatePath, useNavigate } from "react-router";
import { CONTACT_PAGE, HOUSE_PAGE, HOUSE_REDIRECT } from "urls/frontend";
import { BookingsStatusAction, BookingStatus } from "types/enums";
import { KebabMenu } from "components/KebabMenu";
import { Link } from "components/Link";
import { useModal } from "global/hooks/useModal";
import ReservationModal from "components/Modals/ReservationsModal/ReservationModal";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const BookingListItem: FC<BookingListItemProps> = ({
  id,
  house_id,
  check_in_date,
  check_out_date,
  cancellation_fee,
  adults_num,
  children_num,
  main_photo,
  title,
  price,
  price_without_discount,
  booking_status,
  booking_background_color,
  booking_text_color,
  is_editable,
  is_cancellable,
  can_pay_balance,
  can_pay_deposit,
  cancelByUser,
  payDeposit,
  payBalance,
  extras,
}) => {
  const [status, setStatus] = useState<BookingsStatusAction>(
    BookingsStatusAction.NONE
  );
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const bookingStatusLabel = booking_status.split("_").join(" ");
  const reservationRedirectHouseLink = generatePath(HOUSE_PAGE, {
    id: house_id,
  });
  const isRequestNewButtonVisisble = useMemo(
    () =>
      booking_status in
      [
        BookingStatus.CANCELLED_BY_ADMIN,
        BookingStatus.CANCELLED_BY_GUEST,
        BookingStatus.CANCELLED_BY_OWNER,
        BookingStatus.EXPIRED,
        BookingStatus.FINISHED,
        BookingStatus.DECLINED_BY_ADMIN,
        BookingStatus.DECLINED_BY_OWNER,
      ],
    [booking_status]
  );

  const showModal = async (action: BookingsStatusAction) => {
    setStatus(action);
    toggle();
  };

  const closeModal = () => {
    setStatus(BookingsStatusAction.NONE);
    toggle();
  };

  const onConfirm = () => {
    cancelByUser(id);
  };

  const getCancelActionStatus = () => {
    return booking_status === BookingStatus.DEPOSIT_PAID
      ? BookingsStatusAction.CANCELLED_BY_USER_AND_PAID_DEPOSIT
      : booking_status === BookingStatus.BOOKED ||
        booking_status === BookingStatus.ABOUT_TO_START
      ? BookingsStatusAction.CANCELLED_BY_USER_AND_PAID_BALANCE
      : BookingsStatusAction.CANCELLED_BY_USER;
  };

  return (
    <BookingMainBox px={2} py={2} mb={2} display="flex" flexWrap="wrap">
      {status !== BookingsStatusAction.NONE && (
        <ReservationModal
          status={status}
          isShown={isShown}
          price={price}
          refund={cancellation_fee}
          onConfirm={onConfirm}
          onClose={closeModal}
        />
      )}
      <BookingImageBox mr={2}>
        <Image
          variant={"bookingMedium"}
          src={main_photo && `${HOST_URL}${main_photo}`}
        />
      </BookingImageBox>
      <BookingContentBox display="flex" flexDirection="column" flexWrap="wrap">
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box display="flex" flexDirection="column" alignItems="start">
            <Link to={reservationRedirectHouseLink} linkColor="black">
              <BookingTypographyTitle variant={"h4"}>
                {title}
              </BookingTypographyTitle>
            </Link>
            <Chip
              label={bookingStatusLabel}
              backgroundColor={booking_background_color}
              color={booking_text_color}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <ReservationBox>
            <ReservationDatePicker
              checkInDate={check_in_date ? new Date(check_in_date) : undefined}
              checkOutDate={
                check_out_date ? new Date(check_out_date) : undefined
              }
              withBorder={false}
            />
          </ReservationBox>
          <PeopleBox display="flex" alignItems="center">
            <IconButton>
              <GuestIcon />
            </IconButton>
            <Typography variant={"caption"} color={COLORS.typography.body}>
              {adults_num ? `${adults_num} adults` : ""}
              {adults_num && children_num ? ", " : ""}
              {children_num ? `${children_num} children` : ""}
            </Typography>
          </PeopleBox>
        </Box>
        <PriceBox display="flex">
          {price ? (
            price_without_discount ? (
              <>
                <BookingTypographyOldPrice
                  variant={"h4"}
                  color={COLORS.stroke.main}
                >
                  €{price_without_discount}
                </BookingTypographyOldPrice>
                <Typography variant={"h4"}>
                  €{price} {translation["totalPrice"][selectedLanguage]}
                </Typography>
              </>
            ) : (
              <Typography variant={"h4"}>
                €{price} {translation["totalPrice"][selectedLanguage]}
              </Typography>
            )
          ) : (
            <Typography variant={"h4"}>
              {translation["priceOnRequest"][selectedLanguage]}
            </Typography>
          )}
        </PriceBox>
        <Box>
          {extras.map((extra, index) => {
            return (
              <ExtraBox
                display="grid"
                gridAutoFlow="column"
                justifyContent="space-between"
                key={`extra-${index}`}
              >
                <Box>
                  <Typography variant={"caption"}>{extra.name}</Typography>
                </Box>
                <Box>
                  <Typography
                    variant={"caption"}
                    color={COLORS.typography.body}
                  >
                    {extra.price} $
                  </Typography>
                </Box>
              </ExtraBox>
            );
          })}
        </Box>
      </BookingContentBox>
      <BookingButtonsBox display="flex" alignItems="center" flexWrap="wrap">
        {can_pay_balance && (
          <Box pr={3}>
            <Button
              size={ButtonSize.SMALL}
              variant={ButtonVariant.SECONDARY}
              onClick={() => payBalance(id)}
            >
              {translation["payTheBalance"][selectedLanguage]}
            </Button>
          </Box>
        )}
        {can_pay_deposit && (
          <Box pr={3}>
            <Button
              size={ButtonSize.SMALL}
              variant={ButtonVariant.SECONDARY}
              onClick={() => payDeposit(id)}
            >
              {translation["payDeposit"][selectedLanguage]}
            </Button>
          </Box>
        )}
        {isRequestNewButtonVisisble && (
          <Box pr={3}>
            <Button
              size={ButtonSize.SMALL}
              variant={ButtonVariant.SECONDARY}
              onClick={() => {
                navigate(HOUSE_REDIRECT(house_id));
              }}
            >
              {translation["requestAgain"][selectedLanguage]}
            </Button>
          </Box>
        )}
        <KebabMenu
          menuOptions={[
            {
              label: translation["edit"][selectedLanguage],
              value: "Edit",
              isShown: is_editable,
              onClick: () => {
                navigate(HOUSE_REDIRECT(house_id)),
                  {
                    state: {
                      open: true,
                    },
                  };
              },
            },
            {
              label: translation["cancel"][selectedLanguage],
              value: "Cancel",
              isShown: is_cancellable,
              onClick: () => showModal(getCancelActionStatus()),
            },
            {
              label: translation["contactSupport"][selectedLanguage],
              value: "Contact support",
              isShown: true,
              onClick: () => navigate(CONTACT_PAGE),
            },
          ]}
        />
      </BookingButtonsBox>
    </BookingMainBox>
  );
};
