import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { createGlobalStyle } from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
  }
`;

export const StyledTitleContainer = styled(Box)`
  h4 {
    line-height: 22px;
  }
`;

export const TopContainer = styled(Box)`
  .flag-dropdown {
    z-index: 30 !important;
  }

  ${SIZES.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledAccessTypeSelect = styled(Box)`
  grid-template-columns: 237px auto;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const StyledQuestionMarkIcon = styled(Box)`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background-color: ${COLORS.stroke.main};
  font-size: 9px;
  font-family: Inter, sans-serif;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonsContainer = styled(Box)`
  ${SIZES.media.tablet} {
    padding-bottom: ${pxToRem(32)};
  }
`;
