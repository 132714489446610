import styled from "styled-components";
import { pxToRem } from "global/utils";
import { COLORS, SIZES } from "theme";
import { Input } from "components/Input";
import { Dropdown } from "components/Dropdown";
import { ReactComponent as ArrowIcon } from "assets/icons/icon_select_arrow.svg";
import { Box } from "components/Box";

export const StyledContainer = styled(Box)`
  position: relative;
`;

export const StyledInput = styled(Input)`
  padding: ${pxToRem(20.5)} ${pxToRem(18)};
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.typography.headline};
  cursor: auto;

  &::placeholder {
    color: ${COLORS.typography.headline};
  }
`;

export const StyledDropdown = styled(Dropdown)`
  padding: ${pxToRem(9)} ${pxToRem(20)};
  overflow: auto;
  max-height: 229px;
  border: 1px solid ${COLORS.stroke.main};
  border-top: none;

  ${SIZES.media.tablet} {
    width: ${pxToRem(308)};
  }

  ${SIZES.media.desktop} {
    width: ${pxToRem(413)};
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  position: absolute;
  top: 26px;
  right: 22px;
`;

export const StyledSelectedHouseOwnersList = styled(Box)`
  background-color: ${COLORS.accent.boxes};
`;
