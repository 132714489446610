import { Box } from "components/Box";
import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const Content = styled(Box)`
  box-sizing: border-box;
  background-color: ${COLORS.typography.secondary.main};
  padding: ${pxToRem(32)} ${pxToRem(14)};
  width: ${pxToRem(284)};
  height: ${pxToRem(476)};

  ${SIZES.media.tablet} {
    width: ${pxToRem(550)};
    height: ${pxToRem(595)};
    padding: ${pxToRem(64)}};
  }
`;

export const LoginText = styled(Typography)`
  text-align: center;
`;

export const ResetPassword = styled(Typography)`
  line-height: 17px;
  color: ${COLORS.typography.body};
`;

export const StyledButton = styled(Button)`
  padding: ${pxToRem(18)} ${pxToRem(16)};
  ${SIZES.media.tablet} {
    padding: ${pxToRem(18)} ${pxToRem(32)};
  }
`;
