import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { KebabMenu } from "components/KebabMenu";

export const StyledContainer = styled(Box)`
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${COLORS.stroke.main};
  justify-items: center;
  width: 198px;
  margin: 0 auto;
`;

export const StyledKebabMenu = styled(KebabMenu)`
  position: absolute;
  top: 15px;
  right: 10px;
`;

export const StyledFullName = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledButton = styled(Button)`
  width: 154px;
  padding: ${pxToRem(12)} ${pxToRem(17)};
`;

export const StyledLocation = styled(Typography)`
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;

export const HouseOwnerContainer = styled(Box)`
  width: 29px;
  height: 29px;
  box-shadow: 0px 8px 40px rgba(185, 177, 177, 0.4);
  border-radius: 50%;
  position: absolute;
  background: ${COLORS.background.main};
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled(Box)`
  position: relative;
  margin: 0 auto;
  height: fit-content;
`;

export const StyledConfirmationModalContent = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.small};
  }
`;
