import { ButtonVariant } from "components/Button/Button.enums";
import { FC } from "react";
import { Props } from "./QRCodeBoxes.d";
import { ReactComponent as IconOrangePlus } from "assets/icons/icon_orange_plus_circle.svg";
import {
  AddHouseContainer,
  AddHouseOwnerContainer,
  AddContent,
  AddDescription,
  AddHeader,
  AddHouseOwnerImage,
  QRCodeContainer,
  StyledContainer,
  StyledAddHouseImage,
  StyledButton,
  QRCodeContent,
  QRCodeDescription,
  ButtonsContainer,
  StyledQRCodeContainer,
  StyledGoogleWalletIcon,
  StyledAppleWalletIcon,
} from "./QRCodeBoxes.styled";
import InviteOwnerModal from "components/Modals/InviteOwnerModal/InviteOwnerModal";
import { FRONTEND_HOST, HOUSE_APPLICATION_PAGE_OWNER } from "urls/frontend";
import QRCode from "react-qr-code";
import { useWindowSize } from "global/hooks/useWindowSize";
import { getLanguage } from "utils/getLanguage";
import { useProfile } from "context/Profile";
import translation from "./translation.json";
import { useModal } from "global/hooks/useModal";
import { useNavigate } from "react-router";
import { useGetDeviceType } from "global/hooks/useGetDeviceType";
import { Box } from "components/Box";
import { fetchInvitationPass } from "services/Invitation";

const QRCodeBoxes: FC<Props> = ({ token, className }) => {
  const { isTrueTablet } = useWindowSize();
  const { isShown, toggle } = useModal();
  const navigate = useNavigate();
  const profile = useProfile();
  const { deviceType } = useGetDeviceType();

  const invitationLink = token && `${FRONTEND_HOST}/invite-friend/${token}`;
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const getQRCodeSize = () => {
    if (isTrueTablet) return 156;
    return 108;
  };

  const handleAddHouse = () => {
    navigate(HOUSE_APPLICATION_PAGE_OWNER);
  };

  const getInvitationPass = async (isApple: boolean) => {
    const response = await fetchInvitationPass(isApple);

    if (response) {
      const url = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.download = "invitation.pkpass";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <InviteOwnerModal isShown={isShown} toggle={toggle} />
      <StyledContainer className={className}>
        <QRCodeContainer>
          <QRCodeContent>
            <div>
              <QRCodeDescription variant="caption">
                {translation["inviteEasily"][selectedLanguage]}{" "}
                <QRCodeDescription variant="caption" isOrange>
                  {translation["qrCode"][selectedLanguage]}
                </QRCodeDescription>{" "}
                {translation["inviteEasilyEnding"][selectedLanguage]}
              </QRCodeDescription>
              <Box mt={1}>
                <QRCodeDescription variant="caption">
                  {translation["letScan"][selectedLanguage]}
                </QRCodeDescription>
              </Box>
            </div>
            <ButtonsContainer>
              {/* {deviceType === "Android" && (
                <StyledGoogleWalletIcon
                  onClick={() => getInvitationPass(false)}
                />
              )} */}
              {/* {deviceType === "iOS" && <StyledAppleWalletIcon />} */}
              <StyledAppleWalletIcon onClick={() => getInvitationPass(true)} />
              <StyledGoogleWalletIcon
                onClick={() => getInvitationPass(false)}
              />
            </ButtonsContainer>
            <StyledQRCodeContainer>
              <QRCode
                value={invitationLink}
                size={getQRCodeSize()}
                id="qr-code"
              />
            </StyledQRCodeContainer>
          </QRCodeContent>
        </QRCodeContainer>
        {profile?.profileInfo?.can_invite_owners &&
          profile.profileInfo.is_house_owner && (
            <>
              <AddHouseOwnerContainer>
                <AddHouseOwnerImage />
                <AddContent>
                  <AddHeader variant="h3">
                    {translation["addHouseOwner"][selectedLanguage]}
                  </AddHeader>
                  <AddDescription variant="h2">
                    <p>{translation["doYouHaveFriends"][selectedLanguage]}</p>
                    <p>{translation["simplyInvite"][selectedLanguage]}</p>
                  </AddDescription>
                  <StyledButton
                    variant={ButtonVariant.SECONDARY}
                    icon={<IconOrangePlus />}
                    onClick={toggle}
                  >
                    {translation["addHouseOwner"][selectedLanguage]}
                  </StyledButton>
                </AddContent>
              </AddHouseOwnerContainer>
              <AddHouseContainer>
                <StyledAddHouseImage />
                <AddContent>
                  <AddHeader variant="h3">
                    {translation["addHouse"][selectedLanguage]}
                  </AddHeader>
                  <AddDescription variant="h2">
                    {translation["expandYourPortfolio"][selectedLanguage]}
                  </AddDescription>
                  <StyledButton
                    variant={ButtonVariant.SECONDARY}
                    icon={<IconOrangePlus />}
                    onClick={handleAddHouse}
                  >
                    {translation["addHouse"][selectedLanguage]}
                  </StyledButton>
                </AddContent>
              </AddHouseContainer>
            </>
          )}

        {profile?.profileInfo?.can_invite_owners &&
          !profile?.profileInfo?.is_house_owner && (
            <>
              <AddHouseOwnerContainer>
                <AddHouseOwnerImage boxVariant="single-box" />
              </AddHouseOwnerContainer>
              <AddHouseContainer boxVariant="single-box">
                <AddContent>
                  <AddHeader variant="h3">
                    {translation["addHouseOwner"][selectedLanguage]}
                  </AddHeader>
                  <AddDescription variant="h2">
                    <p>{translation["doYouHaveFriends"][selectedLanguage]}</p>
                    <p>{translation["simplyInvite"][selectedLanguage]}</p>
                  </AddDescription>
                  <StyledButton
                    variant={ButtonVariant.SECONDARY}
                    icon={<IconOrangePlus />}
                    onClick={toggle}
                  >
                    {translation["addHouseOwner"][selectedLanguage]}
                  </StyledButton>
                </AddContent>
              </AddHouseContainer>
            </>
          )}

        {!profile?.profileInfo?.can_invite_owners &&
          profile?.profileInfo?.is_house_owner && (
            <>
              <AddHouseOwnerContainer>
                <StyledAddHouseImage boxVariant="single-box" />
              </AddHouseOwnerContainer>
              <AddHouseContainer boxVariant="single-box">
                <AddContent>
                  <AddHeader variant="h3">
                    {translation["addHouse"][selectedLanguage]}
                  </AddHeader>
                  <AddDescription variant="h2">
                    {translation["expandYourPortfolio"][selectedLanguage]}
                  </AddDescription>
                  <StyledButton
                    variant={ButtonVariant.SECONDARY}
                    icon={<IconOrangePlus />}
                    onClick={handleAddHouse}
                  >
                    {translation["addHouse"][selectedLanguage]}
                  </StyledButton>
                </AddContent>
              </AddHouseContainer>
            </>
          )}
      </StyledContainer>
    </>
  );
};

export default QRCodeBoxes;
