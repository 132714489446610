import { FC } from "react";
import {
  StyledContainer,
  StyledButton,
  StyledDoneIconContainer,
  StyledTypography,
} from "./RequestFullAccessFooter.styled";
import { Typography } from "components/Typography";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { COLORS } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { useNavigate } from "react-router";
import { APPLY_FOR_MEMBERSHIP } from "urls/frontend";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { RequestFullAccessFooterProps } from "./RequestFullAccessFooter.d";
import { ReactComponent as DoneIcon } from "assets/icons/icon_done.svg";
import { Box } from "components/Box";

export const RequestFullAccessFooter: FC<RequestFullAccessFooterProps> = ({
  isRequestSent,
}) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <StyledContainer
      display="grid"
      gridAutoFlow="row"
      gridGap={2}
      justifyItems="center"
      isRequestSent={isRequestSent}
    >
      {isRequestSent && (
        <StyledDoneIconContainer>
          <DoneIcon stroke={COLORS.background.main} />
        </StyledDoneIconContainer>
      )}
      <Box display="grid" gridAutoFlow="row" gridGap={5} justifyItems="center">
        <Typography variant="h2" color={COLORS.typography.headline}>
          {isRequestSent
            ? translation["requestSent"][selectedLanguage]
            : translation["accessRequestTitle"][selectedLanguage]}
        </Typography>
        <StyledTypography variant="body" color={COLORS.typography.headline}>
          {isRequestSent
            ? translation["requestSentApprovalMessage"][selectedLanguage]
            : translation["accessRequestMessage"][selectedLanguage]}
        </StyledTypography>
        {!isRequestSent && (
          <StyledButton
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={() => navigate(APPLY_FOR_MEMBERSHIP)}
            icon={
              <ArrowIcon
                stroke={COLORS.stroke.main}
                fill="none"
                width="20px"
                height="20px"
              />
            }
          >
            {translation["requestFullAccess"][selectedLanguage]}
          </StyledButton>
        )}
      </Box>
    </StyledContainer>
  );
};
