import ReservationsList from "components/Booking/ReservationList/ReservationsList";
import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { Tabs } from "components/Tabs";
import { FC, useEffect } from "react";
import { HeaderContainer } from "views/PrivacyPolicy/PrivacyPolicy.styled";
import { MainContainer } from "./Reservations.styled";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useNavigate } from "react-router";
import { HOUSES_PAGE } from "urls/frontend";

const Reservations: FC = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";

  const tabs = [
    { label: "Upcoming", content: <ReservationsList filter="upcoming" /> },
    { label: "Past", content: <ReservationsList filter="past" /> },
    { label: "Cancelled", content: <ReservationsList filter="cancelled" /> },
  ];

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  useEffect(() => {
    if (isBasicUserType) navigate(HOUSES_PAGE);
  }, []);

  return (
    <Layout>
      <MainContainer variant="div">
        <Breadcrumbs currentPageTitle="Reservations" />
        <HeaderContainer variant="h1">
          {translation["reservations"][selectedLanguage]}
        </HeaderContainer>
        <Tabs tabs={tabs} />
      </MainContainer>
    </Layout>
  );
};

export default Reservations;
