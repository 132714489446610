import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "components";
import { Background } from "components/Background";
import { Button } from "components/Button";
import { ButtonSize } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { FC, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { isValidForm } from "utils/isValidForm";
import { forgotPasswordSchema } from "./validators";
import { requestResetPassword } from "services/Auth";
import { Content, HeaderText } from "./ForgotPassword.styled";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

interface RecoveryForm {
  email: string;
}

const ForgotPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RecoveryForm>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: "onBlur",
  });
  const reRef = useRef<any>();

  const onClick = (data: RecoveryForm) => {
    const performResetPassword = async () => {
      const captcha_token = await reRef.current?.executeAsync();
      reRef.current.reset();
      const recaptchaData = { ...data, captcha_token: captcha_token };
      const response = await requestResetPassword(recaptchaData);
    };
    performResetPassword();
  };

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Background>
      {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      ) : null}
      <form onSubmit={handleSubmit(onClick)}>
        <Box>
          <Content
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <HeaderText variant="h3">
              {translation["enterEmail"][selectedLanguage]}
            </HeaderText>
            <Input
              {...register("email")}
              label="e-mail"
              error={!isValidForm(errors) && errors.email?.message}
            />
            <Box display="flex" justifyContent="space-between">
              <Button type="submit" size={ButtonSize.SMALL}>
                {translation["sendLink"][selectedLanguage]}
              </Button>
            </Box>
          </Content>
        </Box>
      </form>
    </Background>
  );
};

export default ForgotPassword;
