import { Box } from "components/Box";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { FC } from "react";
import { COLORS } from "theme";
import { ResultItemProps } from "./ResultItem.d";
import { StyledButton } from "./ResultItem.styled";
import { Typography } from "components/Typography";
import { ReactComponent as AvatarIcon } from "assets/icons/icon_avatar.svg";
import { ReactComponent as DoneIcon } from "assets/icons/icon_done.svg";
import { ReactComponent as PlusIcon } from "assets/icons/icon_plus.svg";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";

export const ResultItem: FC<ResultItemProps> = ({
  result,
  isAddedToHouseOwnersList,
  onRequestClick,
}) => {
  const fullName = `${result.name} ${result.last_name}`;
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={0.8}
    >
      <Box display="grid" gridAutoFlow="column" gridGap={1} alignItems="center">
        <AvatarIcon />
        <Typography variant="caption" color={COLORS.typography.headline}>
          {fullName}
        </Typography>
      </Box>
      <StyledButton
        isAddedToHouseOwnersList={isAddedToHouseOwnersList}
        variant={
          isAddedToHouseOwnersList
            ? ButtonVariant.SECONDARY
            : ButtonVariant.PRIMARY
        }
        size={isAddedToHouseOwnersList ? ButtonSize.SMALL : ButtonSize.LARGE}
        onClick={(event) => onRequestClick(event, result.id)}
      >
        <Box mr={1.2}>
          {isAddedToHouseOwnersList ? (
            <DoneIcon stroke={COLORS.accent.primary} />
          ) : (
            <PlusIcon />
          )}
        </Box>{" "}
        {translation["request"][selectedLanguage]}
      </StyledButton>
    </Box>
  );
};
