import { Box, Typography } from "components";
import QRCodeBoxes from "components/QRCodeBoxes/QRCodeBoxes";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const MainContainer = styled(Box)`
  margin: ${pxToRem(32)} ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(18)};
  ${SIZES.media.tablet} {
    margin: ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(80)} ${pxToRem(80)};
  }
`;
export const HeaderContainer = styled(Typography)`
  margin-top: ${pxToRem(80)};
  margin-bottom: ${pxToRem(18)};
  letter-spacing: 0.03em;
`;

export const StyledQRCodeBoxes = styled(QRCodeBoxes)`
  padding: 0;
`;
