import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { MapTooltip } from "components/MapTooltip";
import { FC } from "react";
import { RecommendationMapMarkerProps } from "./RecommendationMapMarker.d";
import MapMarker from "assets/icons/icon-marker-map.svg";
import { HOST_URL } from "urls/api";

export const RecommendationMapMarker: FC<RecommendationMapMarkerProps> = ({
  id,
  isFullMarker = false,
  latitude,
  longitude,
  title,
  category,
  image,
  onChangeActiveMarker,
}) => {
  const onMarkerClick = (value: string | null) => {
    onChangeActiveMarker && onChangeActiveMarker(value);
  };

  return (
    <>
      {latitude && longitude && (
        <>
          {isFullMarker && (
            <InfoWindowF
              key={latitude}
              position={{
                lat: latitude,
                lng: longitude,
              }}
              onCloseClick={() => onMarkerClick(null)}
            >
              <MapTooltip
                categoryLabel={category}
                title={title}
                image={image && `${HOST_URL}${image}`}
                latitude={latitude}
                longitude={longitude}
              />
            </InfoWindowF>
          )}
          <MarkerF
            key={id}
            position={{
              lat: latitude,
              lng: longitude,
            }}
            icon={MapMarker}
            onClick={() => onMarkerClick(id)}
          />
        </>
      )}
    </>
  );
};
