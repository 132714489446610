import { Box, IconButton } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { ReactComponent as AccordionArrowIcon } from "assets/icons/icon-accordion.svg";

export const MainContainer = styled(Box)`
  position: relative;

  ${SIZES.media.tablet} {
    margin: ${pxToRem(80)} ${pxToRem(33)};
  }

  ${SIZES.media.desktop} {
    margin: ${pxToRem(16)} ${pxToRem(80)};
  }
`;

export const StyledContainer = styled(Box)`
  ${SIZES.media.desktop} {
    display: grid;
    grid-template-columns: auto 416px;
    grid-gap: 20px;
  }
`;

export const LeftContainer = styled(Box)`
  ${SIZES.media.tablet} {
    display: grid;
    grid-gap: ${pxToRem(50)};
    grid-column: 1 / 2;
  }
`;

export const RightContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-column: 2;
  }
`;

export const StyledRulesContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
`;

export const TopContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-gap: ${pxToRem(32)};
  }
`;

export const MobileHeaderContainer = styled(Box)`
  position: absolute;
  background-color: ${COLORS.typography.secondary.main};
  height: 62px;
  width: 100%;
  top: -80px;
`;

export const StyledBackButton = styled(IconButton)`
  top: 15px;
`;

export const StyledAccordionArrowIcon = styled(AccordionArrowIcon)`
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  stroke: ${COLORS.typography.body};
`;
