import BookingList from "components/Booking/BookingList/BookingList";
import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { Tabs } from "components/Tabs";
import { FC, useEffect } from "react";
import { HeaderContainer } from "views/PrivacyPolicy/PrivacyPolicy.styled";
import { MainContainer } from "./Bookings.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { useNavigate } from "react-router";
import { HOUSES_PAGE } from "urls/frontend";

export const Bookings: FC = () => {
  const profile = useProfile();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const tabs = [
    {
      label: translation["upcoming"][selectedLanguage],
      content: <BookingList filter="upcoming" />,
    },
    {
      label: translation["past"][selectedLanguage],
      content: <BookingList filter="past" />,
    },
    {
      label: translation["cancelled"][selectedLanguage],
      content: <BookingList filter="cancelled" />,
    },
  ];

  useEffect(() => {
    if (isBasicUserType) navigate(HOUSES_PAGE);
  }, []);

  return (
    <Layout>
      <MainContainer variant="div">
        <Breadcrumbs currentPageTitle="Bookings" />
        <HeaderContainer variant="h1">
          {translation["bookings"][selectedLanguage]}
        </HeaderContainer>
        <Tabs tabs={tabs} />
      </MainContainer>
    </Layout>
  );
};
