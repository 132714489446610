import { FC } from "react";
import { ImageProps } from "./Image.d";
import { StyledImage, StyledNoImageContainer } from "./Image.styled";
import { ReactComponent as NoImageIcon } from "assets/icons/icon-no-image-provided.svg";
import { ReactComponent as NoAvatarIcon } from "assets/icons/icon_avatar_male.svg";

export const Image: FC<ImageProps> = ({
  variant,
  src,
  withBorder = false,
  isRound,
  ...props
}) => (
  <>
    {src ? (
      <StyledImage isRound={isRound} variant={variant} src={src} {...props} />
    ) : (
      <StyledNoImageContainer
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        sizeVariant={variant}
        withBorder={withBorder}
      >
        {isRound ? <NoAvatarIcon /> : <NoImageIcon />}
      </StyledNoImageContainer>
    )}
  </>
);
