import { Chip } from "components/Chip";
import { FC } from "react";
import { MapTooltipProps } from "./MapTooltip.d";
import {
  StyledContainer,
  StyledMainInfo,
  StyledLink,
  StyledTitle,
} from "./MapTooltip.styled";
import { ReactComponent as LocationIcon } from "assets/icons/icon-location.svg";
import { COLORS } from "theme";
import { Image } from "components/Image";

export const MapTooltip: FC<MapTooltipProps> = ({
  image,
  categoryLabel,
  title,
  latitude,
  longitude,
}) => {
  return (
    <StyledContainer
      display="grid"
      p={0.7}
      alignItems="start"
      justifyContent="start"
    >
      {image && <Image variant="mapTooltip" src={image} />}
      <StyledMainInfo display="grid">
        <Chip label={categoryLabel} />
        <StyledTitle variant="h4">{title}</StyledTitle>
        {latitude && longitude && (
          <StyledLink
            target="_blank"
            href={`https://maps.google.com/?ll=${latitude},${longitude}`}
            rel="noreferrer"
          >
            <LocationIcon
              fill={COLORS.accent.primary}
              width="25px"
              height="25px"
            />
            Open in maps
          </StyledLink>
        )}
      </StyledMainInfo>
    </StyledContainer>
  );
};
