import { Box } from "components/Box";
import { IconWithLabel } from "components/IconWithLabel";
import { Typography } from "components/Typography";
import { FC } from "react";
import { HOST_URL } from "urls/api";
import { HouseRulesProps } from "./HouseRules.d";
import { StyledContainer } from "./HouseRules.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseRules: FC<HouseRulesProps> = ({ rules }) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();

  return (
    <Box>
      <Typography variant="h2">
        {translation["houseRules"][selectedLanguage]}
      </Typography>
      <StyledContainer display="grid">
        {rules.map((rule, index) => {
          return (
            <IconWithLabel
              key={`${rule}-${index}`}
              label={rule.title}
              icon={rule.icon && `${HOST_URL}${rule.icon}`}
            />
          );
        })}
      </StyledContainer>
    </Box>
  );
};
