import { FC } from "react";
import { AmenitiesProps } from "./Amenities.d";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { Tabs } from "components/Tabs";
import { IconWithLabel } from "components/IconWithLabel";
import { StyledContainer, StyledBox } from "./Amenities.styled";
import { HOST_URL } from "urls/api";
import { ReactComponent as PersonIcon } from "assets/icons/icon_person.svg";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const Amenities: FC<AmenitiesProps> = ({ content }) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const allAmenitiesComponents = Object.entries(content).flatMap(
    ([, content]) => {
      return content.map(({ title, icon }, index) => {
        return (
          <IconWithLabel
            key={`${title}-${index}-all`}
            label={title}
            icon={icon ? `${HOST_URL}${icon}` : PersonIcon}
          />
        );
      });
    }
  );
  const allTabContent = (
    <StyledContainer display="grid" grid="1fr 1fr 1fr" gridGap={2}>
      {[...allAmenitiesComponents]}
    </StyledContainer>
  );
  const tabs = Object.entries(content).map(([option, content]) => {
    const amenitiesComponents = content.map(({ title, icon }, index) => {
      return (
        <IconWithLabel
          key={`${title}-${index}`}
          label={title}
          icon={icon ? `${HOST_URL}${icon}` : PersonIcon}
        />
      );
    });
    const tabContent = (
      <StyledContainer display="grid" grid="1fr 1fr 1fr" gridGap={2}>
        {[...amenitiesComponents]}
      </StyledContainer>
    );
    return {
      content: tabContent,
      label: option,
    };
  });
  tabs.unshift({
    content: allTabContent,
    label: translation["all"][selectedLanguage],
  });
  return (
    <>
      {tabs.length > 0 && (
        <StyledBox pt={3}>
          <Typography variant="h2">
            {translation["amenities"][selectedLanguage]}
          </Typography>
          <Box mt={2}>
            <Tabs tabs={tabs} />
          </Box>
        </StyledBox>
      )}
    </>
  );
};
