import styled from "styled-components";
import { COLORS } from "theme";
import { ArrowProps } from "./Slider.d";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/thumbs";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-slider-arrow.svg";

export const StyledContainer = styled.div`
  .swiper-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: min-content;
    height: auto;
  }
`;

export const StyledArrow = styled.div<ArrowProps>`
  position: absolute;
  top: 20%;
  right: ${({ isRight }) => (isRight ? "-18px" : "unset")};
  transform: ${({ isRight }) => (isRight ? "none" : "rotate(180deg)")};
  left: ${({ isRight }) => (isRight ? "unset" : "-18px")};
  font-size: 45px;
  display: grid;
  justify-content: center;
  border-radius: 50%;
  background-color: ${COLORS.typography.secondary.main};
  z-index: 20;
  align-items: center;
  padding: 14px 17px 14px 19px;
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
  transition: 0.5s;
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
  opacity: 0.8;
  &:hover {
    svg {
      stroke: ${({ disabled }) =>
        disabled ? COLORS.typography.headline : COLORS.accent.primary};
    }
  }
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
  stroke: ${COLORS.typography.headline};
`;
