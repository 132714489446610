import { Box, Typography } from "components";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Modal } from "components/Modal";
import { FC } from "react";
import { BookingsStatusAction } from "types/enums";
import { StyledContainer } from "./ReservationModal.styled";

interface ReservationModalProps {
  isShown: boolean;
  status: BookingsStatusAction;
  price?: number;
  refund: number | null;
  onConfirm?: () => void;
  onClose?: () => void;
}

interface MessageProps {
  price?: number;
  refund: number | null;
  onConfirm?: () => void;
  onClose?: () => void;
}

const ReservationApprovedMessage: FC<MessageProps> = ({ onConfirm, price }) => {
  return (
    <>
      <Typography variant="subtitle">
        Request is successfully approved
      </Typography>
      <Typography variant="body">
        Now the guest has to pay <b>€{price}</b> to reserve your place.
      </Typography>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={5}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          size={ButtonSize.SMALL}
          onClick={() => (onConfirm ? onConfirm() : undefined)}
        >
          Okay
        </Button>
      </Box>
    </>
  );
};

const ReservationRejectedMessage: FC<MessageProps> = ({
  onConfirm,
  onClose,
}) => {
  return (
    <>
      <Typography variant="subtitle">
        Are you sure you want to decline?
      </Typography>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={5}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => (onClose ? onClose() : undefined)}
        >
          No
        </Button>
        <Button
          size={ButtonSize.SMALL}
          onClick={() => (onConfirm ? onConfirm() : undefined)}
        >
          Yes, decline
        </Button>
      </Box>
    </>
  );
};

const ReservationCancelledMessage: FC<MessageProps> = ({
  onConfirm,
  onClose,
}) => {
  return (
    <>
      <Typography variant="subtitle">
        Are you sure you want to cancel?
      </Typography>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={5}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => (onClose ? onClose() : undefined)}
        >
          No
        </Button>
        <Button
          size={ButtonSize.SMALL}
          onClick={() => (onConfirm ? onConfirm() : undefined)}
        >
          Yes, cancel
        </Button>
      </Box>
    </>
  );
};

const ReservationCancelledByUserPaidDepositMessage: FC<MessageProps> = ({
  onConfirm,
  onClose,
}) => {
  return (
    <>
      <Typography variant="subtitle">
        Are you sure you want to cancel?
      </Typography>
      <Typography variant="body">Paid deposit won’t be reimbursed.</Typography>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={5}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => (onClose ? onClose() : undefined)}
        >
          No
        </Button>
        <Button
          size={ButtonSize.SMALL}
          onClick={() => (onConfirm ? onConfirm() : undefined)}
        >
          Yes, cancel
        </Button>
      </Box>
    </>
  );
};

const ReservationCancelledByUserPaidBalanceMessage: FC<MessageProps> = ({
  refund,
  onConfirm,
  onClose,
}) => {
  return (
    <>
      <Typography variant="subtitle">
        Are you sure you want to cancel?
      </Typography>
      <Typography variant="body">
        Paid <b>€{refund}</b> won’t be reimbursed.
      </Typography>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={5}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => (onClose ? onClose() : undefined)}
        >
          No
        </Button>
        <Button
          size={ButtonSize.SMALL}
          onClick={() => (onConfirm ? onConfirm() : undefined)}
        >
          Yes, cancel
        </Button>
      </Box>
    </>
  );
};

const ReservationCancelledByOwnerPaidMessage: FC<MessageProps> = ({
  refund,
  onConfirm,
  onClose,
}) => {
  return (
    <>
      <Typography variant="subtitle">
        Are you sure you want to cancel?
      </Typography>
      <Typography variant="body">
        If you continue, you will have to return <b>€{refund}</b> to the guest.
      </Typography>
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={5}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => (onClose ? onClose() : undefined)}
        >
          No
        </Button>
        <Button
          size={ButtonSize.SMALL}
          onClick={() => (onConfirm ? onConfirm() : undefined)}
        >
          Yes, cancel
        </Button>
      </Box>
    </>
  );
};

const ReservationComponent = {
  NONE: null,
  APPROVE: ReservationApprovedMessage,
  REJECT: ReservationRejectedMessage,
  CANCELLED_BY_USER: ReservationCancelledMessage,
  CANCELLED_BY_OWNER: ReservationCancelledMessage,
  CANCELLED_BY_USER_AND_PAID_DEPOSIT:
    ReservationCancelledByUserPaidDepositMessage,
  CANCELLED_BY_USER_AND_PAID_BALANCE:
    ReservationCancelledByUserPaidBalanceMessage,
  CANCELLED_BY_OWNER_AND_PAID_BALANCE: ReservationCancelledByOwnerPaidMessage,
  CANCELLED_BY_OWNER_AND_PAID_DEPOSIT: ReservationCancelledByOwnerPaidMessage,
};

const ReservationModal: FC<ReservationModalProps> = ({
  status,
  refund,
  onConfirm,
  onClose,
  isShown,
  price,
}) => {
  const Component = ReservationComponent[status];
  if (Component)
    return (
      <Modal isShown={isShown} onClose={onClose}>
        <StyledContainer display="grid" gridAutoFlow="row" gridGap={2}>
          <Component
            onClose={onClose}
            onConfirm={onConfirm}
            refund={refund}
            price={price}
          />
        </StyledContainer>
      </Modal>
    );
  return null;
};

export default ReservationModal;
