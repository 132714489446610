import styled from "styled-components";
import { pxToRem } from "../../global/utils";
import { COLORS, SIZES } from "theme";
import { Box } from "components/Box";

// TODO: make it a separate component to be used also for the menu button

interface FooterContainerProps {
  borderTop?: boolean;
  isHousePage?: boolean;
}

export const FooterContainer = styled(Box)<FooterContainerProps>`
  padding: ${pxToRem(28)} ${pxToRem(18)};
  ${({ isHousePage }) => isHousePage && `padding-bottom: ${pxToRem(120)}`};
  ${({ borderTop = false }) => borderTop && "border-top: 1px solid silver;"}

  ${SIZES.media.phone} {
    padding: ${pxToRem(24)} ${pxToRem(32)};
    ${({ isHousePage }) => isHousePage && `padding-bottom: ${pxToRem(120)}`};
  }
  ${SIZES.media.tablet} {
    grid-template-columns: auto 155px;
    padding: ${pxToRem(40)} ${pxToRem(32)};
    align-items: center;
  }
  ${SIZES.media.desktop} {
    padding: ${pxToRem(24)} ${pxToRem(85)};
  }
`;

export const FooterItemsContainer = styled(Box)`
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${pxToRem(13)} ${pxToRem(13)} ${pxToRem(13)};

  a {
    font-size: 12px;
  }

  ${SIZES.media.phone} {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
    grid-template-rows: ${pxToRem(13)} ${pxToRem(13)};
    a {
      font-size: 15px;
    }
  }

  ${SIZES.media.tablet} {
    grid-row-gap: ${pxToRem(32)};
    margin-bottom: 0;
  }

  ${SIZES.media.desktop} {
    grid-gap: ${pxToRem(24)};
    grid-template-rows: unset;
    grid-template-columns: unset;
    grid-auto-flow: column;
  }
`;

export const InstagramButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: ${pxToRem(15)};
  border: 1px solid ${COLORS.stroke.main};
  border-radius: ${pxToRem(100)};
  padding: ${pxToRem(10)} ${pxToRem(16)};
  background: none;
  outline: none;
  font-size: ${pxToRem(15)};
  font-family: "Inter", sans-serif;
  cursor: pointer;
  flex-shrink: 0;
  height: 54px;
  width: 148px;

  svg {
    height: ${pxToRem(26)};
    width: ${pxToRem(26)};
  }
`;
