import styled from "styled-components";
import { pxToRem } from "global/utils";
import { COLORS, SIZES } from "theme";
import { Input } from "components/Input";
import { Dropdown } from "components/Dropdown";
import SearchIcon from "assets/icons/icon_search.svg";

export const StyledInput = styled(Input)`
  background: url(${SearchIcon}) no-repeat scroll 25px 29px;
  padding: ${pxToRem(28)} ${pxToRem(20)} ${pxToRem(28)} ${pxToRem(55)};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.typography.body};
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  padding: ${pxToRem(14)} ${pxToRem(24)};
  overflow: auto;
  max-height: 245px;

  ${SIZES.media.tablet} {
    width: ${pxToRem(308)};
  }

  ${SIZES.media.desktop} {
    width: ${pxToRem(413)};
  }
`;
