import { Box } from "components/Box";
import { hexToRGB } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import CloseIcon from "assets/icons/icon-close.svg";
import { StyledWrapperProps } from "./Modal.d";
import { ModalAlignVariant } from "./Modal.enums";

export const Wrapper = styled(Box)<StyledWrapperProps>`
  position: fixed;
  ${({ variant }) => variant === ModalAlignVariant.RIGHT && "right: 0;"}
  z-index: 700;
  outline: 0;
  overflow-y: auto;
  border: 1px solid ${COLORS.stroke.main};
  background-color: ${COLORS.typography.secondary.main};
  transform: none;
  width: 100%;
  top: 0;
  left: 0;

  ${SIZES.media.tablet} {
    left: unset;
    width: min-content;
    ${({ variant }) =>
      variant === ModalAlignVariant.CENTER &&
      "transform: translate(-50%, -50%);"}
    ${({ variant }) =>
      variant === ModalAlignVariant.CENTER ? "top: 50%;" : "top: 0;"}
    ${({ variant }) => variant === ModalAlignVariant.CENTER && "left: 50%;"}
  }
`;

export const Backdrop = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${hexToRGB(COLORS.typography.headline, "0.2")};
  z-index: 500;
`;

export const CloseButton = styled.button`
  width: 27px;
  height: 27px;
  border: 1.5px solid ${COLORS.stroke.main};
  border-radius: 15px;
  background-image: url(${CloseIcon});
  background-position: 50% 50%;
  background-size: 65%;
  background-repeat: no-repeat;
  background-color: transparent;
  justify-self: end;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledModal = styled(Box)<StyledWrapperProps>`
  max-height: calc(100dvh - 65px);

  ${SIZES.media.tablet} {
    ${({ variant }) =>
      variant === ModalAlignVariant.CENTER &&
      "max-height: calc(100vh - 200px);"}
  }
`;
