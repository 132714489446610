import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS } from "theme";

export const StyledContainer = styled(Box)`
  white-space: pre-wrap;
  border: 1px solid ${COLORS.stroke.main};
`;
export const HouseSummaryDescription = styled(Typography)`
  white-space: pre-wrap;
`;
