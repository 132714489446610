import { FC, PropsWithChildren, useRef } from "react";
import { Box } from "components/Box";
import {
  ButtonContainer,
  ContentContainer,
  StyledArrowContainer,
} from "./Swipe.styled";
import { ReactComponent as ArrowIcon } from "assets/icons/icon_vector.svg";

interface Props {
  scrollOffset?: number;
}

const Swipe: FC<PropsWithChildren<Props>> = ({
  scrollOffset = 50,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleScroll(offset: number) {
    const container = containerRef.current as HTMLDivElement;
    if (container?.scrollLeft !== undefined) {
      container.scroll({
        left: container.scrollLeft + offset,
        behavior: "smooth",
      });
    }
  }

  return (
    <>
      <ContentContainer ref={containerRef}>
        <Box>{children}</Box>
      </ContentContainer>
      <ButtonContainer
        display="grid"
        gridAutoFlow="column"
        justifyContent="center"
        alignItems="center"
        gridGap={2}
      >
        <Box
          display="grid"
          justifyContent="center"
          alignItems="center"
          onClick={() => handleScroll(-scrollOffset)}
        >
          <ArrowIcon />
        </Box>
        <StyledArrowContainer
          display="grid"
          justifyContent="center"
          alignItems="center"
          onClick={() => handleScroll(scrollOffset)}
        >
          <ArrowIcon />
        </StyledArrowContainer>
      </ButtonContainer>
    </>
  );
};

export default Swipe;
