import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import Lightbox from "react-spring-lightbox";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { ReactComponent as AccordionArrowIcon } from "assets/icons/icon-accordion.svg";
import { StyledAccordionArrowProps } from "./Gallery.d";

export const StyledLightBox = styled(Lightbox)`
  background: ${COLORS.typography.headline};
  opacity: 0.9;
  align-content: flex-end;
`;

export const ArrowBox = styled(Button)<StyledAccordionArrowProps>`
  background-color: ${COLORS.background.main};
  align-items: center;
  border-radius: 50%;
  width: ${pxToRem(44)};
  height: ${pxToRem(44)};
  z-index: 10;
  cursor: pointer;
  padding: 0;
  margin: ${pxToRem(80)};
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
  opacity: 0.8;
  ${({ isRight }) =>
    isRight ? `margin-right: ${pxToRem(15)}` : `margin-left: ${pxToRem(15)}`};

  ${SIZES.media.tablet} {
    margin: ${pxToRem(80)};
  }

  &:enabled {
    background-color: ${COLORS.background.main};
    cursor: pointer;
    width: ${pxToRem(44)};
    height: ${pxToRem(44)};
    padding: ${pxToRem(10)};
    &:hover {
      color: ${COLORS.typography.stroke};
      background-color: ${COLORS.background.main};
      & svg {
        stroke: ${COLORS.typography.disabled};
      }
    }
    &:active {
      color: ${COLORS.typography.stroke};
      background-color: ${COLORS.background.main};
    }
  }
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  align-items: center;
  border-radius: 50%;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  z-index: 10;
  cursor: pointer;
  padding: 0;
  margin-top: ${pxToRem(30)};
  margin-bottom: ${pxToRem(30)};
  margin-right: ${pxToRem(30)};
  align-self: flex-end;
  border: 1.5px solid ${COLORS.stroke.main};
  &:enabled {
    background-color: transparent;
    cursor: pointer;
    border: 1.5px solid ${COLORS.stroke.main};
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};
    padding: ${pxToRem(10)};
    & svg {
      stroke: ${COLORS.stroke.main};
      & path {
        stroke: ${COLORS.stroke.main};
      }
    }
    &:hover {
      color: ${COLORS.stroke.main};
      background-color: transparent;
      & svg {
        stroke: ${COLORS.stroke.main};
        & path {
          stroke: ${COLORS.stroke.main};
        }
      }
    }
    &:active {
      color: ${COLORS.typography.stroke};
      background-color: transparent;
    }
  }
`;

export const StyledAccordionArrowIcon = styled(
  AccordionArrowIcon
)<StyledAccordionArrowProps>`
  transform: ${({ isRight }) => (isRight ? "rotate(90deg)" : "rotate(-90deg)")};
`;
