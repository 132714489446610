import { Background } from "components/Background";
import { FC, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  resetPassword,
  ResetPassword as ResetPassordType,
} from "services/Auth";
import { MAIN_PAGE } from "urls/frontend";
import { useForm } from "react-hook-form";
import { Box } from "components";
import { Button } from "components/Button";
import { ButtonSize } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { Content, HeaderText } from "./ResetPassword.styled";
import ReCAPTCHA from "react-google-recaptcha";
import { isValidForm } from "utils/isValidForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "./validators";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

const ResetPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<ResetPassordType, "confirm_password" | "password">>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onBlur",
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const reRef = useRef<any>();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const onClick = async (
    data: Pick<ResetPassordType, "confirm_password" | "password">
  ) => {
    const captcha_token = await reRef.current?.executeAsync();
    reRef.current.reset();
    const response = await resetPassword({
      password: data.password,
      confirm_password: data.confirm_password,
      captcha_token: captcha_token,
      token: searchParams.get("token") ?? "",
      email: searchParams.get("email") ?? "",
    });
    if (response.ok) navigate(MAIN_PAGE);
  };

  useEffect(() => {
    if (!searchParams.get("token")) navigate(MAIN_PAGE);
  }, [searchParams]);

  return (
    <Background>
      {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      ) : null}
      <form onSubmit={handleSubmit(onClick)}>
        <Box>
          <Content
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <HeaderText variant="h3">
              {translation["resetPassowrd"][selectedLanguage]}
            </HeaderText>
            <Input
              type="password"
              {...register("password")}
              label="Password"
              error={!isValidForm(errors) && errors.password?.message}
            />
            <Input
              type="password"
              {...register("confirm_password")}
              label="Confirm Password"
              error={!isValidForm(errors) && errors.confirm_password?.message}
            />
            <Box display="flex" justifyContent="space-between">
              <Button type="submit" size={ButtonSize.SMALL}>
                {translation["newPassword"][selectedLanguage]}
              </Button>
            </Box>
          </Content>
        </Box>
      </form>
    </Background>
  );
};

export default ResetPassword;
