import { Box, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const MainContainer = styled(Box)`
  grid-template-columns: auto 416px;
  max-width: ${pxToRem(736)};
  ${SIZES.media.tablet} {
    margin: ${pxToRem(80)} ${pxToRem(33)};
  }
`;

export const HeaderContainer = styled(Typography)`
  margin-top: ${pxToRem(80)};
  margin-bottom: ${pxToRem(18)};
  letter-spacing: 0.03em;
`;

export const ContentTitle = styled(Typography)`
  margin-bottom: ${pxToRem(43)};
  font-family: "Inter";
  line-height: 27px;
  font-size: 22px;
  font-weight: 500;
`;
export const ContentParagraphContainer = styled(Typography)`
  margin-bottom: ${pxToRem(31)};
  max-width: ${pxToRem(736)};
  opacity: 0.7;
  line-height: 30px;
`;
