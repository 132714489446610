import { Box, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const MainContainer = styled(Box)`
  grid-template-columns: auto 416px;
  max-width: ${pxToRem(791)};
  margin: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(305)} ${pxToRem(16)};
  ${SIZES.media.tablet} {
    margin-left: ${pxToRem(84)};
  }
`;

export const HeaderContainer = styled(Typography)`
  margin-top: ${pxToRem(80)};
  margin-bottom: ${pxToRem(18)};
  letter-spacing: 0.03em;
`;

export const ContentSubHeader = styled(Typography)`
  margin-bottom: ${pxToRem(31)};
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
`;
export const ContentTitle = styled(Typography)`
  margin-bottom: ${pxToRem(43)};
  font-family: "Inter";
  line-height: 27px;
  font-size: 22px;
  font-weight: 500;
`;

export const ContentSubTitle = styled(Typography)`
  margin-bottom: ${pxToRem(18)};
  font-family: "Inter";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const ContentHeaderContainer = styled(Typography)`
  margin-bottom: ${pxToRem(43)};
  max-width: ${pxToRem(736)};
  opacity: 0.7;
  line-height: 30px;
`;
export const ContentParagraphContainer = styled(Typography)`
  margin-bottom: ${pxToRem(31)};
  max-width: ${pxToRem(736)};
  opacity: 0.7;
  line-height: 30px;
`;
export const ContentBulletList = styled(Box)`
  margin-bottom: ${pxToRem(43)};
`;
