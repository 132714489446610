import { useState, useEffect } from "react";

export const useModal = (show = false) => {
  const [isShown, setIsShown] = useState<boolean>(show);
  const toggle = () => setIsShown(!isShown);

  return {
    isShown,
    toggle,
  };
};
