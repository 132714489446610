import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS, SIZES } from "../../theme";
import { Box } from "../Box";
import { TabItemType } from "./Tabs.d";
import { TabsVariant } from "./Tabs.enums";

export const TabsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid ${COLORS.stroke.main};
  height: 44px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const ContentWrapper = styled(Box)`
  margin-top: 24px;
`;

export const TabItem = styled.li<TabItemType>(
  ({ active = false, variant }) => css`
    display: inline-block;
    padding: 10px 16px;
    cursor: pointer;
    height: 23px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    ${variant === TabsVariant.LARGE &&
    css`
      font-family: "Inter", sans-serif;
      font-size: 16px;
      padding: ${pxToRem(10)} ${pxToRem(40)};

      ${SIZES.media.tablet} {
        padding: ${pxToRem(10)} ${pxToRem(80)};
      }
    `}

    ${active &&
    css`
      background: white;
      color: ${COLORS.accent.primary};
      border-bottom: 1px solid ${COLORS.accent.primary};
    `}
  `
);
