import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
  width: 237px;
  height: 82px;
  grid-auto-flow: column;
  grid-gap: 10px;

  ${SIZES.media.phone} {
    width: 348px;
  }
`;

export const StyledMainInfo = styled(Box)`
  grid-gap: 4px;
`;

export const StyledLink = styled.a`
  font-family: "Inter", sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: ${COLORS.accent.primary};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 15px;
  justify-content: start;
  &:focus-visible {
    outline-offset: none;
    outline: none;
  }
`;

export const StyledTitle = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
