import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";
import { Box } from "components/Box";

export const StyledItemAdditionalInfo = styled(Typography)`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  column-gap: ${pxToRem(10)};
`;

export const StyledLink = styled.a`
  font-family: "Inter", sans-serif;
  text-decoration: none;
  color: ${COLORS.typography.body};
`;

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(0.8)} ${SIZES.spacing(1)} ${SIZES.spacing(2)};
`;

export const ImageContainer = styled(Box)`
  margin: 0 auto;
`;

export const StyledDescriptionContainer = styled(Box)`
  max-width: 280px;
  width: 100%;
`;

export const StyledTitle = styled(Typography)`
  max-width: 280px;
  width: 100%;
`;
