import { FC } from "react";
import { ArrowBox } from "./Gallery.styled";
import { COLORS } from "theme";
import { ButtonVariant } from "components/Button/Button.enums";
import { GalleryArrowProps } from "./Gallery.d";
import { StyledAccordionArrowIcon } from "./Gallery.styled";

export const GalleryArrowButton: FC<GalleryArrowProps> = ({
  position,
  onClick,
}) => {
  return (
    <ArrowBox
      variant={ButtonVariant.SECONDARY}
      onClick={onClick}
      isRight={position === "right"}
    >
      {position === "left" && (
        <StyledAccordionArrowIcon
          isRight={false}
          stroke={COLORS.accent.primary}
          fill="none"
        />
      )}
      {position === "right" && (
        <StyledAccordionArrowIcon
          isRight
          stroke={COLORS.accent.primary}
          fill="none"
        />
      )}
    </ArrowBox>
  );
};
