import { Box } from "components/Box";
import { Divider } from "components/Divider";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { ReactComponent as AccordionArrowIcon } from "assets/icons/icon-accordion.svg";
import { StyledAccordionArrowProps } from "./BookedHouseItem.d";

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.typography.secondary.main};
  border: none;
  padding: ${pxToRem(0)};
  max-width: unset;

  ${SIZES.media.tablet} {
    border: 1px solid ${COLORS.stroke.main};
    padding: ${pxToRem(32)};
    max-width: 348px;
  }
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  &:hover {
    svg {
      stroke: ${COLORS.accent.primary};
    }
  }
`;

export const StyledEditButtonContainer = styled(Box)`
  width: 62px;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: ${pxToRem(10)};
  margin-top: ${pxToRem(10)};

  ${SIZES.media.tablet} {
    margin-bottom: ${pxToRem(20)};
    margin-top: ${pxToRem(20)};
  }
`;

export const StyledSelectArrowIcon = styled(ArrowIcon)`
  transform: rotate(45deg);
`;

export const StyledAccordionArrowIcon = styled(
  AccordionArrowIcon
)<StyledAccordionArrowProps>`
  transform: ${({ isOpen }) => (isOpen ? "none" : "rotate(180deg)")};
`;
