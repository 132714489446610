import { FC } from "react";
import { PhoneInputProps } from "./PhoneInput.d";
import { Controller } from "react-hook-form";
import { StyledContainer } from "./PhoneInput.styled";
import PhoneInputWithCountryCodes from "react-phone-input-2";
import { Label } from "components/Label";
import "react-phone-input-2/lib/style.css";

export const PhoneInput: FC<PhoneInputProps> = ({
  id,
  control,
  label,
  error,
  required,
  ...inputProps
}) => {
  const {
    className = "input--default",
    name = id,
    placeholder = "",
    maxLength = 20,
    disabled,
    ...otherInputProps
  } = inputProps;
  return (
    <StyledContainer display="grid" gridGap={1.5} error={!!error}>
      {label && (
        <Label
          required={required}
          htmlFor={id}
          disabled={disabled}
          error={error}
        >
          {error ? error : label}
        </Label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, ...field } }) => (
          <PhoneInputWithCountryCodes
            {...field}
            country="de"
            inputProps={{
              id: id,
              className: className,
              placeholder: placeholder,
              maxLength: maxLength,
              disabled: disabled,
              ref: ref,
              ...otherInputProps,
            }}
            specialLabel=""
          />
        )}
      />
    </StyledContainer>
  );
};
