import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete } from "components/Autocomplete";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { useWindowSize } from "global/hooks/useWindowSize";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { isValidForm } from "utils/isValidForm";
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledFormContainer,
  StyledInputContainer,
} from "./ProfileOptionModal.styled";
import { profileOptionSchema } from "./validators";
import { Option as AutocompleteOption } from "components/Autocomplete/Autocomplete.d";
import {
  createCompany,
  createEducation,
  CreateEducationCompanyForm,
} from "services/Profile";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { toast } from "react-toastify";
import { COLORS } from "theme";
import { Option } from "components/Select/Select.d";

interface Props {
  type: "COMPANY" | "EDUCATION";
  isShown: boolean;
  toggle: () => void;
  countryOptions: AutocompleteOption[];
  onAdd: (data: Option) => void;
}

const MODAL_LABEL = {
  COMPANY: "Add new company",
  EDUCATION: "Add new education",
};

const ProfileOptionModal: FC<Props> = ({
  isShown,
  type,
  toggle,
  countryOptions,
  onAdd,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<CreateEducationCompanyForm>({
    resolver: yupResolver(profileOptionSchema),
    shouldUseNativeValidation: true,
  });
  const [clicked, setClicked] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<AutocompleteOption>({
    label: "",
    value: "",
  });
  const { isMobile } = useWindowSize();

  const getLabel = () => {
    return MODAL_LABEL[type];
  };

  const changeCountry = (value: AutocompleteOption) => {
    setValue("country", value?.label || "");
    setSelectedCountry(value ?? { name: "", value: "" });
  };

  const onSubmit = async (data: CreateEducationCompanyForm) => {
    if (type === "COMPANY") {
      const response = await createCompany(data);
      if (response?.ok) {
        changeCountry({ label: "", value: "" });
        setValue("name", "");
        toast.success("Company created successfully");
        const option = {
          name: response.parsedBody.display_name,
          value: response.parsedBody.value,
        };
        onAdd(option);
        toggle();
      } else {
        toast.error("There was an error during creating company");
      }
    } else if (type === "EDUCATION") {
      const response = await createEducation(data);
      if (response?.ok) {
        changeCountry({ label: "", value: "" });
        setValue("name", "");
        toast.success("Education created successfully");
        const option = {
          name: response.parsedBody.display_name,
          value: response.parsedBody.value,
        };
        onAdd(option);
        toggle();
      } else {
        toast.error("There was an error during creating education");
      }
    }
  };

  return (
    <Modal
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      px={isMobile ? 2 : 8}
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledContainer display="grid" gridGap={3}>
            <Typography variant="subtitle">{getLabel()}</Typography>
            <StyledInputContainer display="grid" gridGap={3}>
              <Input
                {...register("name", {
                  required: "This field is required",
                })}
                label="Name"
                error={!isValidForm(errors) && errors.name?.message}
              />
              <Autocomplete
                {...register("country", {
                  required: "This field is required",
                })}
                label="Country"
                error={!isValidForm(errors) && errors.country?.message}
                value={selectedCountry}
                options={countryOptions}
                onChange={changeCountry}
                maxMenuHeight={100}
              />
            </StyledInputContainer>
            <Box display="grid" justifyContent="flex-end">
              <StyledButtonsContainer
                display="grid"
                gridAutoFlow="column"
                justifyContent="end"
                alignItems="center"
                gridGap={3}
              >
                <Button onClick={toggle} variant={ButtonVariant.SECONDARY}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={clicked}
                  size={ButtonSize.SMALL}
                  variant={ButtonVariant.PRIMARY}
                  icon={
                    <ArrowIcon
                      stroke={COLORS.stroke.main}
                      fill="none"
                      width="24px"
                      height="24px"
                    />
                  }
                >
                  Save
                </Button>
              </StyledButtonsContainer>
            </Box>
          </StyledContainer>
        </form>
      </StyledFormContainer>
    </Modal>
  );
};

export default ProfileOptionModal;
