import styled from "styled-components";
import { Box } from "components/Box";
import { COLORS, SIZES } from "theme";

export const StyledButtonContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
  padding: ${SIZES.spacing(4)};
`;

export const TabletsContainer = styled(Box)`
  box-shadow: 8px 0px 20px rgba(209, 206, 206, 0.25);
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 800;
  background-color: ${COLORS.typography.secondary.main};
  padding: 12px 18px;
  & h4 {
    font-size: 15px;
    font-weight: 400;
  }
  & span {
    line-height: 22px;
  }
  & button {
    padding: 12px 8px;
  }
`;

export const TabletsHolder = styled.div`
  position: relative;
  overflow: hidden;
`;

export const StyledTabletsBookingsListContainer = styled(Box)`
  max-height: calc(100vh - 80px);
  overflow-y: auto;
`;
