import { FC } from "react";
import { Typography } from "components";
import { getLanguage } from "utils/getLanguage";
import translation from "./personal_data_list_translation.json";
import { useProfile } from "context/Profile";

const Email: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["emailAddress"][selectedLanguage]}
    </Typography>
  );
};

const Names: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["nameFields"][selectedLanguage]}
    </Typography>
  );
};

const Foto: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["photoField"][selectedLanguage]}
    </Typography>
  );
};

const Telephone: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["phoneNumberField"][selectedLanguage]}
    </Typography>
  );
};

const Address: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["addressFields"][selectedLanguage]}
    </Typography>
  );
};

const Biography: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["shortBioField"][selectedLanguage]}
    </Typography>
  );
};

const UserData: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["usageDataField"][selectedLanguage]}
    </Typography>
  );
};

export const PersonalDataList = [
  <Email key="email" />,
  <Names key="names" />,
  <Foto key="foto" />,
  <Telephone key="telephone" />,
  <Address key="address" />,
  <Biography key="biography" />,
  <UserData key="user-data" />,
];
