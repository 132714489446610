import { FC } from "react";
import {
  StyledInfoContainer,
  StyledAgeRange,
  StyledCounterContainer,
  StyledCounter,
  CounterIcon,
} from "./GuestPicker.styled";
import { GuestPickerProps } from "./GuestPicker.d";
import { Box } from "components/Box";

export const GuestPicker: FC<GuestPickerProps> = ({
  id,
  label,
  value,
  disabledPlus,
  disabledMinus,
  onChange,
}) => {
  return (
    <Box
      key={id}
      display="grid"
      gridAutoFlow="column"
      justifyContent="space-between"
    >
      <StyledInfoContainer
        display="grid"
        disabled={disabledMinus && disabledPlus && value !== 1}
      >
        <StyledAgeRange variant="body">{label}</StyledAgeRange>
      </StyledInfoContainer>
      <StyledCounterContainer display="grid">
        <CounterIcon
          disabled={disabledMinus}
          onClick={(e) => onChange(e, id, value - 1)}
        >
          <span>−</span>
        </CounterIcon>
        <StyledCounter
          variant="body"
          disabled={disabledMinus && disabledPlus && value !== 1}
        >
          {value}
        </StyledCounter>
        <CounterIcon
          disabled={disabledPlus}
          onClick={(e) => onChange(e, id, value + 1)}
        >
          <span>+</span>
        </CounterIcon>
      </StyledCounterContainer>
    </Box>
  );
};
