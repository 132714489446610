import { FC, useRef, useState } from "react";
import { Swiper } from "swiper/react";
import { Navigation } from "swiper";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-slider-arrow.svg";
import { ReactComponent as HeartIcon } from "assets/icons/icon_heart.svg";
import { ReactComponent as HeartActiveIcon } from "assets/icons/icon_heart_active.svg";
import { PhotoGalleryProps } from "./PhotoGallery.d";
import {
  StyledContainer,
  StyledArrow,
  StyledHeart,
} from "./PhotoGallery.styled";
import { Pagination } from "swiper";

export const PhotoGallery: FC<PhotoGalleryProps> = ({
  slidesPerView,
  allowTouchMove = true,
  children,
  itemsNumber,
  breakpoints,
  isFavorite,
  setIsFavorite,
  props,
}) => {
  const [firstSlide, setFirstSlide] = useState(1);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  return (
    <StyledContainer>
      <Swiper
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 7,
        }}
        slidesPerView={slidesPerView}
        allowTouchMove={allowTouchMove}
        modules={[Navigation, Pagination]}
        className="swiper-container"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        breakpoints={breakpoints}
        onInit={(swiper: any) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        {...props}
      >
        <div className="swiper-wrapper">{children}</div>
        <StyledHeart onClick={() => setIsFavorite()}>
          {isFavorite ? <HeartActiveIcon /> : <HeartIcon />}
        </StyledHeart>
        {itemsNumber > slidesPerView && (
          <>
            <StyledArrow
              isRight={false}
              ref={navigationPrevRef}
              disabled={firstSlide <= 1}
              onClick={() => firstSlide > 1 && setFirstSlide(firstSlide - 1)}
            >
              <ArrowIcon />
            </StyledArrow>
            <StyledArrow
              isRight={true}
              ref={navigationNextRef}
              disabled={firstSlide + slidesPerView - 1 >= itemsNumber}
              onClick={() =>
                firstSlide + slidesPerView - 1 < itemsNumber &&
                setFirstSlide(firstSlide + 1)
              }
            >
              <ArrowIcon />
            </StyledArrow>
          </>
        )}
      </Swiper>
    </StyledContainer>
  );
};
