import { REGISTER_BASIC_USER } from "urls/api";
import fetchAPI from "./fetchApi";

export interface RegistrationForm {
  name: string;
  last_name: string;
  email: string;
  phone_number: string;
  password: string;
  confirm_password: string;
}

export interface RegisterBasicUserResponse {
  details?: string;
  message: string | { [key: string]: string[] | undefined };
}

export const fetchRegisterBasicUser = (data: RegistrationForm) =>
  fetchAPI<RegisterBasicUserResponse>(REGISTER_BASIC_USER, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
