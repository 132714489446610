import { Box, Typography } from "components";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const BedrooomsContainer = styled(Box)`
  grid-row-gap: 24px;

  ${SIZES.media.desktop} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
  }
`;

export const BedroomImageContainer = styled(Box)`
  height: 170px;
  padding-top: 18px;

  ${SIZES.media.tablet} {
    height: 490px;
  }

  ${SIZES.media.desktop} {
    height: 420px;
  }
`;

export const IconContainer = styled(Box)`
  width: 24px;
  height: 24px;
`;

export const IconTypography = styled(Typography)`
  font-weight: 500;
  color: ${COLORS.typography.body};
`;
