import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { StyledButtonProps } from "./ResultItem.d";
import { COLORS } from "theme";

export const StyledButton = styled(Button)<StyledButtonProps>`
  width: 156px;
  padding: ${pxToRem(17.5)} ${pxToRem(32)};
  font-size: 16px;

  &:enabled {
    &:hover {
      & svg {
        stroke: ${({ isAddedToHouseOwnersList }) =>
          isAddedToHouseOwnersList ? COLORS.typography.stroke : "none"};
      }
    }
  }
`;
