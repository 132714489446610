import styled from "styled-components";
import { COLORS } from "theme";
import { StyledDropzoneProps } from "./Dropzone.d";
import { Box } from "components/Box";
import { Typography } from "components/Typography";

export const StyledDropzone = styled(Box)<StyledDropzoneProps>`
  align-items: center;
  background-color: ${COLORS.stroke.secondary};
  border: 1px dashed;
  border-color: ${({ isDragActive }) =>
    isDragActive ? COLORS.accent.primary : COLORS.stroke.main};
  padding: 17px 0;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledIconContainer = styled(Box)`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: ${COLORS.background.main};
`;

export const StyledLabel = styled(Typography)`
  font-size: 14px;
  color: ${COLORS.typography.headline};
  text-transform: capitalize;
`;

export const StyledDescription = styled(Typography)`
  font-size: 12px;
  color: ${COLORS.typography.body};
  text-transform: capitalize;
`;
