import { Box } from "components/Box";
import { useWindowSize } from "global/hooks/useWindowSize";
import { FC, useState } from "react";
import { GalleryProps } from "./Gallery.d";
import { StyledLightBox } from "./Gallery.styled";
import { GalleryArrowButton } from "./GalleryArrowButton";
import { GalleryHeader } from "./GalleryHeader";

export const Gallery: FC<GalleryProps> = ({
  images,
  isOpen,
  setOpen,
  currentImageIndex,
  setCurrentImageIndex,
}) => {
  const [isArrowsVisible, setIsArrowsVisible] = useState<boolean>(true);
  const { isTablet } = useWindowSize();

  const showArrows = () => {
    setIsArrowsVisible(true);
  };

  const hideArrows = () => {
    setTimeout(() => {
      setIsArrowsVisible(false);
    }, 1000);
  };
  const gotoPrevious = () => {
    isTablet && hideArrows();
    currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
  };
  const gotoNext = () => {
    isTablet && hideArrows();
    currentImageIndex + 1 < images.length &&
      setCurrentImageIndex(currentImageIndex + 1);
  };

  return (
    <Box onMouseMove={showArrows} onTouchMove={showArrows}>
      <StyledLightBox
        isOpen={isOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        renderHeader={() => <GalleryHeader onClose={() => setOpen(false)} />}
        renderPrevButton={() =>
          isArrowsVisible && (
            <GalleryArrowButton position={"left"} onClick={gotoPrevious} />
          )
        }
        renderNextButton={() =>
          isArrowsVisible && (
            <GalleryArrowButton position={"right"} onClick={gotoNext} />
          )
        }
        currentIndex={currentImageIndex}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};
