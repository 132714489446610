import { Box } from "components/Box";
import styled from "styled-components";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";
import { StaticContainerType } from "./SecondaryHeader.d";
import { SIZES } from "theme";

export const StyledHeader = styled(Typography)`
  letter-spacing: 0.03em;
  font-size: 36px;
  line-height: 48px;
  width: 85%;

  ${SIZES.media.phone} {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: ${pxToRem(12)};
  }

  ${SIZES.media.desktop} {
    width: 50%;
    margin-bottom: ${pxToRem(30)};
  }
`;

export const StyledContainer = styled(Box)<StaticContainerType>`
  ${({ imageLink }) =>
    imageLink &&
    `background: linear-gradient(85.41deg, rgba(12, 13, 36, 0.5) 0.51%, rgba(13, 14, 37, 0) 73.89%), url(${imageLink})`};
  height: 530px;
  box-sizing: border-box;
  background-position: 55% 0;
  background-size: cover;
  background-repeat: no-repeat;

  ${SIZES.media.phone} {
    padding-top: ${pxToRem(105)};
    padding-left: ${pxToRem(32)};
  }

  ${SIZES.media.tablet} {
    padding-top: ${pxToRem(84)};
    padding-left: ${pxToRem(80)};
    background-position: 50% 95%;
  }
`;
