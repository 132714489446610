import { Box } from "components/Box";
import { Button } from "components/Button";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { RequestFullAccessFooterProps } from "./RequestFullAccessFooter.d";
import { Typography } from "components/Typography";

export const StyledContainer = styled(Box)<RequestFullAccessFooterProps>`
  width: 100%;
  margin: 0 auto;
  text-align: center;

  ${SIZES.media.tablet} {
    width: ${({ isRequestSent }) => (isRequestSent ? "44%" : "49%")};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${SIZES.spacing(3)};
  padding: 22px 27px;
  width: 100%;

  ${SIZES.media.desktop} {
    width: 38%;
  }
`;

export const StyledDoneIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${COLORS.accent.primary};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTypography = styled(Typography)`
  line-height: 30px;
`;
