import { IconWithLabel } from "components/IconWithLabel";
import { ReactComponent as IconGuests } from "assets/icons/icon_guests.svg";
import { ReactComponent as IconBed } from "assets/icons/icon_bed.svg";
import { ReactComponent as IconBathroom } from "assets/icons/icon_bathroom.svg";
import { ReactComponent as IconSquareMeters } from "assets/icons/icon_square_meters.svg";
import { FC } from "react";
import { HouseMainInfoProps } from "./HouseMainInfo.d";
import { StyledContainer } from "./HouseMainInfo.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { useWindowSize } from "global/hooks/useWindowSize";

export const HouseMainInfo: FC<HouseMainInfoProps> = ({
  max_total_guests,
  bedrooms_num,
  bathrooms_num,
  living_space,
}) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  const { isTrueTablet } = useWindowSize();

  return (
    <StyledContainer display="grid" gridGap={0.5}>
      <IconWithLabel
        gap={1.5}
        icon={IconGuests}
        label={`${max_total_guests} Max. ${translation["maxGuestsLabel"][selectedLanguage]}`}
        isVertical={!isTrueTablet}
      />
      <IconWithLabel
        gap={1.5}
        icon={IconBed}
        label={`${bedrooms_num} ${translation["bedroomsLabel"][selectedLanguage]}`}
        isVertical={!isTrueTablet}
      />
      <IconWithLabel
        gap={1.5}
        icon={IconBathroom}
        label={`${bathrooms_num} ${translation["bathroomsLabel"][selectedLanguage]}`}
        isVertical={!isTrueTablet}
      />
      {living_space && (
        <IconWithLabel
          gap={1.5}
          icon={IconSquareMeters}
          label={`${living_space} ${translation["livingSpaceLabel"][selectedLanguage]}`}
          isVertical={!isTrueTablet}
        />
      )}
    </StyledContainer>
  );
};
