import { Box } from "components/Box";
import styled from "styled-components";
import SmallQuoteIcon from "assets/icons/icon-small-quote.svg";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  position: relative;
  &::before {
    content: url(${SmallQuoteIcon});
    left: ${pxToRem(18)};
    top: 0;
    position: absolute;
    ${SIZES.media.phone} {
      left: 0;
    }
  }

  &::after {
    content: url(${SmallQuoteIcon});
    transform: rotate(180deg);
    position: absolute;
    right: ${pxToRem(18)};
    bottom: 0;
    ${SIZES.media.phone} {
      right: 0;
      bottom: -${pxToRem(30)};
    }
  }
  ${SIZES.media.phone} {
    padding: ${pxToRem(32)} ${pxToRem(88)} 0 ${pxToRem(88)};
  }
`;

export const StyledTitle = styled(Typography)`
  z-index: 10;
  letter-spacing: 0.04em;
`;

export const StyledTypography = styled(Typography)`
  z-index: 10;
  line-height: 30px;
  white-space: pre-wrap;
`;
