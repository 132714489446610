import { Box, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const Content = styled(Box)`
  box-sizing: border-box;
  background-color: ${COLORS.typography.secondary.main};
  padding: ${pxToRem(32)} ${pxToRem(14)};
  width: ${pxToRem(284)};
  height: ${pxToRem(458)};

  ${SIZES.media.tablet} {
    width: ${pxToRem(550)};
    height: ${pxToRem(500)};
    padding: ${pxToRem(64)}};
  }
`;

export const HeaderText = styled(Typography)`
  text-align: center;
`;
