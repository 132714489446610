import { Tabs } from "components/Tabs";
import { FC, useEffect, useState } from "react";
import {
  RecommendationsProps,
  RecommendationItemProps,
} from "./Recommendations.d";
import { RecommendationItem } from "./RecmmendationItem";
import { withSlider } from "global/hooks/withSlider";
import { Slider } from "components/Slider";
import { Map } from "components/Map";
import { Box } from "components/Box";
import { RecommendationMapMarker } from "./RecommendationMapMarker/RecommendationMapMarker";
import type { Swiper } from "swiper";
import { StyledTitle, StyledContainer } from "./Recommendations.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const Recommendations: FC<RecommendationsProps> = ({
  tabsData = {},
  houseLatitude,
  houseLongitude,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const tabsWithData = Object.entries(tabsData).filter(
    ([, data]) => data.length
  );
  const [firstActiveTabLabel] = tabsWithData?.length ? tabsWithData[0] : [""];

  const [activeTab, setActiveTab] = useState<string>(firstActiveTabLabel);
  const [defaultActiveRecommendation] = activeTab
    ? tabsData[activeTab]
    : [{ id: "" }];
  const [activeMarkerId, setActiveMarkerId] = useState<string | null>(
    defaultActiveRecommendation.id
  );
  const filteredRecommendationsByActiveTab =
    tabsWithData.length > 0 &&
    tabsData[activeTab].filter((item) => !!item.latitude && !!item.longitude);
  const [controlledSwiper, setControlledSwiper] = useState<Swiper | null>(null);

  const handleActiveMarkerChange = (newMarkerId: string | null) => {
    newMarkerId ? setActiveMarkerId(newMarkerId) : setActiveMarkerId(null);
  };

  useEffect(() => {
    const sliderIndex = activeMarkerId
      ? tabsData[activeTab].map((data) => data.id).indexOf(activeMarkerId)
      : 0;
    controlledSwiper && controlledSwiper.slideTo(sliderIndex);
    controlledSwiper && controlledSwiper.update();
  }, [activeMarkerId]);

  useEffect(() => {
    const [newActiveRecommendation] =
      tabsWithData.length > 0 ? tabsData[activeTab] : [{ id: "" }];
    setActiveMarkerId(newActiveRecommendation.id);
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(firstActiveTabLabel);
  }, [tabsData]);

  const recommendationsMarkers =
    filteredRecommendationsByActiveTab &&
    filteredRecommendationsByActiveTab.map((item: RecommendationItemProps) => (
      <RecommendationMapMarker
        key={item.id}
        id={item.id}
        isFullMarker={activeMarkerId ? item.id === activeMarkerId : false}
        latitude={item.latitude}
        longitude={item.longitude}
        title={item.title}
        category={activeTab}
        image={item.images.length ? item.images[0]?.url : ""}
        onChangeActiveMarker={handleActiveMarkerChange}
      />
    ));

  const isShownMap =
    (filteredRecommendationsByActiveTab &&
      filteredRecommendationsByActiveTab.length !== 0) ||
    (!!houseLatitude && !!houseLongitude);

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
  };

  const getPositions = () =>
    filteredRecommendationsByActiveTab
      ? filteredRecommendationsByActiveTab.map((recommendation) => {
          return {
            lat: recommendation.latitude,
            lng: recommendation.longitude,
          };
        })
      : [];

  return (
    <StyledContainer>
      {tabsWithData.length > 0 && (
        <div>
          <StyledTitle variant="h2">
            {translation["recommendations"][selectedLanguage]}
          </StyledTitle>
          <Tabs
            onTabChange={handleTabChange}
            tabs={tabsWithData.map(([label, data]) => ({
              label,
              content: (
                <Box>
                  <Slider
                    itemsNumber={data.length}
                    spaceBetween={20}
                    allowTouchMove={false}
                    controlledSwiper={controlledSwiper}
                    setControlledSwiper={setControlledSwiper}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                        allowTouchMove: true,
                      },
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        allowTouchMove: true,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {data.map((item) => {
                      const RecommendationItemWithSlider =
                        withSlider(RecommendationItem);
                      return (
                        <RecommendationItemWithSlider
                          key={item.id}
                          id={item.id}
                          isActive={item.id === activeMarkerId}
                          onClick={setActiveMarkerId}
                          title={item.title}
                          comment={item.comment}
                          url={item.url}
                          phoneNumber={item.phone_number}
                          email={item.email}
                          images={item.images}
                          location={item.location}
                          latitude={item.latitude}
                          longitude={item.longitude}
                          distance={item.distance}
                        />
                      );
                    })}
                  </Slider>
                </Box>
              ),
              count: data.length,
            }))}
          />
          {isShownMap && (
            <Box mt={2}>
              <Map
                center={{ lat: houseLatitude, lng: houseLongitude }}
                markers={recommendationsMarkers}
                positions={getPositions()}
                zoom={10}
              />
            </Box>
          )}
        </div>
      )}
    </StyledContainer>
  );
};
