import { makeContext } from "global/utils";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useAuth } from "./Auth";

type Calculator = {
  calculatorHouseId: string;
  adults: number;
  children: number;
  infants: number;
  startDate: Date | null;
  endDate: Date | null;
  setCalculatorHouseId: Dispatch<SetStateAction<string>>;
  setAdults: Dispatch<SetStateAction<number>>;
  setChildren: Dispatch<SetStateAction<number>>;
  setInfants: Dispatch<SetStateAction<number>>;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  resetCalculator: (calculatorHouseId: Calculator["calculatorHouseId"]) => void;
  minCheckOutDate: () => Date;
  resetDates: () => void;
};

const { useConsumer, Provider } = makeContext((): Calculator => {
  const minCheckOutDate = (): Date => {
    const now = new Date();
    now.setDate(now.getDate() + 1);
    return now;
  };

  const auth = useAuth();

  const [calculatorHouseId, setCalculatorHouseId] = useState("");
  const [adults, setAdults] = useState<number>(1);
  const [children, setChildren] = useState<number>(0);
  const [infants, setInfants] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const resetCalculator = (
    calculatorHouseId: Calculator["calculatorHouseId"]
  ) => {
    setCalculatorHouseId(calculatorHouseId);
    setAdults(1);
    setChildren(0);
    setInfants(0);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    resetCalculator(calculatorHouseId);
  }, [auth?.user]);

  return {
    calculatorHouseId,
    adults,
    children,
    infants,
    startDate,
    endDate,
    setCalculatorHouseId,
    setAdults,
    setChildren,
    setInfants,
    setStartDate,
    setEndDate,
    resetCalculator,
    minCheckOutDate,
    resetDates,
  };
});

export const CalculatorProvider = Provider;
export const useCalculator = useConsumer;
