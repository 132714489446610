import { forwardRef } from "react";
import {
  Wrapper,
  Item,
  CheckboxButton,
  CheckboxButtonLabel,
} from "./Checkbox.styled";
import { CheckboxProps } from "./Checkbox.d";
import { Typography } from "components/Typography";
import { COLORS } from "theme";
import { ReactComponent as UncheckedCheckbox } from "assets/icons/icon_unchecked_checkbox.svg";
import { ReactComponent as CheckedCheckbox } from "assets/icons/icon_checked_checkbox.svg";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, checked, disabled = false, ...props }, ref) => {
    const id = Math.random().toString(36).slice(2);
    return (
      <Wrapper>
        <Item key={id} display="flex" alignItems="center" disabled={disabled}>
          <CheckboxButton
            type="checkbox"
            checked={checked}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          <CheckboxButtonLabel>
            {checked ? <CheckedCheckbox /> : <UncheckedCheckbox />}
          </CheckboxButtonLabel>
          <Typography
            variant="caption"
            color={
              disabled ? COLORS.typography.disabled : COLORS.typography.headline
            }
          >
            {label}
          </Typography>
        </Item>
      </Wrapper>
    );
  }
);

Checkbox.displayName = "Checkbox";
