import { FC } from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { COLORS } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { StyledConfirmationModalContent } from "./TrustedFriendModal.styled";
import { TrustedModalProps } from "./TrustedFriendModal.d";

const TrustedFriendModal: FC<TrustedModalProps> = ({
  isShown,
  toggle,
  onTrustedFriendClick,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  return (
    <Modal isShown={isShown}>
      <StyledConfirmationModalContent>
        <Box display="grid" gridGap={1}>
          <Typography variant="h4">
            {translation["trustedFriendStatusLabel"][selectedLanguage]}
          </Typography>
          <Typography variant="body" color={COLORS.stroke.error}>
            {translation["trustedFriendWarningLabel"][selectedLanguage]}
          </Typography>
        </Box>
        <Box
          display="grid"
          mt={5.6}
          gridAutoFlow="column"
          justifyContent="end"
          gridGap={3}
          alignItems="center"
        >
          <Button variant={ButtonVariant.SECONDARY} onClick={toggle}>
            {translation["cancelLabel"][selectedLanguage]}
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            icon={
              <ArrowIcon
                stroke={COLORS.stroke.main}
                fill="none"
                width="20px"
                height="20px"
              />
            }
            onClick={onTrustedFriendClick}
          >
            {translation["setAsTrustedFriendLabel"][selectedLanguage]}
          </Button>
        </Box>
      </StyledConfirmationModalContent>
    </Modal>
  );
};

export default TrustedFriendModal;
