import { FC } from "react";
import { CalendarFooterProps } from "./CalendarFooter.d";
import { StyledBlockedDates } from "./CalendarFooter.styled";
import { Button } from "components/Button";
import { ButtonVariant, ButtonSize } from "components/Button/Button.enums";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";
import { COLORS } from "theme";
import { ReservationDatePicker } from "components/Booking/ReservationDatePicker";
import { Typography } from "components/Typography";
import { useWindowSize } from "global/hooks/useWindowSize";
import { Box } from "components/Box";
import { DaysInterval } from "components/House/HouseOwnerCalendar/HouseOwnerCalendar.d";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const CalendarFooter: FC<CalendarFooterProps> = ({
  onConfirmClick,
  isPrivate,
  clearData,
  handleBlockedDateDelete,
  blockedDates = [],
  isBlockedDatesEditable,
  isSubmitButtonDisabled = false,
  showConfirmButtonMobile = false,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const { isTablet } = useWindowSize();
  return (
    <Box
      display="grid"
      gridAutoFlow={isPrivate ? "row" : "column"}
      gridGap={1.5}
      mt={isPrivate ? 4 : 2.6}
    >
      {isPrivate && blockedDates.length > 0 && (
        <>
          <Typography variant="overline">
            {translation["datesBlockedLabel"][selectedLanguage]}
          </Typography>
          <StyledBlockedDates display="grid" mt={0.8} gridGap={1.5}>
            {blockedDates.map((blockedDate: DaysInterval) => (
              <ReservationDatePicker
                key={blockedDate.id}
                checkInDate={new Date(blockedDate.check_in_date)}
                checkOutDate={new Date(blockedDate.check_out_date)}
                isPrivate={true}
                onIconClick={(e) =>
                  handleBlockedDateDelete &&
                  handleBlockedDateDelete(blockedDate.id, e)
                }
                icon={
                  isBlockedDatesEditable && (
                    <DeleteIcon
                      stroke={COLORS.typography.secondary.dark}
                      strokeWidth={1.5}
                      fill="none"
                      width="24px"
                      height="24px"
                    />
                  )
                }
              />
            ))}
          </StyledBlockedDates>
        </>
      )}
      <Box
        display="grid"
        gridAutoFlow="column"
        gridGap={4.4}
        justifyContent="end"
        alignItems="center"
        mt={4.5}
      >
        {!isPrivate && (
          <Button variant={ButtonVariant.SECONDARY} onClick={clearData}>
            {translation["clearDatesLabel"][selectedLanguage]}
          </Button>
        )}
        {(!isTablet || showConfirmButtonMobile) && (
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={onConfirmClick}
            disabled={isSubmitButtonDisabled}
            icon={
              <ArrowIcon
                stroke={COLORS.stroke.main}
                fill="none"
                width="16px"
                height="16px"
              />
            }
          >
            {isPrivate
              ? translation["addDatesLabel"][selectedLanguage]
              : translation["confirmLabel"][selectedLanguage]}
          </Button>
        )}
      </Box>
    </Box>
  );
};
