import { GenderVariant } from "components/Profile/ProfileForm/ProfileForm.enums";
import MaleDefaultAvatar from "assets/icons/icon_avatar_male.svg";
import FemaleDefaultAvatar from "assets/icons/icon_avatar_female.svg";

export const getDefaultAvatar = (gender: GenderVariant) => {
  switch (gender) {
    case GenderVariant.MALE:
      return MaleDefaultAvatar;
    case GenderVariant.FEMALE:
      return FemaleDefaultAvatar;
    default:
      return MaleDefaultAvatar;
  }
};
