import { FC, useRef, useState } from "react";
import { Swiper } from "swiper/react";
import { Navigation, Controller, Thumbs } from "swiper";
import { SliderProps } from "./Slider.d";
import { StyledContainer, StyledArrow, StyledArrowIcon } from "./Slider.styled";
import { useWindowSize } from "global/hooks/useWindowSize";

export const Slider: FC<SliderProps> = ({
  spaceBetween,
  allowTouchMove = true,
  children,
  itemsNumber,
  breakpoints,
  controlledSwiper,
  setControlledSwiper,
  props,
}) => {
  const { isMobile, isTablet } = useWindowSize();
  const [firstSlide, setFirstSlide] = useState(1);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);
  const slidesPerView = isMobile ? 1 : isTablet ? 2 : 4;

  return (
    <StyledContainer>
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        allowTouchMove={allowTouchMove}
        onSwiper={setControlledSwiper}
        modules={[Navigation, Controller, Thumbs]}
        controller={{ swiper: controlledSwiper }}
        className="swiper-container"
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        breakpoints={breakpoints}
        {...props}
      >
        <div className="swiper-wrapper">{children}</div>
        {itemsNumber > slidesPerView && (
          <>
            <StyledArrow
              isRight={false}
              ref={navigationPrevRef}
              disabled={firstSlide <= 1}
              onClick={() => firstSlide > 1 && setFirstSlide(firstSlide - 1)}
            >
              <StyledArrowIcon />
            </StyledArrow>
            <StyledArrow
              isRight={true}
              ref={navigationNextRef}
              disabled={firstSlide + slidesPerView - 1 >= itemsNumber}
              onClick={() =>
                firstSlide + slidesPerView - 1 < itemsNumber &&
                setFirstSlide(firstSlide + 1)
              }
            >
              <StyledArrowIcon />
            </StyledArrow>
          </>
        )}
      </Swiper>
    </StyledContainer>
  );
};
