import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledPricesContainer = styled(Box)`
  row-gap: 1.25rem;
  grid-auto-rows: max-content;
`;

export const StyledPriceContainer = styled(Box)`
  width: 104px;
  display: block;
  &:nth-child(even) {
    background-color: ${COLORS.typography.stroke};
  }

  ${SIZES.media.desktop} {
    width: 170px;
  }
`;

export const StyledPriceTitle = styled(Typography)`
  font-size: 15px;
`;
