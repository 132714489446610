import { useState, useEffect } from "react";
import { SIZES } from "theme";
import { debounce } from "../utils";

export type Size = {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isTrueTablet: boolean;
};

export const useWindowSize = (update = true): Size => {
  const [windowSize, setWindowSize] = useState<Pick<Size, "height" | "width">>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isMobile = windowSize.width <= SIZES.deviceSize.phone;
  const isTablet = windowSize.width < SIZES.deviceSize.desktop;
  const isDesktop = windowSize.width >= SIZES.deviceSize.desktop;

  const isTrueTablet = windowSize.width >= SIZES.deviceSize.tablets;

  useEffect(() => {
    const type = "resize";
    const listener = debounce(() => {
      if (!update) {
        return;
      }

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });

    window.addEventListener(type, listener);
    return () => window.removeEventListener(type, listener);
  }, [update]);

  return { ...windowSize, isMobile, isTablet, isDesktop, isTrueTablet };
};
