import { Box } from "components/Box";
import { ReadMore } from "components/ReadMore";
import { Typography } from "components/Typography";
import { FC } from "react";
import { COLORS } from "theme";
import { CancellationPolicyProps } from "./CancellationPolicy.d";
import { StyledTypography } from "./CancellationPolicy.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

const MAX_LENGTH = 400;

export const CancellationPolicy: FC<CancellationPolicyProps> = ({ text }) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box display="grid" gridGap={2}>
      <Typography variant="h2" color={COLORS.typography.headline}>
        {translation["cancellationPolicy"][selectedLanguage]}
      </Typography>
      {text.length >= MAX_LENGTH ? (
        <ReadMore text={text} color={COLORS.typography.body} />
      ) : (
        <StyledTypography variant="body" color={COLORS.typography.body}>
          {text}
        </StyledTypography>
      )}
    </Box>
  );
};
