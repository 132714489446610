import { Box } from "components/Box";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.accent.boxes};
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(5)};

  ${SIZES.media.tablet} {
    padding: ${SIZES.spacing(8.8)} ${SIZES.spacing(5)};
  }
`;

export const StyledItemsContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: ${SIZES.spacing(3)};

  ${SIZES.media.tablet} {
    flex-direction: row;
    gap: unset;
  }
`;
