import styled, { createGlobalStyle, css } from "styled-components";

import { COLORS, LAYERS } from "../../theme";
import { StyledDropdownProps } from "./Dropdown.d";

export const transition = 200;

export const CONSTS = {
  scale: 0.5,
  border: 0,
};

export const DropdownContainer = styled.div<StyledDropdownProps>`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: ${({ open }) => !open && "hidden"};
  z-index: ${LAYERS.menu};
`;

export const StyledDropdown = styled.div<StyledDropdownProps>`
  background-color: ${COLORS.background.main};
  ${({ withBorder }) =>
    withBorder && `border: 1px solid ${COLORS.typography.stroke}`};
  ${({ withShadow }) =>
    withShadow && "box-shadow: 0px 8px 40px rgba(185, 177, 177, 0.4);"};
  bottom: ${({ position }) => position?.bottom};
  box-sizing: border-box;
  padding: 1rem;
  left: ${({ position }) => position?.x};
  margin: 0;
  min-width: ${({ position }) => position?.minWidth};
  opacity: ${({ open }) => (open ? 1 : 0)};
  position: absolute;
  top: ${({ position }) => position?.y};
  transform: ${({ open }) => !open && `scaleY(${CONSTS.scale})`};
  transform-origin: ${({ position }) => position?.transformOrigin};
  transition: ${`${transition}ms transform, ${transition}ms opacity, ${transition}ms visibility`};
  max-height: 100vh;

  ${({ withArrow, position }) =>
    withArrow &&
    css`
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 44%;
        ${position?.overflowArrowVariant &&
        `right: ${position?.overflowArrowVariant === "left" ? "74%" : "2%"}`};
        width: 20px;
        height: 20px;
        background-color: ${COLORS.typography.secondary.main};
        transform: translateY(-50%) rotate(45deg);
        z-index: 0;
      }
    `}
`;

export const BodyOverflow = createGlobalStyle<StyledDropdownProps>`
  body {
      overflow-y: ${({ open }) => (open ? "hidden !important" : "overlay")} ;
  }
`;
