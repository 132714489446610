import { Box } from "components/Box";
import { Image } from "components/Image";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  position: relative;

  ${SIZES.media.tablet} {
    grid-template-columns: 4fr;
    grid-template-rows: 1fr;
    grid-gap: ${pxToRem(24)};
  }
`;

export const StyledFirstImage = styled(Box)`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  cursor: pointer;
`;

export const StyledSecondImage = styled(Box)`
  grid-column: 2 / 4;
  grid-row: 1 / 2;
  cursor: pointer;
`;

export const StyledThirdImage = styled(Box)`
  grid-column: 2 / 3;
  cursor: pointer;
`;

export const StyledDesktopLink = styled(Box)`
  position: relative;
  cursor: pointer;
`;

export const StyledTabletLink = styled(Box)`
  position: absolute;
  left: 70%;
  top: 77%;
  width: max-content;
  transform: translate(-50%, -50%);
  color: ${COLORS.typography.secondary.main};
  background-color: ${COLORS.typography.headline};
  opacity: 0.8;
  padding: ${pxToRem(10)};
  font-family: Inter, sans-serif;
  font-size: 15px;
  cursor: pointer;

  ${SIZES.media.phone} {
    left: 83%;
    top: 90%;
  }
`;

export const StyledDesktopLinkText = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
`;

export const StyledDesktopImageLink = styled(Image)`
  filter: brightness(50%);
  transition-duration: 0.2s;
  &:hover {
    filter: brightness(70%);
  }
`;
