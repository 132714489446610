import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { StaticElementType } from "./ReservationDatePicker.d";

export const StyledContainer = styled(Box)<StaticElementType>`
  background-color: ${COLORS.background.main};
  ${({ isPrivate }) => !isPrivate && "grid-template-columns: 5fr 1fr 7fr;"}
  border: ${({ withBorder }) =>
    withBorder ? `1px solid ${COLORS.stroke.main}` : "none"};
  ${({ withBorder }) => withBorder && `padding: ${pxToRem(12)} ${pxToRem(20)}`};

  ${SIZES.media.phone} {
    min-width: 310px;
    ${({ withBorder, pr }) => withBorder && `padding: 0 ${pr}px 0 0`};
  }
`;

export const StyledIcon = styled(Box)`
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  &:hover {
    cursor: pointer;  
`;

export const StyledItem = styled(Box)<StaticElementType>`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "unset")};
  padding: ${({ withBorder }) => (withBorder ? "16px 18px" : 0)};
  &:first-child {
    padding-right: 0;
  }
`;
