import { Typography } from "components/Typography";
import { FC, useState } from "react";
import { BookedHouseItemProps } from "./BookedHouseItem.d";
import {
  StyledContainer,
  StyledIcon,
  StyledEditButtonContainer,
  StyledDivider,
  StyledSelectArrowIcon,
  StyledAccordionArrowIcon,
} from "./BookedHouseItem.styled";
import { Box } from "components/Box";
import { COLORS } from "theme";
import { SummaryPrice } from "../SummaryPrice";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { ReservationDatePicker } from "components/Booking/ReservationDatePicker";
import { differenceInDays } from "date-fns";
import { useWindowSize } from "global/hooks/useWindowSize";
import { PriceComponent } from "types/house.d";
import { calculateBookingCost } from "../BookingCalculator/bookingUtils";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const BookedHouseItem: FC<BookedHouseItemProps> = ({
  id,
  is_main_request,
  check_in_date,
  check_out_date,
  bookingPrice,
  onEditClick,
  adults_num,
  children_num,
  infants_num,
  is_editable,
  seasonalPrices,
  regularPrices,
  discount,
  isPriceOnRequest,
  priceWithoutDiscount,
}) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isTablet } = useWindowSize();

  const childrenInTotal = children_num + infants_num;

  const guestsLabel = `${adults_num} ${translation["adults"][selectedLanguage]}, ${childrenInTotal} ${translation["children"][selectedLanguage]}`;

  const title = `${
    is_main_request
      ? translation["mainBookingLabel"][selectedLanguage]
      : translation["additionalLabel"][selectedLanguage]
  } ${translation["requestLabel"][selectedLanguage]}`;

  const nightsCounter =
    check_in_date && check_out_date
      ? differenceInDays(new Date(check_out_date), new Date(check_in_date))
      : 0;

  const pricePerNight =
    Math.round((bookingPrice / nightsCounter) * 100 + Number.EPSILON) / 100;

  const priceSummary: PriceComponent[] =
    check_in_date && check_out_date
      ? calculateBookingCost(
          new Date(check_in_date),
          new Date(check_out_date),
          adults_num,
          children_num,
          infants_num,
          discount,
          seasonalPrices,
          regularPrices
        )
      : [{ nightsCount: 0, pricePerNight }];

  return (
    <StyledContainer display="grid" gridGap={0.4}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" color={COLORS.typography.headline}>
          {title}
        </Typography>
        <StyledIcon>
          <StyledAccordionArrowIcon
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}
            stroke={COLORS.typography.body}
          />
        </StyledIcon>
      </Box>
      <ReservationDatePicker
        checkInDate={new Date(check_in_date)}
        checkOutDate={new Date(check_out_date)}
        withBorder={false}
      />
      {!isTablet && (
        <Box display="grid">
          <Typography variant="overline" color={COLORS.typography.body}>
            {translation["guestsLabel"][selectedLanguage]}
          </Typography>
          <Typography variant="caption">{guestsLabel}</Typography>
        </Box>
      )}
      {isOpen && (
        <>
          {isTablet && (
            <Box display="grid">
              <Typography variant="overline" color={COLORS.typography.body}>
                {translation["guestsLabel"][selectedLanguage]}
              </Typography>
              <Typography variant="caption">{guestsLabel}</Typography>
            </Box>
          )}
          <StyledDivider />
          <SummaryPrice
            priceSummary={priceSummary}
            isPriceOnRequest={isPriceOnRequest}
            oldPrice={priceWithoutDiscount}
          />
          <StyledDivider />
          {is_editable && (
            <StyledEditButtonContainer>
              <Button
                icon={
                  <StyledSelectArrowIcon
                    stroke={COLORS.accent.primary}
                    fill="none"
                    width="20px"
                    height="20px"
                  />
                }
                variant={ButtonVariant.SECONDARY}
                size={ButtonSize.LARGE}
                onClick={() =>
                  onEditClick({
                    id,
                    check_in_date,
                    check_out_date,
                    adults_num,
                    children_num,
                    infants_num,
                    bookingPrice,
                  })
                }
              >
                {translation["editLabel"][selectedLanguage]}
              </Button>
            </StyledEditButtonContainer>
          )}
        </>
      )}
    </StyledContainer>
  );
};
