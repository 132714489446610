import { LOGIN_PATH, routes } from "./config/routes";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { Wrapper } from "App.styled";
import { useAuth } from "context/Auth";
import { FC, useEffect } from "react";
import { HOUSES_PAGE } from "urls/frontend";
import { Toast } from "components/Toast/Toast";
import { useProfile } from "context/Profile";

const RedirectOnLoginSuccess = () => <Navigate to={{ pathname: LOGIN_PATH }} />;

const App = () => {
  const auth = useAuth();
  const isLoggedIn = auth?.user;
  const profile = useProfile();

  const getPublicRoute = (path: string, Component: FC) => {
    return <Route key={path} path={path} element={<Component />} />;
  };

  const getPrivateRoute = (path: string, Component: FC) => {
    const isLoggedIn = auth?.user;
    const element = !isLoggedIn ? <RedirectOnLoginSuccess /> : <Component />;
    return <Route key={path} path={path} element={element} />;
  };

  const getLoggedOutOnlyRoute = (path: string, Component: FC) => {
    const element = !isLoggedIn ? (
      <Component />
    ) : auth?.redirectPath ? (
      <Navigate to={auth.redirectPath} />
    ) : (
      <Navigate to={{ pathname: HOUSES_PAGE }} />
    );

    return <Route key={path} path={path} element={element} />;
  };

  useEffect(() => {
    isLoggedIn && profile?.fetchProfileData();
  }, [isLoggedIn]);

  return (
    <Wrapper display="flex" flexDirection="column" alignItems="stretch">
      <Toast />
      <Router>
        <Routes>
          {routes.map(({ path, component, accessibility }) => {
            return accessibility === "PUBLIC"
              ? getPublicRoute(path, component)
              : accessibility === "PRIVATE"
              ? getPrivateRoute(path, component)
              : getLoggedOutOnlyRoute(path, component);
          })}
        </Routes>
      </Router>
    </Wrapper>
  );
};

export default App;
