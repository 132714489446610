import { Image } from "components/Image";
import { Typography } from "components/Typography";
import { FC, useEffect, useState } from "react";
import { COLORS } from "theme";
import { HouseOwnersProps, OwnerInfo } from "./HouseOwners.d";
import {
  StyledContainer,
  StyledImagesContainer,
  StyledAvatarContainer,
  StyledAvatarsContainer,
  StyledTextContent,
  OwnerDescription,
} from "./HouseOwners.styled";
import { ButtonVariant } from "components/Button/Button.enums";
import { HOST_URL } from "urls/api";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseOwners: FC<HouseOwnersProps> = ({ ownersInfo }) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const [ownersGroupInfo, ...eachOwnersInfo] = ownersInfo;

  const [activeOwnerInfo, setActiveOwnerInfo] =
    useState<OwnerInfo>(ownersGroupInfo);

  useEffect(() => {
    setActiveOwnerInfo(ownersGroupInfo);
  }, [ownersInfo]);
  return (
    <>
      {ownersInfo.length > 0 && (
        <StyledContainer
          px={2.3}
          py={5.8}
          display="grid"
          flexDirection="row"
          gridGap={3}
          alignItems="center"
        >
          <StyledTextContent
            display="grid"
            flexDirection="column"
            gridGap={4.3}
          >
            <div>
              <Typography variant="h2" color={COLORS.typography.secondary.main}>
                {translation["houseOwner"][selectedLanguage]}
              </Typography>
              {activeOwnerInfo?.full_name && (
                <Typography variant="h4" color={COLORS.typography.disabled}>
                  {activeOwnerInfo?.full_name}
                </Typography>
              )}
            </div>
            <OwnerDescription variant="caption" color={COLORS.stroke.main}>
              {activeOwnerInfo?.description}
            </OwnerDescription>
          </StyledTextContent>
          {activeOwnerInfo?.images.length > 0 && (
            <StyledImagesContainer
              display="grid"
              alignItems="center"
              gridGap={1.8}
              imagesSize={activeOwnerInfo?.images.length}
            >
              {activeOwnerInfo.images.map((image, index) => (
                <Image
                  key={`${activeOwnerInfo?.id}-${index}`}
                  variant="flexible"
                  src={image && `${HOST_URL}${image}`}
                />
              ))}
            </StyledImagesContainer>
          )}
          {ownersInfo.length > 1 && (
            <StyledAvatarsContainer
              display="grid"
              gridAutoFlow="column"
              alignItems="center"
              pt={2}
            >
              {ownersGroupInfo.images.length > 0 && (
                <StyledAvatarContainer
                  isActive={ownersGroupInfo?.id === activeOwnerInfo?.id}
                  variant={ButtonVariant.SECONDARY}
                  onClick={() => setActiveOwnerInfo(ownersGroupInfo)}
                >
                  <Image
                    variant="avatarBig"
                    isRound={true}
                    src={
                      ownersGroupInfo.images[0] &&
                      `${HOST_URL}${ownersGroupInfo.images[0]}`
                    }
                  />
                </StyledAvatarContainer>
              )}
              {eachOwnersInfo.length > 0 &&
                eachOwnersInfo.map((ownerInfo, index) => (
                  <StyledAvatarContainer
                    key={`${ownerInfo?.id}-${index}`}
                    isActive={ownerInfo?.id === activeOwnerInfo?.id}
                    variant={ButtonVariant.SECONDARY}
                    onClick={() => setActiveOwnerInfo(ownerInfo)}
                  >
                    <Image
                      variant="avatarMedium"
                      isRound={true}
                      src={
                        ownerInfo.images[0] &&
                        `${HOST_URL}${ownerInfo.images[0]}`
                      }
                    />
                  </StyledAvatarContainer>
                ))}
            </StyledAvatarsContainer>
          )}
        </StyledContainer>
      )}
    </>
  );
};
