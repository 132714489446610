import * as Yup from "yup";
import { GenderVariant } from "./ProfileForm.enums";

export const IMG_MAX_SIZE = 1024 * 1024 * 20; // 20 MB

export const imageSchema = Yup.mixed()
  .nullable(true)
  .test("isBelowMaxSize", "The file is too big", (value: File) => {
    if (value) {
      return value.size < IMG_MAX_SIZE;
    }
    return true;
  });

export const profileValidationSchema = Yup.object({
  profile: Yup.object().shape({
    phone_number: Yup.string().max(20).nullable(true),
    current_proffesion: Yup.string().nullable(true),
    company_name: Yup.string().nullable(true),
    industry: Yup.string().nullable(true),
    education: Yup.string().nullable(true),
    country: Yup.string().max(100).nullable(true),
    city: Yup.string().max(100).nullable(true),
    street: Yup.string().max(90).nullable(true),
    house: Yup.string().max(10).nullable(true),
    postal_code: Yup.string().max(9).nullable(true),
    about: Yup.string()
      .min(200, "must be at least 200 characters long")
      .max(1000)
      .nullable(true),
    hobbies: Yup.array(),
    avatar: imageSchema,
  }),
  name: Yup.string().required("Please, insert name").max(200),
  last_name: Yup.string().required("Please, insert last name").max(200),
  gender: Yup.string()
    .oneOf([
      GenderVariant.GENDER_UNKNOWN,
      GenderVariant.MALE,
      GenderVariant.FEMALE,
    ])
    .nullable(true),
  maiden_name: Yup.string().max(200).nullable(true),
});

export const passwordValidationSchema = Yup.object({
  old_password: Yup.string().required(),
  password: Yup.string().required(),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
