import { FC } from "react";
import { PaginationItemProps } from "./PaginationItem.d";
import { StyledPaginationItem } from "./PaginationItem.styled";

export const PaginationItem: FC<PaginationItemProps> = ({
  label,
  link,
  isCurrent = false,
}) => {
  return (
    <StyledPaginationItem isCurrent={isCurrent} to={link}>
      {label}
    </StyledPaginationItem>
  );
};
