export const COLORS = {
  accent: {
    primary: "#C47D3C",
    secondary: "#274939",
    hover: "#AF7136",
    pressed: "#9C6430",
    disabled: "#E6E4E2",
    border: "#ECB787",
    boxes: "#FAF3EC",
  },
  typography: {
    main: "#23243E",
    secondary: {
      main: "#FFFFFF",
      light: "#FFF6ED",
      dark: "#000000",
    },
    headline: "#0C0D24",
    body: "#696A79",
    stroke: "#EDEDED",
    icons: "#B7B7B7",
    disabledElement: "#E6E4E2",
    disabled: "#C4C2C0",
  },
  stroke: {
    main: "#E5E2E2",
    secondary: "#F5F5F5",
    hover: "#E6E2E2",
    focus: "#B3B4BC",
    error: "#DD6464",
  },
  error: {
    main: "#E93E3E",
    hover: "#FCEBE9",
    text: "#FFFFFF",
  },
  success: {
    main: "#74AD71",
    secondary: "#5FBE4F",
    hover: "#F1F9F2",
  },
  warning: {
    main: "#FBBC00",
    hover: "#FEF7E4",
  },
  info: {
    main: "#0B3256",
    hover: "#E8ECF0",
  },
  background: {
    main: "#FFFFFF",
    control: "#FAFAFA",
    overlay: "#2D3A4DCC",
    tooltip: "#9DA3BB",
    boxes: "#F9ECDC",
  },
};
