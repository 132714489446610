import { forwardRef } from "react";
import { StyledInput } from "./Input.styled";
import { InputProps } from "./Input.d";
import { Box } from "components/Box";
import { Label } from "components/Label";

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      disabled,
      id,
      name = id,
      required,
      inputFirst = false,
      alignCenter = false,
      ...props
    },
    ref
  ) => {
    return (
      <Box
        display="grid"
        gridGap={1.5}
        gridAutoFlow={alignCenter && "column"}
        alignItems={alignCenter && "center"}
      >
        {label && (
          <Label
            htmlFor={id}
            disabled={disabled}
            required={required}
            error={!!error}
          >
            {error ? error : label}
          </Label>
        )}
        <StyledInput
          id={id}
          name={name}
          disabled={disabled}
          error={!!error}
          ref={ref}
          {...props}
          inputFirst={inputFirst}
        />
      </Box>
    );
  }
);

Input.displayName = "Input";
