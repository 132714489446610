import { useState, FC } from "react";
import { TabsType } from "./Tabs.d";
import { TabItem, TabsList, ContentWrapper } from "./Tabs.styled";
import { slugify } from "../../global/utils";
import { TabsVariant } from "./Tabs.enums";

export const Tabs: FC<TabsType> = ({
  tabs,
  variant = TabsVariant.SMALL,
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState(slugify(tabs[0].label));
  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newActiveTab: string
  ) => {
    e.preventDefault();
    setActiveTab(slugify(newActiveTab));
    onTabChange && onTabChange(newActiveTab);
  };

  return (
    <>
      <TabsList>
        {tabs.map((tab) => {
          const { label, count } = tab;
          return (
            <TabItem
              key={label}
              variant={variant}
              onClick={(e) => handleClick(e, label)}
              active={slugify(label) === activeTab}
            >
              {label}
              {tab.count && <span> ({count})</span>}
            </TabItem>
          );
        })}
      </TabsList>
      {tabs.map((tab) => {
        if (slugify(tab.label) === activeTab)
          return <ContentWrapper key={tab.label}>{tab.content}</ContentWrapper>;
      })}
    </>
  );
};
