import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "components";
import { Background } from "components/Background";
import { Button } from "components/Button";
import { ButtonSize } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { FC, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { reactivateInvitationRequest } from "services/Profile";
import { ResendInvitationForm } from "types/user";
import { isValidForm } from "utils/isValidForm";
import { Content, TextTypography } from "./ReactivateInvitation.styled";
import { ResendInvitationSchema } from "./validators";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const ReactivateInvitation: FC = () => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<Omit<ResendInvitationForm, "captcha_token">>({
    resolver: yupResolver(ResendInvitationSchema),
    mode: "onChange",
  });
  const reRef = useRef<any>();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const onSubmit: SubmitHandler<
    Omit<ResendInvitationForm, "captcha_token">
  > = async (data) => {
    const captcha_token = await reRef.current?.executeAsync();
    reRef.current.reset();
    const form: ResendInvitationForm = {
      ...data,
      captcha_token: captcha_token,
    };
    try {
      const response = await reactivateInvitationRequest(form);
      if (response.status === 200) {
        toast.success(translation["formSendSuccessfully"][selectedLanguage]);
        reset();
      } else {
        toast.error(translation["invitationNotFound"][selectedLanguage]);
      }
    } catch (error) {
      toast.error(translation["errorMessage"][selectedLanguage]);
    }
  };
  return (
    <Background displayLogo>
      {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      ) : null}
      <Content
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gridGap={3}
          >
            <TextTypography variant="h3">
              {translation["confirmEmail"][selectedLanguage]}
            </TextTypography>
            <Input
              required
              label="email address"
              {...register("email")}
              error={!isValidForm(errors) && errors.email?.message}
            />
            <Button type="submit" size={ButtonSize.SMALL}>
              {translation["sendLinkAgain"][selectedLanguage]}
            </Button>
          </Box>
        </form>
      </Content>
    </Background>
  );
};
