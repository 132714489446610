import { hexToRGB } from "global/utils";
import { FC } from "react";
import { COLORS } from "theme";
import { OwnerAdviceProps } from "./OwnerAdvice.d";
import {
  StyledContainer,
  StyledTypography,
  StyledTitle,
} from "./OwnerAdvice.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const OwnerAdvice: FC<OwnerAdviceProps> = ({ ownerAdvice }) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <>
      {ownerAdvice.length > 0 && (
        <StyledContainer display="grid" gridGap={2} p={2.7} mb={9}>
          <StyledTitle variant="h2" color={COLORS.typography.headline}>
            {translation["adviceFromOwner"][selectedLanguage]}
          </StyledTitle>
          <StyledTypography
            variant="caption"
            color={hexToRGB(COLORS.typography.headline, "0.7")}
          >
            {ownerAdvice}
          </StyledTypography>
        </StyledContainer>
      )}
    </>
  );
};
