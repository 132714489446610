import { Box } from "components/Box";
import { Modal } from "components/Modal/Modal";
import styled, { createGlobalStyle } from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  width: 100%;
  position: relative;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const StyledModal = styled(Modal)`
  max-height: calc(100vh - 130px);
`;
