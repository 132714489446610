import { FC } from "react";
import { LoaderBox, SpinnerBox } from "./Loader.styled";

export const Loader: FC = () => {
  return (
    <LoaderBox>
      <SpinnerBox>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </SpinnerBox>
    </LoaderBox>
  );
};
