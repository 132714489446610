import {
  useState,
  useContext,
  createContext,
  ReactNode,
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import { UserProfile } from "types/user.d";
import { fetchProfile } from "services/Profile";
import { HOST_URL } from "urls/api";
import { getLanguage } from "utils/getLanguage";
import { Language } from "types/language";

export type Profile = {
  profileInfo: UserProfile | null;
  fetchProfileData: () => void;
  selectedLanguage: Language;
  setSelectedLanguage: Dispatch<SetStateAction<Language>>;
  isFirstLogin: boolean;
  setIsFirstLogin: Dispatch<SetStateAction<boolean>>;
  refreshProfile: () => void;
};

const profileContext = createContext<Profile | null>(null);

export const useProvideProfile = (): Profile => {
  const [profileInfo, setProfileInfo] = useState<UserProfile | null>(null);
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    getLanguage()
  );

  const fetchProfileData = async () => {
    const response = await fetchProfile();
    const profileData: UserProfile = response.parsedBody;
    if (profileData) {
      setProfileInfo({
        name: profileData.name,
        last_name: profileData.last_name,
        gender: profileData.gender,
        maiden_name: profileData.maiden_name,
        email: profileData.email,
        profile: {
          avatar: profileData.profile?.avatar
            ? HOST_URL + profileData.profile.avatar
            : null,
          current_profession: profileData.profile.current_profession,
          company_name: profileData.profile.company_name,
          education: profileData.profile.education,
          industry: profileData.profile.industry,
          about: profileData.profile?.about,
          city: profileData.profile?.city,
          country: profileData.profile?.country,
          house: profileData.profile?.house,
          phone_number: profileData.profile?.phone_number,
          street: profileData.profile?.street,
          postal_code: profileData.profile?.postal_code,
          hobbies: profileData.profile?.hobbies,
        },
        can_invite_owners: profileData.can_invite_owners,
        is_house_owner: profileData.is_house_owner,
        is_main_owner: profileData.is_main_owner,
        access_type: profileData.access_type,
        can_add_friends: profileData.can_add_friends,
        is_admin: profileData.is_admin,
        is_superadmin: profileData.is_superadmin,
        applied_for_membership: profileData.applied_for_membership,
      });
      setIsFirstLogin(profileData.is_first_login ?? false);
    }
  };

  const refreshProfile = () => {
    setProfileInfo(null);
  };

  return {
    profileInfo,
    fetchProfileData,
    selectedLanguage,
    setSelectedLanguage,
    isFirstLogin,
    setIsFirstLogin,
    refreshProfile,
  };
};

interface IProvideProfile {
  children: ReactNode;
}

export const ProfileProvider: FC<IProvideProfile> = ({ children }) => {
  const profile = useProvideProfile();
  return (
    <profileContext.Provider value={profile}>
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = () => {
  return useContext(profileContext);
};
