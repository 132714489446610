import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS, LAYERS, SHADOWS } from "theme";
import { StyledFieldBasic } from "../Form/common.styled";
import { Label } from "../Label";
import { StyledSelectContainerProps, StyledSelectArrowProps } from "./Select.d";
import { ReactComponent as SelectArrow } from "assets/icons/icon_select_arrow.svg";
import { ReactComponent as IconPlus } from "assets/icons/icon_plus.svg";

export const StyledSelectWrapper = styled.div`
  position: relative;
  height: 62px;
  box-sizing: border-box;
`;

export const StyledSelect = styled.select`
  position: absolute;
  visibility: hidden;
`;

export const StyledLabel = styled(Label)`
  height: 5px;
`;

export const StyledSelectContainer = styled.div<StyledSelectContainerProps>`
  ${({ disabled, isOpen, zIndex }) => css`
    ${StyledFieldBasic}
    position: absolute;
    z-index: ${zIndex ?? LAYERS.dropdown};
    min-height: ${pxToRem(60)};
    color: ${COLORS.typography.headline};
    overflow-wrap: break-word;
    background-color: ${COLORS.typography.secondary.main};
    ${disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
    ${isOpen &&
    css`
      box-shadow: ${SHADOWS.dropHover};
    `}
  `}
`;

export const StyledOptionsList = styled.ul`
  box-sizing: border-box;
  list-style: none;
  margin: ${pxToRem(18)} 0;
  padding: 0;
  max-height: ${pxToRem(270)};
  overflow-y: scroll;
  cursor: pointer;
`;

export const StyledOption = styled.li`
  box-sizing: border-box;
  padding: ${pxToRem(9)} 0;
  min-height: ${pxToRem(18)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    color: ${COLORS.accent.primary};
    svg {
      path {
        fill: ${COLORS.accent.primary};
      }
    }
  }
`;

export const StyledSelectedOptionsContainer = styled(Box)`
  width: 90%;
`;

export const StyledSelectedOptions = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledLabelInside = styled(Label)`
  cursor: pointer;
  font-size: 14px;
  text-transform: none;
  color: ${COLORS.typography.headline};
`;

export const StyledSelectArrowIcon = styled(
  SelectArrow
)<StyledSelectArrowProps>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  transition: 0.2s ease-out;
`;

export const StyledContainer = styled(Box)`
  height: inherit;
`;

export const StyledIconPlus = styled(IconPlus)`
  margin-right: 12px;
  path {
    fill: ${COLORS.typography.headline};
  }
`;
