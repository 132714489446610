import styled from "styled-components";
import { COLORS, SIZES } from "../../../theme";
import { ButtonProps } from "../../../global/types";
import { Box } from "../../Box";
import { Typography } from "../../Typography";
import { CounterIconProps } from "./GuestPicker.d";

export const StyledInfoContainer = styled(Box)<CounterIconProps>`
  align-items: center;
  color: ${({ disabled }) =>
    disabled ? COLORS.typography.disabled : COLORS.typography.headline};
`;

export const StyledAgeRange = styled(Typography)`
  font-size: 15px;
  line-height: 17px;
  font-weight: ${SIZES.fontWeight.regular};
`;

export const StyledCounterContainer = styled(Box)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  min-width: 105px;
`;

export const StyledCounter = styled(Typography)<CounterIconProps>`
  font-size: 15px;
  font-weight: ${SIZES.fontWeight.regular};
  color: ${({ disabled }) =>
    disabled ? COLORS.typography.disabled : COLORS.typography.headline};
`;

export const CounterIcon = styled.button<ButtonProps>`
  font-family: "Playfair Display", sans-serif;
  width: 30px;
  height: 30px;
  line-height: 24px;
  border-radius: 50%;
  border: 1px solid ${COLORS.stroke.main};
  color: ${({ disabled }) =>
    disabled ? COLORS.typography.disabled : COLORS.typography.headline};
  font-size: 22px;
  text-align: center;
  background-color: ${COLORS.typography.secondary.main};
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
  padding: 0 0 2px 0;
  margin: 0;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? COLORS.typography.secondary.main : COLORS.stroke.main};
  }
`;
