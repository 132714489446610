import { FC } from "react";
import { ReactComponent as IconInstagram } from "assets/icons/icon_instagram.svg";
import { FooterProps } from "./Footer.d";
import { Box } from "../Box";
import {
  FooterContainer,
  FooterItemsContainer,
  InstagramButton,
} from "./Footer.styled";
import { Link, ExternalLink } from "components/Link/Link";
import { FooterVariant } from "./Footer.enums";
import { COLORS } from "theme";
import {
  PRIVACY_POLICY_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  COMPANY_IMPRINT_PAGE,
  HOW_IT_WORKS,
  CONTACT_PAGE,
  CONTACT_US,
} from "urls/frontend";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const Footer: FC<FooterProps> = ({
  variant = FooterVariant.PRIVATE,
  borderTop = false,
  linkColor = "black",
  isHousePage,
  demoToken,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <FooterContainer
      display="grid"
      alignItems="flex-start"
      justifyContent="space-between"
      borderTop={borderTop}
      isHousePage={isHousePage}
    >
      <FooterItemsContainer
        color={
          variant === FooterVariant.PRIVATE
            ? COLORS.typography.main
            : COLORS.typography.body
        }
        display="grid"
        gridAutoFlow="column"
        gridGap={3}
        mb={3}
      >
        <Link
          linkColor={linkColor}
          to={`${COMPANY_IMPRINT_PAGE}${
            demoToken ? `?demo_token=${demoToken}` : ""
          }`}
        >
          {translation["imprint"][selectedLanguage]}
        </Link>
        <Link
          linkColor={linkColor}
          to={`${PRIVACY_POLICY_PAGE}${
            demoToken ? `?demo_token=${demoToken}` : ""
          }`}
        >
          {translation["privacyPolicy"][selectedLanguage]}
        </Link>
        <Link
          linkColor={linkColor}
          to={`${TERMS_AND_CONDITIONS_PAGE}${
            demoToken ? `?demo_token=${demoToken}` : ""
          }`}
        >
          {translation["termsAndConditions"][selectedLanguage]}
        </Link>
        <Link
          linkColor={linkColor}
          to={`${HOW_IT_WORKS}${demoToken ? `?demo_token=${demoToken}` : ""}`}
        >
          {translation["howItWorks"][selectedLanguage]}
        </Link>
        {variant === FooterVariant.PUBLIC && (
          <Link
            linkColor={linkColor}
            to={`${CONTACT_US}${demoToken ? `?demo_token=${demoToken}` : ""}`}
          >
            {translation["contactUs"][selectedLanguage]}
          </Link>
        )}
        {variant === FooterVariant.PRIVATE && (
          <Link linkColor={linkColor} to={CONTACT_PAGE}>
            {translation["contactUs"][selectedLanguage]}
          </Link>
        )}
      </FooterItemsContainer>
      <Box>
        {variant === FooterVariant.PRIVATE && (
          <ExternalLink
            linkColor={linkColor}
            href="https://www.instagram.com/houseoffriends.world/"
          >
            <InstagramButton>
              {translation["followUs"][selectedLanguage]}
              <IconInstagram />
            </InstagramButton>
          </ExternalLink>
        )}
      </Box>
    </FooterContainer>
  );
};
