import * as Yup from "yup";
import { AccessVariant } from "./InviteFriendViaEmailModal.enums";

export const emailInvitationValidationSchema = Yup.object({
  name: Yup.string().required("Name is a required field").max(200),
  last_name: Yup.string().required("Last name is a required field").max(200),
  email: Yup.string()
    .email()
    .matches(
      /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
      "Please, enter a valid email address."
    )
    .required("Please, insert email address."),
  discount: Yup.string(),
  personal_message: Yup.string(),
});
