import { FC, useRef, useState } from "react";
import { Box } from "components/Box";
import { Button } from "components/Button";
import { Background } from "components/Background";
import {
  Content,
  LoginText,
  ResetPassword,
  StyledButton,
} from "./Login.styled";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { Link } from "components/Link";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from "context/Auth";
import { LoginForm } from "./Login.d";
import { FORGOT_PASSWORD, REGISTER } from "urls/frontend";
import { loginSchema } from "./validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { isValidForm } from "utils/isValidForm";
import { getLanguage } from "utils/getLanguage";
import { useProfile } from "context/Profile";
import translation from "./translation.json";
import { useNavigate } from "react-router";

const Login: FC = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<LoginForm, "captcha_token">>({
    resolver: yupResolver(loginSchema),
    mode: "onBlur",
  });
  const [message, setMessage] = useState("");
  const reRef = useRef<any>();
  const auth = useAuth();

  const onClick = async (data: Omit<LoginForm, "captcha_token">) => {
    const captcha_token = await reRef.current?.executeAsync();
    reRef.current.reset();
    const response = await auth?.login({
      ...data,
      captcha_token: captcha_token,
    });
    if (!response?.ok && response?.parsedBody.message) {
      setMessage(response.parsedBody.message);
    }
  };

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Background displayLogo>
      {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      ) : null}
      <form onSubmit={handleSubmit(onClick)}>
        <Box>
          <Content
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <LoginText variant="h3">Log in</LoginText>
            <Input
              {...register("email", {
                required: true,
              })}
              label="e-mail"
            />
            <Input
              type="password"
              {...register("password", {
                required: true,
              })}
              label="password"
              error={!isValidForm(errors) && errors.email?.message}
            />
            {message && (
              <Box display="flex" color="red">
                {message}
              </Box>
            )}
            <Link to="/">
              <ResetPassword variant="caption">
                <Link to={FORGOT_PASSWORD} linkColor="black">
                  {translation["resetPassowrd"][selectedLanguage]}
                </Link>
              </ResetPassword>
            </Link>
            <Box display="flex" justifyContent="space-between">
              <Button type="submit" size={ButtonSize.SMALL}>
                {translation["login"][selectedLanguage]}
              </Button>
            </Box>
            <StyledButton
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.SMALL}
              onClick={() => navigate(REGISTER)}
            >
              {translation["becomePartOfNetwork"][selectedLanguage]}
            </StyledButton>
          </Content>
        </Box>
      </form>
    </Background>
  );
};

export default Login;
