import { Box } from "components/Box";
import { Button } from "components/Button";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const BookingMainBox = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
  grid-template-columns: 266px;
  justify-content: center;
  ${SIZES.media.tablet} {
    justify-content: initial;
    grid-template-columns: 197px;
  }
`;
export const BookingImageBox = styled(Box)`
  grid-column: 1;
  margin-bottom: ${pxToRem(20)};
  ${SIZES.media.tablet} {
    grid-column: 1 / 2;
    grid-row: 1;
    margin-bottom: 0;
  }
`;
export const BookingContentBox = styled(Box)`
  grid-row: 2;
  flex-direction: column;
  ${SIZES.media.tablet} {
    grid-column: 2;
    grid-row: 1;
  }
`;
export const BookingButtonsBox = styled(Box)`
  grid-row: 3;
  margin-left: auto;
  grid-row-gap: 8px;
  justify-content: space-between;
  padding-right: 16px;
  ${SIZES.media.tablet} {
    grid-column: 3;
    grid-row: 1;
  }
`;

export const BookingTypographyTitle = styled(Typography)`
  font-family: "Playfair Display";
  line-height: 36px;
  margin-right: ${pxToRem(12)};
  &: hover {
    text-decoration: underline;
  }
`;
export const BookingTypographyOldPrice = styled(Typography)`
  margin-right: ${pxToRem(12)};
  text-decoration: line-through;
`;

export const BookingDeclaineButton = styled(Button)`
  background-color: ${COLORS.background.main};
  border: 0;
  color: ${COLORS.stroke.error};
  text-decoration: underline;
  &:enabled {
    background-color: ${COLORS.background.main};
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.background.main};
    }
  }
`;
export const PriceBox = styled(Box)`
  margin-bottom: ${pxToRem(18)};
  ${SIZES.media.tablet} {
    margin-bottom: 0;
  }
`;
export const PeopleBox = styled(Box)`
  margin-bottom: ${pxToRem(24)};
  ${SIZES.media.tablet} {
    margin-bottom: 0;
  }
`;

export const ReservationBox = styled(Box)`
  margin-right: 0;
  margin-bottom: ${pxToRem(12)};
  ${SIZES.media.tablet} {
    margin-right: ${pxToRem(72)};
    margin-bottom: 0;
  }
`;

export const BookingOptionsContainer = styled(Box)`
  width: 110px;
  height: 140px;
`;

export const ExtraBox = styled(Box)`
  ${SIZES.media.tablet} {
    min-width: 300px;
  }
`;
