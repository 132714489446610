import { Box } from "components";
import styled from "styled-components";
import { FieldBasic } from "../Form/common";
import { StyledFieldBasic } from "../Form/common.styled";

interface InputProps extends FieldBasic {
  inputFirst?: boolean;
  type?: string;
}

export const StyledInput = styled.input<InputProps>`
  ${StyledFieldBasic}
  ${({ inputFirst }) => inputFirst && "grid-column: 1;"}
  ${({ type }) =>
    type === "radio" && "width: 24px; height:24px; padding: 0; margin: 0;"}
`;
