import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";
import { Box } from "components/Box";
import { StaticContainerType } from "./RecommendationItem.d";

export const StyledContainer = styled(Box)<StaticContainerType>`
  box-sizing: border-box;
  padding: ${SIZES.spacing(0.8)} ${SIZES.spacing(1)} ${SIZES.spacing(2)};
  ${({ isActive }) =>
    isActive && `box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25)`};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledItemAdditionalInfo = styled(Typography)`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  column-gap: ${pxToRem(10)};
`;

export const StyledLink = styled.a`
  font-family: "Inter", sans-serif;
  text-decoration: none;
  color: ${COLORS.typography.body};
`;

export const ImageContainer = styled(Box)`
  margin: 0 auto;
`;
export const StyledTypography = styled(Typography)`
  white-space: pre-wrap;
`;

export const StyledDescriptionContainer = styled(Box)`
  max-width: 280px;
  width: 100%;
`;

export const StyledTitle = styled(Typography)`
  max-width: 280px;
  width: 100%;
`;
