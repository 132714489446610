import { AnchorHTMLAttributes, FC } from "react";
import { LinkProps } from "react-router-dom";
import { CustomLinkProps } from "./Link.d";
import { StyledExternalLink, StyledLink } from "./Link.styled";

export const Link: FC<LinkProps & Partial<CustomLinkProps>> = ({
  linkColor,
  ...props
}) => {
  return <StyledLink linkColor={linkColor} {...props} />;
};

export const ExternalLink: FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & Partial<CustomLinkProps>
> = ({ linkColor, ...props }) => {
  return (
    <StyledExternalLink
      linkColor={linkColor}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
};
