export enum BookingStatus {
  NEW = "NEW",
  EXPIRED = "EXPIRED",
  DECLINED_BY_OWNER = "DECLINED_BY_OWNER",
  DECLINED_BY_ADMIN = "DECLINED_BY_ADMIN",
  APPROVED_BY_OWNER = "APPROVED_BY_OWNER",
  APPROVED_BY_ADMIN = "APPROVED_BY_ADMIN",
  WAITING_FOR_CHANGES_APPROVAL = "WAITING_FOR_CHANGES_APPROVAL",
  DEPOSIT_PAID = "DEPOSIT_PAID",
  BOOKED = "BOOKED",
  CANCELLED_BY_OWNER = "CANCELLED_BY_OWNER",
  CANCELLED_BY_ADMIN = "CANCELLED_BY_ADMIN",
  CANCELLED_BY_GUEST = "CANCELLED_BY_GUEST",
  ABOUT_TO_START = "ABOUT_TO_START",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
}

export enum BookingsStatusAction {
  NONE = "NONE",
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  CANCELLED_BY_USER = "CANCELLED_BY_USER",
  CANCELLED_BY_USER_AND_PAID_BALANCE = "CANCELLED_BY_USER_AND_PAID_BALANCE",
  CANCELLED_BY_USER_AND_PAID_DEPOSIT = "CANCELLED_BY_USER_AND_PAID_DEPOSIT",
  CANCELLED_BY_OWNER = "CANCELLED_BY_OWNER",
  CANCELLED_BY_OWNER_AND_PAID_BALANCE = "CANCELLED_BY_OWNER_AND_PAID_BALANCE",
  CANCELLED_BY_OWNER_AND_PAID_DEPOSIT = "CANCELLED_BY_OWNER_AND_PAID_DEPOSIT",
}

export enum UserStatus {
  INVITED = "INVITED",
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  ARCHIVED = "ARCHIVED",
}

export enum FriendRelationType {
  UNKNOWN = "UNKNOWN",
  FRIEND = "FRIEND",
  CONNECTION = "CONNECTION",
}
