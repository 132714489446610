import { Link } from "components";
import { Background } from "components/Background";
import { Input } from "components/Input";
import { useAuth } from "context/Auth";
import { FC, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CenteredSubText,
  CenteredText,
  CheckboxLabel,
  FormBox,
  NameBox,
  TextBox,
} from "./ActivateUser.styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { activationValidationSchema } from "./validators";
import { Button } from "components/Button";
import { UserActivationData } from "types/user";
import {
  activateAccount,
  requestAcceptedTerms,
  verifyActivationRequest,
} from "services/Profile";
import { LoginForm } from "views/Login/Login.d";
import { LOGIN_PAGE } from "urls/frontend";
import { Checkbox } from "components/Checkbox";
import { PRIVACY_POLICY_PAGE, TERMS_AND_CONDITIONS_PAGE } from "urls/frontend";
import { LINK_VISITED } from "urls/api";
import fetchApi from "services/fetchApi";
import { toast } from "react-toastify";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

const ActivateUser: FC = () => {
  const auth = useAuth();
  const reRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<Omit<UserActivationData, "token">>({
    resolver: yupResolver(activationValidationSchema),
    mode: "onBlur",
    defaultValues: {
      email: searchParams.get("email") || "",
      name: searchParams.get("first-name") || "",
      last_name: searchParams.get("last-name") || "",
    },
  });

  const accepted_terms = watch("accepted_terms");

  const onClick = async (data: Omit<UserActivationData, "token">) => {
    if (accepted_terms) {
      const captcha_token = await reRef.current?.executeAsync();
      const activateAccountData: UserActivationData = {
        ...data,
        email: searchParams.get("email") || "",
        token: searchParams.get("token") || "",
      };
      const response = await activateAccount(activateAccountData);

      if (response.ok) {
        const loginData: LoginForm = {
          captcha_token: captcha_token,
          email: activateAccountData.email,
          password: data.password,
        };
        reRef.current.reset();
        await auth?.login(loginData);
      }
    }
  };
  const linkVisitedCheck = (activation_token: string) => {
    fetchApi<Response>(LINK_VISITED(activation_token), {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
    });
  };

  const verifyActivation = async () => {
    if (accepted_terms) {
      await requestAcceptedTerms();
      const data: Pick<UserActivationData, "email" | "token"> = {
        token: searchParams.get("token") || "",
        email: searchParams.get("email") || "",
      };
      const response = await verifyActivationRequest(data);
      if (response.ok) {
        const status = response.parsedBody.status;
        toast.success(translation["registeredSuccessfully"][selectedLanguage]);
        if (status === "ALREADY_USED") navigate(LOGIN_PAGE);
        else {
          const { name, last_name } = response.parsedBody;
          setValue("name", name);
          setValue("last_name", last_name);
        }
      } else {
        toast.error(translation["registrationError"][selectedLanguage]);
      }
    }
  };

  useEffect(() => {
    verifyActivation();
  }, [searchParams]);

  useEffect(() => {
    linkVisitedCheck(searchParams.get("token") || "");
  }, []);

  return (
    <Background displayLogo>
      {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      ) : null}
      <form onSubmit={handleSubmit(onClick)}>
        <TextBox mb={7} mt={12}>
          <CenteredText variant="h1">
            {translation["description"][selectedLanguage]}
          </CenteredText>
          <CenteredSubText variant="body">
            {translation["signUpPrompt"][selectedLanguage]}
          </CenteredSubText>
        </TextBox>
        <FormBox display="grid" mx={2} gridGap={3}>
          <NameBox display="grid" gridGap={3}>
            <Input
              error={errors.name?.message}
              {...register("name")}
              label="First Name"
            />
            <Input
              error={errors.last_name?.message}
              {...register("last_name")}
              label="Last Name"
            />
          </NameBox>

          <Input {...register("email", { disabled: true })} label="Email" />
          <Input
            error={errors.password?.message}
            {...register("password")}
            label="Password"
            type="password"
          />
          <Input
            error={errors.confirm_password?.message}
            {...register("confirm_password")}
            label="Confirm Password"
            type="password"
          />
          <Checkbox
            {...register("accepted_terms")}
            value={1}
            label={
              <CheckboxLabel variant="body">
                {translation["agreementText"][selectedLanguage]}{" "}
                <Link linkColor="black" to={TERMS_AND_CONDITIONS_PAGE}>
                  <u>{translation["termsAndConditions"][selectedLanguage]} </u>
                </Link>{" "}
                {translation["and"][selectedLanguage]}{" "}
                <Link linkColor="black" to={PRIVACY_POLICY_PAGE}>
                  <u>
                    <u>{translation["privacyPolicy"][selectedLanguage]} </u>
                  </u>
                </Link>
              </CheckboxLabel>
            }
            checked={accepted_terms}
          />
          <Button type="submit" disabled={!accepted_terms}>
            <u>{translation["createAccount"][selectedLanguage]} </u>
          </Button>
        </FormBox>
      </form>
    </Background>
  );
};

export default ActivateUser;
