import { ContactUsForm } from "types/user";
import { CONTACT_ENDPOINT } from "urls/api";
import fetchApi from "./fetchApi";

export const contactUsRequest = (data: ContactUsForm) => {
  return fetchApi(CONTACT_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};
