import styled from "styled-components";
import { COLORS } from "theme";
import { ListOptionProps } from "./List.d";

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-y: auto;
  padding: 0;
`;

export const ListOption = styled.li<ListOptionProps>`
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? COLORS.accent.primary : COLORS.typography.main};
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "default")};
  display: flex;
  list-style: none;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.3)};
  padding: 12px 0;
  transition: 0.2s ease-out;
  user-select: none;

  &:hover {
    color: ${COLORS.accent.primary};
  }
`;

export const ListOptionIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;

  svg {
    height: 100%;
    width: 100%;
    color: ${COLORS.accent.primary};
    fill: ${COLORS.accent.primary};
  }
`;
