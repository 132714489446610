import { FC, useRef, useState, SyntheticEvent, useEffect } from "react";
import {
  StyledContainer,
  TabletsHolder,
  TabletsContainer,
  GlobalStyle,
  StyledContactSupportText,
  StyledLink,
  StyledConfirmationModalContent,
  StyledMinPrice,
} from "./BookingCalculator.styled";
import { BookingCalculatorProps } from "./BookingCalculator.d";
import { Typography } from "components/Typography";
import { Divider } from "components/Divider";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { COLORS } from "theme";
import { addSpacesToNumber } from "global/utils";
import { useWindowSize } from "global/hooks/useWindowSize";
import { Box } from "components/Box";
import { SummaryPrice } from "components/Booking/SummaryPrice";
import { BookingDetailsTabletModal } from "../BookingDetailsTabletModal";
import { BookingModal } from "../BookingModal";
import { BookingDetailsMobileModal } from "../BookingDetailsMobileModal";
import { Calendar } from "components/Calendar";
import { CalendarVariant } from "components/Calendar/Calendar.enums";
import { CalendarHeader } from "../../Calendar/CalendarHeader";
import { CalendarFooter } from "../../Calendar/CalendarFooter";
import { Guests } from "../Guests";
import { addDays } from "date-fns";
import { ReservationDatePicker } from "../ReservationDatePicker";
import ReactDatePicker from "react-datepicker";
import {
  calculateBookingCost,
  calculateMaxDate,
  getExcludeDates,
} from "./bookingUtils";
import { CompleteInfoModal } from "components/Modals/CompleteInfoModal/CompleteInfoModal";
import { Chip } from "components/Chip";
import { useProfile } from "context/Profile";
import { Modal } from "components/Modal";
import { useModal } from "global/hooks/useModal";
import { calculatePricePerNight, countNights, getPrice } from "utils/booking";
import { useSearchParams } from "react-router-dom";
import { CONTACT_PAGE } from "urls/frontend";
import { PriceComponent } from "types/house";
import { useCalculator } from "context/Calculator";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const BookingCalculator: FC<BookingCalculatorProps> = ({
  adultsPrice,
  childPrice,
  infantPrice,
  seasonalPrices,
  maxAdults,
  maxChildren,
  maxInfants,
  maxGuests,
  editedBooking,
  discount,
  discountDescription,
  minimumPricePerNight,
  minimumNights,
  blockedDates,
  bookedDates,
  cap,
  sendBookingRequest,
  isShownMobileModal,
  setIsShownMobileModal,
  isShownTabletModal,
  setIsShownTabletModal,
}) => {
  const INITIAL_PRICE_SUMMARY = [
    { nightsCount: 0, pricePerNight: minimumPricePerNight },
  ];
  const {
    adults,
    children,
    infants,
    startDate,
    endDate,
    setAdults,
    setChildren,
    setInfants,
    setStartDate,
    setEndDate,
    resetCalculator,
  } = useCalculator();
  const { isMobile, isTablet } = useWindowSize();
  const { isShown, toggle } = useModal();
  const [searchParams] = useSearchParams();
  const demo_token = searchParams.get("demo_token");
  const toggleMobileModal = () => setIsShownMobileModal(!isShownMobileModal);
  const toggleTabletModal = () => setIsShownTabletModal(!isShownTabletModal);
  const [maxDate, setMaxDate] = useState<undefined | null | Date>(null);
  const [minDate, setMinDate] = useState<Date>(addDays(new Date(), 1));
  const [isShownMobileCalendar, setIsShownMobileCalendar] =
    useState<boolean>(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] =
    useState<boolean>(false);
  const [priceSummary, setPriceSummary] = useState<PriceComponent[]>(
    INITIAL_PRICE_SUMMARY
  );
  const toggleMobileCalendar = () =>
    setIsShownMobileCalendar(!isShownMobileCalendar);
  const profile = useProfile();
  const additionalProfileInfo = profile?.profileInfo?.profile;
  const isNewBookingRequest = editedBooking.length === 0;
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const isPriceOnRequest = minimumPricePerNight === null;

  const calendarRef = useRef<
    ReactDatePicker & { setOpen: (open: boolean) => void }
  >(null);
  const formContainerRef = useRef<HTMLDivElement | null>(null);
  const confirmModalRef = useRef<HTMLDivElement | null>(null);

  const [excludeDates, setExcludeDates] = useState<Array<Date>>([]);

  const nightsCounter = countNights(startDate, endDate);

  useEffect(() => {
    const bookedDatesWithoutCurrent = bookedDates.filter(
      (date) => date.id !== editedBooking
    );
    const newExcludeDates = getExcludeDates([
      ...blockedDates,
      ...bookedDatesWithoutCurrent,
    ]);
    setExcludeDates(newExcludeDates);
  }, [bookedDates, blockedDates]);

  const closeDesktopCalendar = () => {
    calendarRef?.current?.setOpen(false);
  };

  const handleDateChange = (
    date: [Date | null, Date | null],
    event: SyntheticEvent<any, Event> | undefined
  ) => {
    event?.preventDefault();
    const bookedDatesWithoutCurrent = bookedDates.filter(
      (date) => date.id !== editedBooking
    );
    if (Array.isArray(date)) {
      const [start, end] = date;
      if (start?.getTime() !== end?.getTime()) {
        setStartDate(start);
        setEndDate(end);
      } else {
        setStartDate(null);
        setEndDate(null);
      }
      if (start && !end) {
        const newExcludeDates = getExcludeDates(
          [...blockedDates, ...bookedDatesWithoutCurrent],
          start
        );
        const newMaxDate: undefined | Date = calculateMaxDate(
          start,
          excludeDates
        );
        setMaxDate(newMaxDate);
        setMinDate(start);
        setExcludeDates(newExcludeDates);
      }
      if (start && end) {
        setMaxDate(null);
        setMinDate(addDays(new Date(), 1));
        const newExcludeDates = getExcludeDates(
          [...blockedDates, ...bookedDatesWithoutCurrent],
          start
        );
        setExcludeDates(newExcludeDates);
      }
    }
  };

  const handleResetCalculator = () => {
    resetCalculator("");
    const bookedDatesWithoutCurrent = bookedDates.filter(
      (date) => date.id !== editedBooking
    );
    const newExcludeDates = getExcludeDates([
      ...blockedDates,
      ...bookedDatesWithoutCurrent,
    ]);
    setExcludeDates(newExcludeDates);
  };

  const guestsTypes = [
    {
      id: "adults_num",
      label: translation["adults"][selectedLanguage],
      value: adults,
      max: maxAdults,
      price: getPrice(adultsPrice, discount),
    },
    {
      id: "children_num",
      label: translation["children"][selectedLanguage],
      value: children,
      max: maxChildren,
      price: getPrice(childPrice, discount),
    },
    {
      id: "infants_num",
      label: translation["infants"][selectedLanguage],
      value: infants,
      max: maxInfants ?? maxChildren,
      price: getPrice(infantPrice, discount),
    },
  ];

  const [adultsData, childrenData, infantsData] = guestsTypes;

  useEffect(() => {
    const regularPrices = {
      per_adult: adultsPrice,
      per_child: childPrice,
      per_baby: infantPrice,
      minimum_price_per_night: minimumPricePerNight,
      cap,
    };
    startDate &&
      endDate &&
      setPriceSummary(
        calculateBookingCost(
          startDate,
          endDate,
          adults,
          children,
          infants,
          discount,
          seasonalPrices,
          regularPrices
        )
      );
  }, [startDate, endDate, adults, children, infants, discount, seasonalPrices]);

  const pricePerNight = calculatePricePerNight(
    {
      id: editedBooking,
      check_in_date: startDate,
      check_out_date: endDate,
      adults_num: adults,
      children_num: children,
      infants_num: infants,
    },
    {
      adults: adultsData.price,
      children: childrenData.price,
      infants: infantsData.price,
    },
    getPrice(cap, discount),
    getPrice(minimumPricePerNight, discount)
  );

  const priceCounter = priceSummary.reduce(
    (acc, cost) => acc + cost.nightsCount * cost.pricePerNight,
    0
  );
  const isMaximumAdultsNumber = adults >= maxAdults;

  const isMaximumChildrenNumber = children + infants >= maxChildren;

  const isMaximumGuestsNumber = adults + children + infants >= maxGuests;

  const canSubtract = (counter: number, isAdult = false) =>
    isAdult ? counter > 1 : counter > 0;

  const canAdd = (isAdult = false) => {
    const isChildrenAvailable =
      !isMaximumChildrenNumber && !isMaximumGuestsNumber;
    const isAdultsAvailable = !isMaximumAdultsNumber && !isMaximumGuestsNumber;
    return isAdult ? isAdultsAvailable : isChildrenAvailable;
  };

  const isSubmitButtonDisabled = () => {
    return (
      !(startDate instanceof Date && endDate instanceof Date) ||
      !!demo_token ||
      !(adults >= 1) ||
      !(nightsCounter >= minimumNights)
    );
  };

  const updateCounter = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string,
    counterValue: number
  ) => {
    e.preventDefault();
    if (id === "adults_num") {
      setAdults(counterValue);
    } else if (id === "children_num") {
      setChildren(counterValue);
    } else if (id === "infants_num") {
      setInfants(counterValue);
    }
  };

  const isEmptyProfileValue = () => {
    const notRequiredProfileOptions = [
      "hobbies",
      "avatar",
      "company_name",
      "education",
      "industry",
      "current_profession",
    ];
    const profileKeys =
      additionalProfileInfo &&
      Object.fromEntries(
        Object.entries(additionalProfileInfo).filter(
          ([key]) => !notRequiredProfileOptions.includes(key)
        )
      );
    return (
      !!profileKeys &&
      Object.values(profileKeys).some(
        (value) =>
          value === null ||
          value === undefined ||
          value === "" ||
          value?.length === 0
      )
    );
  };

  const toggleMobileTabletModals = () => {
    isMobile ? toggleMobileModal() : toggleTabletModal();
  };

  const onConfirmOpenModalClick = () => {
    isShownMobileCalendar
      ? toggleMobileCalendar()
      : handleSendBookingButtonClick();
  };

  const handleSendBookingButtonClick = () => {
    isEmptyProfileValue()
      ? setIsCompleteModalOpen(true)
      : isNewBookingRequest
      ? sendBookingRequest()
      : toggle();
  };

  const getSubmitButtonName = () => {
    if (isSubmitButtonDisabled() && minimumPricePerNight) {
      return `${translation["minRequestLabel"][selectedLanguage]} € ${getPrice(
        minimumPricePerNight,
        discount
      )}/${translation["night"][selectedLanguage]}`;
    }
    return isPriceOnRequest
      ? translation["sendRequestForPrice"][selectedLanguage]
      : translation["sendBookingRequest"][selectedLanguage];
  };

  return (
    <>
      <GlobalStyle />
      {!isCompleteModalOpen && (
        <>
          <BookingModal
            open={isShownTabletModal}
            onClose={toggleTabletModal}
            isPrivate={false}
            discount={discount}
            modalContent={
              <BookingDetailsTabletModal
                isPrivate={false}
                nights={nightsCounter}
                minNights={minimumNights}
                check_in_date={startDate}
                check_out_date={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onCalendarChange={handleDateChange}
                updateCounter={updateCounter}
                guestsTypes={guestsTypes}
                canAdd={canAdd}
                canSubtract={canSubtract}
                blockedDates={blockedDates}
                onConfirmClick={() => null}
                priceSummary={priceSummary}
                isPriceOnRequest={isPriceOnRequest}
              />
            }
          />
          <BookingModal
            open={isShownMobileModal}
            onClose={toggleMobileModal}
            isPrivate={false}
            discount={discount}
            modalContent={
              <BookingDetailsMobileModal
                isPrivate={false}
                isShownMobileCalendar={isShownMobileCalendar}
                setIsShownMobileCalendar={toggleMobileCalendar}
                nights={nightsCounter}
                check_in_date={startDate}
                check_out_date={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onCalendarChange={handleDateChange}
                updateCounter={updateCounter}
                guestsTypes={guestsTypes}
                canAdd={canAdd}
                canSubtract={canSubtract}
                blockedDates={blockedDates}
                excludeDates={excludeDates}
                onConfirmClick={() => null}
                minNights={minimumNights}
                priceSummary={priceSummary}
                isPriceOnRequest={isPriceOnRequest}
              />
            }
          />
        </>
      )}
      {isTablet && !isCompleteModalOpen ? (
        <TabletsHolder>
          <TabletsContainer
            display="grid"
            gridAutoFlow="column"
            justifyContent="space-between"
            alignItems="center"
            px={2.3}
            py={1.5}
            isCompleteModalOpen={isCompleteModalOpen}
          >
            {!isCompleteModalOpen && (
              <Box display="grid" gridAutoFlow="row" gridGap={isMobile ? 0 : 1}>
                <Typography variant="h4" color={COLORS.typography.headline}>
                  € {addSpacesToNumber(priceCounter)}
                </Typography>
                <Typography variant="caption" color={COLORS.typography.body}>
                  {translation["totalPrice"][selectedLanguage]}
                </Typography>
              </Box>
            )}
            <Button
              variant={ButtonVariant.BOOKING}
              size={ButtonSize.SMALL}
              onClick={
                isShownMobileModal || isShownTabletModal
                  ? onConfirmOpenModalClick
                  : toggleMobileTabletModals
              }
              disabled={
                (isShownMobileModal || isShownTabletModal) &&
                isSubmitButtonDisabled()
              }
              icon={
                <ArrowIcon
                  stroke={COLORS.stroke.main}
                  fill="none"
                  width="16px"
                  height="16px"
                />
              }
            >
              {isShownMobileCalendar
                ? translation["confirm"][selectedLanguage]
                : isSubmitButtonDisabled()
                ? translation["requestBooking"][selectedLanguage]
                : isPriceOnRequest
                ? translation["sendRequestForPrice"][selectedLanguage]
                : translation["sendBookingRequest"][selectedLanguage]}
            </Button>
          </TabletsContainer>
        </TabletsHolder>
      ) : (
        <StyledContainer display="grid" p={4} gridGap={1.6}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">
              {translation["bookNow"][selectedLanguage]}
            </Typography>
            {discount > 0 && (
              <Chip
                label={discountDescription || `-${discount}%`}
                color={COLORS.success.main}
                backgroundColor={COLORS.success.hover}
              />
            )}
          </Box>
          <Calendar
            ref={calendarRef}
            checkInDate={startDate}
            checkOutDate={endDate}
            onChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            variant={CalendarVariant.PUBLIC}
            excludeDates={excludeDates}
            minNights={minimumNights}
            calendarHeader={
              <CalendarHeader
                nights={nightsCounter}
                minNights={minimumNights}
                checkInDate={startDate}
                checkOutDate={endDate}
                isPrivate={false}
              />
            }
            calendarFooter={
              <CalendarFooter
                isPrivate={false}
                onConfirmClick={closeDesktopCalendar}
                clearData={resetCalculator}
              />
            }
            customInput={
              <ReservationDatePicker
                checkInDate={startDate}
                checkOutDate={endDate}
              />
            }
            monthShown={2}
          />
          <StyledMinPrice variant="body" color={COLORS.typography.body}>
            (Min {minimumNights} {translation["nights"][selectedLanguage]})
          </StyledMinPrice>
          <Guests
            guestsTypes={guestsTypes}
            canAdd={canAdd}
            canSubtract={canSubtract}
            updateCounter={updateCounter}
          />
          <Divider mb={1} />
          <SummaryPrice
            priceSummary={priceSummary}
            isPriceOnRequest={isPriceOnRequest}
          />
          <Box mt={0.5}>
            <Button
              variant={ButtonVariant.BOOKING}
              size={ButtonSize.LARGE}
              icon={
                !isSubmitButtonDisabled() ? (
                  <ArrowIcon stroke={COLORS.stroke.main} fill="none" />
                ) : undefined
              }
              disabled={isSubmitButtonDisabled()}
              onClick={handleSendBookingButtonClick}
            >
              {getSubmitButtonName()}
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            mt={0.5}
          >
            <StyledContactSupportText variant="caption">
              {translation["haveQuestion"][selectedLanguage]}
            </StyledContactSupportText>
            {demo_token ? (
              <StyledContactSupportText variant="caption">
                {translation["contactSupport"][selectedLanguage]}
              </StyledContactSupportText>
            ) : (
              <StyledLink to={CONTACT_PAGE}>
                {translation["contactSupport"][selectedLanguage]}
              </StyledLink>
            )}
          </Box>
        </StyledContainer>
      )}
      {(!demo_token || isEmptyProfileValue()) && (
        <CompleteInfoModal
          ref={formContainerRef}
          isShown={isCompleteModalOpen}
          isPriceOnRequest={isPriceOnRequest}
          closeModal={() => setIsCompleteModalOpen(false)}
          closeBookingModals={toggleMobileTabletModals}
          resetCalculator={handleResetCalculator}
          sendBookingRequest={sendBookingRequest}
        />
      )}
      <Modal modalRef={confirmModalRef} onClose={toggle} isShown={isShown}>
        <StyledConfirmationModalContent>
          <Typography variant="caption">
            {translation["cancelInitialRequest"][selectedLanguage]}
          </Typography>
          <Box
            display="grid"
            mt={5.6}
            gridAutoFlow="column"
            justifyContent="end"
            gridGap={3}
            alignItems="center"
          >
            <Button variant={ButtonVariant.SECONDARY} onClick={toggle}>
              {translation["no"][selectedLanguage]}
            </Button>
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.SMALL}
              onClick={sendBookingRequest}
            >
              {translation["yes"][selectedLanguage]}
            </Button>
          </Box>
        </StyledConfirmationModalContent>
      </Modal>
    </>
  );
};
