import { FC } from "react";
import { FriendsListProps } from "./FriendsList.d";
import { StyledContainer } from "./FriendsList.styled";
import { FriendCard } from "../FriendCard/FriendCard";
import { Typography } from "components/Typography";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const FriendsList: FC<FriendsListProps> = ({
  friends,
  variant,
  declineInvitation,
  deactivateEmailInvitation,
  deactivateConnectionInvitation,
  deleteFromFriends,
  sendInvitation,
  sendInvitationReminder,
  acceptInvitation,
  onFriendCardClick,
}) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  return (
    <StyledContainer display="grid" gridGap={2}>
      {friends.length > 0 ? (
        friends.map((friend) => (
          <FriendCard
            variant={variant}
            key={friend.id}
            friend={friend}
            declineInvitation={declineInvitation}
            deactivateConnectionInvitation={deactivateConnectionInvitation}
            deactivateEmailInvitation={deactivateEmailInvitation}
            deleteFromFriends={deleteFromFriends}
            sendInvitation={sendInvitation}
            acceptInvitation={acceptInvitation}
            onFriendCardClick={onFriendCardClick}
            sendInvitationReminder={sendInvitationReminder}
          />
        ))
      ) : (
        <Typography variant="body">
          {translation["noResults"][selectedLanguage]}
        </Typography>
      )}
    </StyledContainer>
  );
};
