export enum AccessVariant {
  SELECTED = "SELECTED",
  ALL = "ALL",
  NONE = "NONE",
}

export enum CheckInvitationConnectionError {
  CHECK_INVITATION_CONNECTION_ERROR = "CHECK_INVITATION_CONNECTION_ERROR",
}

export enum InvitableStatusValues {
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
  INVITED_BY_YOU = "INVITED_BY_YOU",
  INVITED_BY_OTHERS = "INVITED_BY_OTHERS",
  REGISTERED_FULL_ACCESS = "REGISTERED_FULL_ACCESS",
  REGISTERED_RESTRICTED_ACCESS = "REGISTERED_RESTRICTED_ACCESS",
}

export enum UninvitableStatusValues {
  CANNOT_INVITE_YOURSELF = "CANNOT_INVITE_YOURSELF",
  INVITER = "INVITER",
  REGISTERED_INVITED_BY_YOU = "REGISTERED_INVITED_BY_YOU",
  REGISTERED_FRIEND = "REGISTERED_FRIEND",
  USER_ARCHIVED_OR_BLOCKED = "USER_ARCHIVED_OR_BLOCKED",
  ERROR = "ERROR",
}
