import { LOGIN_PAGE } from "urls/frontend";
import { getLanguage } from "utils/getLanguage";

export interface ResponseWithBody<T> extends Response {
  parsedBody: T;
}

const fetchAPI = async <T>(url: string, options?: RequestInit) => {
  const token = localStorage.getItem("token");

  let headers = { ...options?.headers };

  if (token) {
    const parsedToken = JSON.parse(token);
    const authHeaders = {
      "Jwt-Authorization": `Bearer ${parsedToken}`,
      "Accept-Language": getLanguage(),
    };
    headers = { ...headers, ...authHeaders };
  }
  const response = await fetch(url, {
    ...options,
    headers: headers,
  });

  if (!response.ok && response.status === 401) {
    localStorage.clear();
    window.location.href = window.location.origin + LOGIN_PAGE;
  }

  const parsedResponse = await response.json();

  const responseWithBody: ResponseWithBody<T> = {
    ...response,
    ok: response.ok,
    parsedBody: parsedResponse,
    status: response.status,
  };

  return responseWithBody as ResponseWithBody<T>;
};

export default fetchAPI;
