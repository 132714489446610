import { GenderVariant } from "components/Profile/ProfileForm/ProfileForm.enums";
import { Language } from "types/language";
import {
  UserProfile,
  UserActivationData,
  CountryOption,
  HobbiesOption,
  ProfileFormData,
  ResetPassword,
  ResendInvitationForm,
  EducationOption,
  CompanyOption,
  IndustryOption,
} from "types/user.d";
import {
  ACTIVATE_USERS,
  PROFILE_ENDPOINT,
  INTERESTS_ENDPOINT,
  ACCOUNT_DELETE_ENDPOINT,
  COUNTRIES_ENDPOINT,
  VERIFY_ACTIVATION_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
  ACCEPT_TERMS,
  SEND_ACTIVATION_EMAIL_URL,
  COMPANIES_ENDPOINT,
  INDUSTRIES_ENDPOINT,
  EDUCATIONS_ENDPOINT,
  LANGUAGE_ENDPOINT,
} from "urls/api";
import fetchApi from "./fetchApi";

interface VerifyActivationResponse {
  status: string;
  name: string;
  last_name: string;
}

interface LanguageRequest {
  language: Language;
}

export interface CreateEducationCompanyForm {
  name: string;
  country: string;
}

export const fetchProfile = () =>
  fetchApi<UserProfile & Response>(PROFILE_ENDPOINT);

export async function activateAccount(data: UserActivationData) {
  const response = await fetch(ACTIVATE_USERS, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response;
}
export const fetchCountryOptions = () =>
  fetchApi<CountryOption[] & Response>(COUNTRIES_ENDPOINT);

export const fetchInterestsOptions = () =>
  fetchApi<HobbiesOption[] & Response>(INTERESTS_ENDPOINT);

export const fetchUpdateProfile = (
  data: UserProfile | ProfileFormData,
  withAvatar = false
) => {
  const formData = new FormData();
  formData.append("name", data.name ?? "");
  formData.append("last_name", data.last_name ?? "");
  formData.append("gender", data.gender ?? GenderVariant.GENDER_UNKNOWN);
  formData.append("maiden_name", data.maiden_name ?? "");
  formData.append("profile.phone_number", data.profile.phone_number ?? "");
  data.profile.country &&
    formData.append("profile.country", data.profile.country);
  formData.append(
    "profile.current_profession",
    data.profile.current_profession ?? ""
  );
  data.profile.industry &&
    formData.append("profile.industry", `${data.profile.industry}`);
  data.profile.education &&
    formData.append("profile.education", `${data.profile.education}`);
  data.profile.company_name &&
    formData.append("profile.company_name", `${data.profile.company_name}`);

  formData.append("profile.city", data.profile.city ?? "");
  formData.append("profile.street", data.profile.street ?? "");
  formData.append("profile.house", data.profile.house ?? "");
  formData.append("profile.about", data.profile.about ?? "");
  formData.append("profile.postal_code", data.profile.postal_code ?? "");

  data.profile?.hobbies?.forEach((hobby, index) => {
    formData.append(`profile.hobbies[${index}]id`, hobby.id);
    formData.append(`profile.hobbies[${index}]name`, hobby.name);
  });
  withAvatar && formData.append("profile.avatar", data.profile.avatar ?? "");

  return fetchApi(PROFILE_ENDPOINT, {
    method: "PATCH",
    body: formData,
  });
};

export const verifyActivationRequest = async (
  data: Pick<UserActivationData, "email" | "token">
) => {
  return await fetchApi<VerifyActivationResponse>(VERIFY_ACTIVATION_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const requestAccountDeletion = () =>
  fetchApi(ACCOUNT_DELETE_ENDPOINT, { method: "POST" });

export const passwordChangeRequest = (data: ResetPassword) => {
  return fetchApi(CHANGE_PASSWORD_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const requestAcceptedTerms = () =>
  fetchApi(ACCEPT_TERMS, { method: "POST" });

export const reactivateInvitationRequest = async (
  data: ResendInvitationForm
) => {
  return await fetchApi(SEND_ACTIVATION_EMAIL_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const fetchCompanies = () =>
  fetchApi<CompanyOption[]>(COMPANIES_ENDPOINT);

export const fetchIndustries = () =>
  fetchApi<IndustryOption[]>(INDUSTRIES_ENDPOINT);

export const fetchEducations = () =>
  fetchApi<EducationOption[]>(EDUCATIONS_ENDPOINT);

export const fetchLanguage = () => fetchApi(LANGUAGE_ENDPOINT);

export const updateLanguage = async (data: LanguageRequest) => {
  return await fetchApi(LANGUAGE_ENDPOINT, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};
export const createCompany = (data: CreateEducationCompanyForm) =>
  fetchApi<CompanyOption>(COMPANIES_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const createIndusty = (data: CreateEducationCompanyForm) =>
  fetchApi<IndustryOption>(INDUSTRIES_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const createEducation = (data: CreateEducationCompanyForm) =>
  fetchApi<EducationOption>(EDUCATIONS_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
