import { pxToRem } from "../../global/utils";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../theme/colors";
import { CustomLinkProps } from "./Link.d";

const linkStyles = `
  font-family: "Inter", sans-serif;
  font-size: ${pxToRem(15)};
  color: ${COLORS.typography.headline};
  text-decoration: none;
  transition: 0.2s ease-out;
`;

export const StyledLink = styled(Link)<Partial<CustomLinkProps>>`
  ${linkStyles};
  color: ${({ linkColor }) =>
    linkColor === "black"
      ? COLORS.typography.headline
      : COLORS.typography.secondary.light};

  &:hover {
    ${({ linkColor }) =>
      linkColor === "black"
        ? `color: ${COLORS.accent.primary};`
        : "text-decoration: underline;"}
`;

export const StyledExternalLink = styled.a<Partial<CustomLinkProps>>`
  ${linkStyles};
  color: ${({ linkColor }) =>
    linkColor === "black"
      ? COLORS.typography.headline
      : COLORS.typography.secondary.light};

  &:hover {
    ${({ linkColor }) =>
      linkColor === "black"
        ? `color: ${COLORS.accent.primary};`
        : "text-decoration: underline;"}
`;
