import styled from "styled-components";
import { Box } from "components/Box";
import { SIZES } from "theme";

export const StyledBlockedDates = styled(Box)`
  grid-template-columns: 1fr;

  ${SIZES.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${SIZES.media.desktop} {
    grid-template-columns: 1fr;
  }
`;
