import { FC } from "react";
import { PaginationProps } from "./Pagination.d";
import { Box } from "components/Box";
import { useLocation } from "react-router";
import { PaginationItem } from "./PaginationItem/PaginationItem";
import { PaginationSeparator, NavigationButton } from "./Pagination.styled";
import { useWindowSize } from "global/hooks/useWindowSize";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const Pagination: FC<PaginationProps> = ({ count }) => {
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const page = parseInt(query.get("page") || "1", 10);
  const { isMobile } = useWindowSize();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box>
      {page > 1 && (
        <NavigationButton
          to={`${pathname}${page <= 2 ? "" : `?page=${page - 1}`}`}
          linkColor="black"
        >
          {isMobile ? "‹" : translation["previous"][selectedLanguage]}
        </NavigationButton>
      )}
      {count > 1 && (
        <PaginationItem label={1} link={pathname} isCurrent={page === 1} />
      )}
      {page > 3 && (
        <PaginationSeparator variant="caption">...</PaginationSeparator>
      )}
      {page === count && count > 3 && (
        <PaginationItem
          label={page - 2}
          link={`${pathname}?page=${page - 2}`}
        />
      )}
      {page > 2 && (
        <PaginationItem
          label={page - 1}
          link={`${pathname}?page=${page - 1}`}
        />
      )}
      {page !== 1 && page !== count && (
        <PaginationItem
          label={page}
          link={`${pathname}?page=${page}`}
          isCurrent={true}
        />
      )}
      {page < count - 1 && (
        <PaginationItem
          label={page + 1}
          link={`${pathname}?page=${page + 1}`}
        />
      )}
      {page === 1 && count > 3 && (
        <PaginationItem
          label={page + 2}
          link={`${pathname}?page=${page + 2}`}
        />
      )}
      {page < count - 2 && (
        <PaginationSeparator variant="caption">...</PaginationSeparator>
      )}
      {count > 1 && (
        <PaginationItem
          label={count}
          link={`${pathname}?page=${count}`}
          isCurrent={page === count}
        />
      )}
      {page < count && (
        <NavigationButton to={`${pathname}?page=${page + 1}`} linkColor="black">
          {isMobile ? "›" : translation["next"][selectedLanguage]}
        </NavigationButton>
      )}
    </Box>
  );
};
