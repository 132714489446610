import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledTitle = styled(Typography)`
  margin-bottom: ${SIZES.spacing(2)};
`;

export const StyledContainer = styled(Box)`
  overflow: hidden;
`;
