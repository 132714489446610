import { FC } from "react";
import { GuestsProps, GuestType } from "./Guests.d";
import { GuestPicker } from "../GuestPicker";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { useWindowSize } from "global/hooks/useWindowSize";

export const Guests: FC<GuestsProps> = ({
  guestsTypes,
  canSubtract,
  canAdd,
  updateCounter,
}) => {
  const { isMobile } = useWindowSize();
  const [adultsData] = guestsTypes;

  return (
    <Box display="grid" p={isMobile ? 0 : 2} gridGap={isMobile ? 2.5 : 2}>
      <>
        {!isMobile && <Typography variant="body">Guests</Typography>}
        {guestsTypes.length &&
          updateCounter &&
          canSubtract &&
          canAdd &&
          guestsTypes.map((item: GuestType) => {
            const isAdult = item.id === adultsData.id;
            return (
              <GuestPicker
                id={item.id}
                key={item.id}
                label={item.label}
                value={item.value}
                disabledMinus={!canSubtract(item.value, isAdult)}
                disabledPlus={!canAdd(isAdult)}
                onChange={updateCounter}
              />
            );
          })}
      </>
    </Box>
  );
};
