import {
  HouseDeleteItems,
  HouseEditingProps,
  HouseImagesProps,
  RequestBooking,
} from "types/house.d";
import {
  HouseProps,
  CalendarHouseOption,
  DisabledDatesResponse,
} from "types/house.d";
import {
  HOUSE_ENDPOINT,
  REQUEST_BOOKING_ENDPOINT,
  UPDATE_BOOKING_ENDPOINT,
  HOUSES_WITH_DISABLED_DATES,
  HOUSES_CALENDAR_SELECT_OPTIONS,
  DELETE_BLOCKED_DATE_ENDPOINT,
  HOUSE_IMAGES_ENDPOINT,
  PUBLIC_ACCESS_HOUSES_ENDPOINT,
  HOUSE_UPDATE_ENDPOINT,
} from "urls/api";
import fetchApi from "./fetchApi";

interface HouseExampleSelectResponse {
  id: string;
  title: string;
}

export const fetchHouse = (id: string, token?: string | null) =>
  fetchApi<HouseProps & Response>(
    `${HOUSE_ENDPOINT(id)}${token ? `?demo_token=${token}` : ""}`
  );

export const fetchExampleHouses = () =>
  fetchApi<HouseExampleSelectResponse>(PUBLIC_ACCESS_HOUSES_ENDPOINT);

export const fetchHouseImages = (id: string, token?: string | null) =>
  fetchApi<HouseImagesProps & Response>(
    `${HOUSE_IMAGES_ENDPOINT(id)}${token ? `?demo_token=${token}` : ""}`
  );

export const fetchSendBookingRequest = (data: RequestBooking) =>
  fetchApi<HouseProps & Response>(REQUEST_BOOKING_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchUpdateBookingRequest = (
  bookingId: string,
  data: RequestBooking
) =>
  fetchApi<HouseProps & Response>(UPDATE_BOOKING_ENDPOINT(bookingId), {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchSendDisabledDates = (
  houseId: string,
  data: Pick<RequestBooking, "check_in_date" | "check_out_date">
) =>
  fetchApi<Response>(HOUSES_WITH_DISABLED_DATES(houseId), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchCalendarHousesOptions = () =>
  fetchApi<CalendarHouseOption[] & Response>(HOUSES_CALENDAR_SELECT_OPTIONS);

export const fetchHouseBlockedDates = (id: string) =>
  fetchApi<DisabledDatesResponse & Response>(HOUSES_WITH_DISABLED_DATES(id));

export const fetchDeleteBlockedDate = (houseId: string, dateId: string) =>
  fetchApi<Response>(DELETE_BLOCKED_DATE_ENDPOINT(houseId, dateId), {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });

export const fetchUpdateHouse = (id: string, data: HouseEditingProps) => {
  const formData = new FormData();
  const recommendations = { ...data.recommendations, ...data.services };

  formData.append(
    "owneradvice.description",
    data.owneradvice.description ?? ""
  );

  formData.append(
    "staffonsite.responsibility",
    data.staffonsite.responsibility ?? ""
  );
  formData.append("staffonsite.first_name", data.staffonsite.first_name ?? "");
  formData.append("staffonsite.last_name", data.staffonsite.last_name ?? "");
  formData.append("staffonsite.email", data.staffonsite.email ?? "");
  formData.append("staffonsite.phone", data.staffonsite.phone ?? "");
  formData.append(
    "staffonsite.whatsapp",
    data.staffonsite.whatsapp.toString() ?? ""
  );

  let recommendationIndex = 0;

  Object.entries(recommendations).map(([category, recommendations]) => {
    recommendations.map((recommendation, index) => {
      formData.append(
        `recommendations[${recommendationIndex}]id`,
        recommendation.id ?? ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]title`,
        recommendation.title ?? ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]comment`,
        recommendation.comment ?? ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]location`,
        "location" in recommendation ? recommendation.location ?? "" : ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]latitude`,
        "latitude" in recommendation
          ? recommendation.latitude?.toString() ?? ""
          : ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]longitude`,
        "longitude" in recommendation
          ? recommendation.longitude?.toString() ?? ""
          : ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]category`,
        category
      );
      formData.append(
        `recommendations[${recommendationIndex}]email`,
        recommendation.email ?? ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]phone_number`,
        recommendation.phone_number ?? ""
      );
      formData.append(
        `recommendations[${recommendationIndex}]order`,
        index.toString()
      );
      formData.append(
        `recommendations[${recommendationIndex}]url`,
        recommendation.url ?? ""
      );
      recommendation.images?.map((image, imageIndex) => {
        if (image.url || image.id) {
          formData.append(
            `recommendations[${recommendationIndex}]images[${imageIndex}]id`,
            typeof image.url === "string" ? image.id : ""
          );
          formData.append(
            `recommendations[${recommendationIndex}]images[${imageIndex}]url`,
            typeof image.url === "string" ? "" : image.url
          );
        }
      });
      recommendationIndex += 1;
    });
  });

  return fetchApi(HOUSE_UPDATE_ENDPOINT(id), {
    method: "PATCH",
    body: formData,
  });
};

export const deleteRecommendationsImage = async (
  id: string,
  data: HouseDeleteItems
) => {
  return fetchApi(HOUSE_UPDATE_ENDPOINT(id), {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...data,
    }),
  });
};
