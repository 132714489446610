import { FC } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { BreadcrumbLink, BreadcrumbStaticElement } from "./Breadcrumbs.styled";
import { BreadcrumbsProps } from "./Breadcrumbs.d";
import { Box } from "components/Box";
import { HOUSES_EXAMPLE_PAGE } from "urls/frontend";

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  currentPageTitle,
  extraExcludedValues,
  routes,
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const demoToken = searchParams.get("demo_token");
  const pathnames = location.pathname.split("/").filter((x) => x);
  const excludedValues = ["filter", "example"];
  extraExcludedValues && excludedValues.push(extraExcludedValues);
  const filteredPathnames = pathnames.filter(
    (pathname) => !excludedValues.includes(pathname)
  );

  const getCustomBreadCrumbs = () =>
    routes?.map(({ breadcrumbName, path }, index) => {
      const isFirst = index === 0;
      const isLast = index === routes.length - 1;
      return (
        <>
          {!isFirst && (
            <BreadcrumbStaticElement
              variant="span"
              key={`${path}-${index}`}
              isLastPage={true}
            >
              {" > "}
            </BreadcrumbStaticElement>
          )}
          {isLast ? (
            <BreadcrumbStaticElement variant="span" key={path} isLastPage>
              {breadcrumbName}
            </BreadcrumbStaticElement>
          ) : (
            <BreadcrumbLink key={path} to={path}>
              {breadcrumbName}
            </BreadcrumbLink>
          )}
        </>
      );
    });

  return (
    <Box>
      {routes ? (
        getCustomBreadCrumbs()
      ) : (
        <>
          {filteredPathnames.length > 0 ? (
            <BreadcrumbLink
              to={
                demoToken
                  ? `${HOUSES_EXAMPLE_PAGE}?demo_token=${demoToken}`
                  : "/"
              }
            >
              Home{" "}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbStaticElement variant="span" isLastPage>
              Home
            </BreadcrumbStaticElement>
          )}
          {filteredPathnames.map((name, index) => {
            const routeTo = `/${filteredPathnames
              .slice(0, index + 1)
              .join("/")}`;
            const isLast = index === filteredPathnames.length - 1;
            return (
              <>
                <BreadcrumbStaticElement
                  variant="span"
                  key={name}
                  isLastPage={true}
                >
                  {" > "}
                </BreadcrumbStaticElement>
                {isLast ? (
                  <BreadcrumbStaticElement variant="span" key={name} isLastPage>
                    {currentPageTitle ?? name}
                  </BreadcrumbStaticElement>
                ) : (
                  <BreadcrumbLink
                    key={name}
                    to={`${routeTo}${
                      pathnames.includes("example") ? "/example" : ""
                    }${demoToken ? `?demo_token=${demoToken}` : ""}`}
                  >
                    {name}
                  </BreadcrumbLink>
                )}
              </>
            );
          })}
        </>
      )}
    </Box>
  );
};
