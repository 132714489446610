import { Box } from "components/Box";
import { Tooltip } from "components/Tooltip/Tooltip";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledOldPrice = styled(Typography)`
  font-family: "Inter", sans-serif;
  font-weight: ${SIZES.fontWeight.regular};
  padding: 0 10px;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
  }
`;

export const CustomTooltip = styled(Box)`
  padding: 0 10px;
`;
export const ExtraStyleTooltip = styled(Tooltip)`
  width: max-content;
`;
