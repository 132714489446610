import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  width: 260px;
`;

export const StyledIconContainer = styled.div`
  height: 54px;
  width: 54px;
  background-color: ${COLORS.background.boxes};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${SIZES.spacing(2)};
`;

export const StyledDescription = styled(Typography)`
  font-size: 14px;
  margin-top: ${SIZES.spacing(1)};
  width: 75%;
  text-align: center;
  color: ${COLORS.typography.headline};
`;
