import { FC } from "react";
import { FooterItemProps } from "./FooterItem.d";
import {
  StyledContainer,
  StyledIconContainer,
  StyledDescription,
} from "./FooterItem.styled";
import { Typography } from "components/Typography";
import { COLORS } from "theme";

export const FooterItem: FC<FooterItemProps> = ({
  label,
  description,
  icon,
}) => {
  return (
    <StyledContainer display="flex" flexDirection="column" alignItems="center">
      <StyledIconContainer>{icon}</StyledIconContainer>
      <Typography variant="h3" color={COLORS.typography.headline}>
        {label}
      </Typography>
      <StyledDescription variant="body">{description}</StyledDescription>
    </StyledContainer>
  );
};
