import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)``;

export const StyledIconWithLabel = styled(Box)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: ${pxToRem(12)};
  }

  ${SIZES.media.tablet} {
    align-self: flex-end;
  }
`;
