import * as Yup from "yup";

export const resetPasswordSchema = Yup.object({
  password: Yup.string().required("This field is required").min(6),
  confirm_password: Yup.string()
    .required("This field is required")
    .min(6, "CONFIRM PASSWORD MUST BE AT LEAST 6 CHARACTERS")
    .test(
      "doPasswordsMatch",
      "Passwords do not match. Try again",
      function (value) {
        const password = this.parent.password;
        return password === "" || value === password;
      }
    ),
});
