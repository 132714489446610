import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { forwardRef, useEffect, useState } from "react";
import { InviteFriendViaEmailModalProps } from "./InviteFriendViaEmailModal.d";
import {
  StyledContainer,
  StyledTitleContainer,
  TopContainer,
  GlobalStyle,
  StyledQuestionMarkIcon,
  StyledAccessTypeSelect,
  StyledButtonsContainer,
} from "./InviteFriendViaEmailModal.styled";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { COLORS } from "theme";
import { emailInvitationValidationSchema } from "./validators";
import { toast } from "react-toastify";
import { isValidForm } from "utils/isValidForm";
import { useWindowSize } from "global/hooks/useWindowSize";
import { FriendEmailInvitation } from "types/friend.d";
import { Select } from "components/Select";
import { Checkbox } from "components/Checkbox";
import {
  fetchSendEmailInvitation,
  fetchCheckEmailForInvitation,
} from "services/Invitation";
import {
  AccessVariant,
  InvitableStatusValues,
  CheckInvitationConnectionError,
  UninvitableStatusValues,
} from "./InviteFriendViaEmailModal.enums";
import { Tooltip } from "components/Tooltip";
import { PhoneInput } from "components/PhoneInput";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { Option } from "components/Select/Select.d";
import { Textarea } from "components/Textarea";

export const InviteFriendViaEmailModal = forwardRef<
  HTMLDivElement,
  InviteFriendViaEmailModalProps
>(({ isShown, closeModal, discountOptions, emailLanguageOptions }, ref) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const { isMobile } = useWindowSize();
  const discountOptionsToShow = discountOptions.map((option) => {
    return { value: option.name, name: option.description };
  });
  const emailLanguageOptionsToShow = emailLanguageOptions.map((option) => {
    return { value: option.language, name: option.language };
  });
  const [selectedEmailLanguage, setSelectedEmailLanguage] = useState<Option>({
    name: emailLanguageOptions[0].language,
    value: emailLanguageOptions[0].language,
  });

  const isHouseOwner = profile?.profileInfo?.is_house_owner || false;

  const [wrongEmailStatus, setWrongEmailStatus] = useState<boolean | string>(
    false
  );

  const methods = useForm<FriendEmailInvitation>({
    resolver: yupResolver(emailInvitationValidationSchema),
    mode: "onTouched",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
  } = methods;

  const email = watch("email");
  const access_type = watch("access_type");
  const send_email_in = watch("send_email_in");

  useEffect(() => {
    setValue("discount", discountOptions[0].name);
    setValue("send_email_in", emailLanguageOptions[0].language);
  }, [discountOptions, emailLanguageOptions]);

  const isUnInvitable = (
    status:
      | InvitableStatusValues
      | CheckInvitationConnectionError
      | UninvitableStatusValues
  ) =>
    Object.keys(UninvitableStatusValues).includes(status) ||
    Object.keys(CheckInvitationConnectionError).includes(status);

  const onEmailBlur = async () => {
    const isEmailValid = !errors.email?.message;
    if (isEmailValid) {
      const response = await fetchCheckEmailForInvitation(email);
      if (response.ok && isUnInvitable(response.parsedBody.status)) {
        setWrongEmailStatus(response.parsedBody.message);
      } else {
        setWrongEmailStatus(false);
      }
    }
  };

  const onSubmit: SubmitHandler<FriendEmailInvitation> = async (data) => {
    const form = {
      ...data,
      discount: data.discount || discountOptionsToShow[0].value,
      access_type: access_type ? AccessVariant.ALL : AccessVariant.SELECTED,
    };
    const response = await fetchSendEmailInvitation(form);

    if (response.ok) {
      toast.success(translation["invitationSent"][selectedLanguage]);
      closeModal();
      reset();
    } else {
      toast.error(translation["invitationError"][selectedLanguage]);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Modal
        withCloseButton
        modalRef={ref}
        isShown={isShown}
        onClose={closeModal}
        px={isMobile ? 2 : 8}
      >
        <StyledContainer>
          <StyledTitleContainer mb={3}>
            <Typography variant="h4">
              {translation["inviteFriend"][selectedLanguage]}
            </Typography>
          </StyledTitleContainer>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              autoComplete="off"
            >
              <Box display="grid" gridGap={3}>
                <Input
                  {...register("email")}
                  label={translation["friendEmail"][selectedLanguage]}
                  error={
                    (!isValidForm(errors) && errors.email?.message) ||
                    wrongEmailStatus
                  }
                  onBlur={onEmailBlur}
                />
                <TopContainer display="grid" gridGap={3}>
                  <Input
                    {...register("name")}
                    label={translation["firstName"][selectedLanguage]}
                    error={!isValidForm(errors) && errors.name?.message}
                  />
                  <Input
                    {...register("last_name")}
                    label={translation["lastName"][selectedLanguage]}
                    error={!isValidForm(errors) && errors.last_name?.message}
                  />
                </TopContainer>
                <TopContainer display="grid" gridGap={3}>
                  <PhoneInput
                    label={translation["phoneNumber"][selectedLanguage]}
                    control={control}
                    id="phone_number"
                  />
                  <Select
                    {...register("send_email_in")}
                    label={translation["emailLanguage"][selectedLanguage]}
                    options={emailLanguageOptionsToShow}
                    onChange={(option) => {
                      setValue("send_email_in", option.value);
                      setSelectedEmailLanguage({
                        name: option.value,
                        value: option.value,
                      });
                    }}
                    multiple={false}
                    value={selectedEmailLanguage}
                  />
                </TopContainer>
                <StyledAccessTypeSelect display="grid" alignItems="center">
                  <Checkbox
                    {...register("access_type")}
                    label={
                      translation["accessTypeToAllHouses"][selectedLanguage]
                    }
                    checked={access_type}
                  />
                  <Tooltip
                    variant={isMobile ? "left" : "right"}
                    title={translation["doNotSelectThis"][selectedLanguage]}
                  >
                    <StyledQuestionMarkIcon
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      ?
                    </StyledQuestionMarkIcon>
                  </Tooltip>
                </StyledAccessTypeSelect>
                {isHouseOwner && (
                  <Select
                    {...register("discount")}
                    label={translation["addDiscount"][selectedLanguage]}
                    options={discountOptionsToShow}
                    onChange={(option) => setValue("discount", option.value)}
                    multiple={false}
                  />
                )}
                <Textarea
                  label={translation["messageToYourFriend"][selectedLanguage]}
                  rows={4}
                  error={
                    !isValidForm(errors) && errors.personal_message?.message
                  }
                  {...register("personal_message")}
                />
              </Box>
              <StyledButtonsContainer
                display="grid"
                gridAutoFlow="column"
                gridGap={4.4}
                justifyContent="end"
                alignItems="center"
                mt={4.5}
              >
                <Button
                  variant={ButtonVariant.SECONDARY}
                  onClick={closeModal}
                  type="button"
                >
                  {translation["cancel"][selectedLanguage]}
                </Button>
                <Button
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  disabled={
                    Object.keys(errors).length > 0 || !!wrongEmailStatus
                  }
                  icon={
                    <ArrowIcon
                      stroke={COLORS.stroke.main}
                      fill="none"
                      width="20px"
                      height="20px"
                    />
                  }
                >
                  {translation["sendInvitation"][selectedLanguage]}
                </Button>
              </StyledButtonsContainer>
            </form>
          </FormProvider>
        </StyledContainer>
      </Modal>
    </>
  );
});

InviteFriendViaEmailModal.displayName = "InviteFriendViaEmailModal";
