import { Box, Typography } from "components";
import { FC } from "react";
import { BedroomsProps } from "./Bedrooms.d";
import {
  BedroomImageContainer,
  BedrooomsContainer,
  IconContainer,
  IconTypography,
} from "./Bedrooms.styled";
import { Image } from "components/Image";
import { HOST_URL } from "urls/api";
import { ReactComponent as BathroomIcon } from "assets/icons/icon_bathroom.svg";
import { ReactComponent as BedIcon } from "assets/icons/icon_bed.svg";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

const Bedrooms: FC<BedroomsProps> = ({ bedrooms }) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();

  return (
    <Box>
      <Typography variant="h2">
        {translation["bedrooms"][selectedLanguage]}
      </Typography>
      <BedrooomsContainer display="grid">
        {bedrooms.map((bedroom, index) => {
          return (
            <Box key={`bedroom ${index}`}>
              <BedroomImageContainer>
                <Image
                  src={bedroom.images[0] && `${HOST_URL}${bedroom.images[0]}`}
                  variant="flexible"
                />
              </BedroomImageContainer>
              {bedroom.bathroom_type && (
                <Box display="flex" alignItems="center" mt={2}>
                  <IconContainer mr={1}>
                    <BathroomIcon />
                  </IconContainer>
                  <IconTypography variant="body">
                    {bedroom.bathroom_type}
                  </IconTypography>
                </Box>
              )}
              {bedroom.beds.length !== 0 && (
                <Box display="flex" alignItems="center" mt={2}>
                  <IconContainer mr={1}>
                    <BedIcon />
                  </IconContainer>
                  <IconTypography variant="body">
                    {bedroom.beds[0].type}
                  </IconTypography>
                </Box>
              )}
            </Box>
          );
        })}
      </BedrooomsContainer>
    </Box>
  );
};

export default Bedrooms;
