import { FC } from "react";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { addSpacesToNumber } from "global/utils";
import { PriceComponent } from "types/house";
import { COLORS } from "theme";
import {
  CustomTooltip,
  ExtraStyleTooltip,
  StyledOldPrice,
} from "./SummaryPrice.styled";
import { StyledQuestionMarkIcon } from "components/Modals/InviteFriendViaEmailModal/InviteFriendViaEmailModal.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

interface Props {
  priceItem: PriceComponent;
  index: number;
  isLast: boolean;
  isPriceOnRequest: boolean;
  bookingCost: number;
  currentOldPrice: number;
  seasonalTitle?: string;
}

const SummaryPriceItem: FC<Props> = ({
  priceItem,
  index,
  isLast,
  isPriceOnRequest,
  bookingCost,
  currentOldPrice,
  seasonalTitle,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box
      key={`${priceItem.pricePerNight}-${index}`}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        <Typography variant="caption">
          {priceItem.pricePerNight
            ? `€ ${addSpacesToNumber(priceItem.pricePerNight)} x${" "}`
            : ""}
          {priceItem.nightsCount > 1
            ? `${priceItem.nightsCount} ${translation["nights"][selectedLanguage]}`
            : `${priceItem.nightsCount} ${translation["night"][selectedLanguage]}`}
        </Typography>
        {seasonalTitle && (
          <CustomTooltip display="flex" alignItems="center">
            <ExtraStyleTooltip variant={"right"} title={`${seasonalTitle}`}>
              <StyledQuestionMarkIcon
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                ?
              </StyledQuestionMarkIcon>
            </ExtraStyleTooltip>
          </CustomTooltip>
        )}
      </Box>
      {isLast && (
        <Box display="grid" gridGap={1} gridAutoFlow="column">
          {!isPriceOnRequest ? (
            <>
              {!!bookingCost &&
                !!currentOldPrice &&
                bookingCost < currentOldPrice && (
                  <StyledOldPrice variant="h4" color={COLORS.typography.stroke}>
                    €{addSpacesToNumber(currentOldPrice)}
                  </StyledOldPrice>
                )}
              <Typography color={COLORS.typography.headline} variant="h4">
                € {addSpacesToNumber(bookingCost)}
              </Typography>
            </>
          ) : (
            <Typography color={COLORS.typography.headline} variant="h4">
              {translation["priceOnRequestLabel"][selectedLanguage]}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SummaryPriceItem;
