import { Box, Typography } from "components";
import { Chip } from "components/Chip";
import { ChipVariant } from "components/Chip/Chip.enums";
import { FC } from "react";
import {
  StyledIconClose,
  FriendChipContainer,
  FriendLabel,
} from "./Chip.styled";

interface Props {
  handleClick: () => void;
  label: string;
}

const FriendChip: FC<Props> = ({ handleClick, label }) => {
  return (
    <Chip variant={ChipVariant.INTEREST} selected={true}>
      <FriendChipContainer
        display="grid"
        gridAutoFlow="column"
        justifyContent="center"
        alignItems="center"
      >
        <FriendLabel variant="overline">{label}</FriendLabel>
        <StyledIconClose onClick={handleClick} />
      </FriendChipContainer>
    </Chip>
  );
};

export default FriendChip;
