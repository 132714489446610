import * as Yup from "yup";
export const IMG_MAX_SIZE = 1024 * 1024 * 20; // 20 MB

export const houseSpecialtySchema = Yup.boolean().test(
  "atLeastOneChosen",
  "At least one option has to be chosen",
  function () {
    return (
      this.parent.is_location_special ||
      this.parent.is_architecture_special ||
      this.parent.is_history_special ||
      this.parent.is_interior_style_special ||
      this.parent.is_view_special
    );
  }
);

export const houseCaretakerSchema = Yup.boolean().test(
  "atLeastOneChosen",
  "At least one option has to be chosen",
  function () {
    return (
      this.parent.has_concierge ||
      this.parent.has_manager ||
      this.parent.has_staff_member ||
      this.parent.has_contact_person
    );
  }
);

export const houseApplicationValidationSchema = Yup.object({
  email: Yup.string()
    .email("Please, insert a valid email address.")
    .required("Please, insert email address."),
  street: Yup.string().required(),
  street_no: Yup.string().required(),
  zip_code: Yup.string().required(),
  city: Yup.string().required(),
  country: Yup.string().required(),
  house_type: Yup.string().required().typeError("This field is required"),
  house_size: Yup.string().required().typeError("This field is required"),
  bedrooms: Yup.number().required().typeError("Must be a number"),
  guests: Yup.number().required().typeError("Must be a number"),
  is_location_special: houseSpecialtySchema,
  is_architecture_special: houseSpecialtySchema,
  is_history_special: houseSpecialtySchema,
  is_interior_style_special: houseSpecialtySchema,
  is_view_special: houseSpecialtySchema,
  description: Yup.string(),
  has_pool: Yup.string().default("false"),
  has_garden: Yup.string().default("false"),
  is_on_other_platforms: Yup.string()
    .required()
    .typeError("This field is required"),
  other_platforms: Yup.string().test(
    "isProvidedWhenOtherPlatformsOptionIsChosen",
    "This field is required",
    function (value) {
      return this.parent.is_on_other_platforms === "Yes" ? !!value : true;
    }
  ),
  is_guest_ready: Yup.string().required().typeError("This field is required"),
  has_concierge: houseCaretakerSchema,
  has_manager: houseCaretakerSchema,
  has_staff_member: houseCaretakerSchema,
  has_contact_person: houseCaretakerSchema,
  images: Yup.mixed()
    .test("isBelowMaxSize", "The file is too big", (value) => {
      if (value && value.length > 0) {
        return value[0].size < IMG_MAX_SIZE;
      }
      return true;
    })
    .test("isFormatCorrect", "Incorrect file format", (value) => {
      if (value && value.length > 0) {
        const validatedFile = value[0];
        return (
          validatedFile.type === "image/png" ||
          validatedFile.type === "image/jpeg" ||
          validatedFile.type === "image/svg+xml"
        );
      }
      return true;
    }),
  phone_number: Yup.string().max(20).nullable(true),
});
