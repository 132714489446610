import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const IconContainer = styled(Box)`
  width: 260px;
  height: 20px;
`;

export const MenuContainer = styled(Box)`
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);

  ${SIZES.media.phone} {
    padding: ${pxToRem(23)} ${pxToRem(33)};
  }
  ${SIZES.media.tablet} {
    padding: ${pxToRem(24)} ${pxToRem(80)};
  }
`;
