import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS, SIZES } from "theme";
import { StaticHouseItemVariantProps } from "./HouseItem.d";
import { Box } from "components/Box";
import { Button } from "components/Button";

const ellipsisTextOverflowStyles = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledHouseLocation = styled(
  Typography
)<StaticHouseItemVariantProps>`
  letter-spacing: ${SIZES.spacing(0.06)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: ${({ isSmall }) =>
    isSmall
      ? `${pxToRem(10)} 0 ${pxToRem(2)}`
      : `${pxToRem(19)} 0 ${pxToRem(6)}`};
  ${({ isSmall }) =>
    isSmall &&
    css`
      font-size: 18px;
      ${ellipsisTextOverflowStyles}
    `};
`;

export const StyledOldPrice = styled(Typography)`
  font-family: "Inter", sans-serif;
  font-weight: ${SIZES.fontWeight.regular};
  padding: 0 10px;
  position: relative;
  margin-right: ${pxToRem(20)};
  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
  }
`;

export const StyledCurrentPrice = styled(
  Typography
)<StaticHouseItemVariantProps>`
  font-family: "Inter", sans-serif;
  font-weight: ${SIZES.fontWeight.regular};
  ${({ isSmall }) => isSmall && "font-size: 14px"};
`;

export const StyledLink = styled(Box)`
  width: 100%;
  min-width: -moz-available;
  min-width: -webkit-fill-available;
  min-width: fill-available;

  img {
    margin: 0 auto;
  }
`;

export const StyledHouseDetails = styled(
  Typography
)<StaticHouseItemVariantProps>`
  ${({ isSmall }) =>
    isSmall &&
    css`
      ${ellipsisTextOverflowStyles}
      display: block;
    `};
`;

export const EditButton = styled(Button)`
  background-color: ${COLORS.background.control};
  border-radius: 0;
  padding: ${pxToRem(8)} 0 0 0;
  width: 40px;
  height: 40px;
  margin-left: ${pxToRem(5)};
  margin-top: ${pxToRem(20)};

  &:enabled {
    background-color: ${COLORS.background.control};
  }
`;

export const StyledHouseDescription = styled(Box)`
  width: 100%;
`;

export const StyledRequestFullAccessButtonContainer = styled(Box)`
  width: fit-content;
`;
