import { Input } from "components/Input";
import { FC, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { inviteFriendWithQRCode } from "services/Friends";
import { inviteFriendQRCode } from "./validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { CenteredText } from "views/ActivateUser/ActivateUser.styled";
import { Button } from "components/Button";
import { Background } from "components/Background";
import { Content } from "./ConfirmInvitation.styled";
import { MAIN_PAGE } from "urls/frontend";
import { isValidForm } from "utils/isValidForm";
import { toast } from "react-toastify";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

interface InviteForm {
  email: string;
  name: string;
  last_name: string;
}

const ConfirmInvitation: FC = () => {
  const navigate = useNavigate();
  const { tokenId } = useParams<{ tokenId: string }>();
  const reRef = useRef<any>();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<InviteForm, "token">>({
    resolver: yupResolver(inviteFriendQRCode),
    mode: "onBlur",
  });

  const onClick = async (data: InviteForm) => {
    const inviteUserData = {
      invitation_token: tokenId ?? "",
      ...data,
    };
    const response = await inviteFriendWithQRCode(inviteUserData);
    if (response?.status === 200) {
      toast.success(translation["invitationSent"][selectedLanguage], {
        autoClose: 10000,
      });
    } else {
      toast.error(translation["invitationSendingError"][selectedLanguage]);
    }
  };

  return (
    <Background>
      {process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ? (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      ) : undefined}
      <form onSubmit={handleSubmit(onClick)}>
        <Content display="grid" flexDirection="column" gridGap={2}>
          <CenteredText variant="h3">
            {translation["emailAddress"][selectedLanguage]}
          </CenteredText>
          <Input
            {...register("name")}
            label={translation["firstName"][selectedLanguage]}
            error={!isValidForm(errors) && errors.name?.message}
          />
          <Input
            {...register("last_name")}
            label={translation["lastName"][selectedLanguage]}
            error={!isValidForm(errors) && errors.last_name?.message}
          />
          <Input
            {...register("email")}
            label="Email*"
            error={!isValidForm(errors) && errors.email?.message}
          />
          <Button type="submit">
            {translation["sendLink"][selectedLanguage]}
          </Button>
        </Content>
      </form>
    </Background>
  );
};

export default ConfirmInvitation;
