import { Typography } from "components/Typography";
import { FC } from "react";
import { COLORS } from "theme";
import { SecondaryHeaderProps } from "./SecondaryHeader.d";
import { StyledHeader, StyledContainer } from "./SecondaryHeader.styled";

export const SecondaryHeader: FC<SecondaryHeaderProps> = ({
  title,
  subTitle,
  image,
}) => {
  return (
    <StyledContainer imageLink={image} pt={7.4} pl={2.3}>
      <StyledHeader variant="h1" color={COLORS.typography.secondary.main}>
        {title}
      </StyledHeader>
      {subTitle && (
        <Typography variant="body" color={COLORS.typography.secondary.main}>
          {subTitle}
        </Typography>
      )}
    </StyledContainer>
  );
};
