import styled from "styled-components";
import { Box } from "components/Box";
import { SIZES } from "theme";

export const StyledConfirmationModalContent = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.small};
  }
`;
