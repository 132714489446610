import { FC, ChangeEvent } from "react";
import { StyledOption, StyledSelect } from "./LanguageSelect.styled";
import { useProfile } from "context/Profile";
import { Language } from "types/language";
import { updateLanguage } from "services/Profile";

interface Props {
  variant?: "PUBLIC" | "PRIVATE";
}

const OPTIONS = [
  {
    name: "EN",
    value: "en",
  },
  { name: "DE", value: "de" },
  { name: "FR", value: "fr" },
];

const LanguageSelect: FC<Props> = ({ variant = "PUBLIC" }) => {
  const profile = useProfile();

  const handleLanguageChange = async (event: ChangeEvent) => {
    const target = event.target as HTMLOptionElement;
    const language = target.value as Language;
    if (profile) {
      profile.setSelectedLanguage(language);
    }
    localStorage.setItem("selectedLanguage", language);
    if (variant === "PRIVATE") {
      const data = {
        language: language,
      };
      await updateLanguage(data);
      profile?.setSelectedLanguage(language);
    }
  };

  return (
    <StyledSelect
      value={profile?.selectedLanguage || "EN"}
      onChange={handleLanguageChange}
      variant={variant}
    >
      {OPTIONS.map((option) => {
        return (
          <StyledOption
            key={option.value}
            id={option.value}
            value={option.value}
          >
            {option.name}
          </StyledOption>
        );
      })}
    </StyledSelect>
  );
};

export default LanguageSelect;
