import { forwardRef } from "react";
import { Wrapper, Item, RadioButton, RadioButtonLabel } from "./Radio.styled";
import { RadioProps } from "./Radio.d";
import { Label } from "components/Label";

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ value, selectedValue, disabled, defaultValue, label, ...props }, ref) => {
    return (
      <Wrapper>
        <Item
          key={value}
          display="flex"
          alignItems="center"
          disabled={disabled || false}
        >
          <RadioButton
            type="radio"
            disabled={disabled}
            ref={ref}
            value={value}
            checked={value === selectedValue}
            defaultValue={defaultValue}
            {...props}
          />
          <RadioButtonLabel />
          <Label>{label}</Label>
        </Item>
      </Wrapper>
    );
  }
);

Radio.displayName = "Radio";
