import styled from "styled-components";
import { COLORS } from "theme";
import { Box } from "components/Box";
import { Typography } from "components/Typography";

type ItemProps = {
  disabled: boolean;
};

export const CheckboxLabel = styled(Typography)`
  font-family: "Inter";
  font-size: 15px;
`;

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

export const Item = styled(Box)<ItemProps>`
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    label {
      box-shadow: 0px 3px 4px rgba(180, 180, 180, 0.2);
    }
  }
`;

export const CheckboxButtonLabel = styled.label`
  position: absolute;
  left: 4px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const CheckboxButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 12px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    ` ~ ${CheckboxButtonLabel} {
        background: ${COLORS.stroke.main};
      }
      cursor: unset;
  `}
`;
