import { FC, useEffect, useRef } from "react";
import NotificationsList from "components/Notifications/NotificationsList";
import { useNotifications } from "context/Notifications";
import Layout from "components/Layout";
import { Box, Typography } from "components";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useNavigate } from "react-router";
import { HOUSES_PAGE } from "urls/frontend";

const Notifications: FC = () => {
  const { setNotificationsStatusToRead } = useNotifications();
  const profile = useProfile();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  1;

  useEffect(() => {
    setNotificationsStatusToRead();
  }, []);

  useEffect(() => {
    if (isBasicUserType) navigate(HOUSES_PAGE);
  }, []);

  return (
    <Layout>
      <Box my={2} mx={4}>
        <Typography variant="h1">
          {translation["notifications"][selectedLanguage]}
        </Typography>
        <Box mt={3}>
          <NotificationsList />
        </Box>
      </Box>
    </Layout>
  );
};

export default Notifications;
