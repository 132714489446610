import { Box } from "components/Box";
import { BulletList } from "components/BulletList";
import { Typography } from "components/Typography";
import { FC } from "react";
import { COLORS } from "theme";
import { OnYourDemandProps } from "./OnYourDemand.d";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const OnYourDemand: FC<OnYourDemandProps> = ({
  arrangedAdditionally,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box display="grid" gridGap={2}>
      <Typography variant="h2" color={COLORS.typography.headline}>
        {translation["onYourDemand"][selectedLanguage]}
      </Typography>
      {!!arrangedAdditionally?.length && (
        <BulletList
          items={arrangedAdditionally?.map((item) => (
            <Typography
              key={item.title}
              variant="body"
              color={COLORS.typography.headline}
            >{`${item.title} ${item?.comment ?? ""}`}</Typography>
          ))}
        />
      )}
    </Box>
  );
};
