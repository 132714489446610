import { FC, useRef } from "react";
import { COLORS } from "theme";
import { MultiImageInputProps } from "./MultiImageInput.d";
import {
  Container,
  StyledInput,
  StyledTypography,
  StyledUploadContainer,
  StyledImageContainer,
  DeleteButton,
  ImageContainer,
} from "./MultiImageInput.styled";
import { ReactComponent as UploadIcon } from "assets/icons/icon_camera.svg";
import { Image } from "components/Image";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";

const MultiImageInput: FC<MultiImageInputProps> = ({
  disabled,
  defaultValue,
  label,
  images,
  handleChange,
  ...props
}) => {
  const input = useRef<HTMLInputElement | null>(null);
  const onUploadFileClick = () => {
    input.current && input?.current.click();
  };
  const getImagePath = (imageUrl: File) => URL.createObjectURL(imageUrl);

  const onUploadFile = () => {
    const files = input.current?.files ?? [];
    handleChange(files);
  };

  const handleImageDelete = (index: number) => {
    const dt = new DataTransfer();
    if (input?.current) {
      const files = input?.current?.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (index !== i) dt.items.add(file);
        }
        input.current.files = dt.files;
        handleChange(dt.files);
      }
    }
  };

  return (
    <Container display="grid" gridGap={3}>
      {images &&
        images?.length !== 0 &&
        Array.from(images).map((image, index) => {
          return (
            <ImageContainer key={getImagePath(image)}>
              <Image variant="card" src={image ? getImagePath(image) : ""} />
              <DeleteButton
                type="button"
                onClick={() => handleImageDelete(index)}
              >
                <DeleteIcon
                  stroke={COLORS.error.main}
                  strokeWidth={1}
                  fill="none"
                />
              </DeleteButton>
            </ImageContainer>
          );
        })}
      <StyledImageContainer>
        <StyledInput
          disabled={disabled}
          ref={input}
          multiple
          type="file"
          accept="image/png, image/jpeg, image/svg+xml"
          onInput={onUploadFile}
          {...props}
        />
        <StyledUploadContainer
          display="grid"
          justifyItems="center"
          onClick={onUploadFileClick}
          gridGap={1.5}
          px={8}
          py={8.5}
        >
          <UploadIcon />
          <StyledTypography variant="body" color={COLORS.typography.headline}>
            Add a photo
          </StyledTypography>
        </StyledUploadContainer>
      </StyledImageContainer>
    </Container>
  );
};

export default MultiImageInput;
