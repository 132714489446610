import { Box } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const MainContainer = styled(Box)`
  margin: ${pxToRem(32)} ${pxToRem(16)} ${pxToRem(127)} ${pxToRem(18)};
  ${SIZES.media.tablet} {
    margin: ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(305)} ${pxToRem(80)};
  }
`;
