import { useEffect, useState } from "react";

type DeviceType = "Android" | "iOS" | "unknown";

export const useGetDeviceType = () => {
  const [deviceType, setDeviceType] = useState<DeviceType>("unknown");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      setDeviceType("Android");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDeviceType("iOS");
    } else {
      setDeviceType("unknown");
    }
  }, [navigator]);

  return { deviceType };
};
