import { FC } from "react";
import { ReactComponent as IconClose } from "assets/icons/icon-close.svg";
import { CloseButton } from "./Gallery.styled";
import { COLORS } from "theme";
import { GalleryHeaderProps } from "./Gallery.d";
export const GalleryHeader: FC<GalleryHeaderProps> = ({ onClose }) => {
  return (
    <CloseButton onClick={onClose}>
      <IconClose stroke={COLORS.stroke.main} fill="none" />
    </CloseButton>
  );
};
