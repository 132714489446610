import { FC, useEffect, useRef, useState } from "react";
import {
  TabletsContainer,
  TabletsHolder,
  StyledDesktopContainer,
} from "./HouseOwnerCalendar.styled";
import { DisabledDaysInterval, DaysInterval } from "./HouseOwnerCalendar.d";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Calendar } from "components/Calendar";
import { CalendarVariant } from "components/Calendar/Calendar.enums";
import { BookingDetailsMobileModal } from "components/Booking/BookingDetailsMobileModal";
import { BookingDetailsTabletModal } from "components/Booking/BookingDetailsTabletModal";
import { BookingModal } from "components/Booking/BookingModal";
import { useWindowSize } from "global/hooks/useWindowSize";
import { COLORS } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { CalendarHeader } from "components/Calendar/CalendarHeader";
import { CalendarFooter } from "components/Calendar/CalendarFooter";
import { useParams } from "react-router";
import { formatDate, isEmptyObject } from "global/utils";
import { SyntheticEvent } from "react";
import ReactDatePicker from "react-datepicker";
import {
  calculateMaxDate,
  getExcludeDates,
} from "components/Booking/BookingCalculator/bookingUtils";
import addDays from "date-fns/addDays";
import {
  fetchSendDisabledDates,
  fetchCalendarHousesOptions,
  fetchHouseBlockedDates,
  fetchDeleteBlockedDate,
} from "services/House";
import { Option } from "components/Select/Select.d";

export const HouseOwnerCalendar: FC = () => {
  const { id } = useParams<{ id: string }>();
  const calendarRef = useRef<
    ReactDatePicker & { setOpen: (open: boolean) => void }
  >(null);
  const [isShownMobileModal, setIsShownMobileModal] = useState<boolean>(false);
  const [isShownTabletModal, setIsShownTabletModal] = useState<boolean>(false);
  const toggleMobileModal = () => setIsShownMobileModal(!isShownMobileModal);
  const toggleTabletModal = () => setIsShownTabletModal(!isShownTabletModal);
  const { isMobile, isTablet } = useWindowSize();
  const [maxDate, setMaxDate] = useState<undefined | null | Date>(null);
  const [minDate, setMinDate] = useState<Date>(addDays(new Date(), 1));
  const [isBlockedDatesEditable, setIsBlockedDatesEditable] = useState(true);
  const [blockedDates, setBlockedDates] = useState<DaysInterval[]>([]);
  const [bookedDates, setBookedDates] = useState<DaysInterval[]>([]);
  const [excludeDates, setExcludeDates] = useState<Array<Date>>([]);
  const [selectedHouse, setSelectedHouse] = useState<Option>({
    name: "",
    value: id ?? "",
  });
  const [housesList, setHousesList] = useState<Option[]>([]);

  const [selectedDisabledData, setSelectedDisabledData] =
    useState<DisabledDaysInterval>({
      check_in_date: null,
      check_out_date: null,
    });

  const getCalendarHousesOptions = async () => {
    const response = await fetchCalendarHousesOptions();
    setHousesList(
      response.parsedBody.map((house) => ({
        value: house.id,
        name: house.title,
      }))
    );
    if (response.parsedBody.length !== 0) {
      const currentHouse = response.parsedBody.find((house) => house.id === id);
      currentHouse &&
        setSelectedHouse({ value: currentHouse.id, name: currentHouse.title });
    }
  };

  const getHouseBlockedDates = async () => {
    const response = await fetchHouseBlockedDates(selectedHouse.value);
    if (response.ok) {
      setBookedDates(response.parsedBody.booked_dates);
      setBlockedDates(response.parsedBody.blocked_dates);
      setIsBlockedDatesEditable(response.parsedBody.can_edit);
    }
  };

  useEffect(() => {
    getCalendarHousesOptions();
  }, []);

  useEffect(() => {
    getHouseBlockedDates();
  }, [selectedHouse]);

  useEffect(() => {
    const newExcludeDates = getExcludeDates([...blockedDates, ...bookedDates]);
    setExcludeDates(newExcludeDates);
  }, [bookedDates, blockedDates]);

  const handleDateChange = (
    date: [Date | null, Date | null],
    event: SyntheticEvent<any, Event> | undefined
  ) => {
    event?.preventDefault();
    if (Array.isArray(date)) {
      const [start, end] = date;

      setSelectedDisabledData({
        check_in_date: start,
        check_out_date: end,
      });
      if (start && !end) {
        const newExcludeDates = getExcludeDates(
          [...blockedDates, ...bookedDates],
          start
        );
        const newMaxDate: undefined | Date = calculateMaxDate(
          start,
          excludeDates
        );
        setMaxDate(newMaxDate);
        setMinDate(start);
        setExcludeDates(newExcludeDates);
      }
      if (start && end) {
        setMaxDate(null);
        setMinDate(addDays(new Date(), 1));
        const newExcludeDates = getExcludeDates(
          [...blockedDates, ...bookedDates],
          start
        );
        setExcludeDates(newExcludeDates);
      }
    }
  };

  const resetCalculator = () => {
    setSelectedDisabledData({
      check_in_date: null,
      check_out_date: null,
    });
  };

  const sendDisabledDates = async () => {
    const disabledDates = {
      check_in_date:
        selectedDisabledData.check_in_date &&
        formatDate(selectedDisabledData.check_in_date, "dd.MM.yyyy"),
      check_out_date:
        selectedDisabledData.check_out_date &&
        formatDate(selectedDisabledData.check_out_date, "dd.MM.yyyy"),
    };
    const response = await fetchSendDisabledDates(
      selectedHouse.value,
      disabledDates
    );
    if (response.ok) {
      getHouseBlockedDates();
      resetCalculator();
    }
  };

  const deleteDisabledDate = async (
    dateId: string,
    event: SyntheticEvent<any, Event> | undefined
  ) => {
    event?.preventDefault();
    const response = await fetchDeleteBlockedDate(selectedHouse.value, dateId);
    if (response.ok) {
      getHouseBlockedDates();
    }
  };

  const toggleModal = () =>
    isMobile ? toggleMobileModal() : toggleTabletModal();

  const handleHouseChange = (newHouse: Option) => {
    setSelectedHouse(newHouse);
  };

  const closeDesktopModal = () => {
    calendarRef.current?.setOpen(false);
  };

  return (
    <>
      <BookingModal
        open={isShownMobileModal}
        onClose={toggleMobileModal}
        isPrivate={true}
        modalContent={
          <BookingDetailsMobileModal
            isPrivate={true}
            check_in_date={selectedDisabledData.check_in_date}
            check_out_date={selectedDisabledData.check_out_date}
            onCalendarChange={handleDateChange}
            isBlockedDatesEditable={isBlockedDatesEditable}
            excludeDates={excludeDates}
            blockedDates={blockedDates}
            onConfirmClick={sendDisabledDates}
            handleBlockedDateDelete={deleteDisabledDate}
            minDate={minDate}
            maxDate={maxDate}
            housesList={housesList}
          />
        }
      />
      <BookingModal
        open={isShownTabletModal}
        onClose={toggleTabletModal}
        isPrivate={true}
        modalContent={
          <BookingDetailsTabletModal
            isPrivate={true}
            check_in_date={selectedDisabledData.check_in_date}
            check_out_date={selectedDisabledData.check_out_date}
            onCalendarChange={handleDateChange}
            excludeDates={excludeDates}
            blockedDates={blockedDates}
            isBlockedDatesEditable={isBlockedDatesEditable}
            onConfirmClick={sendDisabledDates}
            handleBlockedDateDelete={deleteDisabledDate}
            minDate={minDate}
            maxDate={maxDate}
            housesList={housesList}
          />
        }
      />

      {isTablet ? (
        <TabletsHolder>
          <TabletsContainer
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            p={2.3}
          >
            <Button
              variant={ButtonVariant.BOOKING}
              size={ButtonSize.SMALL}
              onClick={
                isShownTabletModal || isShownMobileModal
                  ? sendDisabledDates
                  : toggleModal
              }
              disabled={
                (isShownTabletModal || isShownMobileModal) &&
                isEmptyObject(selectedDisabledData)
              }
              icon={
                <ArrowIcon
                  stroke={COLORS.stroke.main}
                  fill="none"
                  width="20px"
                  height="20px"
                />
              }
            >
              {isShownTabletModal || isShownMobileModal
                ? "Add dates"
                : "Your calendar"}
            </Button>
          </TabletsContainer>
        </TabletsHolder>
      ) : (
        <Calendar
          ref={calendarRef}
          variant={CalendarVariant.PRIVATE}
          onChange={handleDateChange}
          checkInDate={selectedDisabledData.check_in_date}
          checkOutDate={selectedDisabledData.check_out_date}
          monthShown={1}
          minDate={minDate}
          maxDate={maxDate}
          excludeDates={excludeDates}
          calendarHeader={
            <CalendarHeader
              isPrivate={true}
              housesList={housesList}
              onHouseChange={handleHouseChange}
              selectedHouse={selectedHouse}
              closeModal={closeDesktopModal}
            />
          }
          calendarFooter={
            <CalendarFooter
              isPrivate={true}
              handleBlockedDateDelete={deleteDisabledDate}
              blockedDates={blockedDates}
              isBlockedDatesEditable={isBlockedDatesEditable}
              onConfirmClick={sendDisabledDates}
              isSubmitButtonDisabled={isEmptyObject(selectedDisabledData)}
            />
          }
          customInput={
            <StyledDesktopContainer
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant={ButtonVariant.BOOKING}
                size={ButtonSize.LARGE}
                icon={
                  <ArrowIcon
                    stroke={COLORS.stroke.main}
                    fill="none"
                    width="20px"
                    height="20px"
                  />
                }
              >
                Your calendar
              </Button>
            </StyledDesktopContainer>
          }
        />
      )}
    </>
  );
};
