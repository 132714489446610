import { Typography } from "components";
import { getLanguage } from "utils/getLanguage";
import translation from "./other_requirements.json";
import { useProfile } from "context/Profile";
import { FC } from "react";

const Legal: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["toComplyWithLegalObligation"][selectedLanguage]}
    </Typography>
  );
};

const Protect: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["toProtectAndDefendCompanyRights"][selectedLanguage]}
    </Typography>
  );
};

const Prevent: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["preventionOrInvestigationOfMisconduct"][selectedLanguage]}
    </Typography>
  );
};

const PersonalSafety: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["toProtectPersonalSafety"][selectedLanguage]}
    </Typography>
  );
};

const LegalLiability: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      {translation["protectionFromLegalLiability"][selectedLanguage]}
    </Typography>
  );
};

export const OtherLegalRequirementsList = [
  <Legal key="legal" />,
  <Protect key="protect" />,
  <Prevent key="prevent" />,
  <PersonalSafety key="personal-safety" />,
  <LegalLiability key="legal-liability" />,
];
