import { Box } from "components/Box";
import { IconWithLabel } from "components/IconWithLabel";
import { Typography } from "components/Typography";
import { ReactComponent as IconMapMarker } from "assets/icons/icon_map_marker.svg";
import { ReactComponent as IconHeart } from "assets/icons/icon_favorite.svg";
import { FC } from "react";
import { HouseHeaderProps } from "./HouseHeader.d";
import { StyledContainer, StyledIconWithLabel } from "./HouseHeader.styled";
import { useWindowSize } from "global/hooks/useWindowSize";
import { HouseMainInfo } from "../HouseMainInfo";
import { ReactComponent as HeartActiveIcon } from "assets/icons/icon_heart_active.svg";
import { COLORS } from "theme";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseHeader: FC<HouseHeaderProps> = ({
  title,
  city,
  country,
  max_total_guests,
  bedrooms_num,
  bathrooms_num,
  isFavorite,
  setIsFavorite,
  living_space,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const { isMobile, isTablet } = useWindowSize();
  const locationLabel = `${city}, ${country}`;

  return (
    <Box>
      <Typography variant="h1">{title}</Typography>
      <Box
        display="grid"
        gridGap={1}
        gridAutoFlow="column"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <StyledContainer display="grid" gridAutoFlow="row" gridGap={4}>
          <IconWithLabel gap={1.5} icon={IconMapMarker} label={locationLabel} />
          {!isMobile && (
            <HouseMainInfo
              bedrooms_num={bedrooms_num}
              bathrooms_num={bathrooms_num}
              max_total_guests={max_total_guests}
              living_space={living_space}
            />
          )}
        </StyledContainer>
        {!isMobile &&
          (isFavorite ? (
            <StyledIconWithLabel onClick={() => setIsFavorite()}>
              <HeartActiveIcon />
              {!isTablet && (
                <Typography variant="caption" color={COLORS.typography.body}>
                  {translation["removeFromFavorites"][selectedLanguage]}
                </Typography>
              )}
            </StyledIconWithLabel>
          ) : (
            <StyledIconWithLabel onClick={() => setIsFavorite()}>
              <IconHeart />
              {!isTablet && (
                <Typography variant="caption" color={COLORS.typography.body}>
                  {translation["addToFavorites"][selectedLanguage]}
                </Typography>
              )}
            </StyledIconWithLabel>
          ))}
      </Box>
    </Box>
  );
};
