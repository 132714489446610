import { FC, useState } from "react";
import { Modal } from "components/Modal/Modal";
import { useForm } from "react-hook-form";
import { Box, Typography } from "components";
import {
  GlobalStyle,
  StyledButtonsContainer,
  StyledContainer,
  StyledFormContainer,
  StyledInputsContainer,
  StyledLanguageContainer,
} from "./InviteOwnerModal.styled";
import { Input } from "components/Input";
import { PhoneInput } from "components/PhoneInput";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { InviteOwnerForm } from "./InviteOwnerModal.d";
import { inviteOwner } from "services/Invitation";
import { useWindowSize } from "global/hooks/useWindowSize";
import { isValidForm } from "utils/isValidForm";
import { Radio } from "components/Radio";
import { toast } from "react-toastify";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { InfoBox } from "components/InfoBox";

interface Props {
  isShown: boolean;
  toggle: () => void;
}

const InviteOwnerModal: FC<Props> = ({ isShown, toggle }) => {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<InviteOwnerForm>({
    shouldUseNativeValidation: true,
    defaultValues: {
      send_email_in: "ENGLISH",
      title: "MR",
      house_type: "HOUSE",
    },
  });

  const send_email_in = watch("send_email_in");
  const house_type = watch("house_type");
  const title = watch("title");
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const [clicked, setClicked] = useState<boolean>(false);
  const { isMobile } = useWindowSize();

  const onSubmit = (data: InviteOwnerForm) => {
    setClicked(true);
    const fetchOwner = async () => {
      const response = await inviteOwner(data);
      if (response.status === 200) {
        toggle();
        toast.success(
          translation["invitationSendSuccessfully"][selectedLanguage]
        );
      } else {
        toast.error(translation["invitationSendingError"][selectedLanguage]);
      }
      setClicked(false);
    };
    fetchOwner();
  };

  return (
    <>
      <GlobalStyle />
      <Modal
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        px={isMobile ? 2 : 8}
      >
        <StyledFormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer display="grid" gridGap={3}>
              <Typography variant="subtitle">
                {translation["inviteNewOwner"][selectedLanguage]}
              </Typography>
              <InfoBox
                description={translation["description"][selectedLanguage]}
              />
              <StyledInputsContainer display="grid" gridGap={3}>
                <Input
                  {...register("name", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  label={translation["firstName"][selectedLanguage]}
                  error={!isValidForm(errors) && errors.name?.message}
                />
                <Input
                  {...register("last_name", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  label={translation["lastName"][selectedLanguage]}
                  error={!isValidForm(errors) && errors.last_name?.message}
                />
                <Input {...register("nick_name")} label="Nickname" />
                <Input
                  {...register("email", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  error={!isValidForm(errors) && errors.email?.message}
                  label="E-mail"
                />
              </StyledInputsContainer>
              <PhoneInput
                id="phone_number"
                control={control}
                label={translation["phoneNumber"][selectedLanguage]}
              />
              <Typography variant="subtitle">
                {translation["sendEmailIn"][selectedLanguage]}
              </Typography>
              <StyledLanguageContainer
                display="grid"
                gridAutoFlow="column"
                justifyContent="flex-start"
                gridGap={3}
              >
                <Radio
                  {...register("send_email_in", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"GERMAN"}
                  label={"German"}
                  selectedValue={send_email_in}
                />
                <Radio
                  {...register("send_email_in", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"ENGLISH"}
                  label={"English"}
                  selectedValue={send_email_in}
                />
                <Radio
                  {...register("send_email_in", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"FRENCH"}
                  label={"French"}
                  selectedValue={send_email_in}
                />
              </StyledLanguageContainer>
              <Typography variant="subtitle">
                {translation["gender"][selectedLanguage]}
              </Typography>
              <Box
                display="grid"
                gridAutoFlow="column"
                justifyContent="flex-start"
                gridGap={3}
              >
                <Radio
                  {...register("title", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"MR"}
                  label={"Male"}
                  selectedValue={title}
                />
                <Radio
                  {...register("title", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"MRS"}
                  label={"Female"}
                  selectedValue={title}
                />
              </Box>
              <Typography variant="subtitle">
                {translation["houseType"][selectedLanguage]}
              </Typography>
              <Box
                display="grid"
                gridAutoFlow="column"
                justifyContent="flex-start"
                gridGap={3}
              >
                <Radio
                  {...register("house_type", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"HOUSE"}
                  label={"House"}
                  selectedValue={house_type}
                />
                <Radio
                  {...register("house_type", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={"APARTMENT"}
                  label={"Apartment"}
                  selectedValue={house_type}
                />
              </Box>
              <Box display="grid" justifyContent="flex-end">
                <StyledButtonsContainer
                  display="grid"
                  gridAutoFlow="column"
                  justifyContent="end"
                  alignItems="center"
                  gridGap={3}
                >
                  <Button onClick={toggle} variant={ButtonVariant.SECONDARY}>
                    {translation["cancel"][selectedLanguage]}
                  </Button>
                  <Button
                    type="submit"
                    disabled={clicked}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.PRIMARY}
                  >
                    {translation["sendInvitation"][selectedLanguage]}
                  </Button>
                </StyledButtonsContainer>
              </Box>
            </StyledContainer>
          </form>
        </StyledFormContainer>
      </Modal>
    </>
  );
};

export default InviteOwnerModal;
