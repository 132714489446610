import { useState, useContext, createContext, ReactNode, FC } from "react";
import authService, { LoginResponse } from "services/Auth";
import { LoginForm } from "views/Login/Login.d";
import { Location } from "react-router-dom";
import { parseJwt } from "utils/parseJWT";
import { User } from "types/user.d";
import { ResponseWithBody } from "services/fetchApi";

export type Auth = {
  user: User | null;
  tryToRefreshAndLogoutIfTokensExpired: () => Promise<boolean>;
  login: (params: LoginForm) => Promise<ResponseWithBody<LoginResponse>>;
  logout: () => void;
  redirectPath: string | Location;
  setRedirectPath: (path: string | Location) => void;
};

const authContext = createContext<Auth | null>(null);

export const getCurrentUser = () => {
  const user = localStorage.getItem("user");

  if (user) {
    return JSON.parse(user);
  }

  return null;
};

export const useProvideAuth = (): Auth => {
  const [user, setUser] = useState<User | null>(getCurrentUser());
  const [redirectPath, setRedirectPath] = useState<string | Location>("");

  const login = async (params: LoginForm) => {
    const response = await authService.login(params);
    if (response.parsedBody?.access) {
      localStorage.setItem("token", JSON.stringify(response.parsedBody.access));
      localStorage.setItem(
        "user",
        JSON.stringify(parseJwt(response.parsedBody.access))
      );
      if (response.parsedBody?.refresh) {
        localStorage.setItem(
          "refresh",
          JSON.stringify(response.parsedBody.refresh)
        );
      }
      if (response.parsedBody.user_type) {
        localStorage.setItem(
          "user_type",
          JSON.stringify(response.parsedBody.user_type)
        );
      }
      setUser(parseJwt(response.parsedBody.access));
    }
    return response;
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
  };

  const refreshAccessToken = async () => {
    const rawRefreshToken = localStorage.getItem("refresh");
    if (!rawRefreshToken) return null;

    const refreshToken = JSON.parse(rawRefreshToken);
    const parsedToken = parseJwt(refreshToken);
    const exp = parsedToken.exp;

    if (exp && exp < Date.now() / 1000) return null;

    const response = await authService.refresh(refreshToken);
    if (!response?.parsedBody?.access) return null;

    const accessToken = response.parsedBody.access;
    localStorage.setItem("token", JSON.stringify(accessToken));
    localStorage.setItem("user", JSON.stringify(parseJwt(accessToken)));

    return accessToken;
  };

  const tryToRefreshAndLogoutIfTokensExpired = async () => {
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      const exp = parsedUser.exp;
      if (exp && exp >= Date.now() / 1000) return false;

      const refreshedToken = await refreshAccessToken();
      if (refreshedToken) return false;
      logout();
    }
    return true;
  };

  return {
    user,
    login,
    logout,
    tryToRefreshAndLogoutIfTokensExpired,
    redirectPath,
    setRedirectPath,
  };
};

interface IProvideAuth {
  children: ReactNode;
}

export const AuthProvider: FC<IProvideAuth> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
