import { Box } from "components/Box";
import React, { FC, useEffect, useRef, useState } from "react";
import { fetchConnectionSearch } from "services/Friends";
import { ConnectionSearchResult } from "types/friend.d";
import { ConnectionsSearchProps } from "./ConnectionsSearch.d";
import { StyledInput, StyledDropdown } from "./ConnectionsSearch.styled";
import { ResultItem } from "./ResultItem";
import { Typography } from "components/Typography";
import { COLORS } from "theme";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const transition = 1500;

export const ConnectionsSearch: FC<ConnectionsSearchProps> = ({
  menuOpen,
  setMenuOpen,
  sendInvitation,
  onFriendCardClick,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const anchorEl = useRef(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const [searchResults, setSearchResults] = useState<ConnectionSearchResult[]>(
    []
  );

  const getResults = async (query: string) => {
    if (query.length > 0) {
      const response = await fetchConnectionSearch(query);
      if (response.ok) {
        setSearchResults(response.parsedBody);
      }
    }
  };

  const onChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMenuOpen(true);
    setTimeout(() => setSearchQuery(event.target.value), transition);
  };

  const clearSearch = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    if (searchQuery.length === 0) clearSearch();
    getResults(searchQuery);
  }, [searchQuery]);

  const onConnectClick = (id: string) => {
    sendInvitation(id);
    getResults(searchQuery);
  };

  return (
    <Box>
      <StyledInput
        ref={anchorEl}
        onChange={onChange}
        onFocus={() => searchQuery.length > 0 && toggleMenuOpen()}
      />
      <StyledDropdown
        open={menuOpen}
        orientation="left"
        verticalPosition="bottom"
        anchorEl={anchorEl}
        onClose={toggleMenuOpen}
        withShadow
        withBorder={false}
      >
        {searchResults.length === 0 && searchQuery.length > 0 ? (
          <Typography variant="body" color={COLORS.typography.body}>
            {translation["noResults"][selectedLanguage]}
          </Typography>
        ) : (
          searchResults?.map((result) => (
            <ResultItem
              key={result.id}
              result={result}
              sendInvitation={() => onConnectClick(result.id)}
              onFriendCardClick={onFriendCardClick}
            />
          ))
        )}
      </StyledDropdown>
    </Box>
  );
};
