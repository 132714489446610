import { FC } from "react";
import { StyledLink } from "components/Booking/BookingModal/BookingModal.styled";
import { BookingDetailsTabletModalProps } from "./BookingDetailsTabletModal.d";
import { CalendarHeader } from "components/Calendar/CalendarHeader";
import { Calendar } from "components/Calendar";
import { CalendarVariant } from "components/Calendar/Calendar.enums";
import { Guests } from "../Guests";
import { Divider } from "components/Divider";
import { SummaryPrice } from "../SummaryPrice";
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { CalendarFooter } from "../../Calendar/CalendarFooter";
import { useWindowSize } from "global/hooks/useWindowSize";
import { CONTACT_PAGE } from "urls/frontend";

export const BookingDetailsTabletModal: FC<BookingDetailsTabletModalProps> = ({
  isPrivate,
  nights = 0,
  minNights,
  minDate,
  maxDate,
  onCalendarChange,
  updateCounter,
  check_in_date,
  check_out_date,
  guestsTypes,
  canAdd,
  canSubtract,
  priceSummary,
  excludeDates,
  blockedDates,
  isBlockedDatesEditable,
  onConfirmClick,
  handleBlockedDateDelete,
  housesList = [],
  isPriceOnRequest = false,
}) => {
  const { isTablet } = useWindowSize();
  return (
    <Box mt={5.6}>
      <Calendar
        checkInDate={check_in_date}
        checkOutDate={check_out_date}
        onChange={onCalendarChange}
        minDate={minDate}
        maxDate={maxDate}
        inline={true}
        variant={CalendarVariant.PUBLIC}
        excludeDates={excludeDates}
        monthsShown={isTablet ? 1 : 2}
        minNights={minNights}
        calendarHeader={
          <CalendarHeader
            nights={nights}
            minNights={minNights}
            checkInDate={check_in_date}
            checkOutDate={check_out_date}
            isPrivate={isPrivate}
            housesList={housesList}
          />
        }
        calendarFooter={
          isPrivate ? (
            <CalendarFooter
              isPrivate={isPrivate}
              blockedDates={blockedDates}
              isBlockedDatesEditable={isBlockedDatesEditable}
              handleBlockedDateDelete={handleBlockedDateDelete}
              onConfirmClick={onConfirmClick}
            />
          ) : (
            <>
              <Guests
                guestsTypes={guestsTypes}
                canAdd={canAdd}
                canSubtract={canSubtract}
                updateCounter={updateCounter}
              />
              <Divider mt={4.4} mb={5} />
              <SummaryPrice
                priceSummary={priceSummary}
                isPriceOnRequest={isPriceOnRequest}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="baseline"
                mt={2.5}
              >
                <Typography variant="caption">Have a question?</Typography>
                <StyledLink to={CONTACT_PAGE}>Contact support</StyledLink>
              </Box>
            </>
          )
        }
      />
    </Box>
  );
};
