import { Box, Typography } from "components";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";

export const FormBox = styled(Box)`
  box-sizing: border-box;
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
  background-color: ${COLORS.typography.secondary.main};
  padding: ${pxToRem(32)};
  max-width: ${pxToRem(664)};
  margin: 0 auto ${pxToRem(229)} auto;
  ${SIZES.media.tablet} {
    padding: ${pxToRem(64)};
  }
`;

export const TextBox = styled(Box)`
  ${SIZES.media.desktop} {
    margin-left: ${pxToRem(309)};
    margin-right: ${pxToRem(341)};
  }
  color: ${COLORS.typography.secondary.main};
  text-align: center;
  max-width: ${pxToRem(790)};
`;
export const CenteredText = styled(Typography)`
  font-family: "Playfair Display";
  letter-spacing: 0.03em;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 44px;
`;

export const CenteredSubText = styled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  margin-top: ${pxToRem(18)};
`;

export const NameBox = styled(Box)`
  ${SIZES.media.tablet} {
    display: inline-grid;
    grid-template-columns: auto auto;
  }
`;

export const CheckboxLabel = styled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;
