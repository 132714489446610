import { FC } from "react";

import {
  BOOKINGS_PAGE,
  COMPANY_IMPRINT_PAGE,
  FORGOT_PASSWORD,
  HOUSES_PAGE,
  HOUSE_PAGE,
  LOGIN_PAGE,
  MAIN_PAGE,
  NOTIFICATIONS_PAGE,
  PROFILE_PAGE,
  USER_ACTIVATION_PAGE,
  PRIVACY_POLICY_PAGE,
  RESET_PASSWORD_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  FRIENDS_PAGE,
  HOW_IT_WORKS,
  RESERVATIONS_PAGE,
  CONFIRM_INVITATION_PAGE,
  HOUSES_EXAMPLE_PAGE,
  HOUSE_EXAMPLE_PAGE,
  CONTACT_PAGE,
  HOUSE_PHOTOS_PAGE,
  MY_HOUSE_PAGE,
  HOUSE_APPLICATION_PAGE,
  HOUSE_EDIT_PAGE,
  FAVORITES_PAGE,
  REACTIVATE_INVITATION,
  HOUSE_APPLICATION_PAGE_OWNER,
  CONTACT_US,
  REGISTER,
  APPLY_FOR_MEMBERSHIP,
} from "urls/frontend";
import MainPage from "views/MainPage/MainPage";
import Login from "views/Login/Login";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import Houses from "views/Houses";
import { House } from "views/House";
import { PrivacyPolicy } from "views/PrivacyPolicy";
import { CompanyImprint } from "views/CompanyImprint";
import { Profile } from "views/Profile/Profile";
import { TermsAndConditions } from "views/TermsAndConditions";
import { Bookings } from "views/Bookings";
import Notifications from "views/Notifications";
import ResetPassword from "views/ResetPassword";
import ActivateUser from "views/ActivateUser";
import { Friends } from "views/Friends";
import { HowItWorks } from "views/HowItWorks";
import Reservations from "views/Reservations";
import ConfirmInvitation from "views/ConfirmInvitation/ConfirmInvitation";
import { ContactUs } from "views/ContactUs";
import { HouseImages } from "views/HouseImages";
import { MyHouse } from "views/MyHouse";
import HouseApplication from "views/HouseApplication/HouseApplication";
import { HouseEdit } from "views/HouseEdit";
import { Favorites } from "views/Favorites/Favorites";
import { ReactivateInvitation } from "views/ReactivateInvitation/ReactivateInvitation";
import { ContactUsPublic } from "views/ContactUsPublic";
import { Register } from "views/Register/Register";
import { ApplyForMembership } from "views/ApplyForMembership";

export const DEFAULT_PUBLIC_PATH = "/";
export const LOGIN_PATH = "/login";

interface Route {
  path: string;
  component: FC;
  accessibility: Accessibility;
}

type Accessibility = "PUBLIC" | "PRIVATE" | "LOGGED OUT ONLY";

export const routes: Route[] = [
  {
    path: MAIN_PAGE,
    component: MainPage,
    accessibility: "LOGGED OUT ONLY",
  },
  {
    path: LOGIN_PAGE,
    component: Login,
    accessibility: "LOGGED OUT ONLY",
  },
  {
    path: REGISTER,
    component: Register,
    accessibility: "LOGGED OUT ONLY",
  },
  {
    path: CONFIRM_INVITATION_PAGE,
    component: ConfirmInvitation,
    accessibility: "PUBLIC",
  },
  {
    path: FORGOT_PASSWORD,
    component: ForgotPassword,
    accessibility: "LOGGED OUT ONLY",
  },
  {
    path: HOUSES_PAGE,
    component: Houses,
    accessibility: "PRIVATE",
  },
  {
    path: HOUSES_EXAMPLE_PAGE,
    component: Houses,
    accessibility: "PUBLIC",
  },
  {
    path: HOUSE_EXAMPLE_PAGE,
    component: House,
    accessibility: "PUBLIC",
  },
  {
    path: HOUSE_PAGE,
    component: House,
    accessibility: "PRIVATE",
  },
  {
    path: HOUSE_EDIT_PAGE,
    component: HouseEdit,
    accessibility: "PRIVATE",
  },
  {
    path: MY_HOUSE_PAGE,
    component: MyHouse,
    accessibility: "PRIVATE",
  },
  {
    path: USER_ACTIVATION_PAGE,
    component: ActivateUser,
    accessibility: "LOGGED OUT ONLY",
  },
  {
    path: COMPANY_IMPRINT_PAGE,
    component: CompanyImprint,
    accessibility: "PUBLIC",
  },
  {
    path: TERMS_AND_CONDITIONS_PAGE,
    component: TermsAndConditions,
    accessibility: "PUBLIC",
  },
  {
    path: PRIVACY_POLICY_PAGE,
    component: PrivacyPolicy,
    accessibility: "PUBLIC",
  },
  {
    path: BOOKINGS_PAGE,
    component: Bookings,
    accessibility: "PRIVATE",
  },
  {
    path: RESERVATIONS_PAGE,
    component: Reservations,
    accessibility: "PRIVATE",
  },
  {
    path: NOTIFICATIONS_PAGE,
    component: Notifications,
    accessibility: "PRIVATE",
  },
  {
    path: HOW_IT_WORKS,
    component: HowItWorks,
    accessibility: "PUBLIC",
  },
  { path: PROFILE_PAGE, component: Profile, accessibility: "PRIVATE" },
  {
    path: RESET_PASSWORD_PAGE,
    component: ResetPassword,
    accessibility: "PUBLIC",
  },
  {
    path: FRIENDS_PAGE,
    component: Friends,
    accessibility: "PRIVATE",
  },
  {
    path: CONTACT_PAGE,
    component: ContactUs,
    accessibility: "PRIVATE",
  },
  {
    path: HOUSE_PHOTOS_PAGE,
    component: HouseImages,
    accessibility: "PRIVATE",
  },
  {
    path: HOUSE_APPLICATION_PAGE,
    component: HouseApplication,
    accessibility: "PUBLIC",
  },
  {
    path: HOUSE_APPLICATION_PAGE_OWNER,
    component: HouseApplication,
    accessibility: "PRIVATE",
  },
  {
    path: CONTACT_US,
    component: ContactUsPublic,
    accessibility: "PUBLIC",
  },
  {
    path: FAVORITES_PAGE,
    component: Favorites,
    accessibility: "PRIVATE",
  },
  {
    path: REACTIVATE_INVITATION,
    component: ReactivateInvitation,
    accessibility: "PUBLIC",
  },
  {
    path: APPLY_FOR_MEMBERSHIP,
    component: ApplyForMembership,
    accessibility: "PUBLIC",
  },
];
