import { Box } from "components/Box";
import styled from "styled-components";
import { SIZES } from "theme";

export const ContentContainer = styled(Box)`
  overflow: hidden;
`;

export const ButtonContainer = styled(Box)`
  display: grid;

  ${SIZES.media.tablet} {
    display: none;
  }
`;

export const StyledArrowContainer = styled(Box)`
  transform: rotate(180deg);
`;
