import { Box } from "components/Box";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledFormContainer = styled(Box)`
  ${SIZES.media.tablet} {
    min-height: inherit;
  }
`;

export const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: 12px;
  overflow: visible;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
  }
`;

export const StyledButtonsContainer = styled(Box)`
  ${SIZES.media.tablet} {
    padding-bottom: 12px;
  }
`;

export const StyledInputContainer = styled(Box)`
  grid-auto-flow: row;

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 12px;
  }
`;
