import { FC, forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { CalendarProps, ContainerProps } from "./Calendar.d";
import "react-datepicker/dist/react-datepicker.css";
import locale from "date-fns/locale/en-US";
import {
  StyledCalendarContainer,
  StyledPortalContainer,
  StyledContainer,
  GlobalStyle,
  DefaultDate,
  TransparentDate,
  ExcludedDate,
  TransparentMinDate,
} from "./Calendar.styled";
import { CalendarVariant } from "./Calendar.enums";
import { ReservationDatePicker } from "components/Booking/ReservationDatePicker";
import { getDateCommonFormat } from "components/Booking/BookingCalculator/bookingUtils";

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

export const Calendar = forwardRef<ReactDatePicker, CalendarProps>(
  (
    {
      checkInDate,
      checkOutDate,
      onChange,
      minDate,
      maxDate,
      inline = false,
      variant = CalendarVariant.PRIVATE,
      customInput,
      calendarHeader = null,
      calendarFooter = null,
      excludeDates = [],
      monthShown = 2,
      minNights = 0,
      filterVariant = false,
      ...props
    },
    ref
  ) => {
    const CalendarContainer: FC<ContainerProps> = ({ children }) => {
      return (
        <StyledContainer
          p={inline ? 0 : 2.2}
          inline={inline}
          monthShown={monthShown}
        >
          {calendarHeader}
          <StyledCalendarContainer>{children}</StyledCalendarContainer>
          {calendarFooter}
        </StyledContainer>
      );
    };

    const isExcludedDate = (date: Date) =>
      getDateCommonFormat(excludeDates).includes(date.toLocaleDateString());

    const isMinimumExcludeDate = (
      date: Date,
      checkInDate: Date | null | undefined,
      checkOutDate: Date | null | undefined,
      minNights: number
    ) => {
      if (checkInDate && !checkOutDate) {
        const newStartDate = new Date(checkInDate);
        newStartDate.setDate(newStartDate.getDate() + minNights);
        return date < newStartDate;
      }
      return false;
    };

    const renderDayInPicker = (dayOfMonth: number, date: Date) => {
      if (minDate && date < minDate) {
        return (
          <TransparentDate variant="caption" monthShown={monthShown}>
            {dayOfMonth}
          </TransparentDate>
        );
      }
      if (isMinimumExcludeDate(date, checkInDate, checkOutDate, minNights)) {
        if (
          checkInDate &&
          new Date(date).getTime() !== new Date(checkInDate).getTime()
        ) {
          excludeDates.push(date);
        }

        return (
          <TransparentMinDate variant="caption" monthShown={monthShown}>
            {dayOfMonth}
          </TransparentMinDate>
        );
      }
      if (isExcludedDate(date)) {
        return (
          <ExcludedDate variant="caption" monthShown={monthShown}>
            {dayOfMonth}
          </ExcludedDate>
        );
      }
      return (
        <DefaultDate variant="caption" monthShown={monthShown}>
          {dayOfMonth}
        </DefaultDate>
      );
    };

    return (
      <StyledPortalContainer isPrivate={variant === CalendarVariant.PRIVATE}>
        <GlobalStyle />
        <ReactDatePicker
          ref={ref}
          selectsRange
          startDate={checkInDate}
          endDate={checkOutDate}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          monthsShown={monthShown}
          locale={locale}
          formatWeekDay={(nameOfDay) => `${nameOfDay.slice(0, 3)}.`}
          disabledKeyboardNavigation
          excludeDates={excludeDates}
          withPortal
          inline={inline}
          calendarContainer={CalendarContainer}
          shouldCloseOnSelect={false}
          renderDayContents={renderDayInPicker}
          customInput={
            customInput ?? (
              <ReservationDatePicker
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                filterVariant={filterVariant}
              />
            )
          }
          {...props}
        />
      </StyledPortalContainer>
    );
  }
);

Calendar.displayName = "Calendar";
