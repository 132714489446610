import { Box } from "components/Box";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Image } from "components/Image";
import { FC } from "react";
import { COLORS } from "theme";
import { HOST_URL } from "urls/api";
import { getDefaultAvatar } from "utils/getDefaultAvatar";
import { ResultItemProps } from "./ResultItem.d";
import {
  StyledButton,
  StyledMutualFriends,
  StyledFullName,
} from "./ResultItem.styled";

export const ResultItem: FC<ResultItemProps> = ({
  result,
  sendInvitation,
  onFriendCardClick,
}) => {
  const fullName = `${result.name} ${result.last_name}`;
  const relationsNum = result.mutual_relations.relations_num;

  const onNameClick = () => onFriendCardClick(result.id);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1.2}
      pr={0.8}
    >
      <Box display="grid" gridAutoFlow="column" gridGap={2}>
        <Image
          isRound
          src={
            result.profile?.avatar
              ? `${HOST_URL}${result.profile.avatar}`
              : getDefaultAvatar(result.gender)
          }
          variant="avatarSearchResult"
        />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <StyledFullName variant="caption" weight="bold" onClick={onNameClick}>
            {fullName}
          </StyledFullName>
          {relationsNum > 0 && (
            <StyledMutualFriends
              variant="overline"
              color={COLORS.typography.body}
            >
              {`${relationsNum} MUTUAL FRIEND${relationsNum > 1 ? "S" : ""}`}
            </StyledMutualFriends>
          )}
        </Box>
      </Box>
      {!result.is_friend && (
        <StyledButton
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={() => sendInvitation(result.id)}
        >
          Connect
        </StyledButton>
      )}
    </Box>
  );
};
