import { FC } from "react";
import { StyledLink } from "components/Booking/BookingModal/BookingModal.styled";
import { BookingDetailsMobileModalProps } from "./BookingDetailsMobileModal.d";
import { ReservationDatePicker } from "../ReservationDatePicker";
import { Calendar } from "components/Calendar";
import { CalendarVariant } from "components/Calendar/Calendar.enums";
import { Guests } from "../Guests";
import { Divider } from "components/Divider";
import { SummaryPrice } from "../SummaryPrice";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { CalendarFooter } from "../../Calendar/CalendarFooter";
import { CalendarHeader } from "components/Calendar/CalendarHeader";
import { CONTACT_PAGE } from "urls/frontend";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const BookingDetailsMobileModal: FC<BookingDetailsMobileModalProps> = ({
  isPrivate,
  nights = 0,
  minDate,
  maxDate,
  onCalendarChange,
  check_in_date,
  check_out_date,
  updateCounter,
  guestsTypes,
  canAdd,
  canSubtract,
  blockedDates,
  excludeDates,
  isBlockedDatesEditable,
  onConfirmClick,
  handleBlockedDateDelete,
  isShownMobileCalendar,
  setIsShownMobileCalendar,
  housesList,
  minNights = 0,
  priceSummary,
  isPriceOnRequest = false,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box display="grid" gridGap={2.5} mt={2.5}>
      {isPrivate ? (
        <>
          <Calendar
            checkInDate={check_in_date}
            checkOutDate={check_out_date}
            onChange={onCalendarChange}
            minDate={minDate}
            maxDate={maxDate}
            inline={true}
            variant={CalendarVariant.PRIVATE}
            excludeDates={excludeDates}
            calendarHeader={
              <CalendarHeader isPrivate={true} housesList={housesList} />
            }
            minNights={minNights}
            calendarFooter={
              <CalendarFooter
                isPrivate={true}
                handleBlockedDateDelete={handleBlockedDateDelete}
                blockedDates={blockedDates}
                isBlockedDatesEditable={isBlockedDatesEditable}
                onConfirmClick={onConfirmClick}
              />
            }
            monthShown={1}
          />
        </>
      ) : isShownMobileCalendar ? (
        <Calendar
          checkInDate={check_in_date}
          checkOutDate={check_out_date}
          onChange={onCalendarChange}
          minDate={minDate}
          maxDate={maxDate}
          inline={true}
          excludeDates={excludeDates}
          variant={CalendarVariant.PUBLIC}
          calendarHeader={null}
          calendarFooter={null}
          monthShown={1}
          minNights={minNights}
        />
      ) : (
        <Box>
          <ReservationDatePicker
            checkInDate={check_in_date}
            checkOutDate={check_out_date}
            onClick={setIsShownMobileCalendar}
          />
          <Box mt={3}>
            <Guests
              guestsTypes={guestsTypes}
              canAdd={canAdd}
              canSubtract={canSubtract}
              updateCounter={updateCounter}
            />
          </Box>
          <Divider mt={3.3} mb={2} />
          <SummaryPrice
            priceSummary={priceSummary}
            isPriceOnRequest={isPriceOnRequest}
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            mt={0.7}
          >
            <Typography variant="caption">
              {translation["haveQuestion"][selectedLanguage]}
            </Typography>
            <StyledLink to={CONTACT_PAGE}>
              {translation["contactSupport"][selectedLanguage]}
            </StyledLink>
          </Box>
        </Box>
      )}
    </Box>
  );
};
