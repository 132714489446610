import { Box, Typography } from "components";
import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledHeader = styled(Typography)`
  width: 62%;
  text-align: center;
  letter-spacing: 0.3px;
  z-index: 1;

  ${SIZES.media.tablet} {
    width: 35%;
  }
`;

export const StyledContentContainer = styled(Box)`
  margin: 0 auto;

  ${SIZES.media.tablet} {
    width: 38.5%;
  }
`;

export const StyledSubtitleContainer = styled(Box)`
  width: 95%;

  ${SIZES.media.tablet} {
    width: 90%;
  }
`;

export const StyledFilesListContainer = styled(Box)`
  grid-template-columns: 11fr 1fr;
`;

export const StyledImageContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
`;

export const StyledFileName = styled(Typography)`
  font-size: 12px;
  margin-left: 11px;
  color: ${COLORS.typography.headline};
`;

export const StyledFileSize = styled(Typography)`
  font-size: 12px;
  color: ${COLORS.typography.headline};
`;

export const StyledRemoveIconContainer = styled.button`
  border: 1px solid ${COLORS.stroke.main};
  background-color: ${COLORS.stroke.secondary};
  width: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  margin-top: ${SIZES.spacing(6)};
  padding: ${pxToRem(22)} ${pxToRem(32)};
`;
