import { forwardRef } from "react";
import { StyledContainer, StyledIcon } from "./ReservationDatePicker.styled";
import { ReservationDatePickerProps } from "./ReservationDatePicker.d";
import { ReactComponent as IconRightArrow } from "assets/icons/icon-arrow.svg";
import { COLORS } from "theme";
import { ReservationDateItem } from "./ReservationDateItem";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const ReservationDatePicker = forwardRef<
  HTMLDivElement,
  ReservationDatePickerProps
>(
  (
    {
      checkInDate,
      checkOutDate,
      onClick = undefined,
      icon,
      onIconClick,
      withBorder = true,
      isPrivate = false,
      filterVariant = false,
      ...props
    },
    ref
  ) => {
    const userProfile = useProfile();
    const selectedLanguage = userProfile?.selectedLanguage || getLanguage();

    return (
      <StyledContainer
        display="grid"
        alignItems="center"
        withBorder={withBorder}
        gridAutoFlow="column"
        pr={icon ? 1.5 : 0}
        isPrivate={isPrivate}
        {...props}
      >
        <ReservationDateItem
          ref={ref}
          date={checkInDate}
          onClick={onClick}
          label={
            isPrivate
              ? translation["date"][selectedLanguage]
              : translation["checkIn"][selectedLanguage]
          }
          hasIcon={!!icon}
          withBorder={withBorder}
          filterVariant={filterVariant}
        />
        <IconRightArrow
          stroke={COLORS.typography.body}
          fill="none"
          width="25px"
          height="25px"
        />
        <ReservationDateItem
          ref={ref}
          date={checkOutDate}
          onClick={onClick}
          label={
            isPrivate
              ? translation["date"][selectedLanguage]
              : translation["checkOut"][selectedLanguage]
          }
          hasIcon={!!icon}
          withBorder={withBorder}
          filterVariant={filterVariant}
        />
        {icon && (
          <StyledIcon display="flex" alignItems="center" onClick={onIconClick}>
            {icon}
          </StyledIcon>
        )}
      </StyledContainer>
    );
  }
);

ReservationDatePicker.displayName = "ReservationDatePicker";
