import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { FC } from "react";
import { VicinityProps } from "./Vicinity.d";
import { Image } from "components/Image";
import { Container, StyledContent, StyledTypography } from "./Vicinity.styled";
import { COLORS } from "theme";
import { HOST_URL } from "urls/api";
import { ReadMore } from "components";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

const MAX_LENGTH = 400;

export const Vicinity: FC<VicinityProps> = ({ vicinity }) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Box>
      <Typography variant="h2">
        {translation["vicinity"][selectedLanguage]}
      </Typography>
      <Container mt={2} display="grid">
        <Image
          variant="sectionMedium"
          src={vicinity.image ? HOST_URL + vicinity.image : ""}
          alt="Vicinity image"
        />
        <StyledContent>
          <Box mt={5} mx={4} my={3}>
            {vicinity.description.length >= MAX_LENGTH ? (
              <ReadMore
                text={vicinity.description}
                color={COLORS.typography.body}
              />
            ) : (
              <StyledTypography variant="body" color={COLORS.typography.body}>
                {vicinity.description}
              </StyledTypography>
            )}
            <StyledTypography
              variant="body"
              color={COLORS.typography.body}
            ></StyledTypography>
          </Box>
        </StyledContent>
      </Container>
    </Box>
  );
};

export default Vicinity;
