import Layout from "components/Layout";
import { FC, useState } from "react";
import {
  StyledHeader,
  StyledContentContainer,
  StyledInputsContainer,
  StyledLink,
} from "./Register.styled";
import { Box, Typography } from "components";
import { Input } from "components/Input";
import { PhoneInput } from "components/PhoneInput";
import { useForm } from "react-hook-form";
import { RegisterForm } from "./Register.d";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Checkbox } from "components/Checkbox";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import { isValidForm } from "utils/isValidForm";
import { PRIVACY_POLICY_PAGE, TERMS_AND_CONDITIONS_PAGE } from "urls/frontend";
import { Header } from "components/ApplyForMembership/Header";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidationSchema } from "./validators";
import {
  RegisterBasicUserResponse,
  fetchRegisterBasicUser,
} from "services/Registration";
import { ConfirmSendingRequest } from "components/ApplyForMembership/ConfirmSendingRequest";
import { toast } from "react-toastify";
import { InfoBox } from "components/InfoBox";

export const Register: FC = () => {
  const [clicked, setClicked] = useState(false);

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<RegisterForm>({
    resolver: yupResolver(registerValidationSchema),
    shouldUseNativeValidation: true,
  });

  const agreedToTerms = watch("agreedToTerms");

  const getBackendErrors = (error: RegisterBasicUserResponse): string[] => {
    const errorMessages: string[] = [];

    const extractMessages = (obj: any) => {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          errorMessages.push(...obj[key]);
        } else if (typeof obj[key] === "object") {
          extractMessages(obj[key]);
        }
      }
    };

    if (typeof error.message === "string") {
      errorMessages.push(error.message);
    } else if (typeof error.message === "object") {
      extractMessages(error.message);
    }

    return errorMessages;
  };

  const onSubmit = async (data: RegisterForm) => {
    const { agreedToTerms, ...restData } = data;
    const response = await fetchRegisterBasicUser({ ...restData });
    if (response.ok) {
      setClicked(true);
    }

    if (!response?.ok && response?.parsedBody.message) {
      const errors = getBackendErrors(response.parsedBody);
      errors.forEach((error) => {
        toast.error(error);
      });
    }
  };

  return (
    <Layout>
      <Header>
        <StyledHeader variant="h1">
          {translation["welcomeMessage"][selectedLanguage]}
        </StyledHeader>
      </Header>
      <StyledContentContainer>
        {clicked ? (
          <ConfirmSendingRequest
            title={translation["thanksForJoining"][selectedLanguage]}
            description={translation["requestSubmitted"][selectedLanguage]}
          />
        ) : (
          <>
            <Box mt={8}>
              <InfoBox
                header={translation["warningBoxTitle"][selectedLanguage]}
                description={translation["warningBoxContent"][selectedLanguage]}
              />
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="grid" gridGap={3} mt={5}>
                <StyledInputsContainer display="grid" gridGap={3}>
                  <Input
                    {...register("name", {
                      required:
                        translation["fieldIsRequired"][selectedLanguage],
                    })}
                    label={translation["firstName"][selectedLanguage]}
                    error={!isValidForm(errors) && errors.name?.message}
                  />
                  <Input
                    {...register("last_name", {
                      required:
                        translation["fieldIsRequired"][selectedLanguage],
                    })}
                    label={translation["lastName"][selectedLanguage]}
                    error={!isValidForm(errors) && errors.last_name?.message}
                  />
                </StyledInputsContainer>
                <PhoneInput
                  id="phone_number"
                  required
                  control={control}
                  label={translation["phoneNumber"][selectedLanguage]}
                  error={!isValidForm(errors) && errors.phone_number?.message}
                />
                <Input
                  {...register("email", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  label="E-mail"
                  error={!isValidForm(errors) && errors.email?.message}
                />
                <Input
                  {...register("password", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  label="Password"
                  error={!isValidForm(errors) && errors.password?.message}
                />
                <Input
                  {...register("confirm_password", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  label="Confirm password"
                  error={
                    !isValidForm(errors) && errors.confirm_password?.message
                  }
                />
                <Checkbox
                  {...register("agreedToTerms", {
                    required: translation["fieldIsRequired"][selectedLanguage],
                  })}
                  value={agreedToTerms ? 1 : 0}
                  label={
                    <>
                      {translation["userAgreementText"][selectedLanguage]}{" "}
                      <StyledLink
                        href={TERMS_AND_CONDITIONS_PAGE}
                        target="_blank"
                      >
                        {translation["termsAndConditions"][selectedLanguage]}
                      </StyledLink>{" "}
                      {translation["and"][selectedLanguage]}{" "}
                      <StyledLink href={PRIVACY_POLICY_PAGE} target="_blank">
                        {translation["privacyPolicy"][selectedLanguage]}
                      </StyledLink>
                      .
                    </>
                  }
                  checked={agreedToTerms}
                />
                <Box display="grid" justifyContent="flex-start" mt={3}>
                  <Button
                    type="submit"
                    disabled={clicked}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.PRIMARY}
                  >
                    {translation["createAccount"][selectedLanguage]}
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        )}
      </StyledContentContainer>
    </Layout>
  );
};
