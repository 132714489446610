import { Box } from "components/Box";
import { FC } from "react";
import { ReactComponent as HeaderLogo } from "assets/icons/logo.svg";
import { IconContainer, MenuContainer } from "./Header.styled";
import { Link, UserMenu } from "components";
import { useProfile } from "context/Profile";
import { HeaderProps } from "./Header.d";
import { HOUSES_EXAMPLE_PAGE } from "urls/frontend";

export const Header: FC<HeaderProps> = ({ demoToken }) => {
  const profile = useProfile();
  return (
    <Box>
      <MenuContainer
        px={2.3}
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <IconContainer>
          <Link
            to={
              demoToken ? `${HOUSES_EXAMPLE_PAGE}?demo_token=${demoToken}` : "/"
            }
            linkColor={"black"}
          >
            <HeaderLogo />
          </Link>
        </IconContainer>
        {profile?.profileInfo && <UserMenu profile={profile.profileInfo} />}
      </MenuContainer>
    </Box>
  );
};

export default Header;
