import styled from "styled-components";
import { Typography } from "components";
import { ReactComponent as LeftImage } from "assets/mountains.svg";
import { ReactComponent as RightImage } from "assets/house.svg";
import { COLORS, SIZES } from "theme";

export const StyledHeaderContainer = styled.section`
  position: relative;
  height: 248px;
  background-color: ${COLORS.accent.boxes};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledHeader = styled(Typography)`
  width: 50%;
  text-align: center;
  letter-spacing: 0.3px;

  ${SIZES.media.tablet} {
    width: 17%;
  }
`;

export const StyledLeftImage = styled(LeftImage)`
  position: absolute;
  left: -155px;
  bottom: 0;
  width: 348px;

  ${SIZES.media.tablet} {
    left: 0;
  }
`;

export const StyledRightImage = styled(RightImage)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 150px;

  ${SIZES.media.tablet} {
    width: 365px;
  }
`;
