import styled from "styled-components";
import { Box, Typography } from "components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";

export const Content = styled(Box)`
    box-sizing: border-box;
    background-color: ${COLORS.typography.secondary.main};
    padding: ${pxToRem(32)} ${pxToRem(14)};
    width: ${pxToRem(284)};

    ${SIZES.media.tablet} {
    width: ${pxToRem(550)};
    padding: ${pxToRem(64)}};
    }
`;

export const CenteredText = styled(Typography)`
  text-align: center;
`;
