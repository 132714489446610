import { FC, useRef, useState } from "react";
import { KebabMenuProps } from "./KebabMenu.d";
import { ReactComponent as GearIcon } from "assets/icons/icon_gear.svg";
import { Box } from "components/Box";
import { StyledIconButton, StyledList } from "./KebabMenu.styled";
import { Dropdown } from "components/Dropdown";
import { OptionValue, Option } from "components/List/List.d";

export const KebabMenu: FC<KebabMenuProps> = ({ menuOptions, ...props }) => {
  const anchorEl = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleAction = (val: OptionValue) => {
    const [selectedOption] = menuOptions.filter(
      (option) => option.value === val
    );
    selectedOption?.onClick();
    toggleMenuOpen();
  };

  const getFilteredOptions = (options: Option[]) =>
    options.filter((menuItem) => menuItem?.isShown);

  return (
    <>
      <StyledIconButton
        ref={anchorEl}
        size="small"
        onClick={toggleMenuOpen}
        {...props}
      >
        <GearIcon />
      </StyledIconButton>
      <Dropdown
        withShadow
        withArrow
        orientation="center"
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={toggleMenuOpen}
        withBorder={false}
      >
        <Box py={1}>
          <StyledList
            options={getFilteredOptions(menuOptions)}
            onSelect={handleAction}
          />
        </Box>
      </Dropdown>
    </>
  );
};
