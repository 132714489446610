import { Box, Typography } from "components";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { Label } from "components/Label";
import { Textarea } from "components/Textarea";
import { FC, useEffect, useRef, useState } from "react";
import { COLORS } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";
import {
  AvatarBox,
  ButtonsBox,
  ContentContainer,
  DeleteButton,
  InputBox,
  StyledConfirmationModalContent,
} from "./ProfileForm.styled";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Select } from "components/Select";
import { PhoneInput } from "components/PhoneInput";
import {
  fetchCompanies,
  fetchCountryOptions,
  fetchEducations,
  fetchIndustries,
  fetchInterestsOptions,
  fetchUpdateProfile,
  requestAccountDeletion,
} from "services/Profile";
import {
  Avatar,
  CompanyOption,
  CountryOption,
  EducationOption,
  HobbiesOption,
  IndustryOption,
  ProfileFormData,
} from "types/user.d";
import { Option } from "components/Select/Select.d";
import { Option as AutocompleteOption } from "components/Autocomplete/Autocomplete.d";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidationSchema } from "./validators";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { isValidForm } from "utils/isValidForm";
import { Profile, useProfile } from "context/Profile";
import { Profile as AdditionalUserInfo } from "types/user";
import { ProfileFormProps } from "./ProfileForm.d";
import { Chip } from "components/Chip";
import { ChipVariant } from "components/Chip/Chip.enums";
import { AvatarInput } from "components/AvatarInput";
import { API_URL } from "urls/api";
import { toast } from "react-toastify";
import { GenderVariant } from "./ProfileForm.enums";
import { Modal } from "components/Modal";
import { useModal } from "global/hooks/useModal";
import { Autocomplete } from "components/Autocomplete/Autocomplete";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import ProfileOptionModal from "components/Modals/ProfileOptionModal/ProfileOptionModal";

type ModalType = "COMPANY" | "EDUCATION";

export const ProfileForm: FC<ProfileFormProps> = () => {
  const initialOption = { name: "", value: "" };
  const [industriesOptions, setIndustriesOptions] = useState<Option[]>([
    initialOption,
  ]);
  const [educationOptions, setEducationOptions] = useState<Option[]>([
    initialOption,
  ]);
  const [companyOptions, setCompanyOptions] = useState<Option[]>([
    initialOption,
  ]);
  const [countryOptions, setCountryOptions] = useState<AutocompleteOption[]>([
    { label: "", value: "" },
  ]);
  const [interestsList, setInterestsList] = useState<HobbiesOption[]>();
  const [gender, setGender] = useState<Option>(initialOption);
  const [type, setType] = useState<ModalType>("COMPANY");
  const [industry, setIndustry] = useState<Option>(initialOption);
  const [company, setCompany] = useState<Option>(initialOption);
  const [education, setEducation] = useState<Option>(initialOption);
  const profile = useProfile();
  const additionalProfileInfo = profile?.profileInfo?.profile;
  const [selectedCountry, setSelectedCountry] = useState<AutocompleteOption>({
    label: "",
    value: "",
  });
  const [selectedInterests, setSelectedInterests] = useState<
    HobbiesOption[] | []
  >([]);
  const [avatar, setAvatar] = useState<Avatar | null>(null);
  const confirmModalRef = useRef<HTMLDivElement | null>(null);
  const avatarInputRef = useRef<HTMLInputElement | null>(null);
  const { isShown, toggle } = useModal();
  const { isShown: isShownOptionModal, toggle: toggleProfileOptionModal } =
    useModal();

  const openProfileOptionModal = (type: ModalType) => {
    setType(type);
    toggleProfileOptionModal();
  };

  const methods = useForm<ProfileFormData>({
    resolver: yupResolver(profileValidationSchema),
    mode: "onTouched",
  });

  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const getInterestsList = async () => {
    const response = await fetchInterestsOptions();
    setInterestsList(response.parsedBody);
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
  } = methods;

  const changeCountry = (value: AutocompleteOption) => {
    setValue("profile.country", value?.value || "");
    setSelectedCountry(value ?? { name: "", value: "" });
  };

  useEffect(() => {
    const getOptions = async () => {
      const companies = await fetchCompanies();
      const industries = await fetchIndustries();
      const educations = await fetchEducations();
      const response = await fetchCountryOptions();
      getInterestsList();
      const educationOptions: EducationOption[] = educations.parsedBody;
      const companiesOptions: CompanyOption[] = companies.parsedBody;
      const industriesChoices: IndustryOption[] = industries.parsedBody;
      const choicesArray: CountryOption[] = response.parsedBody;
      const formattedCompanyValue = companiesOptions.map((el) => {
        return {
          value: el.value,
          name: el.display_name,
        };
      });
      const formattedEductionValue = educationOptions.map((el) => {
        return {
          value: el.value,
          name: el.display_name,
        };
      });
      const formattedIndustryValue = industriesChoices.map((el) => {
        return {
          value: el.value,
          name: el.display_name,
        };
      });
      const formattedCountryValue = choicesArray.map((el) => {
        return {
          value: el.value,
          label: el.display_name,
        };
      });
      setCountryOptions(formattedCountryValue);
      setIndustriesOptions(formattedIndustryValue);
      setCompanyOptions(formattedCompanyValue);
      setEducationOptions(formattedEductionValue);
    };
    getOptions();
  }, []);

  const changeAvatar = async (avatar: string) => {
    const source = new URL(avatar, API_URL).href;
    const image = await fetch(source);
    const blob = await image.blob();
    const name = avatar.split("/").pop() ?? "";
    const type = blob.type;
    const file = new File([blob], name, { type });
    setAvatar({ file, source });
    setValue("profile.avatar", file);
    trigger("profile.avatar");
  };

  const assignMainData = (profile: Profile) => {
    setValue("name", profile.profileInfo?.name ?? "");
    setValue("last_name", profile.profileInfo?.last_name ?? "");
    setValue("maiden_name", profile.profileInfo?.maiden_name ?? null);
    setValue("email", profile.profileInfo?.email ?? "");
    setValue(
      "gender",
      profile.profileInfo?.gender ?? GenderVariant.GENDER_UNKNOWN
    );
    profile.profileInfo?.gender === GenderVariant.GENDER_UNKNOWN
      ? setGender(initialOption)
      : setGender({
          name:
            profile.profileInfo?.gender === "FEMALE"
              ? translation["genderFemaleValue"][selectedLanguage]
              : translation["genderMaleValue"][selectedLanguage],
          value: profile.profileInfo?.gender ?? "",
        });
  };
  const assignAdditionalData = (additionalProfileInfo: AdditionalUserInfo) => {
    const findSelectedCountry = countryOptions.filter(
      (el) => el.label === additionalProfileInfo?.country
    )[0];
    const findSelectedEducation = educationOptions.filter((el) => {
      if (education.value) {
        return el.value === education.value;
      } else {
        return el.value === additionalProfileInfo?.education;
      }
    })[0];
    const findSelectedIndustry = industriesOptions.filter(
      (el) => el.value === additionalProfileInfo?.industry
    )[0];
    const findSelectedCompany = companyOptions.filter((el) => {
      if (company.value) {
        return el.value === company.value;
      } else {
        return el.value === additionalProfileInfo?.company_name;
      }
    })[0];

    changeCountry(findSelectedCountry);
    setValue("profile.phone_number", additionalProfileInfo?.phone_number ?? "");
    setValue("profile.city", additionalProfileInfo?.city ?? "");
    setValue("profile.street", additionalProfileInfo?.street ?? "");
    setValue("profile.house", additionalProfileInfo?.house ?? "");
    setValue("profile.postal_code", additionalProfileInfo?.postal_code ?? "");
    setValue("profile.about", additionalProfileInfo?.about ?? "");
    setValue(
      "profile.current_profession",
      additionalProfileInfo?.current_profession
    );
    findSelectedIndustry && onIndustryChange(findSelectedIndustry);
    findSelectedCompany && onChangeCompany(findSelectedCompany);
    findSelectedEducation && onChangeEducation(findSelectedEducation);
    setSelectedInterests(additionalProfileInfo?.hobbies ?? []);
    additionalProfileInfo?.avatar
      ? changeAvatar(additionalProfileInfo?.avatar)
      : setValue("profile.avatar", null);
  };

  useEffect(() => {
    const assignData = async () => {
      if (profile) {
        assignMainData(profile);
        additionalProfileInfo && assignAdditionalData(additionalProfileInfo);
      }
    };
    assignData();
  }, [
    countryOptions,
    additionalProfileInfo,
    companyOptions,
    educationOptions,
    industriesOptions,
  ]);

  const onSubmit: SubmitHandler<ProfileFormData> = async (data) => {
    const form: ProfileFormData = {
      ...data,
      profile: {
        ...data.profile,
        avatar: avatar?.file,
        country: selectedCountry.value,
        hobbies: selectedInterests,
        industry: industry.value,
        education: education.value,
        company_name: company.value,
      },
    };
    const response = await fetchUpdateProfile(form, true);

    if (response.ok) {
      toast.success(
        translation["profileUpdatedSuccessfully"][selectedLanguage]
      );
      profile?.fetchProfileData();
    } else {
      toast.error(translation["profileUpdatingError"][selectedLanguage]);
    }
  };

  const handleRemoveAvatar = () => {
    setAvatar(null);
    setValue("profile.avatar", null);
  };

  const onInterestClick = (newInterest: HobbiesOption, isSelected: boolean) => {
    const interestsWithoutSelected = selectedInterests.filter(
      (interest) => interest.id !== newInterest.id
    );
    isSelected
      ? setSelectedInterests(interestsWithoutSelected)
      : setSelectedInterests([...selectedInterests, newInterest]);
  };

  const onGenderChange = (value: Option) => {
    setGender(value);
    setValue("gender", value.value);
    value.name === GenderVariant.MALE && setValue("maiden_name", null);
  };

  const onIndustryChange = (value: Option) => {
    setIndustry(value);
    setValue("profile.industry", value.name);
  };

  const onChangeEducation = (value: Option) => {
    setEducation(value);
    setValue("profile.education", value.name);
  };

  const onChangeCompany = (value: Option) => {
    setCompany(value);
    setValue("profile.company_name", value.name);
  };

  const deleteAccount = async () => {
    const response = await requestAccountDeletion();
    if (response.ok) {
      toast.success(
        translation["requestSentToAdministrator"][selectedLanguage]
      );
      toggle();
    } else {
      toast.error(translation["requestSendingError"][selectedLanguage]);
    }
  };

  const onEducationAdd = (data: Option) => {
    setEducation(data);
    setValue("profile.education", data.name);
    setEducationOptions([...educationOptions, data]);
  };

  const onCompanyyAdd = (data: Option) => {
    setCompany(data);
    setValue("profile.company_name", data.name);
    setCompanyOptions([...companyOptions, data]);
  };

  return (
    <FormProvider {...methods}>
      <ProfileOptionModal
        type={type}
        isShown={isShownOptionModal}
        toggle={toggleProfileOptionModal}
        countryOptions={countryOptions}
        onAdd={type === "EDUCATION" ? onEducationAdd : onCompanyyAdd}
      />
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <ContentContainer display="grid" gridGap={2.5} pt={1.5}>
          <AvatarBox>
            <AvatarInput
              label={translation["yourAvatar"][selectedLanguage]}
              handleRemoveAvatar={handleRemoveAvatar}
              error={!isValidForm(errors) && errors.profile?.avatar?.message}
              gender={gender.name}
              avatar={avatar}
              setAvatar={setAvatar}
              avatarInputRef={avatarInputRef}
              control={control}
              {...register("profile.avatar")}
            />
          </AvatarBox>
          <Box>
            <InputBox display="grid" mb={3}>
              <Input
                required
                label={translation["firstName"][selectedLanguage]}
                error={!isValidForm(errors) && errors.name?.message}
                {...register("name")}
              />
              <Input
                required
                label={translation["lastName"][selectedLanguage]}
                error={!isValidForm(errors) && errors.last_name?.message}
                {...register("last_name")}
              />
              <Select
                {...register("gender")}
                error={!isValidForm(errors) && errors.gender?.message}
                label={translation["gender"][selectedLanguage]}
                value={gender}
                options={translation["genderOptions"][selectedLanguage]}
                onChange={onGenderChange}
              />
              <Input
                label={translation["maidenName"][selectedLanguage]}
                disabled={gender.name === GenderVariant.MALE}
                {...register("maiden_name")}
              />
              <Input label="email" disabled {...register("email")} />
              <PhoneInput
                id="profile.phone_number"
                control={control}
                label="phone number"
                error={
                  !isValidForm(errors) && errors.profile?.phone_number?.message
                }
              />
              <Input
                label={translation["postalCode"][selectedLanguage]}
                {...register("profile.postal_code")}
                error={
                  !isValidForm(errors) && errors.profile?.postal_code?.message
                }
              />
              <Input
                label={translation["city"][selectedLanguage]}
                {...register("profile.city")}
                error={!isValidForm(errors) && errors.profile?.city?.message}
              />
              <Input
                label={translation["street"][selectedLanguage]}
                {...register("profile.street")}
                error={!isValidForm(errors) && errors.profile?.street?.message}
              />
              <Input
                label="No"
                {...register("profile.house")}
                error={!isValidForm(errors) && errors.profile?.house?.message}
              />
              <Autocomplete
                {...register("profile.country")}
                value={selectedCountry}
                options={countryOptions}
                label={translation["country"][selectedLanguage]}
                error={!isValidForm(errors) && errors.profile?.country?.message}
                onChange={changeCountry}
              />
            </InputBox>
            <Box mb={3}>
              <Label>
                {translation["betterUnderstanding"][selectedLanguage]}
              </Label>
            </Box>
            <InputBox display="grid" mb={3}>
              <Input
                label={translation["currentProfession"][selectedLanguage]}
                {...register("profile.current_profession")}
                error={
                  !isValidForm(errors) &&
                  errors.profile?.current_profession?.message
                }
              />
              <Select
                label={translation["companyName"][selectedLanguage]}
                {...register("profile.company_name")}
                error={
                  !isValidForm(errors) && errors.profile?.company_name?.message
                }
                value={company}
                options={companyOptions}
                onChange={onChangeCompany}
                addOption={{
                  name: translation["addNewCompany"][selectedLanguage],
                  onClick: () => openProfileOptionModal("COMPANY"),
                }}
              />
              <Select
                label={translation["industry"][selectedLanguage]}
                {...register("profile.industry")}
                error={
                  !isValidForm(errors) && errors.profile?.industry?.message
                }
                value={industry}
                options={industriesOptions}
                onChange={onIndustryChange}
                zIndex={19}
              />
              <Select
                label={translation["education"][selectedLanguage]}
                {...register("profile.education")}
                error={
                  !isValidForm(errors) && errors.profile?.education?.message
                }
                value={education}
                options={educationOptions}
                onChange={onChangeEducation}
                zIndex={18}
                addOption={{
                  name: translation["addNewEducation"][selectedLanguage],
                  onClick: () => openProfileOptionModal("EDUCATION"),
                }}
              />
            </InputBox>
            <Box>
              <Label>{translation["interests"][selectedLanguage]}</Label>
              <Box
                display="flex"
                mt={2}
                mb={2}
                alignItems="center"
                flexWrap="wrap"
              >
                {interestsList?.map((interest) => {
                  const isSelected =
                    selectedInterests
                      ?.map((hobby) => hobby.name)
                      ?.includes(interest.name) ?? false;
                  return (
                    <Chip
                      mr={1.7}
                      mb={1.7}
                      key={interest.id}
                      label={interest.name}
                      variant={ChipVariant.INTEREST}
                      selected={isSelected}
                      onClick={() => onInterestClick(interest, isSelected)}
                    >
                      <Typography variant="overline">
                        {interest.name}
                      </Typography>
                    </Chip>
                  );
                })}
              </Box>
            </Box>
            <Box mb={3}>
              <Textarea
                label={translation["aboutYou"][selectedLanguage]}
                rows={6}
                helperText="Characters: 200-1000"
                {...register("profile.about")}
                error={!isValidForm(errors) && errors.profile?.about?.message}
                placeholder={
                  translation["memberProfileExample"][selectedLanguage]
                }
              />
            </Box>
            <ButtonsBox
              display="grid"
              gridAutoFlow="column"
              gridGap={6.5}
              justifyContent="start"
              alignItems="center"
              mt={4.5}
              pb={8}
            >
              <Button
                icon={
                  <ArrowIcon
                    stroke={COLORS.stroke.main}
                    fill="none"
                    width="24px"
                    height="24px"
                  />
                }
                type="submit"
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.SMALL}
                disabled={Object.keys(errors).length > 0}
              >
                {translation["save"][selectedLanguage]}
              </Button>
              <DeleteButton
                onClick={toggle}
                variant={ButtonVariant.SECONDARY}
                type="button"
                icon={
                  <DeleteIcon
                    stroke={COLORS.stroke.error}
                    strokeWidth={1.5}
                    fill="none"
                    width="24px"
                    height="24px"
                  />
                }
              >
                {translation["delete"][selectedLanguage]}
              </DeleteButton>
            </ButtonsBox>
          </Box>
        </ContentContainer>
      </form>
      <Modal modalRef={confirmModalRef} onClose={toggle} isShown={isShown}>
        <StyledConfirmationModalContent>
          <Typography variant="caption">
            {translation["profileDeletionConfirmation"][selectedLanguage]}
          </Typography>
          <Box
            display="grid"
            mt={5.6}
            gridAutoFlow="column"
            justifyContent="end"
            gridGap={3}
            alignItems="center"
          >
            <Button variant={ButtonVariant.SECONDARY} onClick={toggle}>
              {translation["cancel"][selectedLanguage]}
            </Button>
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.SMALL}
              onClick={deleteAccount}
            >
              {translation["deleteForever"][selectedLanguage]}
            </Button>
          </Box>
        </StyledConfirmationModalContent>
      </Modal>
    </FormProvider>
  );
};
