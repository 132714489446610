import * as Yup from "yup";

export const registerValidationSchema = Yup.object({
  name: Yup.string().required("Please, insert name").max(200),
  last_name: Yup.string().required("Please, insert last name").max(200),
  email: Yup.string()
    .email("Please, insert a valid email address.")
    .required("Please, insert email address."),
  phone_number: Yup.string().required("Please, insert phone number").max(20),
  password: Yup.string()
    .required("This field is required.")
    .min(8, "Your password must be at least 8 characters long."),
  confirm_password: Yup.string()
    .required("This field is required")
    .min(8, "Your confirm password must be at least 8 characters long.")
    .test(
      "doPasswordsMatch",
      "Passwords do not match. Try again.",
      function (value) {
        const password = this.parent.password;
        return password === "" || value === password;
      }
    ),
  agreedToTerms: Yup.boolean()
    .oneOf([true], "The terms and conditions must be accepted.")
    .required("The terms and conditions must be accepted."),
});
