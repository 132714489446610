import { FC } from "react";
import { getLanguage } from "utils/getLanguage";
import translation from "./personal_information_list_translation copy.json";
import { useProfile } from "context/Profile";
import { Typography } from "components";

const Service: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>
        {translation["serviceMaintenanceHeader"][selectedLanguage]}
      </strong>
      {translation["serviceMaintenance"][selectedLanguage]}
    </Typography>
  );
};

const Administer: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["accountManagement"][selectedLanguage]} </strong>
      {translation["userRegistrationManagement"][selectedLanguage]}
    </Typography>
  );
};

const Contract: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["contractFulfillment"][selectedLanguage]} </strong>
      {translation["contractExecution"][selectedLanguage]}
    </Typography>
  );
};

const ContactYou: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["contactingYou"][selectedLanguage]} </strong>
      {translation["contactMethods"][selectedLanguage]}
    </Typography>
  );
};

const ProvideNews: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["sendingInformation"][selectedLanguage]} </strong>
      {translation["similarProducts"][selectedLanguage]}
    </Typography>
  );
};

const ManageRequests: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["manageRequests"][selectedLanguage]} </strong>
      {translation["processAndManageYourInquiries"][selectedLanguage]}
    </Typography>
  );
};

const BusinessTransfers: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>
        {translation["forBusinessTransactions"][selectedLanguage]}
      </strong>
      {translation["businessTransfers"][selectedLanguage]}
    </Typography>
  );
};

const Other: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["otherPurposes"][selectedLanguage]} </strong>
      {translation["otherDataPurposes"][selectedLanguage]}
    </Typography>
  );
};

const ServiceProvider: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["withServiceProviders"][selectedLanguage]} </strong>
      {translation["dataWithServiceProviders"][selectedLanguage]}
    </Typography>
  );
};

const BusinessTransfer: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>
        {translation["forBusinessTransactions"][selectedLanguage]}{" "}
      </strong>
      {translation["dataBusinessTransactions"][selectedLanguage]}
    </Typography>
  );
};

const Affiliates: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["affiliatedCompanies"][selectedLanguage]} </strong>
      {translation["dataAffiliatedCompanies"][selectedLanguage]}
    </Typography>
  );
};

const BusinessPartners: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["businessPartners"][selectedLanguage]} </strong>
      {translation["dataBusinessPartners"][selectedLanguage]}
    </Typography>
  );
};

const OtherUsers: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["otherUsers"][selectedLanguage]} </strong>
      {translation["interactWithOtherUsers"][selectedLanguage]}
    </Typography>
  );
};

const Consent: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["withYourConsent"][selectedLanguage]} </strong>
      {translation["discloseWithYourConsent"][selectedLanguage]}
    </Typography>
  );
};

export const PersonalInformationList = [
  <Service key="service" />,
  <Administer key="administer" />,
  <Contract key="contract" />,
  <ContactYou key="contact-you" />,
  <ProvideNews key="provide-news" />,
  <ManageRequests key="manage-requests" />,
  <BusinessTransfers key="business-transfers" />,
  <Other key="other" />,
];

export const DisclosePersonalInformationList = [
  <ServiceProvider key="service-provider" />,
  <BusinessTransfer key="business-transfer" />,
  <Affiliates key="affiliates" />,
  <BusinessPartners key="business-partners" />,
  <OtherUsers key="other-users" />,
  <Consent key="consent" />,
];
