import { Box } from "components/Box";
import { Input } from "components/Input";
import { PhoneInput } from "components/PhoneInput";
import { Textarea } from "components/Textarea";
import { forwardRef, useEffect, useRef, useState, MouseEvent } from "react";
import { RecommendationEditItemProps } from "./RecommendationEditItem.d";
import {
  StyledContainer,
  StyledMainInfo,
  StyledAddress,
  StyledImageContainer,
  StyledDescriptionContainer,
  DeleteButton,
  StyledConfirmationModalContent,
} from "./RecommendationEditItem.styled";
import { Typography } from "components/Typography";
import { COLORS } from "theme";
import { isValidForm } from "utils/isValidForm";
import { Map } from "components/Map";
import { Position } from "components/Map/Map.d";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { useModal } from "global/hooks/useModal";
import { ImageInput } from "components/ImageInput";
import { useGooglePlaceAutoComplete } from "utils/useGooglePlaceAutoComplete";
import { RecommendationEditItemVariant } from "./RecommendationEditItem.enums";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";

export const RecommendationEditItem = forwardRef<
  HTMLDivElement,
  RecommendationEditItemProps
>(
  (
    {
      categoryName,
      index,
      register,
      control,
      remove,
      errors,
      setValue,
      trigger,
      watch,
      updateImagesToDelete,
      houseCenter,
      variant,
    },
    ref
  ) => {
    const { isShown, toggle } = useModal();
    const confirmModalRef = useRef<HTMLDivElement | null>(null);

    const item = watch(`${variant}.[${categoryName}].${index}`);

    const addressRef = useRef<HTMLInputElement | null>(null);
    const googleAutoComplete = useGooglePlaceAutoComplete();
    let autoComplete: google.maps.places.Autocomplete | null = null;

    const defaultCenter: Position = houseCenter && {
      lat: item.latitude || houseCenter.lat,
      lng: item.longitude || houseCenter.lng,
    };
    const [center, setCenter] = useState<Position>(defaultCenter);

    const onDragEnd = (e: google.maps.MapMouseEvent) => {
      const coordinates = e.latLng;
      if (coordinates) {
        setCenter({
          lng: coordinates?.lng(),
          lat: coordinates?.lat(),
        });
      }
    };

    const handleUploadImage = async (newImage: File) => {
      setValue(`[${variant}].[${categoryName}].${index}.images`, [
        { url: newImage, id: "" },
      ]);
      trigger(`${variant}.[${categoryName}].${index}.images`);
    };

    const handleRemoveImage = () => {
      item.images.length &&
        item.images[0].id.length > 0 &&
        updateImagesToDelete(item.images[0].id);
      setValue(`[${variant}].[${categoryName}].${index}.images`, [
        { id: "", url: "" },
      ]);
      trigger(`${variant}.[${categoryName}].${index}.images`);
    };

    const removeRecommendation = (e: MouseEvent) => {
      e.preventDefault();
      remove();
      toggle();
    };

    const handleAddressSelect = async () => {
      if (addressRef.current) {
        const addressObj = await googleAutoComplete.getFullAddress(
          autoComplete
        );
        if (addressObj) {
          addressRef.current.value = addressObj.address || "";
          setCenter({
            lng: addressObj.longitude || defaultCenter.lng,
            lat: addressObj.latitude || defaultCenter.lat,
          });
          setValue(
            `[${variant}].[${categoryName}].${index}.location`,
            addressObj.address
          );
        }
      }
    };

    useEffect(() => {
      const loadGoogleMaps = async () => {
        autoComplete = await googleAutoComplete.initAutoComplete(
          addressRef.current,
          handleAddressSelect
        );
      };
      loadGoogleMaps();
    }, []);

    useEffect(() => {
      setValue(`[${variant}].[${categoryName}].${index}.latitude`, center.lat);
      setValue(`[${variant}].[${categoryName}].${index}.longitude`, center.lng);
    }, [center, item]);

    useEffect(() => {
      if (addressRef.current) {
        addressRef.current.value = item.location || "";
      }
    }, [item]);

    return (
      <Box ref={ref}>
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2.5}
          alignItems="center"
        >
          <Typography variant="h4">{`${categoryName} ${index + 1}`}</Typography>
          <DeleteButton type="button" onClick={toggle}>
            <DeleteIcon
              stroke={COLORS.typography.icons}
              fill="none"
              strokeWidth={1.5}
            />
          </DeleteButton>
        </Box>
        <StyledContainer display="grid" gridGap={2.2}>
          <StyledImageContainer>
            <ImageInput
              id={`${variant}.[${categoryName}].${index}.images[0].id`}
              label="Photo"
              handleRemoveFile={handleRemoveImage}
              handleUploadFile={handleUploadImage}
              error={
                !isValidForm(errors) &&
                errors?.[variant]?.[categoryName] &&
                errors?.[variant]?.[categoryName][index]?.images?.message
              }
              image={item.images.length ? item.images[0].url : ""}
              {...register(`${variant}.[${categoryName}].${index}.images`)}
            />
          </StyledImageContainer>
          <StyledMainInfo display="grid" gridGap={2}>
            <Input
              required
              label="Name"
              {...register(`${variant}.[${categoryName}].${index}.title`)}
              error={
                !isValidForm(errors) &&
                errors?.[variant]?.[categoryName] &&
                errors?.[variant]?.[categoryName][index]?.title?.message
              }
            />
            <PhoneInput
              id={`${variant}.[${categoryName}].${index}.phone_number`}
              control={control}
              label="Phone number"
            />
            <Input
              label="Website"
              inputMode="url"
              {...register(`${variant}.[${categoryName}].${index}.url`)}
              error={
                !isValidForm(errors) &&
                errors?.[variant]?.[categoryName] &&
                errors?.[variant]?.[categoryName][index]?.url?.message
              }
            />
            <Input
              label="E-mail"
              inputMode="email"
              {...register(`${variant}.[${categoryName}].${index}.email`)}
              error={
                !isValidForm(errors) &&
                errors?.[variant]?.[categoryName] &&
                errors?.[variant]?.[categoryName][index]?.email?.message
              }
            />
          </StyledMainInfo>
          {variant === RecommendationEditItemVariant.RECOMMENDATION && (
            <StyledAddress display="grid" gridGap={2} alignItems="start">
              <Input
                label="Address"
                {...register(`${variant}.[${categoryName}].${index}.location`)}
                error={
                  !isValidForm(errors) &&
                  errors?.[variant]?.[categoryName] &&
                  errors?.[variant]?.[categoryName][index]?.location?.message
                }
                ref={addressRef}
              />
              <Box>
                <Map center={center} onMarkerDragEnd={onDragEnd} />
              </Box>
            </StyledAddress>
          )}
          <StyledDescriptionContainer>
            <Textarea
              rows={3}
              label="Description"
              {...register(`${variant}.[${categoryName}].${index}.comment`)}
              error={
                !isValidForm(errors) &&
                errors?.[variant]?.[categoryName] &&
                errors?.[variant]?.[categoryName][index]?.comment?.message
              }
            />
          </StyledDescriptionContainer>
        </StyledContainer>
        <Modal modalRef={confirmModalRef} onClose={toggle} isShown={isShown}>
          <StyledConfirmationModalContent>
            <Typography variant="caption">
              Are you sure you want to delete this item?
            </Typography>
            <Box
              display="grid"
              mt={5.6}
              gridAutoFlow="column"
              justifyContent="end"
              gridGap={3}
              alignItems="center"
            >
              <Button variant={ButtonVariant.SECONDARY} onClick={toggle}>
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.SMALL}
                onClick={removeRecommendation}
              >
                Delete
              </Button>
            </Box>
          </StyledConfirmationModalContent>
        </Modal>
      </Box>
    );
  }
);

RecommendationEditItem.displayName = "RecommendationEditItem";
