import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { SIZES } from "theme";

const ContainerStyles = css`
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: ${pxToRem(20)};
  grid-auto-rows: max-content;

  ${SIZES.media.phone} {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  ${SIZES.media.desktop} {
    grid-template-columns: repeat(auto-fill, minmax(416px, 1fr));
  }
`;

export const StyledContainer = styled(Box)`
  ${ContainerStyles}
`;

export const StyledBlurImagesContainer = styled(Box)`
  ${ContainerStyles}
  height: 275px;
  overflow-y: clip;
`;

export const StyledBlurContainer = styled(Box)`
  height: 278px;
  width: 100%;
  margin-top: -275px;
  position: relative;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) -25.36%,
    #ffffff 51.11%
  );
`;
