import styled from "styled-components";
import { Box, Typography } from "components";
import { COLORS, SIZES } from "theme";
import { Button } from "components/Button";
import { ExternalLink } from "components/Link";
import { pxToRem } from "global/utils";

export const SubHeader = styled(Typography)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 45px;
`;

export const FormBox = styled(Box)`
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
  background: ${COLORS.background.main};
  max-width: 664px;
  padding: ${pxToRem(18)};
  ${SIZES.media.tablet} {
    padding: ${pxToRem(64)};
  }
`;

export const PhoneTypography = styled(ExternalLink)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  margin-left: 12px;
  color: ${COLORS.typography.headline};
`;

export const SendButton = styled(Button)`
  margin-top: ${pxToRem(24)};
`;

export const LineInputBox = styled(Box)`
  gap: ${pxToRem(24)};
  flex-direction: column;
  ${SIZES.media.tablet} {
    flex-direction: row;
  }
`;

export const StyledBox = styled(Box)`
  margin: ${pxToRem(18)};
  margin-bottom: ${pxToRem(80)};
  ${SIZES.media.desktop} {
    margin: ${pxToRem(16)} ${pxToRem(80)};
  }
`;
