import { FC } from "react";
import { RequestFullAccessHeaderProps } from "./RequestFullAccessHeader.d";
import {
  StyledContainer,
  StyledTypography,
  StyledButton,
} from "./RequestFullAccessHeader.styled";
import { ButtonVariant } from "components/Button/Button.enums";
import { COLORS } from "theme";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { useNavigate } from "react-router";
import { APPLY_FOR_MEMBERSHIP } from "urls/frontend";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import { ReactComponent as UserCheckIcon } from "assets/icons/user-check.svg";
import translation from "./translation.json";

export const RequestFullAccessHeader: FC<RequestFullAccessHeaderProps> = ({
  isRequestSent,
}) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <StyledContainer
      px={2.3}
      py={1}
      display="grid"
      gridAutoFlow="column"
      gridGap={2}
      justifyContent="center"
      alignItems="center"
      isRequestSent={isRequestSent}
    >
      {isRequestSent && <UserCheckIcon />}
      <StyledTypography variant="body" color={COLORS.background.main}>
        {isRequestSent
          ? translation["approvalNotificationMessage"][selectedLanguage]
          : translation["accessRequestMessage"][selectedLanguage]}
      </StyledTypography>
      {!isRequestSent && (
        <StyledButton
          variant={ButtonVariant.SECONDARY}
          onClick={() => navigate(APPLY_FOR_MEMBERSHIP)}
          icon={
            <ArrowIcon
              stroke={COLORS.background.main}
              fill="none"
              width="16px"
              height="16px"
              style={{ transform: "rotate(-45deg)" }}
            />
          }
        >
          {translation["requestFullAccess"][selectedLanguage]}
        </StyledButton>
      )}
    </StyledContainer>
  );
};
