import { Box } from "components";
import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const ContentContainer = styled(Box)`
  grid-template-columns: unset;

  ${SIZES.media.desktop} {
    grid-template-columns: 305px auto;
  }
`;

export const AvatarBox = styled(Box)`
  grid-column: unset;
  gap: 19px;
  ${SIZES.media.tablet} {
    grid-column: 1 / 2;
    max-width: ${pxToRem(305)};
    margin: 0 auto;
  }
`;
export const InputBox = styled(Box)`
  grid-column: unset;

  ${SIZES.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${SIZES.media.desktop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 20px;
`;

export const ButtonsBox = styled(Box)`
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  width: 83%;

  ${SIZES.media.tablet} {
    grid-template-columns: 237px 165px auto;
    width: 100%;
  }
`;

export const DeleteButton = styled(Button)`
  text-decoration-color: ${COLORS.stroke.error};
  color: ${COLORS.stroke.error};
`;

export const StyledConfirmationModalContent = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.small};
  }
`;
