import { Breadcrumbs } from "components/Breadcrumbs";
import { HousesList } from "components/HousesList";
import { HouseItem } from "components/HousesList/HousesList.d";
import Layout from "components/Layout";
import { Tabs } from "components/Tabs";
import { useProfile } from "context/Profile";
import { FC, useEffect, useState } from "react";
import { fetchMyHouses, Filter } from "services/Houses";
import { getLanguage } from "utils/getLanguage";
import { HeaderContainer } from "views/PrivacyPolicy/PrivacyPolicy.styled";
import { HouseStatusVariant } from "./HouseStatuses.enums";
import { MainContainer } from "./MyHouse.styled";
import translation from "./translation.json";
import { useNavigate } from "react-router";
import { HOUSES_PAGE } from "urls/frontend";

export const MyHouse: FC = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [houses, setHouses] = useState<HouseItem[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);

  const getHouses = async () => {
    const response = await fetchMyHouses(activeFilter);
    const activeFilters = response && response.parsedBody.filters;
    response && setHouses(response.parsedBody.houses);
    response && setFilters(activeFilters);
    activeFilters.length === 0 && setActiveFilter(activeFilters[0].name);
  };

  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const getTabs = () =>
    filters.map((filter) => ({
      label: filter.name,
      content: (
        <HousesList
          houses={houses}
          withEditIcon={filter.name !== HouseStatusVariant.ARCHIVED}
        />
      ),
      count: filter.counter,
    }));

  useEffect(() => {
    getHouses();
  }, [activeFilter]);

  useEffect(() => {
    if (isBasicUserType) navigate(HOUSES_PAGE);
  }, []);

  return (
    <Layout>
      <MainContainer variant="div">
        <Breadcrumbs currentPageTitle="My house" />
        <HeaderContainer variant="h1">
          {translation["myHouse"][selectedLanguage]}
        </HeaderContainer>
        {!!filters.length && (
          <Tabs tabs={getTabs()} onTabChange={setActiveFilter} />
        )}
      </MainContainer>
    </Layout>
  );
};
