import { FC } from "react";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { addSpacesToNumber } from "global/utils";
import { COLORS } from "theme";
import {
  StyledPriceContainer,
  StyledPricesContainer,
  StyledPriceTitle,
} from "./SeasonPrices.styled";
import { SeasonalPrice } from "types/house";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

interface Props {
  seasonal_prices: Array<SeasonalPrice>;
}

const SeasonalPrices: FC<Props> = ({ seasonal_prices }) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();

  return (
    <Box display="grid" gridGap={2}>
      <Typography variant="h2" color={COLORS.typography.headline}>
        {translation["prices"][selectedLanguage]}
      </Typography>
      <StyledPricesContainer display="flex">
        {seasonal_prices &&
          seasonal_prices.map((item, index) => (
            <StyledPriceContainer key={`${item.title}-${index}`} p={1}>
              <StyledPriceTitle
                variant="body"
                color={COLORS.typography.headline}
              >
                {item.title}
              </StyledPriceTitle>
              <Box display="grid" mt={0.7}>
                <Typography variant="overline" color={COLORS.typography.body}>
                  {translation["maxPricePerNight"][selectedLanguage]}
                </Typography>
                <Typography
                  variant="overline"
                  weight="bold"
                  color={COLORS.typography.body}
                >
                  € {addSpacesToNumber(item.cap)}
                </Typography>
              </Box>
              <Box display="grid" mt={2.5}>
                <Typography variant="overline" color={COLORS.typography.body}>
                  {translation["perAdult"][selectedLanguage]}
                </Typography>
                <Typography
                  variant="overline"
                  weight="bold"
                  color={COLORS.typography.body}
                >
                  € {addSpacesToNumber(item.per_adult)}
                </Typography>
              </Box>
              <Box display="grid" mt={2.5}>
                <Typography variant="overline" color={COLORS.typography.body}>
                  {translation["perChild"][selectedLanguage]}
                </Typography>
                <Typography
                  variant="overline"
                  weight="bold"
                  color={COLORS.typography.body}
                >
                  € {addSpacesToNumber(item.per_child)}
                </Typography>
              </Box>
              <Box display="grid" mt={2.5}>
                <Typography variant="overline" color={COLORS.typography.body}>
                  {translation["perInfant"][selectedLanguage]}
                </Typography>
                <Typography
                  variant="overline"
                  weight="bold"
                  color={COLORS.typography.body}
                >
                  € {addSpacesToNumber(item.per_baby)}
                </Typography>
              </Box>
              <Box display="grid" mt={2.5}>
                <Typography variant="overline" color={COLORS.typography.body}>
                  {translation["minimumPricePerNight"][selectedLanguage]}
                </Typography>
                <Typography
                  variant="overline"
                  weight="bold"
                  color={COLORS.typography.body}
                >
                  € {addSpacesToNumber(item.minimum_price_per_night)}
                </Typography>
              </Box>
            </StyledPriceContainer>
          ))}
      </StyledPricesContainer>
    </Box>
  );
};

export default SeasonalPrices;
