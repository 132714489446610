import { Label } from "components/Label";
import { FC, useRef } from "react";
import { COLORS } from "theme";
import { ImageInputProps } from "./ImageInput.d";
import {
  StyledMainContainer,
  StyledContainer,
  StyledInput,
  StyledUploadContainer,
  StyledTypography,
  DeleteButton,
} from "./ImageInput.styled";
import { Image } from "components/Image";
import { ReactComponent as UploadIcon } from "assets/icons/icon_camera.svg";
import { HOST_URL } from "urls/api";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";

export const ImageInput: FC<ImageInputProps> = ({
  id,
  label,
  error,
  image,
  disabled = false,
  handleUploadFile,
  handleRemoveFile,
  ...inputProps
}) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const getImagePath = (imageUrl: string | File) =>
    typeof imageUrl === "string"
      ? `${HOST_URL}${imageUrl}`
      : URL.createObjectURL(imageUrl);

  const onUploadFileClick = () => {
    hiddenFileInput.current && hiddenFileInput?.current.click();
  };

  const onUploadFile = () => {
    const files = hiddenFileInput.current?.files ?? [];
    const [file] = files;
    handleUploadFile(file);
  };

  return (
    <StyledMainContainer display="grid" gridGap={1.5}>
      {label && (
        <Label htmlFor={id} disabled={disabled} error={!!error}>
          {error ? error : label}
        </Label>
      )}
      <StyledContainer display="grid" gridGap={1.5}>
        {!!image && (
          <Image variant="card" src={image ? getImagePath(image) : ""} />
        )}
        <StyledInput
          ref={hiddenFileInput}
          disabled={disabled}
          type="file"
          accept="image/png, image/jpeg, image/svg+xml"
          onInput={onUploadFile}
          {...inputProps}
        />
        {image ? (
          <DeleteButton type="button" onClick={handleRemoveFile}>
            <DeleteIcon
              stroke={COLORS.error.main}
              strokeWidth={1}
              fill="none"
            />
          </DeleteButton>
        ) : (
          <StyledUploadContainer
            display="grid"
            justifyItems="center"
            gridGap={1.5}
            onClick={onUploadFileClick}
            px={8}
            py={8.5}
          >
            <UploadIcon />
            <StyledTypography variant="body" color={COLORS.typography.headline}>
              Add a photo
            </StyledTypography>
          </StyledUploadContainer>
        )}
      </StyledContainer>
    </StyledMainContainer>
  );
};
