import { FC, Fragment } from "react";
import { Divider } from "components/Divider";
import { Typography } from "components/Typography";
import { Link } from "components/Link";
import { ListProps, OptionValue, Option } from "./List.d";
import { ListOption, ListOptionIcon, StyledList } from "./List.styled";
import { LIST_SEPARATOR } from "./constants";

export const List: FC<ListProps> = ({
  options,
  onSelect,
  optionValue,
  navList,
  ...props
}) => {
  const select = (value: OptionValue) => () => onSelect?.(value);

  const hasIcons = options.some(
    (item: Option | string) => item !== LIST_SEPARATOR && (item as Option).icon
  );

  const renderListOption = ({ label, value, icon, disabled }: Option) => (
    <ListOption
      key={value}
      onClick={disabled ? undefined : select(value)}
      isActive={value === optionValue}
      disabled={disabled}
    >
      {hasIcons && <ListOptionIcon>{icon}</ListOptionIcon>}
      <Typography variant="body">{label}</Typography>
    </ListOption>
  );

  return (
    <StyledList {...props}>
      {options.map((option: Option | string, index) =>
        option === LIST_SEPARATOR ? (
          <Divider key={`list-separator-${index}`} my={1.5} />
        ) : (
          <Fragment key={(option as Option).value}>
            {navList && (
              <>
                {(option as Option)?.isLink ? (
                  <Link
                    key={(option as Option).value}
                    to={(option as Option).value as string}
                  >
                    {renderListOption(option as Option)}
                  </Link>
                ) : (
                  <>{renderListOption(option as Option)}</>
                )}
              </>
            )}
            {!navList && renderListOption(option as Option)}
          </Fragment>
        )
      )}
    </StyledList>
  );
};
