import React, { forwardRef } from "react";
import { Badge, StyledIconButton } from "./IconButton.styled";
import { IconButtonProps } from "./IconButton.d";

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ badge, children, ...props }, ref) => (
    <StyledIconButton ref={ref} {...props}>
      {children}
      {badge && (
        <Badge display="flex" alignItems="center" justifyContent="center">
          {badge}
        </Badge>
      )}
    </StyledIconButton>
  )
);

IconButton.displayName = "IconButton";
