import { Box, BulletList } from "components";
import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { ExternalLink } from "components/Link";
import { FC } from "react";
import { CookiesList, TrackingTechnologies } from "./CookiesList";
import { DefinitionList } from "./DefinitionList";
import { OtherLegalRequirementsList } from "./OtherLegalRequirementsList";
import { PersonalDataList } from "./PersonalDataList";
import {
  DisclosePersonalInformationList,
  PersonalInformationList,
} from "./PersonalInformationList";
import {
  MainContainer,
  HeaderContainer,
  ContentSubHeader,
  ContentParagraphContainer,
  ContentTitle,
  ContentSubTitle,
  ContentHeaderContainer,
  ContentBulletList,
} from "./PrivacyPolicy.styled";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./privacy_policy.json";

export const PrivacyPolicy: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Layout>
      <MainContainer variant="div">
        <Breadcrumbs currentPageTitle="Privacy policy" />
        <HeaderContainer variant="h1">
          {translation["privacyPolicyHeader"][selectedLanguage]}
        </HeaderContainer>
        <ContentSubHeader variant="subtitle">
          {translation["lastUpdated"][selectedLanguage]}
        </ContentSubHeader>
        <ContentHeaderContainer variant="body">
          {translation["privacyPolicy"][selectedLanguage]}
        </ContentHeaderContainer>
        <ContentHeaderContainer variant="body">
          {translation["useOfPersonalData"][selectedLanguage]}
        </ContentHeaderContainer>
        <ContentTitle variant="h2">
          {translation["interpretationAndDefinitions"][selectedLanguage]}
        </ContentTitle>
        <ContentSubTitle variant="h3">
          {translation["interpretation"][selectedLanguage]}
        </ContentSubTitle>
        <ContentParagraphContainer variant="body">
          {translation["capitalizedWords"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentSubTitle variant="h3">
          {translation["definitions"][selectedLanguage]}
        </ContentSubTitle>
        <ContentParagraphContainer variant="body">
          {translation["forPurposes"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={DefinitionList}></BulletList>
        </ContentBulletList>
        <ContentTitle variant="h2">
          {translation["collectingAndUsingPersonalData"][selectedLanguage]}
        </ContentTitle>
        <ContentSubTitle variant="h3">
          {translation["typesOfDataCollected"][selectedLanguage]}
        </ContentSubTitle>
        <ContentSubTitle variant="h3">
          {translation["personalData"][selectedLanguage]}
        </ContentSubTitle>
        <ContentParagraphContainer variant="body">
          {translation["personalDataTypes"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={PersonalDataList}></BulletList>
        </ContentBulletList>
        <ContentTitle variant="h2">
          {translation["dataProvided"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["informationCollected"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["usageDataHeader"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["usageDataCollected"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["usageDataInformation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["browserInformation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["mobileDeviceInformation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["trackingTechnologiesAndCookies"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["trackingTechnologies"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={TrackingTechnologies}></BulletList>
        </ContentBulletList>
        <ContentParagraphContainer variant="body">
          {translation["cookies"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["cookies_purposes"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={CookiesList}></BulletList>
        </ContentBulletList>
        <ContentParagraphContainer variant="body">
          {translation["information"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["useOfPersonalDataHeader"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["companyUseOfPersonalData"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={PersonalInformationList}></BulletList>
        </ContentBulletList>
        <ContentParagraphContainer variant="body">
          {translation["dataDisclosure"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={DisclosePersonalInformationList}></BulletList>
        </ContentBulletList>
        <ContentTitle variant="h2">
          {translation["retentionOfYourPersonalData"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {
            translation["retentionOfYourPersonalDataDescription"][
              selectedLanguage
            ]
          }
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["retentionOfUsageData"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["transferOfYourPersonalData"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {
            translation["informationProcessingAndDataTransfer"][
              selectedLanguage
            ]
          }
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["consentAndAgreementToDataTransfer"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["dataSecurityAndTransferControls"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["personalDataDisclosure"][selectedLanguage]}
        </ContentTitle>
        <ContentSubTitle variant="h3">
          {translation["businessTransactions"][selectedLanguage]}
        </ContentSubTitle>
        <ContentParagraphContainer variant="body">
          {translation["mergerAcquisitionAssetSale"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentSubTitle variant="h3">
          {translation["lawEnforcement"][selectedLanguage]}
        </ContentSubTitle>
        <ContentParagraphContainer variant="body">
          {translation["personalDataDisclosureByLaw"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["otherLegalRequirements"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["necessaryActions"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentBulletList>
          <BulletList items={OtherLegalRequirementsList}></BulletList>
        </ContentBulletList>
        <ContentTitle variant="h2">
          {translation["securityOfPersonalData"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["securityOfPersonalDataInformation"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["childrensPrivacy"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["childrensPrivacyPolicy"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["parentalConsent"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["linksToOtherWebsites"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["linksToOtherWebsitesPolicy"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["thirdPartyResponsibility"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["changesToPrivacyPolicy"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          <Box>{translation["privacyPolicyUpdates"][selectedLanguage]}</Box>
          <Box>{translation["notificationOfChanges"][selectedLanguage]}</Box>
          <Box>{translation["reviewPrivacyPolicy"][selectedLanguage]}</Box>
        </ContentParagraphContainer>
        <ContentTitle variant="h2">
          {translation["contactUs"][selectedLanguage]}
        </ContentTitle>
        <ContentParagraphContainer variant="body">
          {translation["privacyPolicyContact"][selectedLanguage]}
        </ContentParagraphContainer>
        <ContentParagraphContainer variant="body">
          {translation["contactByEmail"][selectedLanguage]}
          <ExternalLink
            href="mailto:contact@houseoffriends.world"
            linkColor="black"
          >
            {" contact@houseoffriends.world"}
          </ExternalLink>
        </ContentParagraphContainer>
      </MainContainer>
    </Layout>
  );
};
