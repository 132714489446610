import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { Button } from "components/Button";

export const StyledContainer = styled(Box)`
  ${SIZES.media.desktop} {
    grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));
    grid-row-gap: ${pxToRem(37)};
  }
`;

export const StyledImageContainer = styled(Box)`
  margin: 0 auto;

  ${SIZES.media.desktop} {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
`;

export const StyledMainInfo = styled(Box)`
  ${SIZES.media.phone} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: fit-content;
  }
  ${SIZES.media.desktop} {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }
`;

export const StyledDescriptionContainer = styled(Box)`
  ${SIZES.media.desktop} {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
  }
`;

export const StyledAddress = styled(Box)`
  ${SIZES.media.desktop} {
    grid-column: 4 / 5;
    grid-row: 1 / 3;
    height: fit-content;
  }
`;

export const DeleteButton = styled(Button)`
  background-color: ${COLORS.background.control};
  border-radius: 0;
  padding: ${pxToRem(7)} 0 0 0;
  width: 40px;
  height: 40px;

  &:enabled {
    background-color: ${COLORS.background.control};
  }
`;

export const StyledConfirmationModalContent = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.small};
  }
`;
