import { Box, Dropdown, IconButton } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, LAYERS, SIZES } from "theme";
import { MenuWrapperProps } from "./UserMenu.d";

export const UserMenuWrapper = styled(Box)`
  position: relative;
`;

export const MenuWrapper = styled(Box)<MenuWrapperProps>`
  border: 1px solid ${COLORS.typography.stroke};
  border-radius: 23px;
`;

export const StyledUserMenu = styled(Dropdown)`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(271)};
  padding: 1.25rem 2rem;
`;

export const NotificationIconButton = styled(IconButton)`
  border: 1px solid ${COLORS.typography.stroke};
  fill: ${COLORS.typography.icons};
  margin-right: 1rem;

  :enabled {
    cursor: pointer;
  }
`;

export const NotificationsIconContainer = styled(Box)`
  position: absolute;
  right: 0;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 7%);
  z-index: ${LAYERS.modalOverDropdown};
  top: 52px;
  padding: 4px 8px;
  max-height: 360px;
  width: 264px;
  overflow-y: scroll;
  overflow-x: hidden;

  ${SIZES.media.tablet} {
    padding: 24px 32px;
    top: 64px;
    width: 435px;
  }
`;
