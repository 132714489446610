import { FC } from "react";
import { Box, Typography } from "components";
import { ExternalLink } from "components/Link";
import { getLanguage } from "utils/getLanguage";
import translation from "./cookies_translation.json";
import { useProfile } from "context/Profile";

const Cookies: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["cookies"][selectedLanguage]} </strong>
      {translation["cookieInfo"][selectedLanguage]}
    </Typography>
  );
};

const CookiesFlash: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <Box>
        <strong>{translation["flashCookies"][selectedLanguage]} </strong>
        {translation["flashCookieInfo"][selectedLanguage]}
      </Box>
      <ExternalLink
        href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects"
        linkColor="black"
      >
        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects
      </ExternalLink>
    </Typography>
  );
};

const WebBeacons: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["webBeacons"][selectedLanguage]} </strong>
      {translation["webBeaconInfo"][selectedLanguage]}
    </Typography>
  );
};

const EssentialCookies: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["essentialCookies"][selectedLanguage]} </strong>
      <Box>{translation["sessionCookiesType"][selectedLanguage]}</Box>
      <Box>{translation["managedBy"][selectedLanguage]}</Box>
      <Box>{translation["purpose"][selectedLanguage]}</Box>
    </Typography>
  );
};

const CookiesPolicy: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["cookiesPolicy"][selectedLanguage]} </strong>
      <Box>{translation["cookieType"][selectedLanguage]}</Box>
      <Box>{translation["managedBy"][selectedLanguage]}</Box>
      <Box>{translation["cookiePurpose"][selectedLanguage]}</Box>
    </Typography>
  );
};

const FunctionalCookiesPolicy: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["functionalCookies"][selectedLanguage]} </strong>
      <Box>{translation["persistentCookies"][selectedLanguage]}</Box>
      <Box>{translation["managedBy"][selectedLanguage]}</Box>
      <Box>{translation["functionalCookiesPurpose"][selectedLanguage]}</Box>
    </Typography>
  );
};

export const TrackingTechnologies = [
  <Cookies key="cookies" />,
  <CookiesFlash key="cookies-flash" />,
  <WebBeacons key="web-beacons" />,
];

export const CookiesList = [
  <EssentialCookies key="essential-cookies" />,
  <CookiesPolicy key="cookies-policy" />,
  <FunctionalCookiesPolicy key="functional-cookies-policy" />,
];
