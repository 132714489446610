import {
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  StyledLabelInside,
  StyledOption,
  StyledOptionsList,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectedOptions,
  StyledSelectedOptionsContainer,
  StyledSelectWrapper,
  StyledLabel,
  StyledSelectArrowIcon,
  StyledContainer,
  StyledIconPlus,
} from "./Select.styled";
import { Option, SelectProps } from "./Select.d";
import { Box } from "components/Box";
import { useHandleClickOutside } from "global/hooks/useHandleClickOutside";

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      error,
      options,
      labelInside,
      disabled,
      multiple,
      id,
      value,
      name = id,
      onChange,
      children,
      isOverflow = true,
      activeOption,
      closeOnClick = true,
      addOption,
      zIndex,
      className,
      ...props
    },
    ref
  ) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Option[]>([]);
    const selectWrapper = useRef<HTMLDivElement | null>(null);
    const selectBase = useRef<HTMLDivElement | null>(null);
    const selectContainer = useRef<HTMLDivElement | null>(null);
    useHandleClickOutside(selectWrapper, close);

    useEffect(() => {
      if (selectBase.current && selectWrapper.current) {
        const selectBaseHeight = selectBase.current.clientHeight;
        selectWrapper.current.style.height = `${
          value?.name === "" ? 55 : selectBaseHeight + 18 * 2 + 2
        }px`;
      }
    }, [selected]);

    useEffect(() => {
      options.length !== 0 && !!selected && setSelected([options[0]]);
      value && setSelected([value]);
    }, [value, options]);

    function handleClick(e: MouseEvent) {
      if (!isOpen) {
        open();
      } else if ((multiple && !(e.ctrlKey || e.metaKey)) || !multiple) {
        if (isClicked) {
          closeOnClick && close();
          setIsClicked(false);
          return;
        }
      }
      setIsClicked(true);
    }

    function open() {
      if (disabled) return;
      setIsOpen(true);
    }

    function close() {
      setIsOpen(false);
      setIsClicked(false);
    }

    function select(e: MouseEvent) {
      const target = e.target as HTMLLIElement;

      const selectedOption: Option = options.find((element) => {
        return element.value == target.id;
      }) ?? { name: "", value: "" };
      selectedOption &&
        setSelected(
          multiple && (e.ctrlKey || e.metaKey)
            ? [...selected, selectedOption]
            : [selectedOption]
        );
      onChange && onChange(selectedOption);
    }

    function displayNamesOfSelected() {
      const namesArray = selected.map((el) => el.name);
      return namesArray.join(", ");
    }

    return (
      <StyledContainer display="grid" gridGap={1.5} className={className}>
        {label && (
          <StyledLabel htmlFor={id} disabled={disabled} error={!!error}>
            {error ? error : label}
          </StyledLabel>
        )}
        <StyledSelectWrapper ref={selectWrapper}>
          <StyledSelectContainer
            disabled={!!disabled}
            error={!!error}
            isOpen={isOpen}
            tabIndex={0}
            onClick={(e) => handleClick(e)}
            onFocus={open}
            ref={selectContainer}
            zIndex={zIndex}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledSelectedOptionsContainer
                display="flex"
                flexDirection="column"
              >
                {labelInside && displayNamesOfSelected().length === 0 && (
                  <StyledLabelInside htmlFor={id} error={!!error}>
                    {error ? error : labelInside}
                  </StyledLabelInside>
                )}
                <StyledSelectedOptions ref={selectBase}>
                  {activeOption ?? displayNamesOfSelected()}
                </StyledSelectedOptions>
              </StyledSelectedOptionsContainer>
              <StyledSelectArrowIcon isOpen={isOpen} />
            </Box>

            <StyledSelect
              id={id}
              ref={ref}
              name={name}
              value={value}
              {...props}
            />

            {isOpen && (
              <StyledOptionsList>
                {options.map((option: Option) => {
                  if (selected.includes(option)) return;
                  return (
                    <StyledOption
                      key={option.value}
                      id={option.value}
                      onClick={(e) => select(e)}
                    >
                      {option.name}
                    </StyledOption>
                  );
                })}
                {addOption && (
                  <StyledOption onClick={() => addOption.onClick()}>
                    <StyledIconPlus />
                    {addOption.name}
                  </StyledOption>
                )}
              </StyledOptionsList>
            )}
          </StyledSelectContainer>
        </StyledSelectWrapper>
      </StyledContainer>
    );
  }
);

Select.displayName = "Select";
