export const urlToFile = async (
  url: string,
  filename: string,
  mimeType: string
) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], filename, { type: mimeType });
    return file;
  } catch (error) {
    console.error("Error fetching the file:", error);
    return null;
  }
};
