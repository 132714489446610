import { FC, useState } from "react";
import { Typography } from "components/Typography";
import {
  ToggleButton,
  StyledContainer,
  StyledTypography,
} from "./ReadMore.styled";
import { ReadMoreProps } from "./ReadMore.d";
import { COLORS } from "theme";
import { ReactComponent as LessIcon } from "assets/icons/icon_read_less.svg";

export const ReadMore: FC<ReadMoreProps> = ({
  text,
  color = COLORS.typography.main,
  labelReadLess = "Close",
  labelReadMore = "Read More",
}) => {
  const [extended, setExtended] = useState(false);

  const toggle = () => {
    setExtended(!extended);
  };

  return (
    <StyledContainer
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
    >
      <StyledTypography variant="body" color={color} extended={extended}>
        {text}
      </StyledTypography>
      <ToggleButton onClick={toggle} display="flex" flexDirection="row">
        {extended ? (
          <>
            <Typography variant="link">{labelReadLess}</Typography>
            <LessIcon className="icon-less" />
          </>
        ) : (
          <>
            <Typography variant="link">{labelReadMore}</Typography>
            <LessIcon className="icon-more" />
          </>
        )}
      </ToggleButton>
    </StyledContainer>
  );
};
