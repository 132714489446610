import { FC, PropsWithChildren, useEffect } from "react";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { PageWrapper, Wrapper } from "./Layout.styled";
import { LayoutProps } from "./Layout.d";
import { FooterVariant } from "components/Footer/Footer.enums";
import { useProfile } from "context/Profile";
import { useSearchParams } from "react-router-dom";

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  isHousePage,
  useBackgroundImage = false,
}) => {
  const profile = useProfile();
  const [searchParams] = useSearchParams();
  const demoToken = searchParams.get("demo_token");

  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper display="flex" flexDirection="column">
      <Header demoToken={demoToken} />
      <PageWrapper useBackgroundImage={useBackgroundImage}>
        {children}
      </PageWrapper>
      <Footer
        borderTop={true}
        isHousePage={isHousePage}
        demoToken={demoToken}
        variant={
          profile?.profileInfo
            ? FooterVariant.PRIVATE
            : demoToken
            ? FooterVariant.DEMO
            : FooterVariant.PUBLIC
        }
      />
    </Wrapper>
  );
};

export default Layout;
