import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchHouseImages } from "services/House";
import { HouseImagesProps } from "types/house";
import { HOST_URL } from "urls/api";
import {
  BackButton,
  ContentBox,
  ImagesBox,
  MainContainer,
  StyledImage,
  StyledTitle,
  MobileHeaderContainer,
} from "./HouseImages.styled";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-slider-arrow.svg";
import { ButtonVariant } from "components/Button/Button.enums";
import { ReactComponent as IconMapMarker } from "assets/icons/icon_map_marker.svg";
import { IconWithLabel } from "components/IconWithLabel";
import { useWindowSize } from "global/hooks/useWindowSize";
import { Gallery } from "components/Gallery/Gallery";
import { ExtraImagesListType } from "./HouseImages.d";
import { Loader } from "components/Loader/Loader";
import { MasonryImageList } from "components/MasonryImageList/MasonryImageList";
import { Box } from "components/Box";

export const HouseImages: FC = () => {
  const { id } = useParams();
  const [house, setHouse] = useState<HouseImagesProps>();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const getHouseData = async () => {
    const response = id && (await fetchHouseImages(id));
    response && setHouse(response.parsedBody);
    setIsLoaded(true);
  };
  const locationLabel = `${house?.location.city}, ${house?.location.country}`;
  const images = house ? Object.values(house.images).flat() : [];
  const slider: ExtraImagesListType = images.map((image, index) => ({
    src: `${HOST_URL}${image}`,
    alt: "",
    index: index,
  }));
  const [isOpenHouseGallery, setOpenHouseGallery] = useState<boolean>(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    getHouseData();
  }, []);
  const chunkSize = 3;
  const imagesSlices = [];
  if (house) {
    for (let i = 0; i < slider.length; i += chunkSize) {
      const chunk = slider.slice(i, i + chunkSize);
      imagesSlices.push(chunk);
    }
  }

  return (
    <Layout>
      {isLoaded ? (
        house && (
          <MainContainer mx={10} mt={2}>
            {!isTablet && (
              <Breadcrumbs
                currentPageTitle={house.title}
                extraExcludedValues={house.id}
              />
            )}
            {isMobile ? (
              <MobileHeaderContainer>
                <BackButton
                  onClick={() => navigate(-1)}
                  icon={<ArrowIcon />}
                  variant={ButtonVariant.PRIMARY}
                />
              </MobileHeaderContainer>
            ) : (
              <BackButton
                onClick={() => navigate(-1)}
                icon={<ArrowIcon />}
                variant={ButtonVariant.PRIMARY}
              />
            )}
            <StyledTitle variant="h1">{house.title}</StyledTitle>
            <IconWithLabel
              gap={1.5}
              icon={IconMapMarker}
              label={locationLabel}
            />
            <Box mt={5}>
              <MasonryImageList
                images={images}
                setOpenHouseGallery={setOpenHouseGallery}
                setCurrentImageIndex={setCurrentImageIndex}
              />
            </Box>
            <Gallery
              images={slider}
              isOpen={isOpenHouseGallery}
              setOpen={setOpenHouseGallery}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
            />
          </MainContainer>
        )
      ) : (
        <Loader />
      )}
    </Layout>
  );
};
