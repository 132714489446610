import { FC } from "react";
import { CalendarHeaderProps } from "./CalendarHeader.d";
import { ReservationDatePicker } from "components/Booking/ReservationDatePicker";
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { COLORS } from "theme";
import { Select } from "components/Select";
import { StyledCloseButton } from "../Calendar.styled";

export const CalendarHeader: FC<CalendarHeaderProps> = ({
  nights = 0,
  minNights = 1,
  checkInDate,
  checkOutDate,
  housesList,
  isPrivate,
  onHouseChange,
  selectedHouse,
  closeModal,
}) => {
  return (
    <Box
      display={isPrivate ? "block" : "grid"}
      alignItems="center"
      gridAutoFlow="column"
      justifyContent="space-between"
      mb={isPrivate ? 3.5 : 4.6}
    >
      {isPrivate ? (
        housesList?.length &&
        onHouseChange && (
          <Box display="grid" gridGap={1}>
            {!!closeModal && <StyledCloseButton onClick={closeModal} />}
            <Select
              options={housesList}
              value={selectedHouse}
              label="Select specific house to block the dates"
              onChange={onHouseChange}
            />
          </Box>
        )
      ) : (
        <>
          <div>
            <Typography variant="h4" color={COLORS.typography.headline}>
              Total {nights} night{nights > 1 && "s"}
            </Typography>
            <Typography variant="caption" color={COLORS.typography.body}>
              (Min {minNights} night{minNights > 1 && "s"})
            </Typography>
          </div>

          <ReservationDatePicker
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
          />
        </>
      )}
    </Box>
  );
};
