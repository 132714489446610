import { useState, useRef, useEffect, forwardRef } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import {
  GlobalStyle,
  StyledContainer,
  StyledModal,
} from "./CropImageModal.styled";
import { CropImageModalProps } from "./CropImageModal.d";
import "react-image-crop/dist/ReactCrop.css";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { Box } from "components/Box";
import { COLORS } from "theme";
import { centerAspectCrop } from "./utils";

export const CropImageModal = forwardRef<HTMLDivElement, CropImageModalProps>(
  ({ setAvatar, onCancel, imgSrc }, ref) => {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [isShownModal, setIsShownModal] = useState(false);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const pixelRatio = 0.5;

    const closeModal = () => {
      onCancel();
      setCrop(undefined);
      setCompletedCrop(undefined);
      setIsShownModal(false);
    };

    useEffect(() => {
      !!imgSrc && setIsShownModal(true);
      if (!imgSrc) {
        setCrop(undefined);
        setCompletedCrop(undefined);
      }
    }, [imgSrc]);

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, 1));
    };

    const onCompleteCrop = () => {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      canvas.width = Math.ceil(crop.width * scaleX * pixelRatio);
      canvas.height = Math.ceil(crop.height * scaleY * pixelRatio);

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    };

    const cropAndSetImage = () => {
      const canvas = previewCanvasRef.current as HTMLCanvasElement;
      const filename = "newFile.jpeg";

      const onBlobConversion = (result: Blob | null) => {
        if (result) {
          const file = new File([result], filename);
          setAvatar(file);
        }
        setIsShownModal(false);
      };
      canvas.toBlob(onBlobConversion, "image/png", 0.75);
    };

    useEffect(onCompleteCrop, [completedCrop]);

    return (
      <>
        <GlobalStyle />
        <StyledModal
          withCloseButton
          modalRef={ref}
          isShown={isShownModal}
          onClose={closeModal}
          px={3}
        >
          <StyledContainer pt={2}>
            <Box display="flex" justifyContent="center">
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </Box>
            <Box
              display="grid"
              gridAutoFlow="column"
              gridGap={3}
              justifyContent="end"
              alignItems="center"
              mt={2}
              pb={2}
            >
              <Button variant={ButtonVariant.SECONDARY} onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.SMALL}
                onClick={cropAndSetImage}
                icon={
                  <ArrowIcon
                    stroke={COLORS.stroke.main}
                    fill="none"
                    width="16px"
                    height="16px"
                  />
                }
              >
                Crop image
              </Button>
            </Box>
          </StyledContainer>
        </StyledModal>
        <div>
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: "contain",
                width: completedCrop.width * pixelRatio,
                height: completedCrop.height * pixelRatio,
                display: "none",
              }}
            />
          )}
        </div>
      </>
    );
  }
);

CropImageModal.displayName = "CropImageModal";
