import { Box } from "components/Box";
import styled from "styled-components";
import QuoteIcon from "assets/icons/icon-quote.svg";
import SmallQuoteIcon from "assets/icons/icon-small-quote.svg";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";
import { COLORS, SIZES } from "theme";
import { Link } from "components/Link";

export const StyledContainer = styled(Box)`
  position: relative;
  padding: ${pxToRem(50)} ${pxToRem(22)} ${pxToRem(55)} ${pxToRem(18)};
  ${SIZES.media.tablet} {
    padding: ${pxToRem(85)} ${pxToRem(0)} ${pxToRem(44)} ${pxToRem(88)};
  }
  &::before {
    left: 0;
    position: absolute;
    content: url(${SmallQuoteIcon});
    left: ${pxToRem(18)};
    top: 0;
    ${SIZES.media.tablet} {
      content: url(${QuoteIcon});
    }
  }

  &::after {
    transform: rotate(180deg);
    position: absolute;
    content: url(${SmallQuoteIcon});
    right: ${pxToRem(18)};
    bottom: 0;
    ${SIZES.media.tablet} {
      right: 0;
      bottom: ${pxToRem(-30)};
      content: url(${QuoteIcon});
    }
  }
`;

export const StyledTitle = styled(Typography)`
  z-index: 10;
  letter-spacing: 0.04em;
`;
export const StyledContentTitle = styled(Typography)`
  letter-spacing: 0.04em;
  margin-top: ${pxToRem(36)};
  margin-bottom: ${pxToRem(16)};
  ${SIZES.media.desktop} {
    text-align: left;
  }
`;

export const StyledTypography = styled(Typography)`
  z-index: 10;
  line-height: 30px;
`;
export const StyledContentTypography = styled(Typography)`
  text-align: center;
  line-height: 30px;
  ${SIZES.media.tablet} {
    margin-left: ${pxToRem(200)};
    margin-right: ${pxToRem(200)};
  }
  ${SIZES.media.desktop} {
    margin-right: 0;
    margin-left: 0;
    text-align: left;
  }
`;

export const HowItWorksContainer = styled(Box)`
  box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
  background: ${COLORS.background.main};
  margin: ${pxToRem(-137)} ${pxToRem(10)} ${pxToRem(300)};
  ${SIZES.media.tablet} {
    margin: ${pxToRem(-237)} ${pxToRem(85)} ${pxToRem(300)};
  }
  ${SIZES.media.desktop} {
    margin: ${pxToRem(-137)} ${pxToRem(80)} ${pxToRem(300)};
    display: flex;
    justify-content: space-evenly;
  }
`;
export const HowItWorksContainerText = styled(Box)`
  padding-top: ${pxToRem(32)};
  ${SIZES.media.tablet} {
    padding-top: ${pxToRem(64)};
    padding-left: ${pxToRem(64)};
    padding-right: ${pxToRem(64)};
  }
  ${SIZES.media.desktop} {
    padding: ${pxToRem(42)} ${pxToRem(54)} ${pxToRem(90)} ${pxToRem(0)};
    max-width: 778px;
  }
`;
export const PhotoBox = styled(Box)`
  height: ${pxToRem(158)};
  padding-top: ${pxToRem(84)};
  ${SIZES.media.tablet} {
    padding-top: ${pxToRem(44)};
    height: ${pxToRem(358)};
  }
  ${SIZES.media.desktop} {
    padding-top: ${pxToRem(350)};
    height: ${pxToRem(315)};
  }
`;

export const SectionBox = styled(Box)`
  margin-left: ${pxToRem(18)};
  margin-right: ${pxToRem(18)};
  margin-top: ${pxToRem(29)};

  ${SIZES.media.phone} {
    padding-top: ${pxToRem(100)};
  }

  ${SIZES.media.tablet} {
    padding-top: 0;
  }

  ${SIZES.media.desktop} {
    margin-left: ${pxToRem(80)};
    margin-right: 0;
    flex-wrap: nowrap;
    margin-bottom: ${pxToRem(116)};
    flex-direction: row;
    &:nth-child(even) {
      flex-direction: row-reverse;
      margin-right: ${pxToRem(80)};
      margin-left: 0;
    }
  }
`;

export const TextBox = styled(Box)`
  ${SIZES.media.desktop} {
    align-items: flex-start;
    margin-left: ${pxToRem(120)};
    margin-right: ${pxToRem(120)};
  }
`;

export const ContactUsExternalLink = styled(Link)`
  color: ${COLORS.accent.primary};
  text-decoration: underline;
  font-size: 12px;

  ${SIZES.media.tablet} {
    font-size: 16px;
  }
`;
