import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { FC } from "react";
import { MainContainer } from "views/House/House.styled";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import {
  ContentParagraphContainer,
  HeaderContainer,
} from "./ContactUsPublic.styled";
import { Box } from "components/Box";

export const ContactUsPublic: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Layout>
      <MainContainer ml={10} my={2} mb={38}>
        <Breadcrumbs currentPageTitle="Contact us public" />
        <HeaderContainer variant="h1">
          {translation["contactInformation"][selectedLanguage]}
        </HeaderContainer>
        <Box display="grid" gridGap={4}>
          <ContentParagraphContainer variant="body">
            <Box>HOF House of Friends Limited</Box>
            <Box>13 Karaiskakis Street</Box>
            <Box>CY3032 Limassol</Box>
            <Box>Cyprus </Box>
          </ContentParagraphContainer>
          <ContentParagraphContainer variant="body" weight="bold">
            <Box>Call us: +49 174 691 60 19</Box>
            <Box>Email: contact@houseoffriends.world</Box>
          </ContentParagraphContainer>
        </Box>
      </MainContainer>
    </Layout>
  );
};
