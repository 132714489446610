import { ReactComponent as HeaderLogo } from "assets/icons/logo.svg";
import { FC, PropsWithChildren } from "react";
import {
  HeaderLogoWrapper,
  Wrapper,
  ContentWrapper,
  LanguageSelectWrapper,
} from "./Background.styled";
import { FooterVariant } from "components/Footer/Footer.enums";
import { Footer } from "components/Footer";
import { Link } from "components/Link";
import LanguageSelect from "components/Select/LanguageSelect/LanguageSelect";

interface Props {
  displayLogo?: boolean;
}

export const Background: FC<PropsWithChildren<Props>> = ({
  displayLogo = false,
  children,
}) => {
  return (
    <Wrapper display="flex" flexDirection="column">
      {displayLogo && (
        <HeaderLogoWrapper>
          <Link to={"/"}>
            <HeaderLogo />
          </Link>
        </HeaderLogoWrapper>
      )}
      <LanguageSelectWrapper>
        <LanguageSelect />
      </LanguageSelectWrapper>
      <ContentWrapper
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </ContentWrapper>
      <Footer linkColor="white" variant={FooterVariant.PUBLIC} />
    </Wrapper>
  );
};
