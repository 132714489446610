import { Box } from "components/Box";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  grid-template-columns: 1fr;

  ${SIZES.media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledBox = styled(Box)`
  ${SIZES.media.tablet} {
    padding: 0;
  }
`;
