import React, { FC, useEffect, useRef, useState } from "react";
import { ApplyForMembershipSearchResult } from "types/friend.d";
import { ConnectionsSearchProps } from "./ConnectionsSearch.d";
import {
  StyledInput,
  StyledDropdown,
  StyledContainer,
  StyledArrowIcon,
  StyledSelectedHouseOwnersList,
} from "./ConnectionsSearch.styled";
import { ResultItem } from "./ResultItem";
import { Typography } from "components/Typography";
import { COLORS } from "theme";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { SelectedHouseOwnerItem } from "./SelectedHouseOwnerItem";
import { fetchAcceptors } from "services/ApplyForMembership";

export const ConnectionsSearch: FC<ConnectionsSearchProps> = ({
  menuOpen,
  setMenuOpen,
  selectedHouseOwners,
  setSelectedHouseOwners,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const anchorEl = useRef(null);
  const [searchResults, setSearchResults] = useState<
    ApplyForMembershipSearchResult[]
  >([]);

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const onChange = () => {
    setMenuOpen(true);
  };

  const onRequestClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.preventDefault();

    const index = selectedHouseOwners.indexOf(id);
    setSelectedHouseOwners(
      index === -1
        ? [...selectedHouseOwners, id]
        : selectedHouseOwners.filter((item) => item !== id)
    );
  };

  useEffect(() => {
    const getAcceptors = async () => {
      const response = await fetchAcceptors();
      if (response.ok) {
        setSearchResults(response.parsedBody);
      }
    };
    getAcceptors();
  }, []);

  return (
    <StyledContainer>
      <StyledInput
        readOnly
        ref={anchorEl}
        onChange={onChange}
        onFocus={toggleMenuOpen}
        placeholder={translation["selectHouseOwner"][selectedLanguage]}
      />
      <StyledDropdown
        open={menuOpen}
        orientation="left"
        verticalPosition="bottom"
        anchorEl={anchorEl}
        onClose={toggleMenuOpen}
        withShadow
        withBorder={false}
        fullWidth
      >
        {searchResults.length === 0 ? (
          <Typography variant="body" color={COLORS.typography.body}>
            {translation["noResults"][selectedLanguage]}
          </Typography>
        ) : (
          searchResults?.map((result) => (
            <ResultItem
              key={result.id}
              result={result}
              isAddedToHouseOwnersList={selectedHouseOwners.includes(result.id)}
              onRequestClick={onRequestClick}
            />
          ))
        )}
      </StyledDropdown>
      <StyledArrowIcon
        style={{ transform: menuOpen ? "rotate(180deg)" : "none" }}
      />
      {selectedHouseOwners.length !== 0 && (
        <StyledSelectedHouseOwnersList mt={4} p={3}>
          {searchResults
            ?.filter((item) => selectedHouseOwners.includes(item.id))
            .map((result) => (
              <SelectedHouseOwnerItem
                key={result.id}
                result={result}
                removeFromList={onRequestClick}
              />
            ))}
        </StyledSelectedHouseOwnersList>
      )}
    </StyledContainer>
  );
};
