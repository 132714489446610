import { FC } from "react";
import { RecommendationItemProps } from "./RecommendationItem.d";
import { ReactComponent as NavigationIcon } from "assets/icons/icon-navigation.svg";
import { ReactComponent as MailIcon } from "assets/icons/icon-mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/icon-phone.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/icon-website.svg";
import {
  StyledItemAdditionalInfo,
  StyledLink,
  StyledContainer,
  ImageContainer,
  StyledTypography,
  StyledDescriptionContainer,
  StyledTitle,
} from "./RecommendationItem.styled";
import { COLORS } from "theme";
import { ReadMore } from "components/ReadMore";
import { Box } from "components/Box";
import { HOST_URL } from "urls/api";
import { Image } from "components/Image";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

const COMMENT_MAX_WORDS = 10;

export const RecommendationItem: FC<RecommendationItemProps> = ({
  id,
  images,
  isActive = false,
  onClick,
  title,
  comment,
  distance,
  latitude,
  longitude,
  url,
  phoneNumber,
  email,
}) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();

  return (
    <StyledContainer
      key={id}
      isActive={isActive}
      display="grid"
      alignItems="start"
      gridGap={1}
      onClick={() => onClick(id)}
    >
      <ImageContainer>
        <Image
          variant="card"
          src={images[0] && `${HOST_URL}${images[0]?.url}`}
        />
      </ImageContainer>
      <StyledTitle variant="h4">{title}</StyledTitle>
      {comment !== null && (
        <StyledDescriptionContainer my={2}>
          {comment.split(" ").length > COMMENT_MAX_WORDS ? (
            <ReadMore text={comment} color={COLORS.typography.body} />
          ) : (
            <StyledTypography variant="body" color={COLORS.typography.body}>
              {comment}
            </StyledTypography>
          )}
        </StyledDescriptionContainer>
      )}
      <Box display="grid" gridGap={1.5}>
        {distance && (
          <StyledItemAdditionalInfo variant="caption">
            <NavigationIcon />
            {latitude && longitude ? (
              <StyledLink
                target="_blank"
                href={`https://maps.google.com/?ll=${latitude},${longitude}`}
                rel="noreferrer"
              >
                {distance}
              </StyledLink>
            ) : (
              distance
            )}
          </StyledItemAdditionalInfo>
        )}
        {url && (
          <StyledItemAdditionalInfo variant="body">
            <WebsiteIcon />
            <StyledLink target="_blank" href={url} rel="noreferrer">
              {translation["goToWebsite"][selectedLanguage]}
            </StyledLink>
          </StyledItemAdditionalInfo>
        )}
        {phoneNumber && (
          <StyledItemAdditionalInfo variant="body">
            <PhoneIcon />
            <StyledLink href={`tel:${phoneNumber}`}>{phoneNumber}</StyledLink>
          </StyledItemAdditionalInfo>
        )}
        {email && (
          <StyledItemAdditionalInfo variant="body">
            <MailIcon />
            <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
          </StyledItemAdditionalInfo>
        )}
      </Box>
    </StyledContainer>
  );
};
