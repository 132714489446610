import styled from "styled-components";
import Select from "react-select";
import { StyledFieldBasic } from "components/Form/common.styled";
import { pxToRem } from "global/utils";
import { COLORS } from "theme";
import { ReactComponent as SelectArrow } from "assets/icons/icon_select_arrow.svg";
import { StyledSelectArrowProps } from "./Autocomplete.d";

export const SelectElement = styled(Select)`
  .react-select__control {
    ${StyledFieldBasic}
    border-radius: 0;
    padding: ${pxToRem(17)};
  }

  .react-select__control--menu-is-open {
    border-bottom: 0;
  }

  .react-select__indicators {
    padding-right: ${pxToRem(7)};
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__single-value {
    margin: 0;
  }

  .react-select__menu {
    max-height: ${pxToRem(270)};
    overflow-y: scroll;
    border-radius: 0;
    margin: 0;
    border: 1px solid ${COLORS.stroke.main};
    border-top: 0;
    box-shadow: none;
    z-index: 50;
    top: ${pxToRem(60)};
  }

  .react-select__option {
    box-sizing: border-box;
    padding: ${pxToRem(9)} ${pxToRem(18)};
    min-height: ${pxToRem(18)};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:hover {
      color: ${COLORS.accent.primary};
    }

    &:select {
      background-color: ${COLORS.typography.secondary.main};
    }

    &:focus {
      background-color: ${COLORS.typography.secondary.main};
    }

    &:active {
      background-color: ${COLORS.typography.secondary.main};
    }
  }

  .react-select__option--is-focused {
    background-color: ${COLORS.typography.secondary.main};
    &:hover {
      cursor: pointer;
    }
  }

  .react-select__option--is-selected {
    background-color: ${COLORS.typography.secondary.main};
    color: ${COLORS.accent.primary};
  }
`;

export const StyledSelectArrowIcon = styled(
  SelectArrow
)<StyledSelectArrowProps>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
`;
