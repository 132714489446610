import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS } from "../../theme/colors";
import { SIZES } from "../../theme/sizes";
import { ButtonProps } from "./Button.d";
import { ButtonSize, ButtonVariant } from "./Button.enums";

const getButtonPadding = (val: ButtonVariant, size: ButtonSize) => {
  if (size === ButtonSize.SMALL) return "18px 32px";
  switch (val) {
    case ButtonVariant.PRIMARY:
      return "28px 36px";
    case ButtonVariant.BOOKING:
      return "25px 66px";
    case ButtonVariant.SECONDARY:
      return 0;
  }
};

const getButtonColor = (val: ButtonVariant, size: ButtonSize) => {
  switch (val) {
    case ButtonVariant.SECONDARY:
      return size === ButtonSize.SMALL
        ? COLORS.typography.secondary.light
        : "transparent";
    default:
      return COLORS.accent.primary;
  }
};

export const StyledButton = styled.button<ButtonProps>(
  ({
    variant = ButtonVariant.PRIMARY,
    disabled = false,
    size = ButtonSize.LARGE,
  }) => css`
    color: ${variant !== ButtonVariant.SECONDARY && !disabled
      ? COLORS.stroke.main
      : COLORS.accent.primary};
    font-size: ${SIZES.typography.button};
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-decoration: ${variant !== ButtonVariant.SECONDARY ||
    size !== ButtonSize.LARGE
      ? "none"
      : "underline"};
    text-decoration-color: ${variant !== ButtonVariant.SECONDARY
      ? "none"
      : COLORS.accent.primary};
    border: none;
    padding: ${getButtonPadding(variant, size)};
    outline: ${variant === ButtonVariant.SECONDARY && size !== ButtonSize.LARGE
      ? `1px solid ${COLORS.accent.border}`
      : "none"};
    width: 100%;
    display: flex;
    justify-content: center;

    &:disabled {
      pointer-events: none;
      color: ${COLORS.typography.disabled};
      background-color: ${variant !== ButtonVariant.SECONDARY ||
      size !== ButtonSize.LARGE
        ? COLORS.accent.disabled
        : "transparent"};
      border: none;
      text-decoration-color: ${variant !== ButtonVariant.SECONDARY
        ? "none"
        : COLORS.accent.disabled};
      svg {
        stroke: ${COLORS.typography.disabled};
      }
    }

    &:enabled {
      background-color: ${getButtonColor(variant, size)};
      cursor: pointer;

      &:hover {
        color: ${variant === ButtonVariant.SECONDARY &&
        size === ButtonSize.LARGE
          ? COLORS.accent.hover
          : COLORS.typography.stroke};
        background-color: ${variant === ButtonVariant.SECONDARY &&
        size === ButtonSize.LARGE
          ? "transparent"
          : COLORS.accent.hover};
        & svg {
          stroke: ${variant === ButtonVariant.SECONDARY &&
          size === ButtonSize.LARGE
            ? COLORS.accent.hover
            : COLORS.typography.stroke};
        }
      }

      &:active {
        color: ${COLORS.typography.stroke};
        background-color: ${variant === ButtonVariant.SECONDARY &&
        size === ButtonSize.LARGE
          ? "transparent"
          : COLORS.accent.pressed};
      }
    }
  `
);

export const StyledIcon = styled(Box)`
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
`;
