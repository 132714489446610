import { FC, Fragment } from "react";
import { StyledContainer, StyledItemsContainer } from "./Footer.styled";
import { Typography } from "components/Typography";
import { FooterItem } from "./FooterItem";
import { ReactComponent as ExploreIcon } from "assets/icons/explore.svg";
import { ReactComponent as ConnectIcon } from "assets/icons/connect.svg";
import { ReactComponent as ApplyIcon } from "assets/icons/apply.svg";
import { ReactComponent as DrawArrowIcon } from "assets/icons/draw-arrow.svg";

export const Footer: FC = () => {
  const howItWorksSteps = [
    {
      label: "Explore",
      description: "Discover unique houses and vibrant communities.",
      icon: <ExploreIcon />,
    },
    {
      label: "Connect",
      description: "Get recommended by a house owner to join.",
      icon: <ConnectIcon />,
    },
    {
      label: "Apply",
      description: "Complete your application and await approval.",
      icon: <ApplyIcon />,
    },
  ];

  return (
    <StyledContainer display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h2" weight="bold">
        How it works?
      </Typography>
      <StyledItemsContainer display="flex" mt={8.8}>
        {howItWorksSteps.map((item, index, array) => (
          <Fragment key={item.label}>
            <FooterItem
              label={item.label}
              description={item.description}
              icon={item.icon}
            />
            {index !== array.length - 1 && <DrawArrowIcon />}
          </Fragment>
        ))}
      </StyledItemsContainer>
    </StyledContainer>
  );
};
