import { forwardRef } from "react";
import { ButtonProps } from "./Button.d";
import { StyledButton, StyledIcon } from "./Button.styled";
import { Box } from "components/Box";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, icon, leftIcon, ...props }, ref) => (
    <StyledButton ref={ref} {...props}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {leftIcon && (
          <StyledIcon display="flex" mr={1} alignItems="center">
            {leftIcon}
          </StyledIcon>
        )}
        {children}{" "}
        {icon && (
          <StyledIcon display="flex" ml={1} alignItems="center">
            {icon}
          </StyledIcon>
        )}
      </Box>
    </StyledButton>
  )
);

Button.displayName = "Button";
