import { FC } from "react";
import { InfoBoxProps } from "./InfoBox.d";
import { ReactComponent as InfoIcon } from "assets/icons/info-circle.svg";
import {
  StyledWarningContent,
  StyledWarningContentBox,
  WarningBox,
} from "./InfoBox.styles";
import { Typography } from "components/Typography";

export const InfoBox: FC<InfoBoxProps> = ({ header, description }) => {
  return (
    <WarningBox
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      px={3.1}
      py={2.8}
    >
      <InfoIcon />
      <StyledWarningContentBox>
        {header && (
          <Typography variant="body" weight="semibold">
            {header}
          </Typography>
        )}
        {description && (
          <StyledWarningContent variant="body">
            {description}
          </StyledWarningContent>
        )}
      </StyledWarningContentBox>
    </WarningBox>
  );
};
