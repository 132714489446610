import { Header } from "components/ApplyForMembership/Header";
import Layout from "components/Layout";
import { FC, useEffect, useState } from "react";
import {
  StyledHeader,
  StyledContentContainer,
  StyledSubtitleContainer,
  StyledFilesListContainer,
  StyledImageContainer,
  StyledFileName,
  StyledFileSize,
  StyledRemoveIconContainer,
  StyledButton,
} from "./ApplyForMembership.styled";
import { Footer } from "components/ApplyForMembership/Footer";
import { Box, Typography } from "components";
import { ConnectionsSearch } from "components/ApplyForMembership/ConnectionsSearch";
import { Textarea } from "components/Textarea";
import { Dropzone } from "components/ApplyForMembership/Dropzone";
import { Image } from "components/Image";
import { ReactComponent as DeleteIcon } from "assets/icons/icon-trash.svg";
import { COLORS } from "theme";
import { formatFileSize } from "utils/formatFileSize";
import { ConfirmSendingRequest } from "components/ApplyForMembership/ConfirmSendingRequest";
import { Label } from "components/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApplyForMembershipForm } from "types/friend";
import { yupResolver } from "@hookform/resolvers/yup";
import { formValidationSchema } from "./validators";
import { isValidForm } from "utils/isValidForm";
import { getLanguage } from "utils/getLanguage";
import { useProfile } from "context/Profile";
import translation from "./translation.json";
import { urlToFile } from "utils/urlToFile";
import { fetchApplyForMembership } from "services/ApplyForMembership";
import { toast } from "react-toastify";
import { fetchUpdateProfile } from "services/Profile";

export const ApplyForMembership: FC = () => {
  const profile = useProfile();
  const appliedForMembership =
    profile?.profileInfo?.applied_for_membership || false;
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const [searchOpen, setSearchOpen] = useState(false);
  const [image, setImage] = useState<{
    path: string;
    name: string;
    size: string;
  } | null>(null);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isAvatarUpdated, setIsAvatarUpdated] = useState(false);
  const [selectedHouseOwners, setSelectedHouseOwners] = useState<string[]>([]);
  const [step, setStep] = useState(1);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
  } = useForm<ApplyForMembershipForm>({
    resolver: yupResolver(formValidationSchema),
    mode: "onTouched",
  });

  const handleFileUpload = async (filesToUpload: File[]) => {
    const [file] = filesToUpload;
    setImage({
      path: URL.createObjectURL(file),
      name: file.name,
      size: formatFileSize(file.size),
    });
    setValue("avatar", file);
    setIsAvatarUpdated(true);
  };

  const removeAvatar = () => {
    setImage(null);
    setValue("avatar", null);
  };

  const houseOwnerChange = (values: string[]) => {
    setSelectedHouseOwners(values);
    setValue("houseOwners", values);
    trigger("houseOwners");
  };

  const sendForm = async (data: ApplyForMembershipForm) => {
    const response = await fetchApplyForMembership({
      acceptors: data.houseOwners,
      message: data.tellUsAboutYourself,
    });
    if (response.ok) {
      toast.success(translation["requestSendSuccess"][selectedLanguage]);
      setIsFormSent(true);
    } else {
      toast.error(translation["sendRequestError"][selectedLanguage]);
    }
  };

  const onSubmit: SubmitHandler<ApplyForMembershipForm> = async (data) => {
    if (isAvatarUpdated) {
      if (data.avatar) {
        const profileResponse = await fetchUpdateProfile(
          {
            name: profile?.profileInfo?.name,
            last_name: profile?.profileInfo?.last_name,
            profile: { avatar: data.avatar },
          },
          true
        );
        if (profileResponse.ok) {
          toast.success(translation["avatarUpdateSuccess"][selectedLanguage]);
          sendForm(data);
        } else {
          toast.error(translation["avatarUpdateError"][selectedLanguage]);
        }
      }
    } else {
      sendForm(data);
    }
  };

  useEffect(() => {
    const profileAvatarUrl = profile?.profileInfo?.profile.avatar;
    const fileName =
      profileAvatarUrl?.substring(profileAvatarUrl?.lastIndexOf("/") + 1) || "";
    const mimeType = "image/jpeg";
    profileAvatarUrl &&
      urlToFile(profileAvatarUrl, fileName, mimeType).then((file) => {
        if (file) {
          setValue("avatar", file);
          setImage({
            path: profileAvatarUrl,
            name: file.name,
            size: formatFileSize(file.size),
          });
        }
      });
  }, [profile]);

  useEffect(() => {
    if (!searchOpen && selectedHouseOwners.length !== 0) {
      setStep(2);
    }
  }, [searchOpen]);

  return (
    <Layout>
      <Header>
        <StyledHeader variant="h1">
          {translation["invitationMessage"][selectedLanguage]}
        </StyledHeader>
      </Header>
      <StyledContentContainer px={3} pt={5} pb={18}>
        {isFormSent || appliedForMembership ? (
          <ConfirmSendingRequest
            title={translation["requestSent"][selectedLanguage]}
            description={
              translation["requestApprovalMessage"][selectedLanguage]
            }
          />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <StyledSubtitleContainer mb={4.5}>
              <Typography variant="h3">
                {translation["friendCheckMessage"][selectedLanguage]}
              </Typography>
            </StyledSubtitleContainer>
            <ConnectionsSearch
              menuOpen={searchOpen}
              setMenuOpen={setSearchOpen}
              selectedHouseOwners={selectedHouseOwners}
              setSelectedHouseOwners={houseOwnerChange}
            />
            {!isValidForm(errors) && !!errors.houseOwners?.message && (
              <Label error>{errors.houseOwners?.message}</Label>
            )}
            {step == 2 && (
              <>
                <Box pt={5} pb={3}>
                  <Textarea
                    required
                    label={translation["tellUsAboutYourself"][selectedLanguage]}
                    placeholder={
                      translation["exampleTellUsAboutYourself"][
                        selectedLanguage
                      ]
                    }
                    rows={3}
                    helperText={
                      translation["minCharactersValue"][selectedLanguage]
                    }
                    error={
                      !isValidForm(errors) &&
                      errors.tellUsAboutYourself?.message
                    }
                    {...register("tellUsAboutYourself")}
                  />
                </Box>
                <Dropzone
                  required
                  label={translation["uploadProfilePicture"][selectedLanguage]}
                  error={!isValidForm(errors) && errors.avatar?.message}
                  handleFileSelection={handleFileUpload}
                />
                {image && (
                  <StyledFilesListContainer
                    pt={2}
                    display="grid"
                    gridGap={1}
                    gridAutoFlow="column"
                  >
                    <StyledImageContainer
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pl={1}
                      pr={2}
                      py={1}
                    >
                      <Box display="flex" alignItems="center">
                        <Image src={image.path} variant="avatarPreview" />
                        <StyledFileName variant="body" weight="medium">
                          {image.name}
                        </StyledFileName>
                      </Box>
                      {image.size && (
                        <StyledFileSize variant="body">
                          {image.size}
                        </StyledFileSize>
                      )}
                    </StyledImageContainer>
                    <StyledRemoveIconContainer onClick={removeAvatar}>
                      <DeleteIcon
                        stroke={COLORS.typography.body}
                        strokeWidth={1.5}
                        fill="none"
                      />
                    </StyledRemoveIconContainer>
                  </StyledFilesListContainer>
                )}
              </>
            )}
            <StyledButton type="submit">
              {translation["sendRequest"][selectedLanguage]}
            </StyledButton>
          </form>
        )}
      </StyledContentContainer>
      {step === 1 && <Footer />}
    </Layout>
  );
};
