import styled from "styled-components";
import { COLORS } from "theme";

export const StyledDoneIconContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${COLORS.accent.primary};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledRemoveButton = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: none;
  background-color: ${COLORS.stroke.focus};

  display: flex;
  align-items: center;
  justify-content: center;

  &:enabled {
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.accent.primary};
    }
  }

  & svg {
    path {
      stroke: ${COLORS.background.main};
    }
  }
`;
