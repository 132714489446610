import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS } from "theme";
import { Box, Typography } from "components";

interface TypographyExtended {
  extended: boolean;
}

export const ToggleButton = styled(Box)`
  color: ${COLORS.accent.primary};
  margin-top: ${pxToRem(16)};

  svg {
    margin: ${pxToRem(4)};
    fill: ${COLORS.accent.primary};
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  .icon-more {
    transform: rotate(90deg);
    transition: 0.2s ease-in-out;
  }

  .icon-less {
    transform: rotate(0);
    transition: 0.2s ease-in-out;
  }
`;

export const StyledContainer = styled(Box)`
  height: fit-content;
`;
export const StyledTypography = styled(Typography)<TypographyExtended>`
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  white-space: pre-wrap;
  word-break: break-word;
  width: 100%;

  ${({ extended }) =>
    !extended &&
    `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
`};
`;
