import { Box } from "components";
import { FC } from "react";
import {
  IndicatorContainer,
  UnreadIndicator,
  UnreadIndicatorTypography,
} from "./Notifications.styled";

interface Props {
  unreadCount: number;
}

const UnreadCounterIndicator: FC<Props> = ({ unreadCount }) => {
  return unreadCount !== 0 ? (
    <UnreadIndicator justifyContent="center" alignItems="center">
      <IndicatorContainer>
        <UnreadIndicatorTypography variant="overline">
          {unreadCount > 9 ? "9+" : unreadCount}
        </UnreadIndicatorTypography>
      </IndicatorContainer>
    </UnreadIndicator>
  ) : null;
};

export default UnreadCounterIndicator;
