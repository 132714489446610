import { Box, Typography } from "components";
import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const Container = styled(Box)`
  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const StyledUploadContainer = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const StyledImageContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
  justify-items: center;
  position: relative;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  right: 6px;
  top: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: ${pxToRem(10)} 0 0 0;
  width: 44px;
  height: 44px;

  &:enabled {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const ImageContainer = styled(Box)`
  position: relative;
`;
