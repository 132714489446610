import { FC } from "react";
import { Box } from "components";
import { ExternalLink } from "components/Link";
import { Typography } from "components/Typography";
import { getLanguage } from "utils/getLanguage";
import translation from "./definition_list_translation.json";
import { useProfile } from "context/Profile";

const Account: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["account"][selectedLanguage]} </strong>
      {translation["accountDefinition"][selectedLanguage]}
    </Typography>
  );
};

const Company: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["companyHeader"][selectedLanguage]} </strong>
      {translation["company"][selectedLanguage]}
    </Typography>
  );
};

const Cookies: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["cookiesHeader"][selectedLanguage]} </strong>
      {translation["cookies"][selectedLanguage]}
    </Typography>
  );
};

const Country: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["countryHeader"][selectedLanguage]} </strong>
      {translation["country"][selectedLanguage]}
    </Typography>
  );
};

const Device: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["deviceHeader"][selectedLanguage]} </strong>
      {translation["device"][selectedLanguage]}
    </Typography>
  );
};

const PersonalData: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["personalHeader"][selectedLanguage]} </strong>
      {translation["personalData"][selectedLanguage]}
    </Typography>
  );
};
const Service: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["serviceHeader"][selectedLanguage]} </strong>
      {translation["service"][selectedLanguage]}
    </Typography>
  );
};

const ServiceProvider: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["serviceProviderHeader"][selectedLanguage]} </strong>
      {translation["serviceProvider"][selectedLanguage]}
    </Typography>
  );
};

const Usage: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["usageDataHeader"][selectedLanguage]} </strong>
      {translation["usageData"][selectedLanguage]}
    </Typography>
  );
};

const Website: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <Box>
        <strong>{translation["websiteHeader"][selectedLanguage]} </strong>
        {translation["website"][selectedLanguage]}
      </Box>
      <ExternalLink href="https://houseoffriends.world/" linkColor="black">
        https://houseoffriends.world/
      </ExternalLink>
    </Typography>
  );
};

const It: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Typography variant="body">
      <strong>{translation["youHeader"][selectedLanguage]} </strong>
      {translation["you"][selectedLanguage]}
    </Typography>
  );
};

export const DefinitionList = [
  <Account key="account" />,
  <Company key="company" />,
  <Cookies key="cookies" />,
  <Country key="country" />,
  <Device key="device" />,
  <PersonalData key="personal-data" />,
  <Service key="service" />,
  <ServiceProvider key="service-provider" />,
  <Usage key="usage" />,
  <Website key="website" />,
  <It key="it" />,
];
