import styled, { css } from "styled-components";
import { COLORS } from "../../theme";
import { Link } from "react-router-dom";
import { Box } from "../Box";

type StaticElementType = {
  isLastPage: boolean;
};
const breadcrumb = css`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-decoration: none;
  text-transform: capitalize;
`;

export const BreadcrumbLink = styled(Link)`
  ${breadcrumb};
  color: ${COLORS.accent.primary};
`;

export const BreadcrumbStaticElement = styled(Box)<StaticElementType>(
  ({ isLastPage = false }) => css`
    ${breadcrumb};
    color: ${!isLastPage ? COLORS.accent.primary : "black"};
  `
);
