import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS } from "theme";
import { LabelProps } from "./Label.d";

export const StyledLabel = styled.label<LabelProps>`
  ${({ error, disabled, required }) => css`
    position: relative;
    font-size: ${pxToRem(10)};
    color: ${error ? COLORS.stroke.error : COLORS.typography.body};
    font-family: "Inter", sans-serif;
    text-transform: uppercase;
    ${disabled && "opacity: 0.5"}

    ${required &&
    css`
      &::after {
        content: " *";
        color: ${COLORS.error.main};
        position: absolute;
        top: -2px;
        padding-left: ${pxToRem(3)};
      }
    `}
  `}
`;
