import styled from "styled-components";
import { COLORS } from "theme";
import { Box } from "components/Box";
import { StaticItemType } from "./MutualFriends.d";

export const StyledItemContainer = styled(Box)<StaticItemType>`
  position: relative;
  ${({ withBorder }) =>
    withBorder && `border: 1px solid ${COLORS.stroke.main}`};
  border-radius: 50%;
  overflow: hidden;
  width: 17px;
  height: 17px;
  margin-left: -4px;

  &:first-child {
    width: 19px;
    height: 19px;
  }
`;

export const RestFriendsNumber = styled(Box)`
  box-sizing: border-box;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-size: 7px;
  font-family: "Inter", sans-serif;
`;
