import Notification from "./Notification";
import { FC, RefObject, useEffect } from "react";
import { Box } from "components/Box";
import { useNotifications } from "context/Notifications";

interface Props {
  container?: RefObject<HTMLDivElement>;
}

const NotificationsList: FC<Props> = ({ container }) => {
  const {
    notifications,
    fetchUserNotifications,
    setNotificationsStatusToRead,
  } = useNotifications();

  const handleScrollToBottom = async () => {
    if (container?.current) {
      if (
        container.current.scrollTop + container.current.offsetHeight ===
        container.current.scrollHeight
      ) {
        await fetchUserNotifications();
      }
    } else if (
      window.innerHeight + window.scrollY ===
      document.body.offsetHeight
    ) {
      await fetchUserNotifications();
    }
  };

  useEffect(() => {
    setNotificationsStatusToRead();
    if (container?.current) {
      container.current.addEventListener("scroll", handleScrollToBottom);
      return () => {
        if (container?.current)
          container.current.removeEventListener("scroll", handleScrollToBottom);
      };
    } else {
      window.addEventListener("scroll", handleScrollToBottom);
      return () => {
        window.removeEventListener("scroll", handleScrollToBottom);
      };
    }
  }, [notifications, container]);

  return (
    <Box display="grid" gridGap={2}>
      {!!notifications.length &&
        notifications.map((notification) => {
          return (
            <Notification notification={notification} key={notification.id} />
          );
        })}
    </Box>
  );
};

export default NotificationsList;
