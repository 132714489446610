import { Box } from "components/Box";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr;

  ${SIZES.media.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
  }
`;
