import styled from "styled-components";
import { COLORS } from "theme";

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

export const ListOption = styled.li`
  align-items: center;
  color: ${COLORS.typography.main};
  display: flex;
  list-style: none;
  margin: 0.375rem 0;
  min-height: 1.5rem;
  padding: 0;
`;

export const ListBulletIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.25rem;
  flex-shrink: 0;
  align-self: start;

  svg {
    height: 100%;
    width: 100%;
    fill: ${COLORS.accent.primary};
  }
`;
