export const FRONTEND_HOST =
  window.location.protocol + "//" + window.location.host;

export const MAIN_PAGE = "/";

export const LOGIN_PAGE = "/login";

export const REGISTER = "/register";

export const CONFIRM_INVITATION_PAGE = "/invite-friend/:tokenId";

export const HOUSES_PAGE = "/houses";

export const HOUSES_EXAMPLE_PAGE = "/houses/example";

export const HOUSE_EXAMPLE_PAGE = "/houses/example/:id";

export const FORGOT_PASSWORD = "/forgot-password";

export const HOUSE_PAGE = "/houses/:id";

export const HOUSE_EDIT_PAGE = "/houses/:id/edit";

export const HOUSE_REDIRECT = (id: string) => `/houses/${id}`;

export const HOUSE_EDIT_PAGE_REDIRECT = (id: string) => `/houses/${id}/edit`;

export const FRIENDS_REDIRECT = "/my-friends?filter=connections";

export const HOUSE_PHOTOS_PAGE = `${HOUSE_PAGE}/photos`;

export const HOUSE_PHOTOS_PAGE_REDIRECT = (id: string) =>
  `/houses/${id}/photos`;

export const FAVORITES_PAGE = "/favorites";

export const BOOKINGS_PAGE = "/bookings";

export const FRIENDS_PAGE = "/my-friends";

export const PROFILE_PAGE = "/my-profile";

export const MY_HOUSE_PAGE = "/my-house";

export const RESERVATIONS_PAGE = "/reservations";

export const NOTIFICATIONS_PAGE = "/notifications";

export const COMPANY_IMPRINT_PAGE = "/company-imprint";

export const HOW_IT_WORKS = "/how-it-works";

export const TERMS_AND_CONDITIONS_PAGE = "/terms-and-conditions";

export const CONTACT_US = "/contact-us-public";

export const PRIVACY_POLICY_PAGE = "/privacy-policy";

export const CONTACT_PAGE = "/contact";

export const HOUSE_APPLICATION_PAGE = "/house-application/:token";

export const HOUSE_APPLICATION_PAGE_OWNER = "/house-application";

export const USER_ACTIVATION_PAGE = "/activate-account";

export const INVITE_NEW_OWNER_PAGE = "/invite-new-owner";

export const RESET_PASSWORD_PAGE = "/reset-password";

export const REACTIVATE_INVITATION = "/confirm-invitation";

export const APPLY_FOR_MEMBERSHIP = "/apply-for-membership";
