import { forwardRef, useState } from "react";
import { SelectElement, StyledSelectArrowIcon } from "./Autocomplete.styled";
import { AutocompleteProps } from "./Autocomplete.d";
import { Box } from "components/Box";
import { Label } from "components/Label";
import { COLORS } from "theme";

export const Autocomplete = forwardRef(
  (
    {
      value,
      onChange,
      options,
      label,
      error,
      disabled = false,
      id,
      maxMenuHeight,
    }: AutocompleteProps,
    ref: React.Ref<any>
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
      <Box display="grid" gridGap={1.5}>
        {label && (
          <Label htmlFor={id} disabled={disabled} error={!!error}>
            {error ? error : label}
          </Label>
        )}
        <SelectElement
          ref={ref}
          value={value}
          menuIsOpen={isOpen}
          onChange={onChange}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          options={options}
          isDisabled={disabled}
          classNamePrefix="react-select"
          blurInputOnSelect={true}
          placeholder="Select"
          maxMenuHeight={maxMenuHeight}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <StyledSelectArrowIcon isOpen={isOpen} />,
          }}
          styles={{
            control: (base) => ({
              ...base,
              boxShadow: "none",
              "&:focus": {
                border: `1px solid ${
                  error ? COLORS.stroke.error : COLORS.stroke.main
                }`,
                boxShadow: "none",
              },
            }),
          }}
        />
      </Box>
    );
  }
);

Autocomplete.displayName = "Autocomplete";
