import { Box } from "components";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  margin: 24px 18px auto;
  color: white;
  text-align: center;

  ${SIZES.media.tablet} {
    margin: 24px 84px auto;
  }

  ${SIZES.media.desktop} {
    margin: 120px 390px auto;
  }
`;

export const StyledFormContainer = styled(Box)`
  margin: 40px auto 92px;
  background-color: white;
  color: black;
  text-align: left;

  ${SIZES.media.tablet} {
    max-width: 660px;
  }
`;

export const StyledContentContainer = styled(Box)`
  padding: 32px 14px;

  ${SIZES.media.tablet} {
    padding: 64px;
  }
`;

export const SizeContainer = styled(Box)`
  grid-auto-flow: row;

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
  }
`;

export const CheckboxContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ButtonContainer = styled(Box)`
  ${SIZES.media.tablet} {
    width: 237px;
  }
`;
