import { AuthProvider } from "context/Auth";
import { CalculatorProvider } from "context/Calculator";
import { NotificationsProvider } from "context/Notifications";
import { ProfileProvider } from "context/Profile";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <CalculatorProvider>
        <ProfileProvider>
          <NotificationsProvider>
            <App />
          </NotificationsProvider>
        </ProfileProvider>
      </CalculatorProvider>
    </AuthProvider>
  </React.StrictMode>
);
