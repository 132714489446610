import { pxToRem } from "global/utils";
import styled from "styled-components";
import { IconButton } from "components";
import { COLORS } from "theme";

export const AvatarIconButton = styled(IconButton)`
  margin: 0px ${pxToRem(6)};
  overflow: hidden;
  fill: ${COLORS.typography.icons};
`;
