import { pxToRem } from "global/utils";
import { css } from "styled-components";
import { COLORS, SIZES } from "theme";
import { FieldBasic } from "./common";

export const StyledFieldBasic = css<FieldBasic>`
  ${({ error }) => css`
    box-sizing: border-box;
    border: 1px solid ${error ? COLORS.stroke.error : COLORS.stroke.main};
    outline: none;
    padding: ${pxToRem(21.5)} ${pxToRem(18)};
    width: 100%;
    font-size: ${SIZES.typography.input};
    color: ${error ? COLORS.stroke.error : COLORS.typography.headline};
    font-family: "Inter", sans-serif;

    &:hover {
      border-color: ${COLORS.stroke.hover};
    }

    &:focus {
      border-color: ${COLORS.stroke.focus};
    }

    &:disabled {
      opacity: 0.5;
    }
  `}
`;
