import * as Yup from "yup";
import { AccessVariant } from "../InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";

export const profileBasicValidationSchema = Yup.object({
  phone_number: Yup.string()
    .max(20)
    .nullable(true)
    .required("Please, insert phone number"),
  country: Yup.string()
    .max(100)
    .nullable(true)
    .required("Please, insert country"),
  city: Yup.string().max(100).nullable(true).required("Please, insert city"),
  street: Yup.string().max(90).nullable(true).required("Please, insert street"),
  house: Yup.string().max(10).nullable(true).required("Please, insert house"),
  postal_code: Yup.string()
    .max(9)
    .nullable(true)
    .required("Please, insert postal code"),
  about: Yup.string()
    .nullable(true)
    .when("$accessType", {
      is: AccessVariant.ALL,
      then: Yup.string()
        .required("Please, insert information about you")
        .min(200, "must be at least 200 characters long")
        .max(1000),
      otherwise: Yup.string(),
    }),
});
