import { useWindowSize } from "global/hooks/useWindowSize";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";

import { DropdownProps, Position } from "./Dropdown.d";
import {
  BodyOverflow,
  CONSTS,
  DropdownContainer,
  StyledDropdown,
  transition,
} from "./Dropdown.styled";

const DropdownMenu: FC<DropdownProps> = ({
  open,
  onClose,
  anchorEl,
  fullWidth,
  fullHeight,
  withBorder = true,
  withShadow = false,
  withArrow = false,
  orientation = "right",
  verticalPosition = false,
  ...props
}) => {
  /* Used only to trigger position update on window resize. since the hook only updates if open,
    actual window size is still taken directly from window object, to make sure it's the latest */
  const windowSize = useWindowSize(open);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<Position>({} as Position);
  const [isOpen, setIsOpen] = useState(false);

  const handleContainerClick = (event: MouseEvent) => {
    if (event.target === containerRef.current) {
      onClose();
    }
  };

  const getPosition = (): Position => {
    if (!anchorEl.current || !innerRef.current) {
      return {} as Position;
    }

    const {
      x: anchorX,
      y: anchorY,
      width: anchorWidth,
      height: anchorHeight,
    } = anchorEl.current.getBoundingClientRect();
    const { width, height } = innerRef.current.getBoundingClientRect();

    /* Vertical */
    const selfHeight = height / (isOpen ? CONSTS.scale : 1) - CONSTS.border;
    const overflowY =
      !fullHeight &&
      anchorY + anchorHeight + selfHeight - CONSTS.border > window.innerHeight;
    const overflowX =
      !fullWidth &&
      (anchorX + width - CONSTS.border > window.innerWidth ||
        anchorX - width < 0);

    const getPositionY = () => {
      if (verticalPosition === "bottom") {
        return anchorY + anchorHeight - CONSTS.border;
      }

      if (verticalPosition === "top") {
        return anchorY - selfHeight;
      }

      return anchorY + anchorHeight - CONSTS.border;
    };

    /* Horizontal */
    const selfWidth = width;
    const canRight = anchorX + selfWidth <= window.innerWidth;
    const canLeft = anchorX + anchorWidth - selfWidth >= 0;

    const getPositionX = () => {
      /* Right */
      if (
        canRight &&
        (orientation === "right" || (orientation === "left" && !canLeft))
      ) {
        return anchorX;
      }

      /* Left */
      if (
        canLeft &&
        (orientation === "left" || (orientation === "right" && !canRight))
      ) {
        return anchorX + anchorWidth - selfWidth;
      }
      /* Center */
      if (overflowX && canLeft) {
        return anchorX - selfWidth + anchorWidth;
      }

      if (overflowX && canRight) {
        return anchorX - anchorWidth;
      }
      return anchorX + anchorWidth / 2 - selfWidth / 2;
    };

    const getArrowVariant = () => {
      if (overflowX && canLeft) {
        return "right";
      }

      if (overflowX && canRight) {
        return "left";
      }
      return false;
    };

    return {
      x: `${getPositionX()}px`,
      y: `${getPositionY()}px`,
      minWidth: fullWidth ? `${anchorWidth}px` : null,
      bottom: fullHeight && 0,
      transformOrigin: overflowY ? "bottom" : "top",
      overflowArrowVariant: getArrowVariant(),
    };
  };

  useEffect(() => {
    setPosition((currentPosition) => {
      const newPosition = getPosition();

      /* Update position only when open and something about it changes */
      const requiresUpdate =
        isOpen &&
        Object.entries(newPosition).some(
          ([key, value]) =>
            new Map(Object.entries(currentPosition)).get(key) !== value
        );

      return requiresUpdate ? newPosition : currentPosition;
    });
  }, [isOpen, windowSize, fullWidth, fullHeight]);

  /* Extra state required to trigger animation by rerender on mount. */
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <DropdownContainer
      ref={containerRef}
      open={isOpen}
      onClick={handleContainerClick}
    >
      <StyledDropdown
        ref={innerRef}
        open={isOpen}
        position={position}
        withBorder={withBorder}
        withShadow={withShadow}
        withArrow={withArrow}
        {...props}
      />
    </DropdownContainer>
  );
};

export const Dropdown: FC<DropdownProps> = ({ open, ...props }) => {
  const [mounted, setMounted] = useState(false);

  /* Ensures that modal window will be unmounted after the animation end, to not litter the DOM. */
  useEffect(() => {
    setTimeout(() => setMounted(open), open ? 0 : transition);
  }, [open]);

  return (
    <>
      {(open || mounted) && <DropdownMenu open={open} {...props} />}
      <BodyOverflow open={open} />
    </>
  );
};
