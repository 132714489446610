import * as Yup from "yup";

export const activationValidationSchema = Yup.object({
  name: Yup.string().max(200),
  last_name: Yup.string().max(200),
  email: Yup.string().email(),
  password: Yup.string().required("Password is a required field").min(6),
  confirm_password: Yup.string()
    .required("Confirm password is a required field")
    .test(
      "doPasswordsMatch",
      "Passwords do not match. Try again",
      function (value) {
        const password = this.parent["password"];
        return password === "" || value === password;
      }
    ),
});
