import { Box, Typography } from "components";
import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { FC, useEffect } from "react";
import {
  FormBox,
  LineInputBox,
  PhoneTypography,
  SendButton,
  StyledBox,
  SubHeader,
} from "./ContactUs.styled";
import { ReactComponent as PhoneIcon } from "assets/icons/icon-phone.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { Input } from "components/Input";
import { Textarea } from "components/Textarea";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { COLORS } from "theme";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ContactUsForm } from "types/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactUsValidationSchema } from "./validators";
import { isValidForm } from "utils/isValidForm";
import { contactUsRequest } from "services/ContactUs";
import { toast } from "react-toastify";
import { Profile, useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const ContactUs: FC = () => {
  const methods = useForm<ContactUsForm>({
    resolver: yupResolver(ContactUsValidationSchema),
    mode: "onSubmit",
  });
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const assignMainData = (profile: Profile) => {
    setValue("sender_email", profile.profileInfo?.email ?? "");
    setValue(
      "sender_name",
      `${profile.profileInfo?.name} ${profile.profileInfo?.last_name}` ?? ""
    );
  };
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods;

  const onSubmit: SubmitHandler<ContactUsForm> = async (data) => {
    const form: ContactUsForm = {
      ...data,
    };
    try {
      await contactUsRequest(form);
      toast.success(translation["formSendSuccess"][selectedLanguage]);
      reset();
    } catch (error) {
      toast.error(translation["requestSendingError"][selectedLanguage]);
    }
  };

  useEffect(() => {
    if (profile) {
      assignMainData(profile);
    }
  }, [profile]);

  return (
    <Layout>
      <StyledBox mx={10} my={2}>
        <Breadcrumbs currentPageTitle="Contact us" />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          mt={10}
        >
          <Typography variant={"h1"}>
            {translation["contactUsHeader"][selectedLanguage]}
          </Typography>
          <SubHeader variant={"body"}>
            {translation["contactUs"][selectedLanguage]}
          </SubHeader>
          <FormBox>
            <Typography variant={"h4"}>
              {translation["byPhone"][selectedLanguage]}
            </Typography>
            <Box display={"flex"} alignItems="center" mt={3} mb={5}>
              <PhoneIcon />
              <PhoneTypography href={"tel:+491746916019"}>
                + 49 174 691 60 19
              </PhoneTypography>
            </Box>
            <Box>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Typography variant={"h4"}>
                    {translation["useForm"][selectedLanguage]}
                  </Typography>
                  <LineInputBox display={"flex"} my={3}>
                    <Input
                      label={"Your e-mail"}
                      type={"email"}
                      error={
                        !isValidForm(errors) && errors.sender_email?.message
                      }
                      {...register("sender_email")}
                    />
                    <Input
                      label={"Your name"}
                      error={
                        !isValidForm(errors) && errors.sender_name?.message
                      }
                      {...register("sender_name")}
                    />
                  </LineInputBox>
                  <Textarea
                    label={"your message"}
                    rows={6}
                    error={!isValidForm(errors) && errors.content?.message}
                    {...register("content")}
                  />
                  <SendButton
                    icon={
                      <ArrowIcon
                        stroke={COLORS.stroke.main}
                        fill="none"
                        width="24px"
                        height="24px"
                      />
                    }
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.SMALL}
                  >
                    {translation["sendMessage"][selectedLanguage]}
                  </SendButton>
                </form>
              </FormProvider>
            </Box>
          </FormBox>
        </Box>
      </StyledBox>
    </Layout>
  );
};
