import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { FC } from "react";
import { HeaderContainer, MainContainer } from "./Profile.styled";
import { ProfileForm } from "components/Profile/ProfileForm";
import { Tabs } from "components/Tabs";
import { TabsVariant } from "components/Tabs/Tabs.enums";
import { PasswordForm } from "components/Profile/PasswordForm";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";

export const Profile: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const tabs = [
    {
      label: translation["personal"][selectedLanguage],
      content: <ProfileForm />,
    },
    {
      label: translation["password"][selectedLanguage],
      content: <PasswordForm />,
    },
  ];

  return (
    <Layout>
      <MainContainer variant="div" mx={2.2} my={4}>
        <Breadcrumbs currentPageTitle="Profile" />
        <HeaderContainer variant="h1">
          {translation["myProfile"][selectedLanguage]}
        </HeaderContainer>
        <Tabs variant={TabsVariant.LARGE} tabs={tabs} />
      </MainContainer>
    </Layout>
  );
};
