import { Box } from "components/Box";
import { FC, useEffect, useState } from "react";
import {
  cancelReservation,
  fetchBookings,
  payBalance,
  payDeposit,
} from "services/Bookings";
import { Booking } from "types/booking";
import { BookingListItem } from "../BookingListItem";

interface Props {
  filter: "upcoming" | "past" | "cancelled";
}

const BookingList: FC<Props> = ({ filter }) => {
  const [bookings, setBookings] = useState<Array<Booking>>([]);
  useEffect(() => {
    const getBookings = async () => {
      const response = await fetchBookings(filter);
      setBookings(response.parsedBody);
    };
    getBookings();
  }, [filter]);

  const handleCancelByUserReservation = async (id: string) => {
    const response = await cancelReservation(id);
    if (response.status === 200) {
      const updatedBooking = bookings.filter((booking) => {
        return booking.id !== id;
      });
      setBookings(updatedBooking);
    }
  };

  const handlePayBalance = async (id: string) => {
    const response = await payBalance(id);
    if (response.status === 200) {
      const link = response?.parsedBody?.link;
      window.location.replace(link);
    }
  };

  const handlePayDeposit = async (id: string) => {
    const response = await payDeposit(id);
    if (response.status === 200) {
      const link = response?.parsedBody?.link;
      window.location.replace(link);
    }
  };

  return (
    <Box>
      {bookings.map((booking) => {
        return (
          <BookingListItem
            id={booking.id}
            house_id={booking.house_id}
            cancellation_fee={booking.cancellation_fee}
            is_cancellable={booking.is_cancellable}
            is_editable={booking.is_editable}
            check_in_date={booking.check_in_date}
            check_out_date={booking.check_out_date}
            adults_num={booking.adults}
            children_num={booking.children}
            main_photo={booking.house_image}
            title={booking.house_title}
            price={booking.price}
            price_without_discount={booking.price_without_discount}
            booking_status={booking.status}
            key={booking.id}
            can_pay_deposit={booking.can_pay_deposit}
            can_pay_balance={booking.can_pay_balance}
            cancelByUser={handleCancelByUserReservation}
            payBalance={handlePayBalance}
            payDeposit={handlePayDeposit}
            extras={booking.extras}
          />
        );
      })}
    </Box>
  );
};

export default BookingList;
