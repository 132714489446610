import { Typography } from "components/Typography";
import { Link } from "components/Link";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";

export const PaginationSeparator = styled(Typography)`
  padding: ${SIZES.spacing(1.5)};
`;

export const NavigationButton = styled(Link)`
  padding: ${pxToRem(11)} ${pxToRem(13)};
  border: 1px solid ${COLORS.stroke.main};
`;
