import { ButtonSize } from "components/Button/Button.enums";
import { FC } from "react";
import { NotificationType } from "types/notification";
import {
  ButtonsContainer,
  NotificationButton,
  NotificationText,
} from "./Notifications.styled";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { Language } from "types/language";

interface NotificationMessageProps {
  inviter: string;
  accepted: boolean;
  invitee: string;
  house: string;
  checkInDate: string;
  checkOutDate: string;
  guest: string;
  bookingCode: number;
  selectedLanguage: Language;
  handleAcceptButtonClick: () => void;
}

const InvitationAcceptedRegisteredUserMessage: FC<NotificationMessageProps> = ({
  inviter,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["youHaveANewFriend"][selectedLanguage]}
      <em>{inviter}</em>
    </NotificationText>
  );
};

const InvitationReceivedMessage: FC<NotificationMessageProps> = ({
  inviter,
  handleAcceptButtonClick,
  selectedLanguage,
}) => {
  return (
    <>
      <NotificationText variant="body">
        {translation["youHaveANewFriendshipRequest"][selectedLanguage]}
        <em>{inviter}</em>
      </NotificationText>
      <ButtonsContainer display="grid" gridAutoFlow="column">
        <NotificationButton
          size={ButtonSize.SMALL}
          onClick={handleAcceptButtonClick}
        >
          {translation["accept"][selectedLanguage]}
        </NotificationButton>
        <NotificationButton size={ButtonSize.SMALL}>
          {translation["ignore"][selectedLanguage]}
        </NotificationButton>
      </ButtonsContainer>
    </>
  );
};

const InvitationReceivedAccessUpgradeMessage: FC<NotificationMessageProps> = ({
  inviter,
  handleAcceptButtonClick,
  selectedLanguage,
}) => {
  return (
    <>
      <NotificationText variant="body">
        {translation["youHaveANewFriendshipRequest"][selectedLanguage]}
        <em>{inviter}</em>
        {translation["thatGivesYouAccessToAllHouses"][selectedLanguage]}
      </NotificationText>
      <ButtonsContainer display="grid" gridAutoFlow="column">
        <NotificationButton
          size={ButtonSize.SMALL}
          onClick={handleAcceptButtonClick}
        >
          {translation["accept"][selectedLanguage]}
        </NotificationButton>
        <NotificationButton size={ButtonSize.SMALL}>
          {translation["ignore"][selectedLanguage]}
        </NotificationButton>
      </ButtonsContainer>
    </>
  );
};

const InvitationAcceptedInviterdMessage: FC<NotificationMessageProps> = ({
  invitee,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      <em>{invitee}</em>{" "}
      {translation["acceptedYourFriendshipRequest"][selectedLanguage]}
    </NotificationText>
  );
};

const InvitationReceivedSelectedMessage: FC<NotificationMessageProps> = ({
  inviter,
  handleAcceptButtonClick,
  selectedLanguage,
}) => {
  return (
    <>
      <NotificationText variant="body">
        {translation["youHaveANewFriendshipRequest"][selectedLanguage]}
        <em>{inviter}</em>
        {translation["thatGivesYouAccessTo"][selectedLanguage]}
        {inviter}
        {translation["youHaveANewFriendshipRequest"][selectedLanguage]}
      </NotificationText>
      <ButtonsContainer display="grid" gridAutoFlow="column">
        <NotificationButton
          size={ButtonSize.SMALL}
          onClick={handleAcceptButtonClick}
        >
          {translation["accept"][selectedLanguage]}
        </NotificationButton>
        <NotificationButton size={ButtonSize.SMALL}>
          {translation["ignore"][selectedLanguage]}
        </NotificationButton>
      </ButtonsContainer>
    </>
  );
};

const HouseAccessUpgraded: FC<NotificationMessageProps> = ({
  inviter,
  handleAcceptButtonClick,
  selectedLanguage,
}) => {
  return (
    <>
      <NotificationText variant="body">
        {translation["updateOn"][selectedLanguage]}
        <em>{inviter}</em>
        {translation["friendshipRequestAccessHouses"][selectedLanguage]}
      </NotificationText>
      <ButtonsContainer display="grid" gridAutoFlow="column">
        <NotificationButton
          size={ButtonSize.SMALL}
          onClick={handleAcceptButtonClick}
        >
          {translation["accept"][selectedLanguage]}
        </NotificationButton>
        <NotificationButton size={ButtonSize.SMALL}>
          {translation["ignore"][selectedLanguage]}
        </NotificationButton>
      </ButtonsContainer>
    </>
  );
};

const HouseAccessDowngraded: FC<NotificationMessageProps> = ({
  inviter,
  handleAcceptButtonClick,
  selectedLanguage,
}) => {
  return (
    <>
      <NotificationText variant="body">
        {translation["updateOn"][selectedLanguage]}
        <em>{inviter}</em>
        {translation["friendshipRequestAccess"][selectedLanguage]}
        <em>{inviter}</em>
        {translation["personHouses"][selectedLanguage]}
      </NotificationText>
      <ButtonsContainer display="grid" gridAutoFlow="column">
        <NotificationButton
          size={ButtonSize.SMALL}
          onClick={handleAcceptButtonClick}
        >
          {translation["accept"][selectedLanguage]}
        </NotificationButton>
        <NotificationButton size={ButtonSize.SMALL}>
          {translation["ignore"][selectedLanguage]}
        </NotificationButton>
      </ButtonsContainer>
    </>
  );
};

const AccessTypeChangeFromSelectedToAll: FC<NotificationMessageProps> = ({
  inviter,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      <em>{inviter}</em>
      {translation["accessToAllHouses"][selectedLanguage]}
    </NotificationText>
  );
};

const BookingRequested: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["newBookingRequest"][selectedLanguage]}
      <em>{house}</em>
      {translation["bookingPeriod"][selectedLanguage]}
      <strong>
        {checkInDate} – {checkOutDate}
      </strong>
      .
    </NotificationText>
  );
};

const BookingRequestedReminder: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["bookingRequestReminder"][selectedLanguage]}
      <em>{house}</em>.
    </NotificationText>
  );
};

const BookingPaid: FC<NotificationMessageProps> = ({
  guest,
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["thankYouForAccepting"][selectedLanguage]}
      <em>{guest}'s</em>
      {translation["houseRequest"][selectedLanguage]}
      <em>{house}</em>
      {translation["houseRentedFrom"][selectedLanguage]}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
      !
    </NotificationText>
  );
};

const OwnerRentIncoming: FC<NotificationMessageProps> = ({
  guest,
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["reminder"][selectedLanguage]}
      <em>{guest}</em>
      {translation["houseReserved"][selectedLanguage]}
      <em>{house}</em>
      {translation["from"][selectedLanguage]}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
      {translation["rentalPeriodStarts"][selectedLanguage]}
    </NotificationText>
  );
};

const BookingCancelledByTenant: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["houseReservation"][selectedLanguage]}
      <em>{house}</em>
      {translation["from"][selectedLanguage]}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>{" "}
      {translation["reservationCancelled"][selectedLanguage]}
    </NotificationText>
  );
};

const BookingCancelledByAdminWithoutDeposit: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["requestCancellationMissingDeposit"][selectedLanguage]}
    </NotificationText>
  );
};

const NewBookingEdited: FC<NotificationMessageProps> = ({
  house,
  guest,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["updatedReservationInquiry"][selectedLanguage]}
      <em>{house}</em>
      {translation["reservationEdited"][selectedLanguage]}
      <em>{guest}</em>
    </NotificationText>
  );
};

const ApprovedBookingEdited: FC<NotificationMessageProps> = ({
  house,
  guest,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["approvedBookingRequest"][selectedLanguage]}
      <em>{house}</em>
      {translation["reservationEditedByGuest"][selectedLanguage]}
      <em>{guest}</em>
    </NotificationText>
  );
};

const BookingRequestSent: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["newBookingRequestSent"][selectedLanguage]} <em>{house}</em>
      {translation["bookingPeriodFrom"][selectedLanguage]}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
      .
    </NotificationText>
  );
};

const BookingRequestApproved: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["bookingRequestAccepted"][selectedLanguage]}
      <em>{house}</em>
      {translation["rentalPeriodFrom"][selectedLanguage]}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
      .
    </NotificationText>
  );
};

const BookingRequestFullyPaid: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  bookingCode,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["confirmationReceipt"][selectedLanguage]} <em>{house}</em>{" "}
      {translation["from"][selectedLanguage]}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>{" "}
      / {bookingCode}.
    </NotificationText>
  );
};

const BookingRequestDeclined: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["bookingRequestFor"][selectedLanguage]}
      <em>{house}</em>
      {translation["bookingRequestNotAccepted"][selectedLanguage]}
    </NotificationText>
  );
};

const BookingCancelledByOwner: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["bookingRequestOfHouse"][selectedLanguage]} <em>{house}</em>{" "}
      {translation["from"][selectedLanguage]}{" "}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>{" "}
      {translation["bookingRequestDeclined"][selectedLanguage]}
    </NotificationText>
  );
};

const GuestBookingCancelledByGuest: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["requestCancellation"][selectedLanguage]}
      <em>{house}</em>
    </NotificationText>
  );
};

const BookingCancelledByAdminNoDepositPaid: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["requestCancellationDueToMissingDeposit"][selectedLanguage]}
    </NotificationText>
  );
};

const BookingCancelledByAdminNoPaymentPaid: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {
        translation["requestCancellationDueToMissingFinalPayment"][
          selectedLanguage
        ]
      }
    </NotificationText>
  );
};

const TenantBookingCancelledWithoutDeposit: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["requestCancellationDueToMissingDeposit"][selectedLanguage]}
    </NotificationText>
  );
};

const TenantRentIncoming: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["rentalPeriodStartsInTwoWeeks"][selectedLanguage]}
    </NotificationText>
  );
};

const TenantRentFinished: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["thankYouForStay"][selectedLanguage]}
      <em>{house}</em>.
    </NotificationText>
  );
};

const PaymentFullPriceRequired: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["finalPaymentForHouse"][selectedLanguage]}
      <em>{house}</em>
      {translation["paymentRequiredSoon"][selectedLanguage]}
    </NotificationText>
  );
};

const PaymentFullPriceRequiredWithoutDeposit: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["totalPaymentForHouse"][selectedLanguage]}
      <em>{house}</em>
      {translation["paymentRequiredNow"][selectedLanguage]}
    </NotificationText>
  );
};

const BookingRequestEdited: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["bookingRequestEdited"][selectedLanguage]}
      <em>{house}</em>
      {translation["bookingPeriodFrom"][selectedLanguage]}{" "}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
      .
    </NotificationText>
  );
};

const BookingRequestApprovedEdited: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["approvedBookingRequestEdited"][selectedLanguage]}{" "}
      <em>{house}</em>
      {translation["bookingPeriodFrom"][selectedLanguage]}{" "}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
      .
    </NotificationText>
  );
};

const HouseOnboarded: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["congratulationsHouse"][selectedLanguage]}
      <em>{house}</em>
    </NotificationText>
  );
};

const OwnerInvited: FC<NotificationMessageProps> = ({
  inviter,
  invitee,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      <em>{inviter}</em>
      {translation["invitationSent"][selectedLanguage]}
      <em>{invitee}</em>
      {translation["invitationToApplyNewHouse"][selectedLanguage]}
    </NotificationText>
  );
};

const OwnerBookingCancelledByGuest: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["requestCancellationByGuest"][selectedLanguage]}
    </NotificationText>
  );
};

const ConnectionInvitationReceived: FC<NotificationMessageProps> = ({
  inviter,
  handleAcceptButtonClick,
  selectedLanguage,
}) => {
  return (
    <>
      <NotificationText variant="body">
        {translation["newConnectionRequest"][selectedLanguage]}
        {inviter}
      </NotificationText>
      <ButtonsContainer display="grid" gridAutoFlow="column">
        <NotificationButton
          size={ButtonSize.SMALL}
          onClick={handleAcceptButtonClick}
        >
          {translation["accept"][selectedLanguage]}
        </NotificationButton>
        <NotificationButton size={ButtonSize.SMALL}>
          {translation["ignore"][selectedLanguage]}
        </NotificationButton>
      </ButtonsContainer>
    </>
  );
};

const ConnectionInvitationAccepted: FC<NotificationMessageProps> = ({
  invitee,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      <em>{invitee}</em>
      {translation["connectionRequestAccepted"][selectedLanguage]}
    </NotificationText>
  );
};

const ConnectionInvitationReceivedAccepted: FC<NotificationMessageProps> = ({
  inviter,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["newConnection"][selectedLanguage]}
      {inviter}
    </NotificationText>
  );
};

const GuestPaidBookingEditRequested: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["rebookingRequest"][selectedLanguage]} {house}
      {translation["forDates"][selectedLanguage]}
      <strong>
        {checkInDate}-{checkOutDate}
      </strong>
      .
    </NotificationText>
  );
};

const GuestRentFinished: FC<NotificationMessageProps> = ({
  house,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["thankYouForStay"][selectedLanguage]}
      <em>{house}</em>
    </NotificationText>
  );
};

const GuestRentIncoming: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["journeyStartsInTwoWeeks"][selectedLanguage]}
    </NotificationText>
  );
};

const PaymentReminderDeposit: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["outstandingDepositPayment"][selectedLanguage]}{" "}
      <em>{house}</em>
      {translation["from"][selectedLanguage]}
      <strong>
        {checkInDate} {translation["to"][selectedLanguage]} {checkOutDate}
      </strong>
    </NotificationText>
  );
};

const PaymentReminderFullPrice: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["outstandingPayment"][selectedLanguage]}
      <em>{house}</em>
      {translation["from"][selectedLanguage]}{" "}
      <strong>
        {checkInDate}
        {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
    </NotificationText>
  );
};

const PaymentReminderFullPriceRequired: FC<NotificationMessageProps> = ({
  house,
  checkInDate,
  checkOutDate,
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["outstandingBalancePayment"][selectedLanguage]}
      Outstanding balance payment for your booking for the house{" "}
      <em>{house}</em>
      {translation["from"][selectedLanguage]}{" "}
      <strong>
        {checkInDate} {translation["to"][selectedLanguage]}
        {checkOutDate}
      </strong>
    </NotificationText>
  );
};

const NewApplyForMembershipRequest: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["newApplyForMembershipRequest"][selectedLanguage]}
    </NotificationText>
  );
};

const ReminderApplyForMembershipRequest: FC<NotificationMessageProps> = ({
  selectedLanguage,
}) => {
  return (
    <NotificationText variant="body">
      {translation["reminderApplyForMembershipRequest"][selectedLanguage]}
    </NotificationText>
  );
};

const ApplyForMembershipRequestAlreadyConfirmed: FC<
  NotificationMessageProps
> = ({ selectedLanguage }) => {
  return (
    <NotificationText variant="body">
      {
        translation["applyForMembershipRequestAlreadyConfirmed"][
          selectedLanguage
        ]
      }
    </NotificationText>
  );
};

const NotificationMessages = {
  INVITATION_ACCEPTED_REGISTERED_USER: InvitationAcceptedRegisteredUserMessage,
  INVITATION_RECEIVED: InvitationReceivedMessage,
  INVITATION_RECEIVED_ACCESS_UPGRADE: InvitationReceivedAccessUpgradeMessage,
  INVITATION_RECEIVED_NEW_SELECTED: InvitationReceivedSelectedMessage,
  INVITATION_ACCEPTED_INVITER: InvitationAcceptedInviterdMessage,
  HOUSE_ACCESS_UPGRADED: HouseAccessUpgraded,
  HOUSE_ACCESS_DOWNGRADED: null,
  ACCESS_TYPE_ALL_FROM_SELECTED_CHANGE: AccessTypeChangeFromSelectedToAll,
  BOOKING_REQUESTED: BookingRequested,
  BOOKING_REQUESTED_REMINDER: BookingRequestedReminder,
  BOOKING_DEPOSIT_PAID: BookingPaid,
  BOOKING_FULLY_PAID: BookingPaid,
  OWNER_RENT_INCOMING: OwnerRentIncoming,
  BOOKING_CANCELLED_BY_TENANT: BookingCancelledByTenant,
  OWNER_BOOKING_CANCELLED_BY_ADMIN_WHEN_NO_DEPOSIT:
    BookingCancelledByAdminWithoutDeposit,
  NEW_BOOKING_EDITED: NewBookingEdited,
  APPROVED_BOOKING_EDITED: ApprovedBookingEdited,
  BOOKING_REQUEST_SENT: BookingRequestSent,
  BOOKING_REQUEST_APPROVED: BookingRequestApproved,
  BOOKING_REQUEST_FULLY_PAID: BookingRequestFullyPaid,
  BOOKING_REQUEST_DECLINED: BookingRequestDeclined,
  BOOKING_CANCELLED_BY_OWNER: BookingCancelledByOwner,
  TENANT_BOOKING_CANCELLED_BY_ADMIN_WHEN_NO_DEPOSIT:
    TenantBookingCancelledWithoutDeposit,
  TENANT_RENT_INCOMING: TenantRentIncoming,
  TENANT_RENT_FINISHED: TenantRentFinished,
  PAYMENT_INFO_FULL_PRICE_REQUIRED: PaymentFullPriceRequired,
  PAYMENT_INFO_FULL_PRICE_REQUIRED_WHEN_NO_DEPOSIT:
    PaymentFullPriceRequiredWithoutDeposit,
  BOOKING_REQUEST_EDITED: BookingRequestEdited,
  BOOKING_REQUEST_APPROVED_EDITED: BookingRequestApprovedEdited,
  HOUSE_ONBOARDED: HouseOnboarded,
  OWNER_INVITED: OwnerInvited,
  OWNER_BOOKING_CANCELLED_BY_GUEST: OwnerBookingCancelledByGuest,
  GUEST_BOOKING_CANCELLED_BY_GUEST: GuestBookingCancelledByGuest,
  GUEST_BOOKING_CANCELLED_BY_ADMIN_WHEN_NO_DEPOSIT:
    BookingCancelledByAdminNoDepositPaid,
  GUEST_BOOKING_CANCELLED_BY_ADMIN_WHEN_NO_FULL_PAYMENT:
    BookingCancelledByAdminNoPaymentPaid,
  CONNECTION_INVITATION_RECEIVED: ConnectionInvitationReceived,
  CONNECTION_INVITATION_ACCEPTED_INVITER: ConnectionInvitationAccepted,
  GUEST_PAID_BOOKING_EDIT_REQUESTED: GuestPaidBookingEditRequested,
  GUEST_RENT_FINISHED: GuestRentFinished,
  GUEST_RENT_INCOMING: GuestRentIncoming,
  TENANT_RENT_STARTED: null,
  OWNER_RENT_STARTED: null,
  OWNER_RENT_FINISHED: null,
  PAYMENT_REMINDER: null,
  PAYMENT_REMINDER_DEPOSIT: PaymentReminderDeposit,
  PAYMENT_REMINDER_FULL_PRICE: PaymentReminderFullPrice,
  PAYMENT_REMINDER_FULL_PRICE_REQUIRED: PaymentReminderFullPriceRequired,
  NEW_APPLY_FOR_MEMBERSHIP_REQUEST: NewApplyForMembershipRequest,
  REMINDER_APPLY_FOR_MEMBERSHIP_REQUEST: ReminderApplyForMembershipRequest,
  APPLY_FOR_MEMBERSHIP_REQUEST_ALREADY_CONFIRMED:
    ApplyForMembershipRequestAlreadyConfirmed,
};

interface Props extends Omit<NotificationMessageProps, "selectedLanguage"> {
  type: NotificationType;
}

const NotificationMessage: FC<Props> = ({
  type,
  guest,
  inviter,
  invitee,
  accepted,
  house,
  checkInDate,
  checkOutDate,
  bookingCode,
  handleAcceptButtonClick,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  let Component;

  if (accepted && type === "INVITATION_RECEIVED") {
    Component = InvitationAcceptedRegisteredUserMessage;
  } else if (!accepted && type === "INVITATION_RECEIVED") {
    Component = InvitationReceivedMessage;
  } else if (accepted && type === "CONNECTION_INVITATION_RECEIVED") {
    Component = ConnectionInvitationReceivedAccepted;
  } else if (!accepted && type === "CONNECTION_INVITATION_RECEIVED") {
    Component = ConnectionInvitationReceived;
  } else {
    Component = NotificationMessages[type];
  }

  return Component ? (
    <Component
      inviter={inviter}
      bookingCode={bookingCode}
      guest={guest}
      accepted={accepted}
      invitee={invitee}
      house={house}
      checkInDate={checkInDate}
      checkOutDate={checkOutDate}
      selectedLanguage={selectedLanguage}
      handleAcceptButtonClick={handleAcceptButtonClick}
    />
  ) : null;
};

export default NotificationMessage;
