import { FC } from "react";
import { ServicesItemProps } from "./ServicesItem.d";
import { ReactComponent as MailIcon } from "assets/icons/icon-mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/icon-phone.svg";
import {
  StyledItemAdditionalInfo,
  StyledLink,
  StyledContainer,
  ImageContainer,
  StyledDescriptionContainer,
  StyledTitle,
} from "./ServicesItem.styled";
import { COLORS } from "theme";
import { ReadMore } from "components/ReadMore";
import { Typography } from "components/Typography";
import { Box } from "components/Box";
import { HOST_URL } from "urls/api";
import { Image } from "components/Image";
const COMMENT_MAX_LENGTH = 64;

export const ServicesItem: FC<ServicesItemProps> = ({
  id,
  images,
  title,
  comment,
  phoneNumber,
  email,
}) => {
  return (
    <StyledContainer
      key={id}
      display="grid"
      alignItems="start"
      gridGap={1}
      p={1}
    >
      {images.length > 0 && (
        <ImageContainer>
          <Image
            variant="card"
            src={images[0].url && `${HOST_URL}${images[0].url}`}
          />
        </ImageContainer>
      )}
      <StyledTitle variant="h4">{title}</StyledTitle>
      <StyledDescriptionContainer>
        {comment.length > COMMENT_MAX_LENGTH ? (
          <ReadMore text={comment} color={COLORS.typography.body} />
        ) : (
          <Typography variant="body" color={COLORS.typography.body}>
            {comment}
          </Typography>
        )}
      </StyledDescriptionContainer>
      <Box display="grid" gridGap={1.5} mt={1.9}>
        {phoneNumber && (
          <StyledItemAdditionalInfo variant="body">
            <PhoneIcon />
            <StyledLink href={`tel:${phoneNumber}`}>{phoneNumber}</StyledLink>
          </StyledItemAdditionalInfo>
        )}
        {email && (
          <StyledItemAdditionalInfo variant="body">
            <MailIcon />
            <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
          </StyledItemAdditionalInfo>
        )}
      </Box>
    </StyledContainer>
  );
};
