import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { keyframes } from "styled-components";
import { COLORS } from "theme";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerBox = styled(Box)`
  display: inline-block;
  position: relative;
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${pxToRem(64)};
    height: ${pxToRem(64)};
    margin: 8px;
    border: 8px solid ${COLORS.accent.primary};
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${COLORS.accent.primary} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const LoaderBox = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: ${COLORS.background.main};
  display: flex;
  justify-content: center;
  align-items: center;
`;
