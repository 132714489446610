import styled, { createGlobalStyle } from "styled-components";
import { Box } from "components/Box";
import { COLORS, SIZES } from "theme";
import {
  StaticElementType,
  StaticPortalType,
  StaticDateType,
} from "./Calendar.d";
import { hexToRGB } from "global/utils";
import { Typography } from "components/Typography";
import { CloseButton } from "components/Booking/BookingModal/BookingModal.styled";

export const StyledPortalContainer = styled.div<StaticPortalType>`
  .react-datepicker__portal {
    align-items: baseline;
    padding: ${({ isPrivate }) =>
      isPrivate ? "53px 0 150px 0" : "53px 0 150px 17%"};
    overflow-y: scroll;
    z-index: 1000;
    max-height: calc(100vh - 200px);
    background-color: ${hexToRGB(COLORS.typography.headline, "0.2")};
    padding-left: 0;
  }
`;

export const StyledCalendarContainer = styled.div`
  position: relative;
  margin: 0 auto;
`;

export const StyledContainer = styled(Box)<StaticElementType>`
  max-width: ${({ monthShown }) => (monthShown === 1 ? "337px" : "755px")};
  background-color: ${COLORS.typography.secondary.main};
  ${({ inline }) =>
    inline ? "border: none" : `border: 1px solid ${COLORS.stroke.main}`};
  box-shadow: ${({ inline }) =>
    inline ? "none" : "0px 8px 20px rgba(209, 206, 206, 0.25)"};
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .react-datepicker {
    border: none;
    width: 100%;
  }

  .react-datepicker__header {
    border-bottom: none;
    background-color: ${COLORS.typography.secondary.main};
    padding-top: 13px;
  }

  .react-datepicker__day-names {
    margin-top: ${({ monthShown }) => (monthShown === 1 ? "25px" : "28px")};
  }

  .react-datepicker__day-name {
    font-size: 12px;
    width: ${({ monthShown }) => (monthShown === 1 ? "13.5%" : "2.9rem")};
    line-height: ${({ monthShown }) => (monthShown === 1 ? "27px" : "19px")};
    text-align: ${({ monthShown }) => (monthShown === 1 ? "left" : "center")};
    margin: 0.166rem 2px;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ monthShown }) => (monthShown === 1 ? "11px" : "5px")};
  }

  .react-datepicker__current-month {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .react-datepicker__month {
    margin: ${({ monthShown }) => (monthShown === 1 ? 0 : "6.4px 0")};
  }

  .react-datepicker__month-container {
    width: ${({ monthShown }) => (monthShown === 1 ? "100%" : "unset")};
    .react-datepicker__current-month:first-child {
      margin-left: 61px;
    }
    &:last-child {
      margin-left: ${({ monthShown }) => (monthShown === 1 ? 0 : "41px")};
      .react-datepicker__current-month {
        margin-right: ${({ monthShown }) => (monthShown === 1 ? 0 : "52px")};
        margin-left: 0;
      }
      @media screen and (max-width: 810px) {
        margin-left: 5px;
      }
    }
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${COLORS.typography.body};
    border-style: solid;
    border-width: 2px 2px 0 0;
    height: 9px;
    width: 9px;
    top: 13px;
  }

  .react-datepicker__navigation--next {
    right: ${({ monthShown }) => (monthShown === 1 ? "-5px" : "-1px")};
  }

  .react-datepicker__navigation--previous {
    left: ${({ monthShown }) => (monthShown === 1 ? "-5px" : "7px")};
  }

  .react-datepicker__day--in-range {
    background-color: ${COLORS.accent.disabled};
    color: ${COLORS.typography.body};
    border-radius: 0;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: ${COLORS.accent.primary};
    color: ${COLORS.stroke.main};
    border-radius: 0;
    &:hover {
      background-color: ${COLORS.accent.primary};
      color: ${COLORS.stroke.main};
      border-radius: 0;
    }
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${COLORS.accent.disabled};
    color: ${COLORS.typography.body};
    border-radius: 0;
  }

  .react-datepicker__day--today {
    font-weight: ${SIZES.fontWeight.regular};
  }

  .react-datepicker__day {
    padding: 5px 0px;
    width: 1.9rem;
    line-height: 18px;
    margin: 0 5px;
    &:hover {
      border-radius: 0;
    }

    ${SIZES.media.phone} {
      width: 2.4rem;
      padding: 8px 0px;
      margin: ${({ monthShown }) =>
        monthShown === 1 ? "0 5px" : "0.166rem 6px"};
    }
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  @media screen and (max-width: 767px) {
    .react-datepicker__week {
      justify-content: center;
    }
    .react-datepicker__day-name {
      width: 36px;
    }
    .react-datepicker__current-month {
      margin-right: 0 !important;
    }
  }
`;

export const DefaultDate = styled(Typography)<StaticDateType>`
  border-radius: 0;
  font-size: 15px;
  margin: ${({ monthShown }) =>
    monthShown === 1 ? "0.166rem 0" : "0.166rem 6px"};
  &:hover {
    color: ${COLORS.typography.body};
  }
`;

export const TransparentDate = styled(Typography)<StaticDateType>`
  border-radius: 0;
  font-size: 15px;
  color: ${COLORS.typography.disabledElement};
`;

export const TransparentMinDate = styled(Typography)<StaticDateType>`
  border-radius: 0;
  font-size: 15px;
  color: ${COLORS.typography.disabled};
`;

export const ExcludedDate = styled(Typography)<StaticDateType>`
  border-radius: 0;
  font-size: 15px;
  text-decoration: line-through;
  color: ${COLORS.typography.body};
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const StyledCloseButton = styled(CloseButton)`
  justify-self: end;
`;
