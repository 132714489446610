import { Box } from "components/Box";
import { Dropdown, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { Label } from "components/Label";
import { ReactComponent as SelectArrow } from "assets/icons/icon_select_arrow.svg";
import { StyledSelectArrowProps } from "components/Select/Select.d";
import { Select } from "components/Select/Select";
import { Checkbox } from "components/Checkbox";

interface LinkProps {
  isActive: boolean;
  onClick: () => void;
}

interface DropdownProps {
  isOpen: boolean;
}

export const MainContainer = styled(Box)`
  li {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    padding: ${pxToRem(10)} ${pxToRem(40)};
    ${SIZES.media.tablet} {
      font-size: 18px;
      padding: ${pxToRem(10)} ${pxToRem(20)};
    }
  }

  ${SIZES.media.tablet} {
    margin: ${pxToRem(31)} ${pxToRem(32)};
  }

  ${SIZES.media.desktop} {
    margin: ${pxToRem(16)} ${pxToRem(80)} ${pxToRem(100)};
  }
`;

export const Header = styled(Typography)`
  font-size: 18px;
  ${SIZES.media.desktop} {
    font-size: 30px;
  }
`;

export const StyledFiltersContainer = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
`;

export const StyledFilterLink = styled(Typography)<LinkProps>`
  display: grid;
  align-items: center;
  grid-gap: ${pxToRem(12)};
  grid-auto-flow: column;
  border-bottom: none;
  color: ${({ isActive }) =>
    isActive ? COLORS.accent.primary : COLORS.typography.headline};

  & svg {
    fill: ${COLORS.typography.headline};
    width: 18px;
    height: 18px;
  }

  &:hover {
    color: ${COLORS.accent.primary};

    & svg {
      fill: ${COLORS.accent.primary};
    }
  }
`;

export const StyledContentContainer = styled(Box)`
  grid-auto-flow: row;

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
    grid-template-columns: 308px auto;
  }

  ${SIZES.media.desktop} {
    grid-template-columns: 413px auto;
  }
`;

export const StyledInviteButtonContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: 65%;
  }
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 22px;
  letter-spacing: 0.03em;
`;

export const HeaderContainer = styled(Box)`
  grid-auto-flow: row;

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const FilersContainer = styled(Box)`
  grid-auto-flow: row;

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
  }
`;

export const SelectedOptionsContainer = styled(Box)`
  grid-auto-flow: row;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  direction: rtl;

  ${SIZES.media.tablet} {
    grid-column-gap: 32px;
    grid-row-gap: 12px;
  }

  ${SIZES.media.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const SelectContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-auto-flow: column;
    width: 190px;
  }
`;

export const FilterBox = styled(Box)<DropdownProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 32px;
  background: #ffffff;
  border: 1px solid #e5e2e2;
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen &&
    `
    border-bottom: none;
  `}
  user-select: none;
`;

export const StyledLabel = styled(Label)`
  cursor: pointer;
  font-size: 14px;
  text-transform: none;
  color: ${COLORS.typography.headline};
  margin-left: 12px;
`;

export const StyledDropdown = styled(Dropdown)<DropdownProps>`
  ${({ isOpen }) =>
    isOpen &&
    `
    border-top: none;
    `}
  user-select: none;
  width: calc(100% - 36px);

  ${SIZES.media.tablet} {
    width: 190px;
  }
`;

export const StyledSelectArrowIcon = styled(
  SelectArrow
)<StyledSelectArrowProps>`
  margin-left: 16px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(-180deg)" : "rotate(0)")};
  transition: 0.2s ease-out;
`;

export const StyledOptionsContainer = styled(Box)`
  display: grid;
  gap: 12px;

  ${SIZES.media.tablet} {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
  }
`;

export const StyledSelectContainer = styled(Box)`
  ${SIZES.media.tablet} {
    width: 200px;
    height: 54px;
  }
`;

export const StyledSortSelect = styled(Select)`
  & > div {
    & > div {
      padding: ${pxToRem(18)} ${pxToRem(32)};
      min-height: 0;
    }
  }
  ul {
    overflow-y: auto;
  }
  li {
    box-sizing: border-box;
    padding: 0.5625rem 0;
    min-height: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    margin: 0;
    font-family: Inter;
    font-size: 14px;
    line-height: normal;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 24px;
`;
