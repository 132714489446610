import * as Yup from "yup";
export const IMG_MAX_SIZE = 1024 * 1024 * 20; // 20 MB

export const imageSchema = Yup.mixed()
  .nullable(true)
  .test("isBelowMaxSize", "The file is too big", (value: File) => {
    if (value) {
      return value.size < IMG_MAX_SIZE;
    }
    return true;
  });

const recommendationItemValidation = Yup.object({
  comment: Yup.string().nullable(true),
  distance: Yup.string().nullable(true),
  email: Yup.string()
    .email("Please, enter a valid email address.")
    .nullable(true),
  id: Yup.string().nullable(true),
  images: Yup.array(
    Yup.object({
      id: Yup.string().nullable(true),
      url: Yup.lazy((value) =>
        typeof value == "string" ? Yup.string().nullable(true) : imageSchema
      ),
    })
  ).nullable(true),
  latitude: Yup.string().nullable(true),
  location: Yup.string().nullable(true),
  longitude: Yup.string().nullable(true),
  phone_number: Yup.string().nullable(true),
  title: Yup.string()
    .max(50, "Name must be no more than 50 characters.")
    .required("Please, insert name."),
  url: Yup.string().nullable(true),
});

const recommendationValidation = Yup.lazy((value) => {
  if (value) {
    const newEntries = Object.keys(value).reduce(
      (acc, val) => ({
        ...acc,
        [val]: Yup.array(recommendationItemValidation),
      }),
      {}
    );

    return Yup.object().shape(newEntries);
  }
  return Yup.mixed().notRequired();
});

export const houseEditingSchema = Yup.object({
  staffonsite: Yup.object().shape({
    responsibility: Yup.string().max(20).nullable(true),
    first_name: Yup.string().max(100).nullable(true),
    last_name: Yup.string().max(100).nullable(true),
    email: Yup.string().email().nullable(true),
    phone: Yup.string().max(20).nullable(true),
  }),
  owneradvice: Yup.object().shape({
    description: Yup.string().max(1000).nullable(true),
  }),
  recommendations: recommendationValidation,
  services: recommendationValidation,
});
