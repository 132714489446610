import { Link } from "components";
import { ComponentType } from "react";
import { SwiperSlide } from "swiper/react";

export const withSlider = (
  WrappedComponent: ComponentType<any>,
  redirectPath?: string
) => {
  const SwiperSlideWrapped = (props: any) => {
    return (
      <SwiperSlide key={props.id}>
        {redirectPath ? (
          <Link to={redirectPath}>
            <WrappedComponent {...props} />
          </Link>
        ) : (
          <WrappedComponent {...props} />
        )}
      </SwiperSlide>
    );
  };
  return SwiperSlideWrapped;
};
