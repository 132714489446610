import { RequestBooking } from "components/Booking/BookingCalculator/BookingCalculator.d";
import { differenceInDays } from "date-fns";

type Price = {
  adults: number;
  children: number;
  infants: number;
};

export const calculatePricePerNight = (
  bookingData: RequestBooking,
  price: Price,
  cap: number,
  minimumPricePerNight: number
) => {
  const pricePerNight =
    bookingData.adults_num * price.adults +
    bookingData.children_num * price.children +
    bookingData.infants_num * price.infants;
  if (pricePerNight < minimumPricePerNight) return minimumPricePerNight;
  return Math.min(cap, pricePerNight);
};

export const countNights = (
  check_in_date: Date | null,
  check_out_date: Date | null
) =>
  check_in_date && check_out_date
    ? differenceInDays(check_out_date, check_in_date)
    : 0;

export const getPrice = (price: number, discount: number) =>
  discount ? (price * (100 - discount)) / 100 : price;
