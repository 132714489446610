import { FC, SetStateAction } from "react";
import { Image } from "components/Image";
import { ImageBoxContainer, ImagesBox } from "./MasonryImageList.styled";
import { HOST_URL } from "urls/api";

interface Props {
  images: Array<string>;
  setOpenHouseGallery: (value: SetStateAction<boolean>) => void;
  setCurrentImageIndex: (value: SetStateAction<number>) => void;
}

export const MasonryImageList: FC<Props> = ({
  images,
  setOpenHouseGallery,
  setCurrentImageIndex,
}) => {
  const handleClick = (index: number) => {
    setOpenHouseGallery(true);
    setCurrentImageIndex(index);
  };

  return (
    <ImagesBox>
      {images.map((image, index) => {
        return (
          <ImageBoxContainer key={`masonry-${index}`}>
            <Image
              src={`${HOST_URL}${image}`}
              variant="flexible"
              onClick={() => handleClick(index)}
            />
          </ImageBoxContainer>
        );
      })}
    </ImagesBox>
  );
};
