import { Box } from "components/Box";
import { pxToRem } from "global/utils";
import styled, { css } from "styled-components";
import { COLORS, SIZES } from "theme";
import { TooltipProps } from "./Tooltip.d";

const tooltip = css<TooltipProps>`
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  padding: ${pxToRem(10)};
  color: ${({ color }) => (color ? color : COLORS.typography.secondary.main)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : COLORS.background.tooltip};
  font-size: 12px;
  font-family: Inter, sans-serif;
  line-height: 15px;
  z-index: 100;
  width: 100px;

  ${SIZES.media.tablet} {
    width: 320px;
  }

  &:before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: -6px;
  }
`;

export const LeftTooltip = styled.div`
  ${tooltip}
  left: auto;
  right: calc(100% + 17px);
  top: 100%;
  transform: translateX(0) translateY(-50%);

  &:before {
    left: auto;
    right: -12px;
    top: 45%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : COLORS.background.tooltip};
  }
`;

export const RightTooltip = styled.div`
  ${tooltip}
  left: calc(100% + 17px);
  top: 100%;
  transform: translateX(0) translateY(-50%);

  &:before {
    left: -6px;
    top: 35%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : COLORS.background.tooltip};
  }
`;

export const TopTooltip = styled.div`
  ${tooltip}
  top: -50px;

  &:before {
    top: 100%;
    border-top-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : COLORS.background.tooltip};
  }
`;

export const BottomTooltip = styled.div`
  ${tooltip}
  bottom: -50px;

  &:before {
    bottom: 100%;
    border-bottom-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : COLORS.background.tooltip};
  }
`;

export const StyledContainer = styled(Box)`
  display: inline-block;
  position: relative;
  width: fit-content;
`;
