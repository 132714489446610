import { Box } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const ContentContainer = styled(Box)`
  grid-template-columns: 305px;
  max-width: ${pxToRem(305)};
`;

export const ButtonsBox = styled(Box)`
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  width: 83%;

  ${SIZES.media.tablet} {
    grid-template-columns: 237px 165px auto;
    width: 100%;
  }
`;
