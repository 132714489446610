import { Box } from "components/Box";
import styled from "styled-components";
import { SIZES } from "theme";

interface PageWrapperProps {
  useBackgroundImage?: boolean;
}

export const Wrapper = styled(Box)`
  width: 100%;
  min-height: 100vh;
`;

export const PageWrapper = styled(Box)<PageWrapperProps>`
  flex-grow: 1;
  width: 100%;
  max-width: ${SIZES.deviceSize.desktop}px;
  margin: 0 auto;
  ${({ useBackgroundImage = false }) =>
    useBackgroundImage &&
    `background-image: url(/images/background.jpeg); max-width: initial;
  background-position: center;
  background-attachment: fixed;

    background-size: cover;`}
`;
