import { Box } from "components";
import styled from "styled-components";
import { SIZES } from "theme";

export const StyledContainer = styled(Box)`
  position: relative;
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
  }
`;
