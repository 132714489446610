import styled from "styled-components";
import { Box } from "components/Box";
import { SIZES } from "theme";

export const ImagesBox = styled(Box)`
  column-count: 1;

  ${SIZES.media.laptop} {
    column-count: 2;
    column-gap: 8px;
  }
`;

export const ImageBoxContainer = styled(Box)`
  height: auto;
  margin-bottom: 8px;
  break-inside: avoid;
  position: relative;
  cursor: pointer;
`;
