import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { forwardRef, useRef, useState } from "react";
import { UserProfilePreviewModalProps } from "./UserProfilePreviewModal.d";
import {
  StyledContainer,
  StyledTitleContainer,
  GlobalStyle,
  StyledConfirmationModalContent,
} from "./UserProfilePreviewModal.styled";
import { ModalAlignVariant } from "components/Modal/Modal.enums";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Button } from "components/Button";
import { FriendCardVariant } from "components/Friends/FriendCard/FriendCard.enums";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { Option } from "components/Select/Select.d";
import { UserStatus } from "types/enums";
import { useWindowSize } from "global/hooks/useWindowSize";
import { COLORS } from "theme";
import { Box } from "components/Box";
import { AccessVariant } from "../InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";
import { ModalContent } from "./ModalContent";
import { useModal } from "global/hooks/useModal";
import { useProfile } from "context/Profile";
import translation from "./translation.json";
import { getLanguage } from "utils/getLanguage";

export const UserProfilePreviewModal = forwardRef<
  HTMLDivElement,
  UserProfilePreviewModalProps
>(
  (
    {
      variant,
      userInfo,
      discountOptions,
      isShown,
      closeModal,
      declineInvitation,
      deactivateEmailInvitation,
      deactivateConnectionInvitation,
      deleteFromFriends,
      sendInvitation,
      sendInvitationReminder,
      acceptInvitation,
      updateFriendOptions,
      updateEmailInvitation,
    },
    ref
  ) => {
    const profile = useProfile();
    const selectedLanguage = profile?.selectedLanguage || getLanguage();
    const deleteFriendModal = useModal();
    const { isMobile } = useWindowSize();
    const [isConfirmModalOpen, setIsConfirmModalOpen] =
      useState<boolean>(false);
    const toggleConfirmModal = () => setIsConfirmModalOpen(!isConfirmModalOpen);
    const confirmModalRef = useRef<HTMLDivElement | null>(null);
    const fullName = `${userInfo.name} ${userInfo.last_name}`;

    const discountOptionsToShow =
      discountOptions.map((option) => {
        return { value: option.name, name: option.description };
      }) ?? [];
    const isInvitedStatus = userInfo.status === UserStatus.INVITED;

    const discountCurrentValue = {
      value: userInfo.discount,
      name: discountOptions.find((option) => option.name === userInfo.discount)
        .description,
    };

    const menuOptions = [
      {
        label: translation["deleteLabel"][selectedLanguage],
        value: "Delete",
        isShown: variant === FriendCardVariant.CONNECTIONS,
        onClick: deleteFriendModal.toggle,
      },
      {
        label: translation["declineLabel"][selectedLanguage],
        value: "Decline",
        isShown: variant === FriendCardVariant.REQUESTS_RECEIVED,
        onClick: () =>
          userInfo.received_connection_invitation &&
          declineInvitation(
            userInfo.id,
            userInfo.received_connection_invitation.id
          ),
      },
      {
        label: translation["deactivateLabel"][selectedLanguage],
        value: "Deactivate",
        isShown: variant === FriendCardVariant.REQUESTS_SENT,
        onClick: () =>
          isInvitedStatus
            ? userInfo.sent_friend_invitation?.invited_user_email &&
              deactivateEmailInvitation(
                userInfo.sent_friend_invitation?.invited_user_email
              )
            : userInfo.sent_connection_invitation &&
              deactivateConnectionInvitation(
                userInfo.sent_connection_invitation.id
              ),
      },
      {
        label: translation["sendReminderLabel"][selectedLanguage],
        value: "Send a reminder",
        isShown: isInvitedStatus && variant === FriendCardVariant.REQUESTS_SENT,
        onClick: () =>
          userInfo.sent_friend_invitation &&
          userInfo.sent_friend_invitation?.invited_user_email &&
          sendInvitationReminder(
            userInfo,
            userInfo.sent_friend_invitation?.invited_user_email
          ),
      },
    ];

    const onDiscountSelectChange = (option: Option) => {
      const discount = option.value;
      isInvitedStatus
        ? userInfo.sent_friend_invitation &&
          updateEmailInvitation(
            { discount },
            userInfo.sent_friend_invitation?.id
          )
        : updateFriendOptions({ discount }, userInfo.id);
    };

    const onAddAccessButtonClick = () => {
      isInvitedStatus
        ? userInfo.sent_friend_invitation &&
          updateEmailInvitation(
            { access_type: AccessVariant.ALL },
            userInfo.sent_friend_invitation.id
          )
        : updateFriendOptions({ access_type: AccessVariant.ALL }, userInfo.id);
      toggleConfirmModal();
    };

    return (
      <>
        <GlobalStyle />
        <Modal isShown={deleteFriendModal.isShown}>
          <StyledConfirmationModalContent>
            <Typography variant="h4">
              {translation["areYouSureYouWantToRemove"][selectedLanguage]}{" "}
              {fullName}{" "}
              {translation["fromYourListOfFriends"][selectedLanguage]}
            </Typography>
            <Box
              display="grid"
              mt={5.6}
              gridAutoFlow="column"
              justifyContent="end"
              gridGap={3}
              alignItems="center"
            >
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={deleteFriendModal.toggle}
              >
                {translation["cancelLabel"][selectedLanguage]}
              </Button>
              <Button
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.SMALL}
                icon={
                  <ArrowIcon
                    stroke={COLORS.stroke.main}
                    fill="none"
                    width="20px"
                    height="20px"
                  />
                }
                onClick={() => deleteFromFriends(userInfo.id)}
              >
                {translation["deleteLabel"][selectedLanguage]}
              </Button>
            </Box>
          </StyledConfirmationModalContent>
        </Modal>
        {isConfirmModalOpen ? (
          <Modal
            modalRef={confirmModalRef}
            onClose={toggleConfirmModal}
            isShown={isConfirmModalOpen}
          >
            <StyledConfirmationModalContent>
              <Box display="grid" gridGap={1}>
                <Typography variant="h4">
                  {translation["allHousesAccessLabel"][selectedLanguage]}
                </Typography>
                <Typography variant="body" color={COLORS.stroke.error}>
                  {translation["beAwareLabel"][selectedLanguage]}
                </Typography>
              </Box>
              <Box
                display="grid"
                mt={5.6}
                gridAutoFlow="column"
                justifyContent="end"
                gridGap={3}
                alignItems="center"
              >
                <Button
                  variant={ButtonVariant.SECONDARY}
                  onClick={toggleConfirmModal}
                >
                  {translation["cancelLabel"][selectedLanguage]}
                </Button>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  icon={
                    <ArrowIcon
                      stroke={COLORS.stroke.main}
                      fill="none"
                      width="20px"
                      height="20px"
                    />
                  }
                  onClick={onAddAccessButtonClick}
                >
                  {translation["addToAllHousesLabel"][selectedLanguage]}
                </Button>
              </Box>
            </StyledConfirmationModalContent>
          </Modal>
        ) : (
          <Modal
            withCloseButton
            modalRef={ref}
            isShown={isShown}
            onClose={closeModal}
            px={isMobile ? 2 : 4}
            variant={ModalAlignVariant.RIGHT}
          >
            <StyledContainer>
              <StyledTitleContainer>
                <Typography variant="h4">
                  {translation["personInformationLabel"][selectedLanguage]}
                </Typography>
              </StyledTitleContainer>
              <ModalContent
                variant={variant}
                userInfo={userInfo}
                discountCurrentValue={discountCurrentValue}
                discountOptionsToShow={discountOptionsToShow}
                onDiscountSelectChange={onDiscountSelectChange}
                toggleConfirmModal={toggleConfirmModal}
                menuOptions={menuOptions}
                acceptInvitation={acceptInvitation}
                sendInvitation={sendInvitation}
              />
            </StyledContainer>
          </Modal>
        )}
      </>
    );
  }
);

UserProfilePreviewModal.displayName = "UserProfilePreviewModal";
