import { FC } from "react";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { StyledIcon, StyledContainer } from "./IconWithLabel.styled";
import { IconWithLabelProps } from "./IconWithLabel.d";
import { Image } from "components/Image";
import { COLORS } from "theme";

export const IconWithLabel: FC<IconWithLabelProps> = ({
  icon: Icon,
  label,
  gap = 2,
  isVertical = false,
}) => {
  return (
    <StyledContainer display="grid" alignItems="center" isVertical={isVertical}>
      {Icon && (
        <StyledIcon display="flex" mr={gap} isVertical={isVertical}>
          {typeof Icon === "string" ? (
            <Image variant="icon" src={Icon} />
          ) : (
            <Icon />
          )}
        </StyledIcon>
      )}
      {label && (
        <Typography variant="caption" color={COLORS.typography.body}>
          {label}
        </Typography>
      )}
    </StyledContainer>
  );
};
