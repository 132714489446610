import styled, { css } from "styled-components";
import { COLORS } from "theme/colors";
import { SIZES } from "theme/sizes";
import { IconButtonProps } from "./IconButton.d";
import { Box } from "../Box";

const ICON_SIZES = {
  small: "1.5rem",
  medium: "2rem",
  large: "2.5rem",
};

const ICON_MARGIN = {
  small: "0.125rem",
  medium: "0.25rem",
  large: "0.5rem",
};

export const StyledIconButton = styled.button<IconButtonProps>(
  ({ size = "medium", disabled }) => css`
    align-items: center;
    border: 0;
    border-radius: 50%;
    color: ${COLORS.typography.main};
    background-color: transparent;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    opacity: ${disabled && 0.3};
    outline: 0;
    padding: ${() => ICON_MARGIN[size]};
    margin: 0;
    position: relative;
    transition: 0.2s ease-out;

    height: ${() => {
      return ICON_SIZES[size] || "1.5rem";
    }};
    width: ${() => {
      return ICON_SIZES[size] || "1.5rem";
    }};

    :enabled {
      cursor: pointer;

      :hover {
        color: ${COLORS.accent.hover};
        fill: ${COLORS.accent.hover};
        background-color: ${COLORS.background.main};
      }

      :active {
        color: ${COLORS.accent.pressed};
        fill: ${COLORS.accent.pressed};
        background-color: ${COLORS.background.main};
      }
    }

    img,
    svg {
      color: ${COLORS.typography.main};
      height: ${() => {
        return ICON_SIZES[size] || "1.5rem";
      }};
      width: ${() => {
        return ICON_SIZES[size] || "1.5rem";
      }};
    }
  `
);

export const Badge = styled(Box)`
  color: ${COLORS.error.text};
  background-color: ${COLORS.error.main};
  font-size: ${SIZES.typography.badge};
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  top: 0;
  right: 0;
  position: absolute;
  text-align: center;
`;
