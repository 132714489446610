import { FC } from "react";
import { SelectedHouseOwnerItemProps } from "./SelectedHouseOwnerItem.d";
import { Box } from "components/Box";
import { ReactComponent as AvatarIcon } from "assets/icons/icon_avatar.svg";
import { Typography } from "components/Typography";
import { COLORS } from "theme";
import { ReactComponent as RemoveIcon } from "assets/icons/icon-close.svg";
import { ReactComponent as DoneIcon } from "assets/icons/icon_done.svg";
import {
  StyledDoneIconContainer,
  StyledRemoveButton,
} from "./SelectedHouseOwnerItem.styled";

export const SelectedHouseOwnerItem: FC<SelectedHouseOwnerItemProps> = ({
  result,
  removeFromList,
}) => {
  const fullName = `${result.name} ${result.last_name}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={0.8}
    >
      <Box display="grid" gridAutoFlow="column" gridGap={1} alignItems="center">
        <AvatarIcon />
        <Typography variant="caption" color={COLORS.typography.headline}>
          {fullName}
        </Typography>
      </Box>
      <Box display="grid" gridAutoFlow="column" gridGap={1} alignItems="center">
        <StyledDoneIconContainer>
          <DoneIcon stroke={COLORS.background.main} />
        </StyledDoneIconContainer>
        <StyledRemoveButton
          onClick={(event) => removeFromList(event, result.id)}
        >
          <RemoveIcon />
        </StyledRemoveButton>
      </Box>
    </Box>
  );
};
