import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { SIZES } from "theme";
import { StaticElementType } from "./ReservationDateItem.d";

interface TypographyProps {
  filterVariant: boolean;
}

export const StyledItem = styled(Box)<StaticElementType>`
  flex-direction: column;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
  padding: 12px 14px;
  &:first-child {
    padding: 0;
  }
  &:last-child {
    padding: 0;
    padding-left: 25px;
  }
  & span {
    line-height: 26px;
  }

  ${SIZES.media.phone} {
    padding: ${({ withBorder }) => (withBorder ? "16px 18px" : "16px 0")};
    &:first-child {
      padding: 16px 0 16px 18px;
      padding: ${({ filterVariant }) =>
        filterVariant ? "12px 0 12px 18px" : "16px 0 16px 18px"};
      padding-right: ${({ hasIcon }) => (hasIcon ? "13px" : 0)};
    }
    &:last-child {
      padding-left: ${({ hasIcon }) => (hasIcon ? "26px" : "48px")};
    }
  }
`;

export const StyledLabel = styled(Typography)<TypographyProps>`
  line-height: ${({ filterVariant }) =>
    filterVariant ? "12px !important" : "inherit"};
  margin-bottom: 4px;
`;

export const StyledChooseDate = styled(Typography)<TypographyProps>`
  line-height: ${({ filterVariant }) =>
    filterVariant ? "16px !important" : "inherit"};
`;
