import { FC, useEffect, useState } from "react";
import { GoogleMap, MarkerF, InfoWindowF } from "@react-google-maps/api";
import HouseMapMarker from "assets/icons/icon-house-location-marker.svg";
import { Position, MapProps } from "./Map.d";
import { StyledContainer, StyledEditMapTooltip } from "./Map.styled";
import { COLORS } from "theme";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const styleOptions = [
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const options = {
  disableDefaultUI: true,
  styles: styleOptions,
};

const defaultCenter: Position = {
  lat: 0,
  lng: 0,
};

export const Map: FC<MapProps> = ({
  center,
  markers,
  zoom = 10,
  positions = [],
  onMarkerDragEnd,
}) => {
  const [map, setMap] = useState<GoogleMap | null>();
  const [isDragged, setIsDragged] = useState<boolean>(false);

  const updateZoom = () => {
    if (map && positions.length && positions.length > 0) {
      const bounds = new window.google.maps.LatLngBounds(
        center?.lat && center.lng ? center : defaultCenter
      );
      const locationCenter = new window.google.maps.LatLng(
        center?.lat && center.lng ? center : defaultCenter
      );
      bounds.extend(locationCenter);
      positions.forEach((position) => {
        const location = new window.google.maps.LatLng(
          position.lat,
          position.lng
        );
        bounds.extend(location);
      });
      map.state.map?.setCenter(bounds.getCenter());
      map.state.map?.fitBounds(bounds);
    }
  };

  useEffect(() => {
    updateZoom();
  }, [map, positions, markers]);

  return (
    <StyledContainer draggable={!!onMarkerDragEnd}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center?.lat && center.lng ? center : defaultCenter}
        options={options}
        ref={(map) => setMap(map)}
        zoom={zoom}
        onLoad={updateZoom}
      >
        {markers}
        {center && (
          <MarkerF
            position={center}
            icon={HouseMapMarker}
            draggable={!!onMarkerDragEnd}
            onDragEnd={onMarkerDragEnd}
            onDragStart={onMarkerDragEnd ? () => setIsDragged(true) : undefined}
          />
        )}
        {!!onMarkerDragEnd && !isDragged && (
          <InfoWindowF position={center ?? defaultCenter}>
            <StyledEditMapTooltip
              variant="caption"
              color={COLORS.typography.headline}
            >
              Drag to adjust the location
            </StyledEditMapTooltip>
          </InfoWindowF>
        )}
      </GoogleMap>
    </StyledContainer>
  );
};

Map.displayName = "Map";
