import {
  LOGIN_URL,
  REFRESH_URL,
  RESET_PASSWORD_ACTION_URL,
  RESET_PASSWORD_REQUEST_URL,
} from "urls/api";
import fetchApi from "./fetchApi";
import { LoginForm } from "views/Login/Login.d";

export type RequestResetPassword = {
  email: string;
  captcha_token: string;
};

export type ResetPassword = {
  password: string;
  confirm_password: string;
  token: string;
  captcha_token: string;
  email: string;
};

export interface LoginResponse extends Response {
  access: string;
  refresh: string;
  user_type: "BASIC" | "ACTIVE";
  detail: string;
  message?: string;
}

export interface RefreshResponse extends Response {
  access: string;
  detail: string;
  code: string;
}

export const login = async (data: LoginForm) => {
  const response = await fetchApi<LoginResponse>(LOGIN_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response;
};

export const refresh = async (refreshToken: string) => {
  const response = await fetchApi<RefreshResponse>(REFRESH_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refresh: refreshToken }),
  });
  return response;
};

export const requestResetPassword = async (data: RequestResetPassword) => {
  const response = await fetchApi(RESET_PASSWORD_REQUEST_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response;
};

export const resetPassword = async (data: ResetPassword) => {
  const response = await fetchApi(RESET_PASSWORD_ACTION_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response;
};

export const authService = {
  login,
  refresh,
  requestResetPassword,
  resetPassword,
};

export default authService;
