import styled from "styled-components";
import { COLORS } from "theme";
import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { StaticContainerType } from "./AvatarInput.d";

export const StyledInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

export const StyledContainer = styled(Box)<StaticContainerType>`
  border: 1px solid
    ${({ error }) => (error ? COLORS.stroke.error : COLORS.stroke.main)};
  justify-items: center;
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
`;

export const StyledUploadContainer = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;
