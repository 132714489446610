import { Box, Typography } from "components";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { SIZES } from "theme";

export const MainContainer = styled(Box)`
  grid-template-columns: auto 416px;
  max-width: ${pxToRem(791)};
  margin: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(305)} ${pxToRem(16)};
  ${SIZES.media.tablet} {
    margin-left: ${pxToRem(84)};
  }
`;

export const HeaderContainer = styled(Typography)`
  margin-top: ${pxToRem(80)};
  margin-bottom: ${pxToRem(51)};
`;

export const ContentParagraphContainer = styled(Typography)`
  line-height: 30px;
`;
