import styled from "styled-components";
import { StyledFieldBasic } from "components/Form/common.styled";
import { COLORS } from "theme";
import { pxToRem } from "global/utils";
import { Typography } from "components/Typography";

export const StyledTextarea = styled.textarea`
  ${StyledFieldBasic}
  font-size: 16px;
  line-height: 30px;
  padding: ${pxToRem(18)};
  resize: none;

  &::placeholder {
    color: ${COLORS.typography.body};
  }
`;

export const StyledHelperText = styled(Typography)`
  font-size: 10px;
  line-height: 12px;
`;
