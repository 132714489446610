export const HOST_URL =
  process.env.REACT_APP_HOST_URL || "http://127.0.0.1:8000";

export const WS_HOST_URL =
  process.env.REACT_APP_WS_HOST_URL || "127.0.0.1:8000";

export const NOTIFICATIONS_WEBSOCKET = (id: string) =>
  `${
    window.location.protocol === "https:" ? "wss://" : "ws://"
  }${WS_HOST_URL}/ws/notifications/${id}/`;

export const API_URL = HOST_URL + "/api/";

export const LOGIN_URL = API_URL + "users/login/";
export const REFRESH_URL = API_URL + "users/refresh/";

export const RESET_PASSWORD_REQUEST_URL =
  API_URL + "users/reset_password_request/";

export const LOGGED_USER_RESET_PASSWORD_REQUEST_URL =
  API_URL + "users/logged_user_reset_password_request/";

export const RESET_PASSWORD_ACTION_URL =
  API_URL + "users/reset_password_action/";

export const REGISTER_BASIC_USER = `${API_URL}users/basic/registration/`;

export const HOUSES_ENDPOINT = API_URL + "houses/";

export const HOUSE_ENDPOINT = (id: string) => `${HOUSES_ENDPOINT}${id}/`;

export const HOUSE_UPDATE_ENDPOINT = (id: string) =>
  `${HOUSE_ENDPOINT(id)}update/`;

export const HOUSE_IMAGES_ENDPOINT = (id: string) =>
  `${HOUSES_ENDPOINT}${id}/gallery/`;

export const MY_HOUSES_ENDPOINT = HOUSES_ENDPOINT + "my-houses/";

export const HOUSE_APPLICATION_ENDPOINT = HOUSES_ENDPOINT + "application/";

export const SEND_ACTIVATION_EMAIL_URL =
  API_URL + "users/send_activation_email/";

const USERS_ENDPOINT = API_URL + "users/";

const BOOKINGS_ENDPOINT = API_URL + "bookings/";

export const REQUEST_BOOKING_ENDPOINT = BOOKINGS_ENDPOINT + "request/";

export const UPDATE_BOOKING_ENDPOINT = (id: string) =>
  `${BOOKINGS_ENDPOINT}request/${id}/`;

export const GET_RESERVATIONS = BOOKINGS_ENDPOINT + "received/";

export const GET_BOOKINGS = BOOKINGS_ENDPOINT + "sent/";

export const APPROVE_RESERVATION = (id: string) =>
  `${BOOKINGS_ENDPOINT}${id}/approve/`;

export const APPROVE_CHANGES_RESERVATION = (id: string) =>
  `${BOOKINGS_ENDPOINT}${id}/approve-changes/`;

export const CANCEL_RESERVATION = (id: string) =>
  `${BOOKINGS_ENDPOINT}${id}/cancel/`;

export const REJECT_RESERVATION = (id: string) =>
  `${BOOKINGS_ENDPOINT}${id}/reject/`;

export const PAY_BALANCE = (id: string) =>
  `${BOOKINGS_ENDPOINT}sent/${id}/pay_balance/`;

export const PAY_DEPOSIT = (id: string) =>
  `${BOOKINGS_ENDPOINT}sent/${id}/pay_deposit/`;

export const ACTIVATE_USERS = USERS_ENDPOINT + "activate/";

export const FRIENDS_ENDPOINT = USERS_ENDPOINT + "friends/";

export const USER_LIMITED_INFO_ENDPOINT = (id: string) =>
  `${USERS_ENDPOINT}limited_info/${id}/`;

export const CONNECTION_SEARCH_ENDPOINT = (query: string) =>
  `${USERS_ENDPOINT}connection_search/${query}/`;

export const FRIEND_ENDPOINT = (id: string) => `${FRIENDS_ENDPOINT}${id}/`;

export const CONNECTION_INVITATION_ENDPOINT = `${USERS_ENDPOINT}connection_invitation/`;

export const ACCEPT_CONNECTION_INVITATION_ENDPOINT = (id: string) =>
  `${CONNECTION_INVITATION_ENDPOINT}${id}/accept/`;

export const DECLINE_CONNECTION_INVITATION_ENDPOINT = (id: string) =>
  `${CONNECTION_INVITATION_ENDPOINT}${id}/decline/`;

export const DEACTIVATE_CONNECTION_INVITATION_ENDPOINT = (id: string) =>
  `${CONNECTION_INVITATION_ENDPOINT}${id}/`;

export const INVITATIONS_ENDPOINT = USERS_ENDPOINT + "invitation/";

export const INVITATION_ENDPOINT = (id: string) =>
  `${INVITATIONS_ENDPOINT}${id}/`;

export const CHECK_INVITATION_ENDPOINT = USERS_ENDPOINT + "invitation/check/";

export const INVITE_OWNER_ENDPOINT = USERS_ENDPOINT + "owner_invitation/";

export const INVITATION_ACCEPT_ENDPOINT = (id: string) =>
  `${INVITATIONS_ENDPOINT}${id}/accept/`;

export const RETRIEVE_OWNER_INVITATION_ENDPOINT = (token: string) =>
  API_URL + `users/owner_invitation/${token}/`;

export const DISCOUNT_LIST_ENDPOINT = USERS_ENDPOINT + "discount/";

export const VERIFY_ACTIVATION_ENDPOINT = USERS_ENDPOINT + "activation/verify/";

export const NOTIFICATIONS_ENDPOINT = API_URL + "notifications/";

export const NOTIFICATIONS_UPDATE_ENDPOINT = NOTIFICATIONS_ENDPOINT + "update/";

export const FAVORITES_ENDPOINT = HOUSES_ENDPOINT + "bookmarks/";

export const HOUSES_EXAMPLE_ENDPOINT = HOUSES_ENDPOINT + "public/";

export const FAVORITES_UPDATE_ENDPOINT = (id: string) =>
  `${HOUSES_ENDPOINT}${id}/bookmarks/`;

export const OWNERS_ENDPOINT = (id: string) =>
  `${HOUSES_ENDPOINT}${id}/owners/`;

export const PUBLIC_ACCESS_HOUSES_ENDPOINT = HOUSES_ENDPOINT + "public-houses/";

export const HOUSES_WITH_DISABLED_DATES = (id: string) =>
  `${HOUSES_ENDPOINT}${id}/disabled-dates/`;

export const DELETE_BLOCKED_DATE_ENDPOINT = (
  house_id: string,
  blocked_date_id: string
) => `${HOUSES_ENDPOINT}${house_id}/disabled-dates/${blocked_date_id}/`;

export const HOUSES_CALENDAR_SELECT_OPTIONS =
  HOUSES_ENDPOINT + "house-select-options/";

export const PROFILE_ENDPOINT = USERS_ENDPOINT + "profile/";

export const CHANGE_PASSWORD_ENDPOINT = USERS_ENDPOINT + "change_password/";

export const ACCOUNT_DELETE_ENDPOINT = USERS_ENDPOINT + "delete_account/";

export const GET_FRIEND_QR_CODE_INVITATION_TOKEN =
  USERS_ENDPOINT + "invitation_token/";

export const INVITE_FRIEND_WITH_QR_CODE =
  USERS_ENDPOINT + "invitation/with_token/";

export const CONTACT_ENDPOINT = API_URL + "contact/";

export const COMPANIES_ENDPOINT = USERS_ENDPOINT + "companies/";

export const INDUSTRIES_ENDPOINT = USERS_ENDPOINT + "industries/";

export const EDUCATIONS_ENDPOINT = USERS_ENDPOINT + "educations/";

export const INTERESTS_ENDPOINT = `${USERS_ENDPOINT}hobbies/`;

export const COUNTRIES_ENDPOINT = USERS_ENDPOINT + "country_choices/";

export const ACCEPT_TERMS = USERS_ENDPOINT + "accept_tc/";

export const LINK_VISITED = (activation_token: string) =>
  `${USERS_ENDPOINT}invitation-open/${activation_token}/`;

export const LANGUAGE_ENDPOINT = API_URL + "users/language/";

export const ACCEPTORS = `${USERS_ENDPOINT}basic/acceptors/`;

export const APPLY_FOR_MEMBERSHIP = `${USERS_ENDPOINT}basic/application/`;

export const INVITATION_PASS_APPLE = `${USERS_ENDPOINT}invitation/pass_apple/`;

export const INVITATION_PASS_GOOGLE = `${USERS_ENDPOINT}invitation/pass_google/`;
