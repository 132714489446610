import { DaysInterval } from "components/House/HouseOwnerCalendar/HouseOwnerCalendar.d";
import {
  differenceInDays,
  addDays,
  eachDayOfInterval,
  subDays,
} from "date-fns";
import { PriceComponent, SeasonalPrice, RegularPrice } from "types/house.d";

export type Price = {
  title?: string;
  per_adult: number;
  per_child: number;
  per_baby: number;
  minimum_price_per_night: number;
  cap: number;
  start_date?: string;
  end_date?: string;
};

export const getExcludeDates = (
  intervals: DaysInterval[],
  pickedDate?: Date
) => {
  return intervals
    .map((interval: DaysInterval) => {
      const start = pickedDate
        ? addDays(new Date(interval.check_in_date), 1)
        : new Date(interval.check_in_date);
      const end = pickedDate
        ? new Date(interval.check_out_date)
        : subDays(new Date(interval.check_out_date), 1);
      let result: Date[] | Date = [];

      if (start === end) {
        result = new Date(interval.check_in_date);
      } else {
        if (start > end) {
          result = new Date(interval.check_out_date);
        } else {
          result = eachDayOfInterval({
            start,
            end,
          });
        }
      }
      return result;
    })
    .flat();
};

export const calculateMaxDate = (
  startDate: Date,
  blockedDates: Array<Date>
): undefined | Date => {
  const sortedDateArray = blockedDates.sort(function (a: Date, b: Date) {
    return a.getTime() - b.getTime();
  });
  return sortedDateArray.find((date) => differenceInDays(startDate, date) < 0);
};

export const getDateCommonFormat = (dates: Date[]) =>
  dates?.length ? dates.map((date) => new Date(date).toLocaleDateString()) : [];

export const calculatePricePerNight = (
  adultsNum: number,
  childrenNum: number,
  infantsNum: number,
  adultPrice: number,
  childPrice: number,
  infantPrice: number,
  cap: number,
  minimumPricePerNight: number
) => {
  const pricePerNight =
    adultsNum * adultPrice +
    childrenNum * childPrice +
    infantsNum * infantPrice;
  if (pricePerNight < minimumPricePerNight) return minimumPricePerNight;
  return Math.min(cap, pricePerNight);
};

export const getPrice = (price: number, discount: number) =>
  discount ? (price * (100 - discount)) / 100 : price;

const combineNights = (arr: PriceComponent[]): PriceComponent[] => {
  const result: { [pricePerNight: number]: PriceComponent } = {};
  arr.forEach((obj) => {
    const { pricePerNight } = obj;
    if (result[pricePerNight]) {
      result[pricePerNight].nightsCount += 1;
    } else {
      result[pricePerNight] = { pricePerNight, nightsCount: 1 };
    }
    result[pricePerNight].seasonalTitle = obj.seasonalTitle;
  });
  return Object.values(result);
};

export const calculateBookingCost = (
  checkInDate: Date,
  checkoutDate: Date,
  adults: number,
  children: number,
  infants: number,
  discount: number,
  seasonalPrices: SeasonalPrice[],
  regularPrices: RegularPrice
): PriceComponent[] => {
  const priceComponents: PriceComponent[] = [];
  const dateRange = eachDayOfInterval({
    start: checkInDate,
    end: subDays(checkoutDate, 1),
  });

  dateRange.forEach((date) => {
    const currentDate = date.getTime();
    let pricePerNight = 0;
    const currentSeasonalPrice =
      seasonalPrices.length !== 0 &&
      seasonalPrices.find((season) => {
        const [startDate, startMonth] = season.start_date.split("-");
        const [endDate, endMonth] = season.end_date.split("-");
        const seasonStart = new Date(
          new Date().getFullYear(),
          +startMonth - 1,
          +startDate
        ).getTime();
        const seasonEnd = new Date(
          new Date().getFullYear(),
          +endMonth - 1,
          +endDate
        ).getTime();

        return currentDate <= seasonEnd && currentDate >= seasonStart;
      });
    const seasonalTitle = currentSeasonalPrice
      ? currentSeasonalPrice.title
      : undefined;
    const prices: RegularPrice = currentSeasonalPrice || regularPrices;

    pricePerNight = calculatePricePerNight(
      adults,
      children,
      infants,
      getPrice(prices.per_adult, discount),
      getPrice(prices.per_child, discount),
      getPrice(prices.per_baby, discount),
      getPrice(prices.cap, discount),
      getPrice(prices.minimum_price_per_night, discount)
    );

    priceComponents.push({
      pricePerNight,
      nightsCount: 1,
      seasonalTitle: seasonalTitle,
    });
  });

  return combineNights(priceComponents);
};
