import styled from "styled-components";
import { COLORS } from "theme";
import { ArrowProps } from "components/Slider/Slider.d";
import { StyledArrow as SliderArrow } from "components/Slider/Slider.styled";
import { Box } from "components/Box";

export const StyledArrow = styled(SliderArrow)<ArrowProps>`
  bottom: calc(50% - 22px);
  top: 42%;
  right: ${({ isRight }) => (isRight ? "24px" : "unset")};
  left: ${({ isRight }) => (isRight ? "unset" : "24px")};
  display: none;
  opacity: 0.7;
  height: 17px;

  svg {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    stroke: ${COLORS.typography.headline};
  }
`;

export const StyledContainer = styled.div`
  .swiper-container {
    position: relative;
    margin: 0 !important;

    &:hover {
      ${StyledArrow} {
        display: grid;
      }
    }
  }
  .swiper-pagination-bullet {
    opacity: 0.5;
    background: ${COLORS.typography.secondary.main};
    margin: 0 1px !important;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
`;

export const StyledHeart = styled(Box)`
  position: absolute;
  width: 44px;
  height: 44px;
  right: 12px;
  top: 12px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
