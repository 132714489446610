export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BOOKING = "booking",
}

export enum ButtonSize {
  SMALL = "small",
  LARGE = "large",
}
