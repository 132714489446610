import { MutableRefObject, useEffect } from "react";

export const useHandleClickOutside = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  callback: () => void
) => {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [callback]);

  function handleClickOutside(e: MouseEvent) {
    const target = e.target as Element;
    if (
      ref.current &&
      !ref.current.contains(target) &&
      target?.tagName !== "INPUT"
    ) {
      callback();
    }
  }
};
