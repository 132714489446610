import {
  INVITATION_ACCEPT_ENDPOINT,
  NOTIFICATIONS_ENDPOINT,
  NOTIFICATIONS_UPDATE_ENDPOINT,
} from "urls/api";
import { Notification } from "types/notification";
import fetchAPI from "./fetchApi";

interface GetNotificationsProps {
  results: Notification[];
  unread_count: number;
}

interface NotificationResponse {
  id: string;
}

export const markNotificationsAsRead = async () => {
  const options = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  };
  const response = fetchAPI(`${NOTIFICATIONS_UPDATE_ENDPOINT}`, options);
  return response;
};

export const updateNotificationStatus = async (id: string) => {
  const options = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  };
  const response = fetchAPI<NotificationResponse>(
    `${NOTIFICATIONS_ENDPOINT}${id}/`,
    options
  );
  return response;
};

export const getNotifications = (count: number) => {
  const response = fetchAPI<GetNotificationsProps>(
    `${NOTIFICATIONS_ENDPOINT}?limit=${count}`
  );
  return response;
};

export const acceptInvitation = (id: string) => {
  const acceptInvitation = fetchAPI<NotificationResponse>(
    INVITATION_ACCEPT_ENDPOINT(id),
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }
  );
  return acceptInvitation;
};
