import { Box } from "components/Box";
import { Button } from "components/Button";
import { Chip } from "components/Chip";
import { KebabMenu } from "components/KebabMenu";
import { Tooltip } from "components/Tooltip";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled, { createGlobalStyle } from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContainer = styled(Box)`
  width: 100%;
  height: 90vh;
  position: relative;
  padding-bottom: ${pxToRem(100)};

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.medium};
    padding-bottom: 0;
  }
`;

export const StyledTitleContainer = styled(Box)`
  position: absolute;
  top: -26px;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

export const StyledContent = styled(Box)`
  border: 1px solid ${COLORS.stroke.main};
`;

export const StyledMainContent = styled(Box)`
  img {
    margin: 0 auto;
  }

  ${SIZES.media.phone} {
    grid-template-columns: 112px auto;
    grid-auto-flow: column;
  }
`;

export const StyledButton = styled(Button)`
  width: 154px;
  padding: ${pxToRem(12)} ${pxToRem(17)};
`;

export const StyledFullName = styled(Typography)`
  padding-top: 5px;
  padding-bottom: 2px;
  order: 0;

  ${SIZES.media.phone} {
    order: unset;
  }
`;

export const StyledHobby = styled(Typography)`
  text-transform: capitalize;
  margin-right: ${pxToRem(4)};
  margin-bottom: ${pxToRem(10)};
`;

export const StyledAccessContainer = styled(Box)`
  box-sizing: border-box;
  grid-template-rows: 100px auto;

  ${SIZES.media.phone} {
    grid-template-columns: 277px auto;
    grid-template-rows: unset;
    padding-bottom: ${pxToRem(5)};
  }
`;

export const StyledHousesContainer = styled(Box)`
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: ${pxToRem(25)};

  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledConfirmationModalContent = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.small};
  }
`;

export const StyledFriendRequestButtonContainer = styled(Box)`
  order: 4;

  ${SIZES.media.phone} {
    grid-auto-flow: column;
    align-items: end;
    margin-top: ${pxToRem(5)};
    order: unset;
  }
`;

export const StyledChip = styled(Chip)`
  order: 2;
  margin-top: ${pxToRem(5)};

  ${SIZES.media.phone} {
    order: unset;
    margin-top: 0;
  }
`;

export const StyledLocation = styled(Typography)`
  order: 1;
  ${SIZES.media.phone} {
    order: unset;
  }
`;

export const StyledKebabMenu = styled(KebabMenu)`
  position: absolute;
  margin-left: auto;
  right: 18px;
`;

export const StyledAboutContent = styled(Typography)`
  white-space: initial;
  word-break: break-word;
  word-wrap: break-word;
`;

export const StyledTrustedFriendContainer = styled(Box)`
  margin-top: 18px;

  ${SIZES.media.tablet} {
    margin-top: 24px;
  }
`;

export const StyledMailBox = styled(Box)`
  margin-left: 24px;
  padding-top: 5px;
`;

export const StyledTooltip = styled(Tooltip)`
  text-align: center;
  padding: 10px;
  width: max-content;
`;
