import { FC } from "react";
import { Typography } from "components/Typography";
import { HouseSummaryProps, KeyInfo } from "./HouseSummary.d";
import {
  HouseSummaryDescription,
  StyledContainer,
} from "./HouseSummary.styled";
import { COLORS } from "theme";
import { BulletList } from "components/BulletList";
import { Box } from "components/Box";
import { ReadMore } from "components/ReadMore";
import { useWindowSize } from "global/hooks/useWindowSize";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseSummary: FC<HouseSummaryProps> = ({
  description,
  keyInfos,
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const { isMobile } = useWindowSize();
  const MAX_LENGTH = isMobile ? 280 : 700;

  const keyInfoItem = keyInfos.map(({ title, comment }: KeyInfo) => (
    <Box key={`key-info-${title}`}>
      <Typography variant="body">{title}</Typography>
      <Typography variant="body" color={COLORS.typography.body}>
        {comment}
      </Typography>
    </Box>
  ));

  return (
    <StyledContainer display="grid" gridGap={2} p={4}>
      <Typography variant="h2" color={COLORS.typography.headline}>
        {translation["summary"][selectedLanguage]}
      </Typography>
      {keyInfos.length > 0 && <BulletList items={keyInfoItem} />}
      {description.length >= MAX_LENGTH ? (
        <ReadMore text={description} color={COLORS.typography.body} />
      ) : (
        <HouseSummaryDescription variant="body" color={COLORS.typography.body}>
          {description}
        </HouseSummaryDescription>
      )}
    </StyledContainer>
  );
};
