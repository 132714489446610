import { Box } from "components/Box";
import { Image } from "components/Image";
import { Typography } from "components/Typography";
import { FC } from "react";
import { COLORS } from "theme";
import { HOST_URL } from "urls/api";
import { MutualFriendsProps } from "./MutualFriends.d";
import { RestFriendsNumber, StyledItemContainer } from "./MutualFriends.styled";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

const numberImagesToShow = 2;

export const MutualFriends: FC<MutualFriendsProps> = ({
  images,
  number = 0,
}) => {
  const userProfile = useProfile();
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  const restNumberOfMutualFriends = number - numberImagesToShow;
  const filteredAvatars = [
    ...images.slice(0, numberImagesToShow),
    ...Array(number - images.length),
  ].slice(0, numberImagesToShow);
  return (
    <Box display="flex">
      <Box display="inline-flex" pr={0.5}>
        {filteredAvatars.map((image, index) => (
          <StyledItemContainer
            key={image}
            withBorder={index > 0}
            display="flex"
            justifyContent="center"
          >
            <Image
              isRound
              variant="mutualFriend"
              src={image && `${HOST_URL}${image}`}
            />
          </StyledItemContainer>
        ))}
        {number > numberImagesToShow && (
          <StyledItemContainer withBorder>
            <RestFriendsNumber
              display="flex"
              alignItems="center"
              justifyContent="center"
              color={COLORS.stroke.main}
              backgroundColor={COLORS.typography.headline}
            >{`+${restNumberOfMutualFriends}`}</RestFriendsNumber>
          </StyledItemContainer>
        )}
      </Box>
      <Typography variant="overline" color={COLORS.typography.body}>
        {translation["mutualFriendLabel"][selectedLanguage]}
      </Typography>
    </Box>
  );
};
