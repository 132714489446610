import { Box, Typography } from "components";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { Button } from "components/Button";
import { Calendar } from "components/Calendar";

export const FilterContainer = styled(Box)`
  background-color: ${COLORS.background.control};

  ${SIZES.media.tablet} {
    padding: 14px 80px;
  }
`;

export const FilterContentContainer = styled(Box)`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  justify-content: center;

  ${SIZES.media.tablet} {
    grid-template-rows: repeat(3, 62px);
    grid-auto-flow: column;
    padding: 0;
  }
  ${SIZES.media.laptop} {
    grid-template-rows: 1fr;
    grid-template-columns: 195px 316px 274px 212px;
  }
`;

export const GuestContainer = styled(Box)`
  position: absolute;
  background-color: ${COLORS.background.control};
  border-radius: 8px;
  top: 80px;
  z-index: 10;
  padding: 12px;
  border: 1px solid ${COLORS.stroke.main};
  ${SIZES.media.tablet} {
    padding: 0;
  }
  ${SIZES.media.laptop} {
    grid-row: 1;
  }
`;

export const GuestButton = styled(Button)`
  height: 62px;
  border: 1px solid ${COLORS.stroke.main};
  font-weight: 400;
  font-size: 15px;
  color: ${COLORS.typography.headline};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-align: start;
`;

export const SearchButton = styled(Button)`
  justify-content: center;
  align-items: center;
  height: 62px;
  ${SIZES.media.tablet} {
    grid-row: 3;
  }

  ${SIZES.media.laptop} {
    grid-row: 1;
  }
`;

export const StyledCalendar = styled(Calendar)`
  ${SIZES.media.tablet} {
    height: 62px;
    grid-row: 2;
    box-sizing: border-box;
  }

  ${SIZES.media.laptop} {
    grid-row: 1;
  }
`;

export const GuestButtonContainer = styled(Box)`
  position: relative;
  background-color: ${COLORS.background.main};

  ${SIZES.media.tablet} {
    grid-row: 2;
  }

  ${SIZES.media.laptop} {
    grid-row: 1;
  }
`;

export const SelectContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-column: 1/3;
  }

  ${SIZES.media.laptop} {
    grid-column: 1;
  }
`;

export const GuestLabel = styled(Typography)`
  line-height: 16px;
`;

export const GuestHeader = styled(Typography)`
  height: 16px;
  line-height: 12px;
`;
