import { FC, useEffect, useRef, useState } from "react";
import { UserMenuProps } from "./UserMenu.d";
import {
  MenuWrapper,
  NotificationIconButton,
  NotificationsIconContainer,
  StyledUserMenu,
  UserMenuWrapper,
} from "./UserMenu.styled";
import { ReactComponent as CloseIcon } from "assets/icons/icon_x_square.svg";
import { ReactComponent as HamburgerIcon } from "assets/icons/icon_menu.svg";
import { ReactComponent as BellIcon } from "assets/icons/icon_bell.svg";
import { List, Box, IconButton } from "components";
import { Option, OptionValue } from "components/List/List.d";
import { Avatar } from "components/UserLabel";
import { LIST_SEPARATOR } from "components/List/constants";
import {
  HOUSES_PAGE,
  BOOKINGS_PAGE,
  FRIENDS_PAGE,
  PROFILE_PAGE,
  RESERVATIONS_PAGE,
  LOGIN_PAGE,
  HOW_IT_WORKS,
  MY_HOUSE_PAGE,
  FAVORITES_PAGE,
  NOTIFICATIONS_PAGE,
} from "urls/frontend";
import { useAuth } from "context/Auth";
import NotificationList from "components/Notifications/NotificationsList";
import UnreadCounterIndicator from "components/Notifications/UnreadCounterIndicator";
import { useNotifications } from "context/Notifications";
import { useNavigate } from "react-router";
import { generatePath } from "react-router";
import { FriendCardVariant } from "components/Friends/FriendCard/FriendCard.enums";
import { AccessVariant } from "components/Modals/InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";
import { useSearchParams } from "react-router-dom";
import { useWindowSize } from "global/hooks/useWindowSize";
import InviteOwnerModal from "components/Modals/InviteOwnerModal/InviteOwnerModal";
import { useModal } from "global/hooks/useModal";
import LanguageSelect from "components/Select/LanguageSelect/LanguageSelect";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const UserMenu: FC<UserMenuProps> = ({
  notificationsCount = 0,
  profile,
}) => {
  const userProfile = useProfile();
  const userType = localStorage.getItem("user_type") || "";
  const isBasicUserType = !!userType && JSON.parse(userType) === "BASIC";
  const selectedLanguage = userProfile?.selectedLanguage || getLanguage();
  const anchorEl = useRef(null);
  const container = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const notificationsMenu = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const auth = useAuth();
  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState(false);
  const { unreadNotificationsCount, setNotificationsStatusToRead } =
    useNotifications();
  const { isMobile } = useWindowSize();
  const { isShown, toggle } = useModal();

  const handlers = {
    logout: () => {
      auth?.logout();
      userProfile?.refreshProfile();
      navigate(LOGIN_PAGE);
    },
    toggle,
  } as { [key: string]: any };

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleAction = (val: OptionValue) => {
    handlers[val]?.(val);
    toggleMenuOpen();
  };

  const handleClickOutsideNotifications = (event: MouseEvent) => {
    const target = event.target as Node;
    if (!notificationsMenu.current?.contains(target)) {
      setNotificationsMenuOpen(false);
    }
  };

  const demo_token = searchParams.get("demo_token");
  const isLink = (): boolean => {
    return !demo_token;
  };

  const menuItems: Array<Option | typeof LIST_SEPARATOR> = [
    {
      label: translation["notifications"][selectedLanguage],
      value: NOTIFICATIONS_PAGE,
      isShown: isMobile && !isBasicUserType,
      isLink: isLink(),
    },
    {
      label: translation["allHouses"][selectedLanguage],
      value: HOUSES_PAGE,
      isShown: !isBasicUserType,
      isLink: isLink(),
    },
    {
      label: translation["favorites"][selectedLanguage],
      value: FAVORITES_PAGE,
      isShown: !isBasicUserType,
      isLink: true,
    },
    {
      label: translation["bookings"][selectedLanguage],
      value: BOOKINGS_PAGE,
      isShown: !isBasicUserType,
      isLink: isLink(),
    },
    {
      label: translation["friends"][selectedLanguage],
      value: generatePath(
        `${FRIENDS_PAGE}?filter=${FriendCardVariant.CONNECTIONS}`
      ),
      isShown:
        (profile?.can_add_friends ||
          profile?.access_type === AccessVariant.ALL) ??
        false,
      isLink: isLink(),
    },
    {
      label: translation["profile"][selectedLanguage],
      value: PROFILE_PAGE,
      isShown: true,
      isLink: isLink(),
    },
    LIST_SEPARATOR,
    {
      label: translation["myHouse"][selectedLanguage],
      value: MY_HOUSE_PAGE,
      isShown:
        (profile?.is_house_owner ||
          profile?.is_admin ||
          profile?.is_superadmin) ??
        false,
      isLink: true,
    },
    {
      label: translation["reservations"][selectedLanguage],
      value: RESERVATIONS_PAGE,
      isShown: profile?.is_house_owner ?? false,
      isLink: isLink(),
    },
    {
      label: translation["inviteNewOwner"][selectedLanguage],
      value: "toggle",
      isShown: profile?.can_invite_owners ?? false,
    },
    LIST_SEPARATOR,
    {
      label: translation["howItWorks"][selectedLanguage],
      value: HOW_IT_WORKS,
      isShown: true,
      isLink: isLink(),
    },
    LIST_SEPARATOR,
    {
      label: translation["logOut"][selectedLanguage],
      value: "logout",
      isShown: true,
      isLink: false,
    },
  ];

  const getFilteredOptions = (
    options: Array<Option | typeof LIST_SEPARATOR>
  ) => {
    const filteredMenuItems = options.filter((menuItem) =>
      menuItem !== LIST_SEPARATOR ? menuItem?.isShown : true
    );
    return filteredMenuItems.filter(
      (item, index, array) => item !== array[index - 1]
    );
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideNotifications);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideNotifications
      );
    };
  }, [notificationsMenu]);

  return (
    <>
      <InviteOwnerModal isShown={isShown} toggle={toggle} />
      <UserMenuWrapper display="block">
        <Box
          ml={2.5}
          ref={anchorEl}
          display="flex"
          justifyContent="flex-end"
          flexDirection="row"
        >
          {!isMobile && !demo_token && !isBasicUserType && (
            <Box ref={notificationsMenu}>
              <NotificationIconButton
                onClick={() => setNotificationsMenuOpen(!notificationsMenuOpen)}
                size="large"
                badge={notificationsCount || ""}
              >
                <BellIcon />
              </NotificationIconButton>
              <UnreadCounterIndicator unreadCount={unreadNotificationsCount} />

              {notificationsMenuOpen && (
                <NotificationsIconContainer ref={container}>
                  <NotificationList container={container} />
                </NotificationsIconContainer>
              )}
            </Box>
          )}
          <MenuWrapper display="flex" onClick={toggleMenuOpen}>
            <Avatar image={profile?.profile?.avatar} />
            <IconButton size="large">
              {menuOpen ? <CloseIcon /> : <HamburgerIcon />}
            </IconButton>
          </MenuWrapper>
          <LanguageSelect variant="PRIVATE" />
        </Box>

        <StyledUserMenu
          orientation="left"
          open={menuOpen}
          anchorEl={anchorEl}
          onClose={toggleMenuOpen}
        >
          <Box py={1}>
            <List
              navList={true}
              options={getFilteredOptions(menuItems)}
              onSelect={handleAction}
            />
          </Box>
        </StyledUserMenu>
      </UserMenuWrapper>
    </>
  );
};
