import { FC } from "react";
import { useDropzone } from "react-dropzone";
import {
  StyledDropzone,
  StyledIconContainer,
  StyledLabel,
  StyledDescription,
} from "./Dropzone.styled";
import { ReactComponent as UploadIcon } from "assets/icons/icon_upload.svg";
import { DropzoneProps } from "./Dropzone.d";
import { Box } from "components/Box";
import { Label } from "components/Label";
import translation from "./translation.json";
import { useProfile } from "context/Profile";
import { getLanguage } from "utils/getLanguage";

export const Dropzone: FC<DropzoneProps> = ({
  required,
  label,
  error,
  handleFileSelection,
}) => {
  const onDrop = handleFileSelection;
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box display="grid" gridGap={1.5}>
      {label && (
        <Label required={required} error={!!error}>
          {error ? error : label}
        </Label>
      )}
      <StyledDropzone
        display="grid"
        justifyItems="center"
        gridGap={1}
        {...getRootProps({ isDragActive })}
      >
        <input {...getInputProps()} />
        <StyledIconContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <UploadIcon />
        </StyledIconContainer>
        <Box display="grid" justifyItems="center">
          <StyledLabel variant="body" weight="medium">
            {translation["uploadPhoto"][selectedLanguage]}
          </StyledLabel>
          <StyledDescription variant="body">
            {translation["dragAndDropFilesHere"][selectedLanguage]}
          </StyledDescription>
        </Box>
      </StyledDropzone>
    </Box>
  );
};
