import { useEffect } from "react";

export const useShortcuts = (isOpen: boolean, closingFunction: () => void) => {
  function handleCloseWithEscKey(e: KeyboardEvent) {
    if (e.key === "Escape" && isOpen) {
      closingFunction();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleCloseWithEscKey);
    return () => {
      document.removeEventListener("keydown", handleCloseWithEscKey);
    };
  }, []);
};
