import { Box } from "components/Box";
import { Typography } from "components/Typography";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const WarningBox = styled(Box)`
  background-color: ${COLORS.background.control};
`;

export const StyledWarningContentBox = styled(Box)`
  width: 89%;

  ${SIZES.media.tablet} {
    width: 92%;
  }
`;

export const StyledWarningContent = styled(Typography)`
  font-size: 14px;
`;
