import { Box } from "components/Box";
import { Typography } from "components/Typography";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";

export const StyledContent = styled(Box)`
  width: 100%;
`;

export const Container = styled(Box)`
  grid-auto-flow: row;
  border: 1px solid ${COLORS.stroke.main};
  padding-bottom: ${pxToRem(24)};

  ${SIZES.media.tablet} {
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 0;
  }
`;
export const StyledTypography = styled(Typography)`
  white-space: pre-wrap;
`;
