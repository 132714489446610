import { pxToRem } from "global/utils";
import styled from "styled-components";

import { Box } from "components/Box";
import { COLORS, SIZES } from "theme";

interface IconProps {
  isVertical?: boolean;
}

export const StyledIcon = styled(Box)<IconProps>`
  height: ${pxToRem(24)};
  width: ${pxToRem(24)};
  ${({ isVertical }) => isVertical && "margin-right: 0"};

  svg {
    color: ${COLORS.typography.icons};
    fill: ${COLORS.typography.icons};
    width: 100%;
    height: 100%;
  }
  ${SIZES.media.phone} {
    ${({ isVertical, mr }) =>
      isVertical && mr && `margin-right: ${SIZES.spacing(mr)}`};
  }
`;

export const StyledContainer = styled(Box)<IconProps>`
  grid-template-columns: auto 1fr;

  ${({ isVertical }) =>
    isVertical &&
    `
    display: grid;
    grid-auto-flow: row;
    justify-items: center;
    grid-template-columns: 1fr;
  `}

  span {
    ${({ isVertical }) => isVertical && "text-align: center"};
  }

  ${SIZES.media.phone} {
    span {
      line-height: 18px;
    }
  }
  ${SIZES.media.phone} {
    ${({ isVertical }) => isVertical && "display: flex;"}
    ${({ isVertical }) => isVertical && "grid-gap: unset;"}
  }
`;
