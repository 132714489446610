import { FC } from "react";
import { SummaryPriceProps } from "./SummaryPrice.d";
import SummaryPriceItem from "./SummaryPriceItem";

export const SummaryPrice: FC<SummaryPriceProps> = ({
  oldPrice,
  priceSummary = [],
  isPriceOnRequest = false,
}) => {
  const bookingCost = priceSummary.reduce(
    (acc, cost) => acc + cost.nightsCount * cost.pricePerNight,
    0
  );
  const currentOldPrice = oldPrice ?? bookingCost;
  return (
    <>
      {priceSummary.map((priceItem, index) => {
        const isLast = index === priceSummary.length - 1;
        return (
          <SummaryPriceItem
            key={`price-summary-item-${index}`}
            priceItem={priceItem}
            index={index}
            isLast={isLast}
            isPriceOnRequest={isPriceOnRequest}
            bookingCost={bookingCost}
            currentOldPrice={currentOldPrice}
            seasonalTitle={priceItem ? priceItem.seasonalTitle : ""}
          />
        );
      })}
    </>
  );
};
