import {
  ConnectionSearchResult,
  FriendsProps,
  FriendLimitedInfo,
  EditableFriendOptions,
} from "types/friend.d";
import {
  FRIENDS_ENDPOINT,
  USER_LIMITED_INFO_ENDPOINT,
  CONNECTION_INVITATION_ENDPOINT,
  ACCEPT_CONNECTION_INVITATION_ENDPOINT,
  DECLINE_CONNECTION_INVITATION_ENDPOINT,
  DEACTIVATE_CONNECTION_INVITATION_ENDPOINT,
  FRIEND_ENDPOINT,
  CONNECTION_SEARCH_ENDPOINT,
  INVITE_FRIEND_WITH_QR_CODE,
  INVITATIONS_ENDPOINT,
  INVITATION_ENDPOINT,
} from "urls/api";
import fetchApi from "./fetchApi";

export const fetchFriends = (
  filter: string | null,
  discount: string | null,
  userType: string | null,
  sorting: string | null
) => {
  const queryString = [
    filter ? `?filter=${filter}` : "",
    discount ? `discount-type=${discount}` : "",
    userType ? `user-type=${userType}` : "",
    sorting ? `sorting=${sorting}` : "",
  ]
    .filter((x) => x !== null && x !== "")
    .join("&");

  return fetchApi<FriendsProps & Response>(`${FRIENDS_ENDPOINT}${queryString}`);
};

export const fetchUserInfo = (id: string) =>
  fetchApi<FriendLimitedInfo & Response>(`${USER_LIMITED_INFO_ENDPOINT(id)}`);

export const fetchSendInviteToConnections = (data: { invited_user: string }) =>
  fetchApi(CONNECTION_INVITATION_ENDPOINT, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchAcceptConnectionInvitation = (
  data: {
    invited_user: string;
  },
  invitationId: string
) =>
  fetchApi<Response>(ACCEPT_CONNECTION_INVITATION_ENDPOINT(invitationId), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchDeclineConnectionInvitation = (
  data: {
    invited_user: string;
  },
  invitationId: string
) =>
  fetchApi<Response>(DECLINE_CONNECTION_INVITATION_ENDPOINT(invitationId), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchDeactivateConnectionInvitation = (invitationId: string) =>
  fetchApi<Response>(DEACTIVATE_CONNECTION_INVITATION_ENDPOINT(invitationId), {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });

export const fetchDeactivateEmailInvitation = (data: { email: string }) =>
  fetchApi<Response>(INVITATIONS_ENDPOINT, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchDeleteFromFriends = (id: string) =>
  fetchApi<Response>(FRIEND_ENDPOINT(id), {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });

export const fetchConnectionSearch = (query: string) =>
  fetchApi<ConnectionSearchResult[] & Response>(
    `${CONNECTION_SEARCH_ENDPOINT(query)}`
  );
export const inviteFriendWithQRCode = (data: {
  invitation_token: string;
  email: string;
}) => {
  return fetchApi(INVITE_FRIEND_WITH_QR_CODE, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...data }),
  });
};

export const fetchUpdateFriendOptions = (
  data: EditableFriendOptions,
  friendId: string
) =>
  fetchApi<Response>(FRIEND_ENDPOINT(friendId), {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const fetchUpdateEmailInvitation = (
  data: EditableFriendOptions,
  invitationId: string
) =>
  fetchApi<Response>(INVITATION_ENDPOINT(invitationId), {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
