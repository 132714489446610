import { FC, useState } from "react";
import {
  StyledContainer,
  LeftTooltip,
  RightTooltip,
  TopTooltip,
  BottomTooltip,
} from "./Tooltip.styled";
import { TooltipProps } from "./Tooltip.d";

const mapping = {
  left: LeftTooltip,
  right: RightTooltip,
  top: TopTooltip,
  bottom: BottomTooltip,
};

export const Tooltip: FC<TooltipProps> = ({
  children,
  title,
  variant,
  ...props
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const TooltipContentComponent = mapping[variant];
  let timeout: string | number | NodeJS.Timeout | undefined;

  const showTooltip = () => {
    timeout = setTimeout(() => {
      setIsShown(true);
    }, 400);
  };

  const hideTooltip = () => {
    clearInterval(timeout);
    setIsShown(false);
  };

  return (
    <StyledContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {isShown && (
        <TooltipContentComponent variant={variant} {...props}>
          {title}
        </TooltipContentComponent>
      )}
    </StyledContainer>
  );
};
