export const useGooglePlaceAutoComplete = () => {
  const initAutoComplete = async (
    input: HTMLInputElement | null,
    callback: () => void
  ) => {
    const autoComplete =
      input && new window.google.maps.places.Autocomplete(input);
    autoComplete?.addListener("place_changed", callback);
    return autoComplete;
  };

  const getFullAddress = async (
    autoComplete: google.maps.places.Autocomplete | null
  ) => {
    const place = autoComplete?.getPlace();

    return (
      place && {
        address: place?.formatted_address,
        latitude: place.geometry?.location?.lat() ?? null,
        longitude: place.geometry?.location?.lng() ?? null,
      }
    );
  };

  return {
    initAutoComplete,
    getFullAddress,
  };
};
