import { forwardRef, MouseEvent, useEffect } from "react";
import { Box } from "components";
import {
  useFieldArray,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { RecommendationEditItem } from "components/HouseEdit/RecommendationEditItem";
import { UseFormRegister, Control } from "react-hook-form";
import { HouseEditingProps, EditRecommendation } from "types/house.d";
import { StyledButton } from "./HouseEdit.styled";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Position } from "components/Map/Map.d";
import { RecommendationEditItemVariant } from "components/HouseEdit/RecommendationEditItem/RecommendationEditItem.enums";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export type RecommendationEditItemProps = {
  data: EditRecommendation[];
  categoryName: string;
  control: Control<HouseEditingProps, any>;
  register: UseFormRegister<HouseEditingProps>;
  errors: DeepMap<FieldValues, FieldError>;
  setValue: UseFormSetValue<HouseEditingProps>;
  trigger: UseFormTrigger<HouseEditingProps>;
  watch: UseFormWatch<HouseEditingProps>;
  updateImagesToDelete: (imageId: string) => void;
  houseCenter: Position;
  variant: RecommendationEditItemVariant;
};

export const RecommendationsEdit = forwardRef<
  HTMLDivElement,
  RecommendationEditItemProps
>(
  (
    {
      control,
      data,
      register,
      categoryName,
      errors,
      setValue,
      trigger,
      watch,
      updateImagesToDelete,
      houseCenter,
      variant,
    },
    ref
  ) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: `${variant}.[${categoryName}]`,
    });
    const profile = useProfile();
    const selectedLanguage = profile?.selectedLanguage || getLanguage();

    const initialRecommendationValue =
      variant === RecommendationEditItemVariant.RECOMMENDATION
        ? {
            title: "",
            phone_number: "",
            comment: "",
            email: "",
            images: [{ id: "", url: "" }],
            location: "",
            latitude: houseCenter.lat,
            longitude: houseCenter.lng,
            url: "",
            id: "",
          }
        : {
            title: "",
            phone_number: "",
            comment: "",
            email: "",
            images: [{ id: "", url: "" }],
            url: "",
            id: "",
          };

    useEffect(() => {
      if (fields.length === 0) {
        setValue(`${variant}.[${categoryName}]`, data);
      }
    }, [data]);

    const addNewRecommendation = (e: MouseEvent) => {
      e.preventDefault();
      append(initialRecommendationValue);
    };

    return (
      <Box display="grid" gridGap={3}>
        {fields.map((recommendation, index) => (
          <RecommendationEditItem
            ref={ref}
            key={recommendation.id}
            index={index}
            categoryName={categoryName}
            register={register}
            control={control}
            remove={() => remove(index)}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
            updateImagesToDelete={updateImagesToDelete}
            houseCenter={houseCenter}
            variant={variant}
          />
        ))}
        <StyledButton
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.SMALL}
          onClick={addNewRecommendation}
          type="button"
        >
          {translation["addMore"][selectedLanguage]}
        </StyledButton>
      </Box>
    );
  }
);

RecommendationsEdit.displayName = "RecommendationsEdit";
