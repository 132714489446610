import { Box, Typography } from "components";
import { Button } from "components/Button";
import { pxToRem } from "global/utils";
import styled from "styled-components";
import { COLORS, SIZES } from "theme";
import { ImageBoxProps } from "./HouseImages.d";
import { Image } from "components/Image";

export const MainContainer = styled(Box)`
  margin-left: ${pxToRem(16)};
  margin-right: ${pxToRem(16)};
  margin-bottom: ${pxToRem(46)};
  ${SIZES.media.tablet} {
    margin-left: ${pxToRem(80)};
    margin-right: ${pxToRem(80)};
    margin-bottom: ${pxToRem(46)};
  }
`;

export const BackButton = styled(Button)`
background-color: ${COLORS.background.main};
align-items: center;
border-radius: 50%;
width: ${pxToRem(44)};
height: ${pxToRem(44)};
z-index: 10;
cursor: pointer;
padding: 0;
box-shadow: 0px 8px 20px rgba(209, 206, 206, 0.25);
opacity: 0.8;

${SIZES.media.tablet} {
  margin-top: ${pxToRem(30)};
  margin-bottom: ${pxToRem(18)};
}

&:enabled {
    color: ${COLORS.typography.body};
    background-color: ${COLORS.background.main};
    & svg {
        stroke: ${COLORS.typography.secondary.dark};
    }
  cursor: pointer;
  width: ${pxToRem(44)};
  height: ${pxToRem(44)};
  &:hover {
    color: ${COLORS.typography.body};
    background-color: ${COLORS.background.main};
    & svg {
        stroke: ${COLORS.typography.body};
    }
  }
  &:active {
    color: ${COLORS.typography.stroke};
    background-color: ${COLORS.background.main};
  }
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${pxToRem(21)};
`;

export const ImagesBox = styled(Box)<ImageBoxProps>`
  grid-template-columns: ${({ reverse }) => (reverse ? `1fr 2fr` : `2fr 1fr`)};
  grid-column-gap: 20px;
  grid-row-gap: 32px;
  & img:nth-child(3n) {
    grid-column: 1/-1;
  }
`;

export const ContentBox = styled(Box)`
  grid-row-gap: 32px;
  min-height: 564px;
`;
export const StyledImage = styled(Image)`
  cursor: pointer;
  width: 100%;
  height: 216px;
  ${SIZES.media.tablet} {
    height: 513px;
  }
  ${SIZES.media.desktop} {
    height: 564px;
  }
`;

export const MobileHeaderContainer = styled(Box)`
  position: absolute;
  background-color: ${COLORS.typography.secondary.main};
  height: 62px;
  width: 100%;
  top: 0;
  left: 0;
  & button {
    margin-top: 8px;
    margin-left: 8px;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
  }
`;
