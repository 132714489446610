import { Box } from "components/Box";
import { Button } from "components/Button";
import { ButtonSize, ButtonVariant } from "components/Button/Button.enums";
import { Input } from "components/Input";
import { Modal } from "components/Modal/Modal";
import { Typography } from "components/Typography";
import { forwardRef, useEffect, useState } from "react";
import { CompleteInfoModalProps } from "./CompleteInfoModal.d";
import {
  StyledContainer,
  StyledTitleContainer,
  TopContainer,
  MiddleContainer,
  TabletsHolder,
  GlobalStyle,
} from "./CompleteInfoModal.styled";
import { FormProvider, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Profile } from "types/user.d";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { COLORS } from "theme";
import { PhoneInput } from "components/PhoneInput";
import { profileBasicValidationSchema } from "./validators";
import { Textarea } from "components/Textarea";
import { fetchCountryOptions, fetchUpdateProfile } from "services/Profile";
import { CountryOption } from "types/user.d";
import { Option } from "components/Autocomplete/Autocomplete.d";
import { toast } from "react-toastify";
import { isValidForm } from "utils/isValidForm";
import { useWindowSize } from "global/hooks/useWindowSize";
import { useProfile } from "context/Profile";
import { Autocomplete } from "components/Autocomplete/Autocomplete";
import { AccessVariant } from "../InviteFriendViaEmailModal/InviteFriendViaEmailModal.enums";

export const CompleteInfoModal = forwardRef<
  HTMLDivElement,
  CompleteInfoModalProps
>(
  (
    {
      isShown,
      closeModal,
      closeBookingModals,
      resetCalculator,
      sendBookingRequest,
      isPriceOnRequest,
    },
    ref
  ) => {
    const [countryOptions, setCountryOptions] = useState<Option[]>([
      { label: "", value: "" },
    ]);
    const { isMobile, isTablet } = useWindowSize();
    const profile = useProfile();
    const isSelectAccessType =
      profile?.profileInfo?.access_type === AccessVariant.SELECTED;
    const additionalProfileInfo = profile?.profileInfo?.profile;
    const [selectedCountry, setSelectedCountry] = useState<Option>({
      label: "",
      value: "",
    });
    const methods = useForm<Profile>({
      resolver: yupResolver(profileBasicValidationSchema),
      context: { accessType: profile?.profileInfo?.access_type },
      mode: "onTouched",
    });

    const {
      register,
      control,
      formState: { errors },
      handleSubmit,
      setValue,
    } = methods;

    const changeCountry = (value: Option) => {
      setValue("country", value?.label || "");
      setSelectedCountry(
        value ?? {
          name: "",
          value: "",
        }
      );
    };

    useEffect(() => {
      const getCountries = async () => {
        const response = await fetchCountryOptions();
        const choicesArray: CountryOption[] = response.parsedBody;
        const formattedValue = choicesArray.map((el) => {
          return {
            value: el.value,
            label: el.display_name,
          };
        });
        setCountryOptions(formattedValue);
      };
      getCountries();
    }, []);

    useEffect(() => {
      const assignData = async () => {
        if (profile) {
          setValue("phone_number", additionalProfileInfo?.phone_number ?? "");
          const findSelectedCountry = () => {
            return countryOptions.filter(
              (el) => el.label === additionalProfileInfo?.country
            )[0];
          };
          setValue("city", additionalProfileInfo?.city ?? "");
          setValue("street", additionalProfileInfo?.street ?? "");
          setValue("house", additionalProfileInfo?.house ?? "");
          setValue("postal_code", additionalProfileInfo?.postal_code ?? "");
          setValue("about", additionalProfileInfo?.about ?? "");
          changeCountry(findSelectedCountry());
        }
      };
      assignData();
    }, [countryOptions, isShown, additionalProfileInfo]);

    const onSubmit: SubmitHandler<Profile> = async (data) => {
      const form = {
        ...data,
        country: selectedCountry.label,
      };
      const response = await fetchUpdateProfile({
        ...profile?.profileInfo,
        profile: { ...profile?.profileInfo?.profile, ...form },
      });
      if (response.ok) {
        toast.success("Profile updated successfully");
        closeModal();
        resetCalculator();
        isTablet && closeBookingModals();
        sendBookingRequest();
        profile?.fetchProfileData();
      } else {
        toast.error("There was an error during updating your profile");
      }
    };

    const getSubmitButtonName = () =>
      isPriceOnRequest ? "Send a request for price" : "Send a booking request";

    return (
      <>
        <GlobalStyle />
        <Modal
          withCloseButton
          modalRef={ref}
          isShown={isShown}
          onClose={closeModal}
          px={isMobile ? 2 : 8}
        >
          <StyledContainer>
            <StyledTitleContainer mb={3}>
              <Typography variant="h4">
                Please fill in and check your contact data before sending a
                request
              </Typography>
            </StyledTitleContainer>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete="off"
              >
                <Box display="grid" gridGap={3}>
                  <TopContainer display="grid" gridGap={3}>
                    <PhoneInput
                      id="phone_number"
                      control={control}
                      label="phone number"
                      error={
                        !isValidForm(errors) && errors.phone_number?.message
                      }
                    />
                    {countryOptions.length > 0 && (
                      <Autocomplete
                        {...register("country")}
                        value={selectedCountry}
                        onChange={changeCountry}
                        options={countryOptions}
                        label="country"
                        error={!isValidForm(errors) && errors.country?.message}
                      />
                    )}
                    <Input
                      {...register("city")}
                      label="city"
                      error={!isValidForm(errors) && errors.city?.message}
                    />
                    <Input
                      {...register("postal_code")}
                      label="postal code"
                      error={
                        !isValidForm(errors) && errors.postal_code?.message
                      }
                    />
                  </TopContainer>
                  <MiddleContainer display="grid" gridGap={3}>
                    <Input
                      {...register("street")}
                      label="street"
                      error={!isValidForm(errors) && errors.street?.message}
                    />
                    <Input
                      {...register("house")}
                      label="no"
                      error={!isValidForm(errors) && errors.house?.message}
                    />
                  </MiddleContainer>
                  {!isSelectAccessType && (
                    <Textarea
                      {...register("about")}
                      rows={6}
                      label="about you"
                      helperText="Characters: 200-1000"
                      placeholder={
                        "Here is the profile of one of our members as an example:\n\nSebastian grew up in the Rhineland and is the youngest of three brothers. After completing his international MBA, he started his career in finance and now manages a private equity fund based in Munich. He discovered his passion for Kenya and Africa as a teenager on his first trip to Mount Kenya Game Ranch. He had the time of his life and his love for Africa has never stopped since. Sebastian is passionate about skiing, fly fishing and his."
                      }
                      error={!isValidForm(errors) && errors.about?.message}
                    />
                  )}
                </Box>
                {isTablet ? (
                  <TabletsHolder p={2.3}>
                    <Button
                      variant={ButtonVariant.BOOKING}
                      size={ButtonSize.SMALL}
                      type="submit"
                      disabled={Object.keys(errors).length > 0}
                      icon={
                        <ArrowIcon
                          stroke={COLORS.stroke.main}
                          fill="none"
                          width="16px"
                          height="16px"
                        />
                      }
                    >
                      {getSubmitButtonName()}
                    </Button>
                  </TabletsHolder>
                ) : (
                  <Box
                    display="grid"
                    gridAutoFlow="column"
                    gridGap={4.4}
                    justifyContent="end"
                    alignItems="center"
                    mt={4.5}
                    pb={8}
                  >
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      onClick={closeModal}
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      disabled={Object.keys(errors).length > 0}
                      icon={
                        <ArrowIcon
                          stroke={COLORS.stroke.main}
                          fill="none"
                          width="16px"
                          height="16px"
                        />
                      }
                    >
                      {getSubmitButtonName()}
                    </Button>
                  </Box>
                )}
              </form>
            </FormProvider>
          </StyledContainer>
        </Modal>
      </>
    );
  }
);

CompleteInfoModal.displayName = "CompleteInfoModal";
