import { FC, useEffect, useState } from "react";
import { HouseBookingsProps } from "./HouseBookings.d";
import { BookingCalculator } from "components/Booking/BookingCalculator";
import { Button } from "components/Button";
import { HouseOwnerCalendar } from "components/House/HouseOwnerCalendar";
import { ButtonVariant } from "components/Button/Button.enums";
import { Box } from "components/Box";
import { BookedHouseItem } from "components/Booking/BookedHouseItem";
import {
  StyledButtonContainer,
  TabletsContainer,
  TabletsHolder,
  StyledTabletsBookingsListContainer,
} from "./HouseBookings.styled";
import { ReactComponent as ArrowIcon } from "assets/icons/icon-arrow.svg";
import { COLORS } from "theme";
import { Booking } from "types/house.d";
import { BookedHouseItemProps } from "components/Booking/BookedHouseItem/BookedHouseItem.d";
import { useWindowSize } from "global/hooks/useWindowSize";
import { Divider } from "components/Divider";
import { useCalculator } from "context/Calculator";
import { getLanguage } from "utils/getLanguage";
import translation from "./translation.json";
import { useProfile } from "context/Profile";

export const HouseBookings: FC<HouseBookingsProps> = ({
  isPrivate,
  bookingRequests,
  bookedDates,
  editedBooking,
  setEditedBooking,
  sendBookingRequest,
  maxRequests,
  cap,
  adultsPrice,
  childPrice,
  infantPrice,
  seasonalPrices,
  ...bookingProps
}) => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();
  const { setAdults, setChildren, setInfants, setStartDate, setEndDate } =
    useCalculator();
  const { isMobile, isTablet } = useWindowSize();
  const [isShownBookingCalculator, setIsShownBookingCalculator] =
    useState<boolean>(bookingRequests.length < 1);
  const [isShownMobileModal, setIsShownMobileModal] = useState<boolean>(false);
  const [isShownTabletModal, setIsShownTabletModal] = useState<boolean>(false);
  const isPriceOnRequest = bookingProps.minimumPricePerNight === null;

  useEffect(() => {
    setIsShownBookingCalculator(bookingRequests.length < 1);
  }, [bookingRequests]);

  const handleSendingBookingRequest = () => {
    sendBookingRequest();
    setIsShownBookingCalculator(false);
  };

  const openMobileTabletModal = () => {
    isMobile
      ? setIsShownMobileModal(true)
      : isTablet && setIsShownTabletModal(true);
  };

  const handleCreateNewRequest = () => {
    setIsShownBookingCalculator(true);
    openMobileTabletModal();
  };

  const handleEditRequest = (bookingRequest: BookedHouseItemProps) => {
    setIsShownBookingCalculator(true);
    openMobileTabletModal();
    setEditedBooking(bookingRequest.id);
    setAdults(bookingRequest.adults_num);
    setChildren(bookingRequest.children_num);
    setInfants(bookingRequest.infants_num);
    setStartDate(new Date(bookingRequest.check_in_date));
    setEndDate(new Date(bookingRequest.check_out_date));
  };

  return (
    <>
      {isPrivate ? (
        <HouseOwnerCalendar />
      ) : isShownBookingCalculator ? (
        <BookingCalculator
          bookedDates={bookedDates}
          sendBookingRequest={handleSendingBookingRequest}
          isShownMobileModal={isShownMobileModal}
          setIsShownMobileModal={setIsShownMobileModal}
          isShownTabletModal={isShownTabletModal}
          setIsShownTabletModal={setIsShownTabletModal}
          editedBooking={editedBooking}
          setEditedBooking={setEditedBooking}
          cap={cap}
          adultsPrice={adultsPrice}
          childPrice={childPrice}
          infantPrice={infantPrice}
          seasonalPrices={seasonalPrices}
          {...bookingProps}
        />
      ) : isTablet ? (
        <TabletsHolder>
          <TabletsContainer
            display="grid"
            gridAutoFlow="row"
            alignItems="center"
            px={4}
            py={2.3}
          >
            <StyledTabletsBookingsListContainer mb={2}>
              {bookingRequests.map((bookingRequest: Booking, index: number) => {
                const isLast = index === bookingRequests.length - 1;
                return (
                  <>
                    <BookedHouseItem
                      key={`booked-house-${bookingRequest.id}`}
                      id={bookingRequest.id}
                      is_main_request={index === 0}
                      check_in_date={bookingRequest.check_in_date}
                      check_out_date={bookingRequest.check_out_date}
                      bookingPrice={bookingRequest.price}
                      seasonalPrices={seasonalPrices}
                      regularPrices={{
                        per_adult: adultsPrice,
                        per_child: childPrice,
                        per_baby: infantPrice,
                        minimum_price_per_night:
                          bookingProps.minimumPricePerNight,
                        cap,
                      }}
                      isPriceOnRequest={isPriceOnRequest}
                      discount={bookingProps.discount}
                      onEditClick={handleEditRequest}
                      house={bookingRequest.house}
                      adults_num={bookingRequest.adults}
                      children_num={bookingRequest.children}
                      infants_num={bookingRequest.infants}
                      is_editable={bookingRequest.is_editable}
                      priceWithoutDiscount={
                        bookingRequest.price_without_discount
                      }
                    />
                    {!isLast && <Divider mb={2} mt={2} />}
                  </>
                );
              })}
            </StyledTabletsBookingsListContainer>
            <Button
              variant={ButtonVariant.BOOKING}
              onClick={handleCreateNewRequest}
              disabled={
                maxRequests ? bookingRequests.length >= maxRequests : false
              }
              icon={
                <ArrowIcon
                  stroke={COLORS.stroke.main}
                  fill="none"
                  width="20px"
                  height="20px"
                />
              }
            >
              {translation["createAnotherRequest"][selectedLanguage]}
            </Button>
          </TabletsContainer>
        </TabletsHolder>
      ) : (
        <Box display="grid" gridAutoFlow="row" gridGap={2}>
          {bookingRequests.map((bookingRequest: Booking, index: number) => (
            <BookedHouseItem
              key={`booked-house-${bookingRequest.id}`}
              id={bookingRequest.id}
              is_main_request={index === 0}
              check_in_date={bookingRequest.check_in_date}
              check_out_date={bookingRequest.check_out_date}
              bookingPrice={bookingRequest.price}
              seasonalPrices={seasonalPrices}
              regularPrices={{
                per_adult: adultsPrice,
                per_child: childPrice,
                per_baby: infantPrice,
                minimum_price_per_night: bookingProps.minimumPricePerNight,
                cap,
              }}
              discount={bookingProps.discount}
              isPriceOnRequest={isPriceOnRequest}
              onEditClick={handleEditRequest}
              house={bookingRequest.house}
              adults_num={bookingRequest.adults}
              children_num={bookingRequest.children}
              infants_num={bookingRequest.infants}
              is_editable={bookingRequest.is_editable}
              priceWithoutDiscount={bookingRequest.price_without_discount}
            />
          ))}
          <StyledButtonContainer>
            <Button
              variant={ButtonVariant.BOOKING}
              onClick={handleCreateNewRequest}
              disabled={
                maxRequests ? bookingRequests.length >= maxRequests : false
              }
              icon={
                <ArrowIcon
                  stroke={COLORS.stroke.main}
                  fill="none"
                  width="20px"
                  height="20px"
                />
              }
            >
              {translation["createAnotherRequest"][selectedLanguage]}
            </Button>
          </StyledButtonContainer>
        </Box>
      )}
    </>
  );
};
