import { Box } from "components";
import { Breadcrumbs } from "components/Breadcrumbs";
import Layout from "components/Layout";
import { useProfile } from "context/Profile";
import { FC } from "react";
import { getLanguage } from "utils/getLanguage";
import {
  ContentParagraphContainer,
  HeaderContainer,
  MainContainer,
} from "./CompanyImprint.styled";
import translation from "./translation.json";

export const CompanyImprint: FC = () => {
  const profile = useProfile();
  const selectedLanguage = profile?.selectedLanguage || getLanguage();

  return (
    <Layout>
      <MainContainer variant="div">
        <Breadcrumbs currentPageTitle="Imprint" />
        <HeaderContainer variant="h1">
          {translation["imprint"][selectedLanguage]}
        </HeaderContainer>
        <Box display="grid" gridGap={4}>
          <ContentParagraphContainer variant="body">
            <Box>HOF House of Friends Limited</Box>
            <Box>13 Karaiskakis Street</Box>
            <Box>CY3032 Limassol</Box>
            <Box>Cyprus </Box>
          </ContentParagraphContainer>
          <ContentParagraphContainer variant="body" weight="bold">
            <Box>Telefon: +49 174 691 60 19</Box>
            <Box>Email: contact@houseoffriends.world</Box>
          </ContentParagraphContainer>
          <ContentParagraphContainer variant="body">
            <Box>{translation["managingDirector"][selectedLanguage]}</Box>
            <Box>{translation["corporatePurpose"][selectedLanguage]}</Box>
            <Box>
              <Box>{translation["legalForm"][selectedLanguage]}</Box>
              <Box>{translation["vat"][selectedLanguage]}</Box>
            </Box>
          </ContentParagraphContainer>
        </Box>
      </MainContainer>
    </Layout>
  );
};
