import styled from "styled-components";
import { Box, Typography } from "components";
import { COLORS, SIZES } from "theme";
import { pxToRem } from "global/utils";

export const StyledHeader = styled(Typography)`
  width: 50%;
  text-align: center;
  letter-spacing: 0.3px;

  ${SIZES.media.tablet} {
    width: 17%;
  }
`;

export const StyledContentContainer = styled.section`
  width: 95%;
  margin: 0 auto ${pxToRem(300)} auto;

  ${SIZES.media.tablet} {
    width: 39%;
  }
`;

export const StyledInputsContainer = styled(Box)`
  ${SIZES.media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledLink = styled.a`
  color: ${COLORS.typography.headline};

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;
