import styled from "styled-components";
import { COLORS } from "theme";
import { Box } from "components/Box";
import { Typography } from "components/Typography";

type ItemProps = {
  disabled: boolean;
};

export const RadioLabel = styled(Typography)`
  font-family: "Inter";
  font-size: 15px;
`;

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

export const Item = styled(Box)<ItemProps>`
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${COLORS.stroke.main};
  box-sizing: border-box;
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 12px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    ` ~ ${RadioButtonLabel} {
        background: ${COLORS.stroke.main};
      }
      cursor: unset;
  `}

  ${({ checked, disabled }) =>
    checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      background: white;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin: 5px;
        background: ${disabled ? COLORS.stroke.main : COLORS.accent.primary};
      }
    }
  `}
`;
